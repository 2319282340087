import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import * as Config from '../../config.js';
import { createBrowserHistory } from 'history';

import ReactChartkick, { ColumnChart } from 'react-chartkick';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Showdate from './includes/Showdate';
import Helmet from 'react-helmet';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
import Pagination from "react-js-pagination";

const axios = require('axios');
var moment = require('moment');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');

class TaiChinhTonKho extends Component {

    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
            startDate: moment().format('YYYY-MM-01'),
            endDate: moment().format('YYYY-MM-DD'),
            from: undefined,
            to: undefined,
            show: false,
        };
        this.handlePageChange = this.handlePageChange.bind(this);

        this.Chart = this.Chart.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }
    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }

    Chart() {
        return (
            <div className="next-card next-card--primary">
                <div id="reports-chart" className="reports-chart" style={{ opacity: 1 }}>
                    <section className="next-card__section">
                        <ColumnChart donut={true} thousands="." decimal="," data={this.state.chartData} />
                    </section>
                </div>
            </div>
        )
    }
    show() {
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }

    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        }, () => { this.loadData(); this.show() });
    }
    onDateRangeSelectChange(event) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate = moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-') + moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate = moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate = moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate = moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;

            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        }, () => { this.loadData() }
        )
    }
    showCalendar() {
        const from = new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{ marginBottom: 10, width: 350 }}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> -
                <span className="InputFromTo-to">
                    <DayPickerInput
                        ref={el => (this.to = el)}
                        value={to}
                        placeholder="To"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers,
                            month: to,
                            months: Config.MONTHS,
                            numberOfMonths: 2,
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }


    // Hàm

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate = (prevProps, prevState) => {

        const query = {
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
            {
                pathname: '/tai-chinh-ton-kho',
                search: searchString,
            }
        )

        // Get data
        if (prevState.page !== this.state.page || this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate) {

            this.setState({
                loading: true
            })
            var startDate = '';
            if (this.state.startDate) {
                startDate = this.state.startDate;
            }
            var endDate = '';
            if (this.state.endDate) {
                endDate = this.state.endDate;
            }

            this.loadData();
            // End Get data



        }
    }

    loadData() {
        let token = localStorage.getItem('token');

        axios.get(Config.API_URL + 'tai-chinh-ton-kho?token=' + token + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + "&page=" + this.state.page)
            .then(response => {
                // console.log(response.data.data);

                this.setState({
                    data: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            });


    }
    // End hàm

    render() {
        // console.log( this.state.data + 'hi');

        return (
            <div>
                <div id="content">
                    <div id="dashboard" className="container-fluid-md">
                        <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">
                            <div className="ui-title-bar">
                                <div className="ui-title-bar__navigation">
                                    <div className="ui-breadcrumbs">
                                        <Link className="ui-button ui-button--transparent ui-breadcrumb" to="/analytics">
                                            <svg className="next-icon next-icon--size-20 next-icon--no-nudge">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" />
                                            </svg><span className="ui-breadcrumb__item">Báo cáo</span></Link>
                                    </div>
                                    <div className="ui-title-bar__pagination" />
                                </div>
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group">
                                        <h1 className="ui-title-bar__title">Tài chính tồn kho</h1></div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div id="reportrangereceived" className="form-group" style={{ position: 'relative' }}>
                                                <span onClick={this.show} style={{ position: 'absolute', top: '5px', width: '100%' }}>
                                                    {moment(this.state.startDate).format('DD/MM/YYYY')} đến {moment(this.state.endDate).format('DD/MM/YYYY')}</span>
                                                <i onClick={this.show} className="fa fa-calendar" style={{ float: 'right', paddingTop: '7px', paddingLeft: '7px' }}></i>
                                                {this.state.show ? <this.showCalendar /> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="next-card next-card--primary" style={{ position: 'relative' }}>
                                <section className="next-card__section">
                                    <div id="report-table">
                                        <table className="expanded st">
                                            <thead>
                                                <tr>
                                                    <th className="reports-measure is-sortable"><span className="field">Cập nhật</span></th>
                                                    <th className="reports-property is-sortable"><span className="field">Tiền đang nhập</span></th>
                                                    <th className="reports-property is-sortable"><span className="field">Sp đang nhập</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Tiền tồn kho</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Sp tồn kho</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Tiền đã gửi</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Sp đã gửi</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Tiền t/c</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Sp t/c</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Tiền hoàn</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Sp hoàn</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Nợ ADS</span></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.map((value, key) => {
                                                        return <tr key={key} className="data-row" style={{ opacity: 1 }}>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.ctime}</span></div>
                                                            </td>
                                                            <td className="reports-property">
                                                                <div className="reports-property-content--drillable"><span className="value">{value.tien_dang_ve}</span></div>
                                                            </td>
                                                            <td className="reports-property">
                                                                <div className="reports-property-content--drillable"><span className="value">{value.san_pham_dang_ve}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.tien_trong_kho}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.san_pham_trong_kho}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.tien_da_gui}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.san_pham_da_gui}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.tien_da_gui_thanh_cong}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.san_pham_da_gui_thanh_cong}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.tien_hoan}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.san_pham_hoan}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.ads}</span></div>
                                                            </td>

                                                        </tr>
                                                    })
                                                }

                                            </tbody>
                                            {/* <tfoot>
                                                <tr>
                                                    <th className="reports-property is-sortable"><span className="field">TỔNG</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">28.624.523</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">317</span></th>
                                                </tr>
                                            </tfoot> */}
                                        </table>
                                    </div>
                                </section>

                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default TaiChinhTonKho;