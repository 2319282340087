import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Modal from 'react-responsive-modal';
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 
class QuanLyKho extends Component {
    constructor(props) {
        super(props);
        this.state = {
          trangThai: '',
          search: '',
          loading: true,
          dateFrom: '',
          dateTo: '',
          website: '',
          khac: '',
          data: [],
          activePage: 0,
          itemsCountPerPage: 0,
          totalItemsCount: 0,
          pageRangeDisplayed: 5,
          page:1,
          nhanthanhtoan:false,
          Loader: false,
          openChuanBi: false, // Modal
          noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
          t_id: '',
          so_luong: '',
        };
        this.handlePageChange = this.handlePageChange.bind(this);
      }

      handlePageChange(pageNumber) {
        this.setState({
          page:pageNumber
        })
        window.scrollTo(0, 0);
      }


      timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
          [name]: value
        });
    
    
      }



      componentWillMount() {
        this.setState({
          trangThai: queryString.parse(window.location.search).trangThai,
          search: queryString.parse(window.location.search).search,
          website: queryString.parse(window.location.search).website,
          dateFrom: queryString.parse(window.location.search).dateFrom,
          dateTo: queryString.parse(window.location.search).dateTo,
          khac: queryString.parse(window.location.search).khac,
          page: queryString.parse(window.location.search).page,
        })
      }
    
    
    
      
      componentDidUpdate = (prevProps, prevState) => {
    
        const query = {
          trangThai: this.state.trangThai,
          search: this.state.search,
          website: this.state.website,
          dateFrom: this.state.dateFrom,
          dateTo: this.state.dateTo,
          khac: this.state.khac,
          page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
          {
            pathname: '/quan-ly-kho',
            search: searchString,
          }
        )
    
        // Get data
        if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan) {
    
    
          if(prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
            this.setState({
              page:1
            })
          }
          this.setState({
            loading: true
          })
          var trangThai = '';
          if (this.state.trangThai) {
            trangThai = this.state.trangThai;
          }
          var search = '';
          if (this.state.search) {
            search = this.state.search;
          }
          var website = '';
          if (this.state.website) {
            website = this.state.website;
          }
          var khac = '';
          if (this.state.khac) {
            khac = this.state.khac;
          }
          var dateFrom = '';
          if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
          }
          var dateTo = '';
          if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
          }
          let token = localStorage.getItem('token');
          axios.get(Config.API_URL + 'products-admin?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
              this.setState({
                  items: response.data.data,
                  loading: false,
                  activePage: response.data.current_page,
                  itemsCountPerPage: response.data.per_page,
                  totalItemsCount: response.data.total,
                  path: response.data.path,
            })
          })
            .catch(function (error) {
              // this.setState({ loading: false });
              console.log(error);
            });
    
        }
        // End Get data
    
    
      }

      componentDidMount = () => {
        this.setState({
          loading: true
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
           website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
         khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.dateFrom) {
           dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = '';
        if (this.state.dateTo) {
          dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }
    
        
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'products-admin?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
              this.setState({
                  items: response.data.data,
                  loading: false,
                  activePage: response.data.current_page,
                  itemsCountPerPage: response.data.per_page,
                  totalItemsCount: response.data.total,
                  path: response.data.path,
            })
          })
          .catch(function (error) {
            console.log(error);
          });
    
    
        // End Get data


        axios.get(Config.API_URL + 'dem-so-can-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
            .then(responsedem => {

                this.setState({
                    demsodon: responsedem.data,
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    
    
      }

    delProduct(id) {
        var ma_don = '';
        if (this.state.ma_don) {
            var ma_don = this.state.ma_don;
        }
        var ma_giao_dich = '';
        if (this.state.ma_giao_dich) {
            var ma_giao_dich = this.state.ma_giao_dich;
        }
        var date_from = '';
        if (this.state.date_from) {
            var date_from = Moment(this.state.date_from).format('DD-MM-YYYY');
        }
        var date_to = '';
        if (this.state.date_to) {
            var date_to = Moment(this.state.date_to).format('DD-MM-YYYY');
        }
        var trangThai = '';
        if (this.state.trangThai) {
            var trangThai = this.state.trangThai;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'delItems?masp=' + id + "&token=" + token)
            .then(response => {
                axios.get(Config.API_URL + 'products-admin?ma_don=' + ma_don + '&ma_giao_dich=' + ma_giao_dich + '&date_from=' + date_from + '&date_to=' + date_to + '&trangThai=' + trangThai + '&page=' + this.state.page + "&token=" + token)
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            loading: false,
                            path: response.data.path,
                        });
                    });
            });
    }


    
// Chuẩn bị
onOpenModalChuanBi = (id,modelid,title,name) => {


    
    this.setState({ 
        openChuanBi: true,
        id: id,
        modelid: modelid,
        title: title,
        name: name,
       });
    
  };
  
  onCloseModalChuanBi = () => {
    this.setState({ openChuanBi: false });
  };
  // Mở Modal

  
    capNhat() {
        if (this.state.so_luong) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.soluong = this.state.so_luong;
            order.id = this.state.id;
            order.modelid = this.state.modelid;
            order.title = this.state.title;
            order.search = this.state.search;
            order.ly_do = this.state.ly_do;

            if (!this.state.modelid || !this.state.so_luong || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                    openChuanBi: false

                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-ton-kho?token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }

    truDi() {
        if (this.state.so_luong) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.soluong = this.state.so_luong;
            order.id = this.state.id;
            order.modelid = this.state.modelid;
            order.title = this.state.title;
            order.search = this.state.search;
            order.ly_do = this.state.ly_do;

            if (!this.state.modelid || !this.state.so_luong || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                    openChuanBi: false

                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-ton-kho?type=trudi' + '&token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }


    themVao() {
        if (this.state.so_luong) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.soluong = this.state.so_luong;
            order.id = this.state.id;
            order.modelid = this.state.modelid;
            order.title = this.state.title;
            order.search = this.state.search;
            order.ly_do = this.state.ly_do;

            if (!this.state.modelid || !this.state.so_luong || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                    openChuanBi: false

                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-ton-kho?type=themvao' + '&token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                        });
                        var redirectWindow = window.open(Config.API_URL+"in-nhan?id=" + this.state.id + "&soluong=" + this.state.so_luong + "&ghichu=", '_blank');
                        redirectWindow.focus();
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }

  isChange = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({
        [name]: value
    });
}


updateGiuCho(id) {
    let token = localStorage.getItem('token');
    
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }

        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var activePage = '';
        if (this.state.activePage) {
            activePage = this.state.activePage;
        }

    var order = {};
    order.id = id;
    order.search = this.state.search;
    order.activePage = this.state.activePage;
        
    axios.post(Config.API_URL + 'cap-nhat-giu-cho?trangThai=' +trangThai+ '&search='+search+'&website=&dateFrom=&dateTo=&khac=&page='+this.state.activePage+'&token=' + token, qs.stringify(order))
        .then(response => {
            //
            var trangThai = 'tat_ca';
        if (this.state.trangThai) {
            trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
            search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
            website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
            khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.startDate) {
            dateFrom = this.state.startDate;
        }
        var dateTo = '';
        if (this.state.endDate) {
            dateTo = this.state.endDate;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'products-admin?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
        .then(response => {
            this.setState({
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
            });
        })
        .catch(function (error) {
            // this.setState({ loading: false });
            console.log(error);
        });
            //
        })
        .catch(function (error) {
            console.log(error);
            alert("Lỗi")
        });
}

chonTrangThai(e) {
    this.setState({
        trangThai: e,
    })
}


componentDidUpdate = (prevProps, prevState) => {

    const query = {
        trangThai: this.state.trangThai,
        search: this.state.search,
        website: this.state.website,
        dateFrom: this.state.dateFrom,
        dateTo: this.state.dateTo,
        khac: this.state.khac,
        page: this.state.page,
    };
    const searchString = qs.stringify(query);
    history.push(
        {
            pathname: '/quan-ly-kho',
            search: searchString,
        }
    )

    // Get data
    if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan || this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate) {


        if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
            this.setState({
                page: 1
            })
        }
        this.setState({
            loading: true
        })
        var trangThai = 'tat_ca';
        if (this.state.trangThai) {
            trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
            search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
            website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
            khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.startDate) {
            dateFrom = this.state.startDate;
        }
        var dateTo = '';
        if (this.state.endDate) {
            dateTo = this.state.endDate;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'products-admin?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
        .then(response => {
            this.setState({
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
            });
        })
        .catch(function (error) {
            // this.setState({ loading: false });
            console.log(error);
        });


       

        axios.get(Config.API_URL + 'dem-so-can-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
            .then(responsedem => {

                this.setState({
                    demsodon: responsedem.data,
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    // End Get data




// Check trùng
if (prevState.data !== this.state.data) {
var sdt_khach = [];
this.state.data.dontongid.data.map((valueOrder, key) => {

  JSON.parse(JSON.stringify(this.state.data.don_tong))[valueOrder.id].map((value, key) => {
    
    
    if (sdt_khach[key] && sdt_khach[key] === value.phone) {
                                      
    }else{
      sdt_khach.push(value.phone);
    }


  })

  
})

let token = localStorage.getItem('token');
axios.post(Config.API_URL + 'check-trung?token=' + token, qs.stringify({
  'product': sdt_khach,
}))

  .then(response => {

    var result = Object.keys(response.data.check_trung).map(function(key) {
      return [Number(key), response.data.check_trung[key]];
    });

    this.setState({
      check_trung: result,
      loading: false,
    });

  })
  .catch(() => {
    this.setState({ loading: false });
    // alert('Có lỗi xảy ra')
  });


}
//  End Check trùng

}

    render() {

        // Modal
    const { openChuanBi } = this.state;
    // Modal

        return (
            <div id="content">
                
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    <h1 className="ui-title-bar__title">Quản lý tồn kho</h1>
                                </div>
                                <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                    <div className="action-bar__item action-bar__item--link-container">
                                    </div>
                                    <div className="ui-title-bar__mobile-primary-actions">
                                        <div className="ui-title-bar__actions">
                                            <Link to="/add-product" className="ui-button ui-button--primary ui-title-bar__action">Thêm sản phẩm</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                                <div className="ui-title-bar__actions">
                                    <Link to="/add-product" className="ui-button ui-button--primary ui-title-bar__action">Thêm sản phẩm</Link>
                                </div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item"> 
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li onClick={() => this.chonTrangThai('tat_ca')} className="filter-tab-item">
                                                        <a className={this.state.trangThai === 'tat_ca' ? "filter-tab filter-tab-active show-all-items next-tab next-tab--is-active" : "filter-tab next-tab"}>Tất cả (<span id="donmoi">{this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).tat_ca : ""}</span>)</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('can_nhap')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'can_nhap' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Mã cần nhập ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).can_nhap : ""})</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                                <th className="image" />
                                                                <th><span>Sản phẩm</span></th>
                                                                <th><span>Số ngày</span></th>
                                                                <th><span>TB ngày</span></th>
                                                                <th><span>Tồn Kho</span></th>
                                                                <th><span>Đã bán</span></th>
                                                                <th><span>Giữ chỗ</span></th>
                                                                <th><span>Khả dụng</span></th>
                                                                <th><span>Đang về</span></th>
                                                                <th><span>Đang hoàn</span></th>
                                                                <th><span>Cần nhập</span></th>
                                                                <th><span>Trọng lượng</span></th>
                                                                <th><span>In nhãn</span></th>
                                                                <th><span>Kiểm kho</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.items?
                                                                this.state.items.map( (value,key)=>{
                                                                    var html = "<img src="+value.image+" width='400px' />"
                                                                    return <tr key={key}>
                                                                    
                                                                    <td>
                                                                    {/* <p data-tip="hello world"><img title={value.title} className="aspect-ratio__content" src={value.image} alt={value.title} /></p> */}
                                                            
                                                                        <a className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" data-tip={html}>
                                                                        
                                                                        <img title={value.title} className="aspect-ratio__content" src={value.image} alt={value.title} />
                                                                        <ReactTooltip html={true} place={'right'}/>
                                                                        </a>
                                                                    </td>
                                                                    <td className="name">
                                                                        <div className="ui-stack ui-stack--wrap">
                                                                            <div className="ui-stack-item">
                                                                                <Link to={"lich-su-nhap-kho?id="+value.modelid} >{value.title} - {value.modelid}</Link>
                                                                                <p>{value.name} ({value.ngay_cap_nhat})</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="total">
                                                                        <p>{value.so_ngay_nhap}</p>
                                                                    </td>
                                                                    <td className="total">
                                                                        <p>{value.tb_ngay}</p>
                                                                    </td>
                                                                    <td className="vendor">
                                                                        <p><b>{value.stock}</b></p>
                                                                    </td>
                                                                    <td className="type">
                                                                        <p>{value.sold}</p>
                                                                    </td>
                                                                    <td className="type">
                                                                        <p onClick={()=>this.updateGiuCho(value.modelid)}>{value.giu_cho} <i className="fa fa-retweet" aria-hidden="true" /></p>
                                                                        
                                                                    </td>
                                                                    <td className="type">
                                                                        <p><b>{
                                                                            value.stock-value.giu_cho >=1 ?
                                                                            <span style={{color: 'blue'}}>{value.stock-value.giu_cho}</span>
                                                                            :
                                                                            <span style={{color: 'red'}}>{value.stock-value.giu_cho}</span>
                                                                            }</b></p>
                                                                    </td>
                                                                    
                                                                    <td className="vendor">
                                                                        <p>{value.dang_ve}</p>
                                                                    </td>

                                                                    <td className="vendor">
                                                                        <p>{value.so_don_dang_hoan}</p>
                                                                    </td>


                                                                    <td className="vendor">
                                                                        <p>{value.can_nhap}</p>
                                                                    </td>

                                                                    <td className="vendor">
                                                                        <p>{value.trong_luong}</p>
                                                                    </td>

                                                                        <td class="total">
                                                                            <a target="_blank" href={Config.API_URL+"in-nhan?id=" + value.id + "&soluong=" + (value.stock-value.giu_cho) + "&ghichu="}>
                                                                                <label class="badge badge--status-warning badge--progress-complete">In Nhãn</label>
                                                                            </a>
                                                                        </td>

                                                                    <td class="total"><label onClick={()=>this.onOpenModalChuanBi(value.id,value.modelid,value.title,value.name)} class="badge badge--status-success badge--progress-complete">Kiểm kho</label></td>
                                                                   

                                                                </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Chuẩn bị */}
           <Modal open={openChuanBi} onClose={this.onCloseModalChuanBi} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content" style={{ 'width': '450px' }}>
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Kiểm kho</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">
                                    <label class="next-label">Sản phẩm: {this.state.title} / {this.state.modelid} / {this.state.name}</label>
                                    <label class="next-label">Tồn kho: </label>

                                    <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số lượng" name="so_luong" type="text"/>
                                    <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập lý do" name="ly_do" type="text"/>

                                </div>
                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">

                                <div className="ui-modal__secondary-actions">
                                    <div className="button-group">
                                        <button onClick={() => this.capNhat()} className="ui-button close-modal" type="button">Cập nhật làm tồn kho</button>
                                    </div>
                                </div>


                                <div className="ui-modal__secondary-actions">
                                    <div className="button-group">
                                        <button onClick={() => this.truDi()} className="ui-button close-modal" type="button">Trừ đi</button>
                                    </div>
                                </div>
                                <div className="ui-modal__primary-actions">
                                    <button onClick={() => this.themVao()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Thêm vào</button>
                                </div>

                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}


            </div>

        );
    }
}

export default QuanLyKho;