import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Modal from 'react-responsive-modal';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
import ReactChartkick, { ColumnChart } from 'react-chartkick';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Showdate from './includes/Showdate';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import { LamTronNghin } from '../../functions/function.js';
var moment = require('moment');
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 
const danhMuc = [
    { value: '', label: 'Tất cả kho' },
    { value: '298196550963488', label: 'Gia Dụng Nguyễn Dương' },
    { value: '100045055118383', label: 'Dương Thị Thảo' },
    { value: '869612199871909', label: 'Gia Dụng Thanh Thanh' },
    { value: '100028022222272', label: 'Hoàng Văn Nội' },
    { value: '100014618620831', label: 'Kho Huyền Công' },
    { value: '652866278427324', label: 'Gia Dụng Thảo Nhung' },
    { value: '100037043449348', label: 'Huyền Tây' },
    { value: '107837004292968', label: 'Thanh Hoa' },
];
class SanPhamTongKho extends Component {
    constructor(props) {
        super(props);
        this.state = {
          trangThai: '',
          search: '',
          loading: true,
          dateFrom: '',
          dateTo: '',
          website: '',
          khac: '',
          data: [],
          activePage: 0,
          itemsCountPerPage: 0,
          totalItemsCount: 0,
          pageRangeDisplayed: 5,
          page:1,
          nhanthanhtoan:false,
          Loader: false,
          openChuanBi: false, // Modal
          noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
          t_id: '',
          so_luong: '',
          startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          endDate:  moment().format('YYYY-MM-DD'),
        danhmuc:{ value: '', label: 'Tất cả kho' },
        time: '',
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
      }

      danhMuc = (e) => {
        this.setState({
          danhmuc: e,
          kho: e.value,
        });
      }

      handlePageChange(pageNumber) {
        this.setState({
          page:pageNumber
        })
        window.scrollTo(0, 0);
      }

      show() {
        this.setState({
            show: !this.state.show
        });
    }

      handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }

    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        }, () => { this.loadData(); this.show() });
    }
    onDateRangeSelectChange(event) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate = moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-') + moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate = moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate = moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate = moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;

            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        }, () => { this.loadData() }
        )
    }
    showCalendar() {
        const from = new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{ marginBottom: 10, width: 350 }}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> -
                <span className="InputFromTo-to">
                    <DayPickerInput
                        ref={el => (this.to = el)}
                        value={to}
                        placeholder="To"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers,
                            month: to,
                            months: Config.MONTHS,
                            numberOfMonths: 2,
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }

      timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
          [name]: value
        });
    
    
      }


      componentWillMount() {
        // this.reLoad();
        this.setState({
          trangThai: queryString.parse(window.location.search).trangThai,
          search: queryString.parse(window.location.search).search,
          kho: queryString.parse(window.location.search).kho,
          dateFrom: queryString.parse(window.location.search).dateFrom,
          dateTo: queryString.parse(window.location.search).dateTo,
          khac: queryString.parse(window.location.search).khac,
          page: queryString.parse(window.location.search).page,
        })
      }
      reLoad(){
        for (let i = 0; i < 100; i++) {
            setTimeout(() => {
                this.loadData();
            }, i * 1000*60*5);//5 phút
        }
      }
      loadData(){
        this.setState({
            loading: true,
            items:[],
          })
          var trangThai = '';
          if (this.state.trangThai) {
            trangThai = this.state.trangThai;
          }
          var search = '';
          if (this.state.search) {
            search = this.state.search;
          }
          var kho = '';
          if (this.state.danhmuc.value) {
            kho = this.state.danhmuc.value;
          }
          var khac = '';
          if (this.state.khac) {
            khac = this.state.khac;
          }
          var dateFrom = '';
          if (this.state.startDate) {
            dateFrom = (dateFormat(this.state.startDate, "dd-mm-yyyy"));
          }
          var dateTo = '';
          if (this.state.endDate) {
            dateTo = (dateFormat(this.state.endDate, "dd-mm-yyyy"));
          }
          let token = localStorage.getItem('token');
          axios.get(Config.API_URL + 'danh-sach-san-pham-kho?trangThai=' + trangThai + '&search=' + search + '&kho=' + kho + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
              this.setState({
                  items: response.data.data,
                  loading: false,
                  activePage: response.data.current_page,
                  itemsCountPerPage: response.data.per_page,
                  totalItemsCount: response.data.total,
                  path: response.data.path,
                  time: new Date().getTime(),
            })
          })
            .catch(function (error) {
              // this.setState({ loading: false });
              console.log(error);
            });

            axios.get(Config.API_URL + 'dem-da-dang?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&kho=' + kho + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
            .then(responsedem => {

                this.setState({
                    demsodon: responsedem.data,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
            
      }
    
      
      
     
      componentDidMount = () => {
        this.setState({
          loading: true
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }
        var kho = '';
        if (this.state.kho) {
            kho = this.state.kho;
        }
        var khac = '';
        if (this.state.khac) {
         khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.startDate) {
           dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = '';
        if (this.state.endDate) {
          dateTo = (dateFormat(this.state.endDate, "dd-mm-yyyy"));
        }
    
        
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'danh-sach-san-pham-kho?trangThai=' + trangThai + '&search=' + search + '&kho=' + kho + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
              this.setState({
                  items: response.data.data,
                  loading: false,
                  activePage: response.data.current_page,
                  itemsCountPerPage: response.data.per_page,
                  totalItemsCount: response.data.total,
                  path: response.data.path,
            })
          })
          .catch(function (error) {
            console.log(error);
          });
    
    
        // End Get data


        axios.get(Config.API_URL + 'dem-da-dang?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&kho=' + kho + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
            .then(responsedem => {

                this.setState({
                    demsodon: responsedem.data,
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    
    
      }

    delProduct(id) {
        var ma_don = '';
        if (this.state.ma_don) {
            var ma_don = this.state.ma_don;
        }
        var ma_giao_dich = '';
        if (this.state.ma_giao_dich) {
            var ma_giao_dich = this.state.ma_giao_dich;
        }
        var date_from = '';
        if (this.state.date_from) {
            var date_from = Moment(this.state.date_from).format('DD-MM-YYYY');
        }
        var date_to = '';
        if (this.state.date_to) {
            var date_to = Moment(this.state.date_to).format('DD-MM-YYYY');
        }
        var trangThai = '';
        if (this.state.trangThai) {
            var trangThai = this.state.trangThai;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'delItems?masp=' + id + "&token=" + token)
            .then(response => {
                axios.get(Config.API_URL + 'danh-sach-san-pham-kho?ma_don=' + ma_don + '&ma_giao_dich=' + ma_giao_dich + '&date_from=' + date_from + '&date_to=' + date_to + '&trangThai=' + trangThai + '&page=' + this.state.page + "&token=" + token)
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            loading: false,
                            path: response.data.path,
                        });
                    });
            });
    }


    
// Chuẩn bị
onOpenModalChuanBi = (id,name) => {


    this.setState({ 
        openChuanBi: true,
        id: id,
        name: name,
       });

      
    
  };
  
  onCloseModalChuanBi = () => {
    this.setState({ openChuanBi: false });
  };
  // Mở Modal
  isChangeMaSp = (id) => {
  
    this.setState({
        maspdang:id
    });
}
  
    capNhat(id) {
        alert(this.state.maspdang);
        if (this.state.link_dang) {
            var order = {};

            order.link_dang = this.state.link_dang;
            order.id = this.state.id;
            order.ghi_chu = this.state.ghi_chu;

            this.setState({
                loading: true,
                openChuanBi: false

            })
            let token = localStorage.getItem('token');
            axios.post(Config.API_URL + 'cap-nhat-link-dang?token=' + token, qs.stringify(order))
                .then(response => {
                    this.setState({
                        loading: false,
                       
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    alert("Lỗi")
                });
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }

   

  isChange = (event,id) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({
        [name]: value,
        id
    });
}


_handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        loading: true,
        items:[],
      })
      // Get data
      var trangThai = '';
      if (this.state.trangThai) {
        trangThai = this.state.trangThai;
      }
      var search = '';
      if (this.state.search) {
        search = this.state.search;
      }
      var website = '';
      if (this.state.website) {
         website = this.state.website;
      }
      var khac = '';
      if (this.state.khac) {
       khac = this.state.khac;
      }
      var dateFrom = '';
      if (this.state.dateFrom) {
         dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
      }
      var dateTo = '';
      if (this.state.dateTo) {
        dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
      }
      var kho = '';
      if (this.state.kho) {
          kho = this.state.kho;
      }
      
      let token = localStorage.getItem('token');
      axios.get(Config.API_URL + 'danh-sach-san-pham-kho?trangThai=' + trangThai + '&search=' + search + '&kho=' + kho + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
        .then(response => {
            this.setState({
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
          })
        })
        .catch(function (error) {
          console.log(error);
        });
  
  
        axios.get(Config.API_URL + 'dem-da-dang?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&kho=' + kho + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
        .then(responsedem => {
  
          this.setState({ 
            demsodon: responsedem.data, 
          })
        })
        .catch(function (error) {
          console.log(error);
        });
      // End Get data
    }
  }
  


updateGiuCho(id) {
    let token = localStorage.getItem('token');
    var order = {};
    order.id = id;
    order.search = this.state.search;
    var search = '';
        if (this.state.search) {
          search = this.state.search;
        }

        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }
    
        
    axios.post(Config.API_URL + 'cap-nhat-giu-cho?trangThai=' +trangThai+ '&search='+search+'&website=&dateFrom=&dateTo=&khac=&page='+this.state.activePage+'&token=' + token, qs.stringify(order))
        .then(response => {
            this.setState({
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
            });
        })
        .catch(function (error) {
            console.log(error);
            alert("Lỗi")
        });
}

chonTrangThai(e) {
    this.setState({
        trangThai: e,
    })
}


componentDidUpdate = (prevProps, prevState) => {

    const query = {
        trangThai: this.state.trangThai,
        search: this.state.search,
        kho: this.state.kho,
        dateFrom: this.state.startDate,
        dateTo: this.state.endDate,
        khac: this.state.khac,
        page: this.state.page,
    };
    const searchString = qs.stringify(query);
    history.push(
        {
            pathname: '/san-pham-tong-kho',
            search: searchString,
        }
    )

    // Get data
    if (prevState.danhmuc !== this.state.danhmuc || prevState.trangThai !== this.state.trangThai || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page) {


        if (prevState.trangThai !== this.state.trangThai || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
            this.setState({
                page: 1
            })
        }

        this.loadData();

       

        // axios.get(Config.API_URL + 'dem-so-can-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
        //     .then(responsedem => {

        //         this.setState({
        //             demsodon: responsedem.data,
        //         })
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });



   

    }
    // End Get data






}



    CapNhatGiaNhap = (e) => {
        if (e.key === 'Enter') {
            this.setState({
                loading: true
            })

            if (this.state.id) {

                let token = localStorage.getItem('token');
                axios.get(Config.API_URL + 'gia-nhap-tong-kho?id=' + this.state.id + "&gia_nhap=" + this.state.gia_nhap + "&lai_muon=" + this.state.lai_muon + "&sale=" + this.state.sale + "&flash_sale=" + this.state.flash_sale + "&sieu_sale=" + this.state.sieu_sale + "&token=" + token)
                    .then(response => {

                        // NotificationManager.success('Cập nhật thành công', '', 2000, false);
                        this.loadData();

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

            // End Get data
        }
    }

    DaDang(id){
        let token = localStorage.getItem('token');
      axios.get(Config.API_URL + 'cap-nhat-da-dang?id=' + id + "&token=" + token)
        .then(response => {
            this.loadData();
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    render() {

        // Modal
    const { openChuanBi } = this.state;
    // Modal

        return (
            <div id="content">
                
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    <h1 className="ui-title-bar__title">Sản phẩm tổng kho</h1>

                                    <div className="col-sm-3">
                                            <div id="reportrangereceived" className="form-group" style={{ position: 'relative' }}>
                                                <span onClick={this.show} style={{ position: 'absolute', top: '5px', width: '100%' }}>
                                                    {moment(this.state.startDate).format('DD/MM/YYYY')} đến {moment(this.state.endDate).format('DD/MM/YYYY')}</span>
                                                <i onClick={this.show} className="fa fa-calendar" style={{ float: 'right', paddingTop: '7px', paddingLeft: '7px' }}></i>
                                                {this.state.show ? <this.showCalendar /> : ""}
                                            </div>
                                        </div>

                                       

                                </div>
                                <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                    <div className="action-bar__item action-bar__item--link-container">
                                    </div>
                                    <div className="ui-title-bar__mobile-primary-actions">
                                        <div className="ui-title-bar__actions">
                                        {
                                    this.state.loading?
                                    <a className="ui-button ui-button--primary ui-title-bar__action"><i className='fa fa-spinner fa-spin' style={{ fontSize: 12 }} /></a>
                                    :
                                            <a onClick={()=>this.loadData()} className="ui-button ui-button--primary ui-title-bar__action">Tải lại</a>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                            <div className="ui-title-bar__actions">

                            {this.state.time?
                            moment(this.state.time).fromNow()
                            :
                            ''
                            }
                                        {
                                    this.state.loading?
                                    <a className="ui-button ui-button--primary ui-title-bar__action"><i className='fa fa-spinner fa-spin' style={{ fontSize: 12 }} /></a>
                                    :
                                            <a onClick={()=>this.loadData()} className="ui-button ui-button--primary ui-title-bar__action">Tải lại</a>
                                        }
                                        </div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item"> 
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li onClick={() => this.chonTrangThai('tat_ca')} className="filter-tab-item">
                                                        <a className={this.state.trangThai === 'tat_ca' ? "filter-tab filter-tab-active show-all-items next-tab next-tab--is-active" : "filter-tab next-tab"}>Tất cả (<span id="donmoi">{this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).tat_ca : ""}</span>)</a>
                                                    </li>
                                                  
                                                    <li onClick={() => this.chonTrangThai('da_dang')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'da_dang' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Đã đăng ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).da_dang : ""})</a>
                                                    </li>
                                                </ul>
                                            </div>
                                           
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                            
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                               
                                                    <div className="next-input-wrapper">
                                                    <div className="col-sm-3">
                                            <Select
                                                autofocus="autofocus"
                                                closeMenuOnSelect={false}
                                                components={makeAnimated()}
                                                // isMulti
                                                onChange={this.danhMuc}
                                                options={danhMuc}
                                                placeholder={this.state.danhmuc?this.state.danhmuc.label:'Chọn tổng kho'}
                                            />
                                        </div>
                                                        <div className="next-field__connected-wrapper">
                                                            <div className="next-form next-form--full-width next-field--connected--no-border-radius" >                                                    
                                                            
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onKeyDown={this._handleKeyDown} onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" />
                                                                </div>
                                                            </div>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                          
                                                                <th className="image" />
                                                                <th width="200px"><span>Sản phẩm</span></th>
                                                                <th width="50px"><span>Giá sỉ</span></th>
                                                                <th><span>Giá Nhập</span></th>
                                                                <th><span>Lãi muốn</span></th>
                                                                <th><span>Sale</span></th>
                                                                <th><span>Flash Sale</span></th>
                                                                <th><span>Siêu Sale</span></th>
                                                                <th><span>Action</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.items?
                                                                this.state.items.map( (value,key)=>{
                                                                    var html = "<img src="+value.full_picture+" width='400px' />"
                                                                    return <tr key={key}>
                                                                  
                                                                    <td>
                                                                    {/* <p data-tip="hello world"><img title={value.title} className="aspect-ratio__content" src={value.image} alt={value.title} /></p> */}
                                                            
                                                                        <a className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" data-tip={html}>
                                                                        
                                                                        <img title={value.title} className="aspect-ratio__content" src={value.full_picture} alt={value.title} />
                                                                        <ReactTooltip html={true} place={'right'}/>
                                                                        </a>
                                                                        <p>#{value.ma_san_pham}</p>
                                                                    </td>
                                                                    <td className="name">
                                                                        <div className="ui-stack ui-stack--wrap">
                                                                            <div className="ui-stack-item">
                                                                            <p><a href={"https://shopee.vn/search?page=0&sortBy=sales&keyword=" + value.message.split('\n')[0]} target = "_blank">{value.message.split('\n')[0].substring(0,45)}</a></p>
                                                                            <p><a href={"https://fb.com/"+value.id_post} target = "_blank" >{value.created_time} ({value.created_time?'Đăng '+Math.ceil(( new Date().getTime() - new Date(value.created_time).getTime() )/(1000 * 60 * 60 * 24)) + ' ngày':""})</a></p>
                                                                                <p><a href={"https://fb.com/"+value.id_page} target = "_blank" ><b>{value.name_page}</b></a></p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="total">
                                                                        <p>{value.message.split('\n')[1]?value.message.split('\n')[1].includes("si") || value.message.split('\n')[1].includes("Si") || value.message.split('\n')[1].includes("SỈ") || value.message.split('\n')[1].includes("Sỉ") || value.message.split('\n')[1].includes("sỉ") || value.message.split('\n')[1].includes("x/")?value.message.split('\n')[1].substring(0,20):"":""}</p>
                                                                        <p>{value.message.split('\n')[2]?value.message.split('\n')[2].includes("si") || value.message.split('\n')[2].includes("Si") || value.message.split('\n')[2].includes("SỈ") || value.message.split('\n')[2].includes("Sỉ") || value.message.split('\n')[2].includes("sỉ") || value.message.split('\n')[2].includes("x/")?value.message.split('\n')[2].substring(0,20):"":""}</p>
                                                                        <p>{value.message.split('\n')[3]?value.message.split('\n')[3].includes("si") || value.message.split('\n')[3].includes("Si") || value.message.split('\n')[3].includes("SỈ") || value.message.split('\n')[3].includes("Sỉ") || value.message.split('\n')[3].includes("sỉ") || value.message.split('\n')[3].includes("x/")?value.message.split('\n')[3].substring(0,20):"":""}</p>
                                                                        <p>{value.message.split('\n')[4]?value.message.split('\n')[4].includes("si") || value.message.split('\n')[4].includes("Si") || value.message.split('\n')[4].includes("SỈ") || value.message.split('\n')[4].includes("Sỉ") || value.message.split('\n')[4].includes("sỉ") || value.message.split('\n')[4].includes("x/")?value.message.split('\n')[4].substring(0,20):"":""}</p>
                                                                        {value.check_trung == 1? 
                                                                        <a href={"/san-pham-tong-kho?search="+value.message.split('\n')[0].split(' ').slice(0,4).join(' ')} target="_blank"><p><label class="badge badge--status-success badge--progress-warning">Trùng</label></p> </a>
                                                                        :""}
                                                                    
                                                                    </td>
                                                                    

                                                                    <td className="total">
                                                                        <p>
                                                                        <input onKeyDown={this.CapNhatGiaNhap} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="gia_nhap" defaultValue={value.gia_nhap} />
                                                                        </p>
                                                                        <p>Hòa Vốn: {LamTronNghin((value.gia_nhap+2000)/(1-8/100))}</p>
                                                                    </td>



                                                                    <td className="total">
                                                                        <p>
                                                                        <input onKeyDown={this.CapNhatGiaNhap} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="lai_muon" defaultValue={value.lai_muon} />
                                                                        </p>
                                                                        <p>Giá bán: {LamTronNghin((value.gia_nhap+2000)/(1-(8/100)-(value.lai_muon/100)))}</p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <p>
                                                                        <input onKeyDown={this.CapNhatGiaNhap} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="sale" defaultValue={value.sale} />
                                                                        </p>
                                                                        <p><b>Giá Sale: {LamTronNghin(((value.gia_nhap+2000)/(1-(8/100)-(value.lai_muon/100)))/(1-value.sale/100))}</b></p>
                                                                    </td>
                                                                    
                                                                    
                                                                    <td className="total">
                                                                        <p>
                                                                        <input onKeyDown={this.CapNhatGiaNhap} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="flash_sale" defaultValue={value.flash_sale} />
                                                                        </p>
                                                                        <p>Giá FS: {LamTronNghin(((value.gia_nhap+2000)/(1-(8/100)-(value.lai_muon/100)))/(1-value.sale/100)*(value.flash_sale/100))}</p>
                                                                    </td>


                                                                    <td className="total">
                                                                        <p>
                                                                        <input onKeyDown={this.CapNhatGiaNhap} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="sieu_sale" defaultValue={value.sieu_sale} />
                                                                        </p>
                                                                        <p>Giá SS: {LamTronNghin(((value.gia_nhap+2000)/(1-(8/100)-(value.lai_muon/100)))/(1-value.sale/100)*(1-value.sieu_sale/100))}</p>
                                                                    </td>



                                                                    <td class="total">
                                                                        {
                                                                            value.da_dang?"Đăng rồi":<label onClick={()=>this.DaDang(value.id)} class="badge badge--status-success badge--progress-complete">Đăng bài</label>
                                                                        }
                                                                        
                                                                        <a href={"https://api.roza.vn/htags?id="+value.ma_san_pham+"&key="+value.message.split('\n')[0].split(' ').slice(0,4).join(' ') } target="_blank"><label class="badge badge--status-success badge--progress-complete">Htags</label></a>
                                                                        </td>
                                                                   

                                                                </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Chuẩn bị */}
           <Modal open={openChuanBi} onClose={this.onCloseModalChuanBi} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content" style={{ 'width': '450px' }}>
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Đăng bài</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">
                                    <label class="next-label">Sản phẩm: {this.state.name} - {this.state.id}</label>
                                    <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập link đăng" name="link_dang" type="text"/>
                                    <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập ghi chú" name="ghi_chu" type="text"/>

                                </div>
                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">

                                <div className="ui-modal__primary-actions">
                                    <button onClick={() => this.DaDang(this.state.id)} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Cập nhật</button>
                                </div>

                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}


            </div>

        );
    }
}

export default SanPhamTongKho;