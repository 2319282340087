import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';


import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { lam_tron } from '../../functions/function.js';


const moment = require('moment');
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 
class ViDienTu extends Component {
    constructor(props) {
        super(props);
        this.state = {
          trangThai: '',
          search: '',
          loading: true,
          dateFrom: '',
          dateTo: '',
          website: '',
          khac: '',
          data: [],
          activePage: 0,
          itemsCountPerPage: 0,
          totalItemsCount: 0,
          pageRangeDisplayed: 5,
          page:1,
          nhanthanhtoan:false,
          Loader: false,
          openChuanBi: false, // Modal
          noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
          t_id: '',
          so_luong: '',
        //   startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
          startDate: moment().format('YYYY-MM-01'),
          endDate: moment().format('YYYY-MM-DD'),
        };
        this.handlePageChange = this.handlePageChange.bind(this);

        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
      }

      handlePageChange(pageNumber) {
        this.setState({
          page:pageNumber
        })
        window.scrollTo(0, 0);
      }


      timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
          [name]: value
        });
    
    
      }



      componentWillMount() {
        this.setState({
          trangThai: queryString.parse(window.location.search).trangThai,
          search: queryString.parse(window.location.search).search,
          website: queryString.parse(window.location.search).website,
          dateFrom: queryString.parse(window.location.search).dateFrom,
          dateTo: queryString.parse(window.location.search).dateTo,
          khac: queryString.parse(window.location.search).khac,
          page: queryString.parse(window.location.search).page,
        })
      }
    
    
    
      
      componentDidUpdate = (prevProps, prevState) => {
    
        const query = {
          trangThai: this.state.trangThai,
          search: this.state.search,
          website: this.state.website,
          dateFrom: this.state.dateFrom,
          dateTo: this.state.dateTo,
          khac: this.state.khac,
          page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
          {
            pathname: '/vi-dien-tu',
            search: searchString,
          }
        )
    
        // Get data
        if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan || this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate) {
    
    
          if(prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
            this.setState({
              page:1
            })
          }
          this.setState({
            loading: true
          })
          var trangThai = '';
          if (this.state.trangThai) {
            trangThai = this.state.trangThai;
          }
          var search = '';
          if (this.state.search) {
            search = this.state.search;
          }
          var website = '';
          if (this.state.website) {
            website = this.state.website;
          }
          var khac = '';
          if (this.state.khac) {
            khac = this.state.khac;
          }
          var dateFrom = '';
          if (this.state.startDate) {
            dateFrom = this.state.startDate;
          }
          var dateTo = '';
          if (this.state.endDate) {
            dateTo = this.state.endDate;
          }
          let token = localStorage.getItem('token');
          axios.get(Config.API_URL + 'user/vi-dien-tu?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
            .then(response => {
              this.setState({ 
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
               });
            })
            .catch(function (error) {
              // this.setState({ loading: false });
              console.log(error);
            });

            axios.get(Config.API_URL + 'dem-so-vi-dien-tu?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
            .then(responsedem => {

                this.setState({
                    demsodon: responsedem.data,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    
        }
        // End Get data
    
    
      }

      componentDidMount = () => {
        this.setState({
          loading: true
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
           website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
         khac = this.state.khac;
        }
        var dateFrom =  this.state.startDate;
        if (this.state.dateFrom) {
           dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = this.state.endDate;
        if (this.state.dateTo) {
          dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }
    
        
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'user/vi-dien-tu?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
              this.setState({
                  items: response.data.data,
                  loading: false,
                  activePage: response.data.current_page,
                  itemsCountPerPage: response.data.per_page,
                  totalItemsCount: response.data.total,
                  path: response.data.path,
            })
          })
          .catch(function (error) {
            console.log(error);
          });

        
          axios.get(Config.API_URL + 'dem-so-vi-dien-tu?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
              .then(responsedem => {

                  this.setState({
                      demsodon: responsedem.data,
                  })
              })
              .catch(function (error) {
                  console.log(error);
              });
    
    
        // End Get data
    
    
      }

    delProduct(id) {
        var ma_don = '';
        if (this.state.ma_don) {
            var ma_don = this.state.ma_don;
        }
        var ma_giao_dich = '';
        if (this.state.ma_giao_dich) {
            var ma_giao_dich = this.state.ma_giao_dich;
        }
        var date_from = '';
        if (this.state.date_from) {
            var date_from = Moment(this.state.date_from).format('DD-MM-YYYY');
        }
        var date_to = '';
        if (this.state.date_to) {
            var date_to = Moment(this.state.date_to).format('DD-MM-YYYY');
        }
        var trangThai = '';
        if (this.state.trangThai) {
            var trangThai = this.state.trangThai;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'delItems?masp=' + id + "&token=" + token)
            .then(response => {
                axios.get(Config.API_URL + 'user/vi-dien-tu?ma_don=' + ma_don + '&ma_giao_dich=' + ma_giao_dich + '&date_from=' + date_from + '&date_to=' + date_to + '&trangThai=' + trangThai + '&page=' + this.state.page + "&token=" + token)
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            loading: false,
                            path: response.data.path,
                        });
                    });
            });
    }


    
// Chuẩn bị
onOpenModalChuanBi = (id,modelid,title,name) => {


    
    this.setState({ 
        openChuanBi: true,
        id: id,
        modelid: modelid,
        title: title,
        name: name,
       });
    
  };
  
  onCloseModalChuanBi = () => {
    this.setState({ openChuanBi: false });
  };
  // Mở Modal

  
    capNhat() {
        if (this.state.so_luong) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.soluong = this.state.so_luong;
            order.id = this.state.id;
            order.modelid = this.state.modelid;
            order.title = this.state.title;
            order.search = this.state.search;
            order.ly_do = this.state.ly_do;

            if (!this.state.modelid || !this.state.so_luong || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                    openChuanBi: false

                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-ton-kho?token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }

    truDi() {
        if (this.state.so_luong) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.soluong = this.state.so_luong;
            order.id = this.state.id;
            order.modelid = this.state.modelid;
            order.title = this.state.title;
            order.search = this.state.search;
            order.ly_do = this.state.ly_do;

            if (!this.state.modelid || !this.state.so_luong || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                    openChuanBi: false

                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-ton-kho?type=trudi' + '&token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }


    themVao() {
        if (this.state.so_luong) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.soluong = this.state.so_luong;
            order.id = this.state.id;
            order.modelid = this.state.modelid;
            order.title = this.state.title;
            order.search = this.state.search;
            order.ly_do = this.state.ly_do;

            if (!this.state.modelid || !this.state.so_luong || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                    openChuanBi: false

                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-ton-kho?type=themvao' + '&token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                        });
                        var redirectWindow = window.open(Config.API_URL+"in-nhan?id=" + this.state.id + "&soluong=" + this.state.so_luong + "&ghichu=", '_blank');
                        redirectWindow.focus();
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }

  isChange = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({
        [name]: value
    });
}

chonTrangThai(e) {
    this.setState({
      trangThai: e,
    })
  }

updateGiuCho(id) {
    let token = localStorage.getItem('token');
    var order = {};
    order.id = id;
    order.search = this.state.search;
    axios.post(Config.API_URL + 'cap-nhat-giu-cho?token=' + token, qs.stringify(order))
        .then(response => {
            this.setState({
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
            });
        })
        .catch(function (error) {
            console.log(error);
            alert("Lỗi")
        });
}













show(){
    this.setState({
        show: !this.state.show
    });
}
handleFromChange(from) {
    this.setState({
        startDate: moment(from).format('YYYY-MM-DD')
    });
}

handleToChange(to) {
    this.setState({
        endDate: moment(to).format('YYYY-MM-DD')
    });
}
onDateRangeSelectChange(event){
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    this.show();
    switch (event.target.value) {
        case 'yesterday':
            startDate = endDate =  moment().subtract(1, 'days').format('YYYY-MM-DD');
            break;
        case 'today':
            startDate = endDate =  moment().format('YYYY-MM-DD');
            break;
        case 'last7days':
            startDate =  moment().subtract(7, 'days').format('YYYY-MM-DD');
            endDate =  moment().format('YYYY-MM-DD');
            break;
        case 'last30days':
            startDate =  moment().subtract(30, 'days').format('YYYY-MM-DD');
            endDate =  moment().format('YYYY-MM-DD');
            break;
        case 'last90days':
            startDate =  moment().subtract(90, 'days').format('YYYY-MM-DD');
            endDate =  moment().format('YYYY-MM-DD');
            break;
        case 'lastmonth':
            startDate =  moment().subtract(1, 'months').format('YYYY-MM-01');
            endDate = moment().subtract(1, 'months').format('YYYY-MM-')+moment().subtract(1, 'months').daysInMonth();
            break;
        case 'thismonth':
            startDate =  moment().format('YYYY-MM-01');
            endDate = moment().format('YYYY-MM-DD');
            break;
        case 'thisyear':
            startDate =  moment().format('YYYY-01-01');
            endDate = moment().format('YYYY-MM-DD');
            break;
        case 'lastyear':
            startDate =  moment().subtract(1, 'years').format('YYYY-01-01');
            endDate = moment().subtract(1, 'years').format('YYYY-12-31');
            break;
    
        default:
            break;
    };
    this.setState({
        startDate: startDate,
        endDate: endDate
    })  
}
showCalendar(){
    const from =  new Date(this.state.startDate);
    const to = new Date(this.state.endDate);
    const modifiers = { start: from, end: to };
    return (
        <div className="InputFromTo">
            <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{marginBottom:10,width:350}}>
                <option selected="selected" value="custom">Tùy chọn</option>
                <option value="today">Hôm nay</option>
                <option value="yesterday">Hôm qua</option>
                <option value="last7days">7 ngày trước</option>
                <option value="last30days">30 ngày trước</option>
                <option value="last90days">90 ngày trước</option>
                <option value="lastmonth">Tháng trước</option>
                <option value="thismonth">Tháng này</option>
                <option value="thisyear">Năm nay</option>
                <option value="lastyear">Năm trước</option>
            </select>
            <DayPickerInput
                className="startDate"
                value={from}
                placeholder="From"
                formatDate={formatDate}
                parseDate={parseDate}
                format="DD-MM-YYYY"
                dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to },
                    toMonth: to,
                    modifiers,
                    month: from,
                    months: Config.MONTHS,
                    numberOfMonths: 2,
                    onDayClick: () => this.to.getInput().focus(),
                }}
                onDayChange={this.handleFromChange}
            /> - 
            <span className="InputFromTo-to">
            <DayPickerInput
                ref={el => (this.to = el)}
                value={to}
                placeholder="To"
                formatDate={formatDate}
                parseDate={parseDate}
                format="DD-MM-YYYY"
                dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: to,
                    months: Config.MONTHS,
                    numberOfMonths: 2,
                }}
                onDayChange={this.handleToChange}
            />
            </span>
            <Helmet>
                <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                </style>
            </Helmet>
        </div>
    );
}


    render() {
console.log(this.state.endDate)
        // Modal
    const { openChuanBi } = this.state;
    // Modal

        return (
            <div id="content">
                
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    <h1 className="ui-title-bar__title">
                                        Lịch sử giao dịch:
                                       


                                    </h1>
                                    <div id="reportrangereceived" className="form-group" style={{position:'relative'}}>
                                                <span  onClick={this.show} style={{position:'absolute',top:'5px',width:'100%',fontSize: '18px'}}>{ moment(this.state.startDate).format('DD/MM/YYYY') } đến { moment(this.state.endDate).format('DD/MM/YYYY') }</span>
                                                <i  onClick={this.show} className="fa fa-calendar" style={{float:'right',paddingTop: '7px',paddingLeft:'7px'}}></i>
                                                { this.state.show  ? <this.showCalendar /> : "" }
                                            </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">

                                                <li onClick={() => this.chonTrangThai()} className="filter-tab-item">
                                                        <a className={!this.state.trangThai ? "filter-tab filter-tab-active show-all-items next-tab next-tab--is-active" : "filter-tab next-tab"}>Tất cả ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).tat_ca:"" })</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('hoa_hong')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'hoa_hong' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Thưởng ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).hoa_hong:"" })</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('tru')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'tru' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Trừ ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).tru:"" })</a>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                                <th><span>Đơn hàng</span></th>
                                                                <th><span>Ngày giao dịch</span></th>
                                                                <th><span> Nội dung</span></th>
                                                                <th><span>Số dư đầu</span></th>
                                                                <th><span>Số tiền</span></th>
                                                                <th><span>Số dư cuối</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.items?
                                                                this.state.items.map( (value,key)=>{
                                                                    return <tr>

                                                                        <td className="name">
                                                                            <div className="ui-stack ui-stack--wrap">
                                                                                <div className="ui-stack-item">
                                                                                    <a href={"orders?search=" + value.van_don } target="_blank" >#{value.ma_don}</a>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td className="total">
                                                                            <p>{value.created_at}</p>
                                                                        </td>
                                                                       
                                                                        <td className="total">
                                                                            <p>{value.noi_dung}</p>
                                                                        </td>
                                                                        <td className="total">
                                                                            <p><b>{value.sodudau.toLocaleString()+'₫'}</b></p>
                                                                        </td>
                                                                        <td className="vendor">
                                                                            <p>
                                                                                {
                                                                                    value.kieu == '+' ?
                                                                                        <font color='blue'>+{value.sotien.toLocaleString() + '₫'}</font> 
                                                                                        : 
                                                                                        <font color='red'>-{value.sotien.toLocaleString() + '₫'}</font>
                                                                                }
                                                                            </p>
                                                                            
                                                                        </td>
                                                                        <td className="vendor">
                                                                            <p><b>{value.soducuoi.toLocaleString()+'₫'}</b></p>
                                                                        </td>



                                                                    </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Chuẩn bị */}


            </div>

        );
    }
}

export default ViDienTu;