import React, { Component } from 'react';
import ReactChartkick, { LineChart, PieChart } from 'react-chartkick';

import * as Func from '../../../functions/function.js';
import * as Config from '../../../config.js';
import Doanhthu from './Doanhthu';
const axios = require('axios');

class Loinhuan extends Component {
    constructor(props) {
        super(props);
        this.state = {
          today:[],
          sales: [],
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        };
    }

    loadData(){
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'analytics/sales?startDate='+this.state.startDate+'&endDate='+this.state.endDate+'&token=' + token)
        .then(sales => {
            this.setState({
                sales: JSON.parse(JSON.stringify(sales.data)),
            });
        });
    }

    componentDidMount() {
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'analytics/today?token=' + token)
        .then(today => {
            this.setState({
                today: JSON.parse(JSON.stringify(today.data)),
            });
        });
      
        this.loadData();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.state.startDate || nextProps.endDate != this.state.endDate){
            this.setState({
                startDate: nextProps.startDate ,
                endDate: nextProps.endDate
            },()=>this.loadData())
        }
    }
    render (){
        return (
        <div>

        <div className="section_dasborad_day section">
        <div className="row row_custome">
            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 col_custome padding-right">
                <div className="rows">
                    <div className="wrap_bg_left">
                        <div className="header_left header_title">
                            Tình hình lợi nhuận và kinh doanh toàn cửa hàng
                        </div>
                        <div className="inner_content">
                            <div className="over-view-report" id="overview-chart-container">
                                <LineChart donut={true} discrete="true"  thousands="." decimal=","  data={this.state.sales.chart} />
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 col_custome no_padding_left">
                <div className="rows">
                    <div className="wrap_bg_right">
                        <div className="header_left header_title">
                            Thông tin kinh doanh ngày hôm nay
                        </div>
                        <div className="wrap_content_right">
                            <div className="inner_content">
                                <ul className="customer-and-order">
                                    <li className="customer-and-order-item">
                                        <div className="count _1">
                                            <svg className="customer-buy" style={{width:50,height:50}}>
                                                <use xlinkHref="#customer-buy" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                                <svg id="customer-buy" width="100%" height="100%" x={13} y={13}>
                                                    <g id="Group_1591" data-name="Group 1591" clipPath="url(#clip-path)">
                                                        <g id="Group_1590" data-name="Group 1590" transform="translate(2.182 0)">
                                                        <g id="Group_1589" data-name="Group 1589">
                                                            <path id="Path_4129" data-name="Path 4129" d="M150.375,133.617a.934.934,0,0,0-.171-.056l-3.376-.775v-1.752a5.978,5.978,0,0,0,1.964-4.45V123.1a5.478,5.478,0,0,0-5.421-5.522h-.94a5.478,5.478,0,0,0-5.421,5.522v3.479a5.976,5.976,0,0,0,1.964,4.45v1.752l-3.376.775a.962.962,0,0,0-.171.056,3.939,3.939,0,0,0-2.344,3.621V139.3a1,1,0,0,0,.741.969l1.636.423a31.255,31.255,0,0,0,7.426.89,31.6,31.6,0,0,0,7.468-.893l1.625-.42a1,1,0,0,0,.74-.969v-2.062A3.939,3.939,0,0,0,150.375,133.617Z" transform="translate(-133.083 -117.583)" fill="#928fd2" />
                                                        </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </svg>
                                        </div>
                                        <div className="content-right" data-bind="customer">{this.state.today.khachhang} Khách hàng mới</div>
                                    </li>
                                    <li className="customer-and-order-item">
                                        <div className="count _2">
                                            <svg className="shopping-turn" style={{width:50,height:50}}>
                                                <use xlinkHref="#shopping-turn" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                                <svg id="shopping-turn" width="100%" height="100%" x={13} y={13}>
                                                    <g id="shopping-cart">
                                                        <path id="Path_4152" data-name="Path 4152" d="M250.2,262.2a2.4,2.4,0,1,0,2.4,2.4A2.407,2.407,0,0,0,250.2,262.2ZM243,243v2.4h2.4l4.32,9.12-1.68,2.88a4.264,4.264,0,0,0-.24,1.2,2.407,2.407,0,0,0,2.4,2.4h14.4v-2.4H250.68a.258.258,0,0,1-.24-.24v-.12l1.08-2.04h8.88a2.189,2.189,0,0,0,2.04-1.2l4.32-7.8a.661.661,0,0,0,.24-.6,1.134,1.134,0,0,0-1.2-1.2H248.04l-1.08-2.4Zm19.2,19.2a2.4,2.4,0,1,0,2.4,2.4A2.407,2.407,0,0,0,262.2,262.2Z" transform="translate(-243 -243)" fill="#54cfe8" />
                                                    </g>
                                                </svg>
                                            </svg>
                                        </div>
                                        <div className="content-right" data-bind="allOrder">{this.state.today.donmoi} Đơn mới</div>
                                    </li>
                                    <li className="customer-and-order-item">
                                        <div className="count _3">
                                            <svg className="order-finalize" style={{width:50,height:50}}>
                                                <use xlinkHref="#order-finalize" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                                <svg id="order-finalize" width="100%" height="100%" x={13} y={13}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                        <g id="Group_1579" data-name="Group 1579" transform="translate(0 0)" clipPath="url(#clip-path)">
                                                        <g id="Group_1578" data-name="Group 1578" transform="translate(2.687 0)">
                                                            <g id="Group_1577" data-name="Group 1577">
                                                            <g id="Group_1576" data-name="Group 1576">
                                                                <path id="Path_4119" data-name="Path 4119" d="M136.25,100.157v5.335h5.5Z" transform="translate(-124.066 -99.58)" fill="#5eb858" />
                                                                <path id="Path_4120" data-name="Path 4120" d="M124.752,106.967a1,1,0,0,1-1.015-.985v-6.9H114.6a1,1,0,0,0-1.015.986v21.68a1,1,0,0,0,1.015.985h16.245a1,1,0,0,0,1.015-.985V106.967Zm-3.532,11.1-1.925,1.495a1.039,1.039,0,0,1-1.352-.073l-.77-.748a.966.966,0,0,1,0-1.394,1.037,1.037,0,0,1,1.435,0l.128.124,1.215-.945a1.038,1.038,0,0,1,1.429.154A.969.969,0,0,1,121.22,118.067Zm0-3.942-1.925,1.5a1.039,1.039,0,0,1-1.352-.073l-.77-.748a.965.965,0,0,1,0-1.393,1.037,1.037,0,0,1,1.435,0l.128.124,1.215-.945a1.039,1.039,0,0,1,1.429.154A.968.968,0,0,1,121.22,114.125Zm0-3.942-1.925,1.495a1.037,1.037,0,0,1-1.352-.073l-.77-.748a.965.965,0,0,1,0-1.393,1.037,1.037,0,0,1,1.435,0l.128.124,1.215-.945a1.04,1.04,0,0,1,1.429.154A.969.969,0,0,1,121.22,110.183Zm6.578,8.609h-4.062a.986.986,0,1,1,0-1.971H127.8a.986.986,0,1,1,0,1.971Zm0-3.942h-4.062a.986.986,0,1,1,0-1.971H127.8a.986.986,0,1,1,0,1.971Zm0-3.942h-4.062a.986.986,0,1,1,0-1.971H127.8a.986.986,0,1,1,0,1.971Z" transform="translate(-113.583 -99.083)" fill="#5eb858" />
                                                            </g>
                                                            </g>
                                                        </g>
                                                        </g>
                                                    </svg>
                                                </svg>
                                            </svg>
                                        </div>
                                        <div className="content-right" data-bind="finishedOrder">{this.state.today.hoanthanh} Giao hàng thành công</div>
                                    </li>
                                    <li className="customer-and-order-item">
                                        <div className="count _4">
                                            <svg className="order-return" style={{width:50,height:50}}>
                                                <use xlinkHref="#order-return" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                                <svg id="shopping-turn" width="100%" height="100%" x={13} y={13}>
                                                <g id="shopping-cart">
                                                    <path id="Path_4152" data-name="Path 4152" d="M250.2,262.2a2.4,2.4,0,1,0,2.4,2.4A2.407,2.407,0,0,0,250.2,262.2ZM243,243v2.4h2.4l4.32,9.12-1.68,2.88a4.264,4.264,0,0,0-.24,1.2,2.407,2.407,0,0,0,2.4,2.4h14.4v-2.4H250.68a.258.258,0,0,1-.24-.24v-.12l1.08-2.04h8.88a2.189,2.189,0,0,0,2.04-1.2l4.32-7.8a.661.661,0,0,0,.24-.6,1.134,1.134,0,0,0-1.2-1.2H248.04l-1.08-2.4Zm19.2,19.2a2.4,2.4,0,1,0,2.4,2.4A2.407,2.407,0,0,0,262.2,262.2Z" transform="translate(-243 -243)" fill="#54cfe8" />
                                                </g>
                                            </svg>
                                            </svg>
                                        </div>
                                        <div className="content-right" data-bind="returnOrder">{this.state.today.hoanhang} Đơn hàng trả</div>
                                    </li>
                                    <li className="customer-and-order-item">
                                        <div className="count _5">
                                            <svg className="order-cancel" style={{width:50,height:50}}>
                                                <use xlinkHref="#order-cancel" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                                                                                
                                                <svg id="order-cancel" width="100%" height="100%" x={13} y={13}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx={12} cy={12} r={12} fill="#ff5246" />
                                                        <path id="Path_4151" data-name="Path 4151" d="M39.16,31.847a1.2,1.2,0,0,1-1.692,0L34,28.379l-3.468,3.468a1.2,1.2,0,1,1-1.692-1.692l3.468-3.467L28.84,23.219a1.2,1.2,0,0,1,1.692-1.692L34,25l3.468-3.469a1.2,1.2,0,0,1,1.692,1.692l-3.468,3.469,3.468,3.468A1.216,1.216,0,0,1,39.16,31.847Z" transform="translate(-22 -14.688)" fill="#fff" />
                                                    </svg>
                                                </svg>
                                            </svg>
                                        </div>
                                        <div className="content-right" data-bind="canceledOrder">{this.state.today.donhuy} Đơn hàng hủy</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    <Doanhthu sales={this.state.sales}/>

    </div>
        )
    }
}


export default Loinhuan;