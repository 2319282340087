import React, { Component } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";
import * as Config from '../../config.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const qs = require('qs');

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            ghinho: '',
            isload: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        var { email, password, ghinho } = this.refs;
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT fefege...'
        }

        this.setState({ isload: true });
        axios.post(Config.API_URL + 'login', qs.stringify({
            email: email.value,
            password: password.value,
            ghinho: ghinho.value
        }, { "headers": headers }))
            .then(response => {
                console.log(response.data);
                
                if (JSON.parse(JSON.stringify(response.data)).response === 'error') {
                    if (JSON.parse(JSON.stringify(response.data)).message == 'invalid_email_or_password') {
                        NotificationManager.error('Sai tài khoản hoặc mật khẩu', '', 2000, false);
                        this.setState({ isload: false });
                    }

                } else {
                    NotificationManager.success('Đăng nhập thành công', '', 4000, false);
                    if (response.data.result.token) {
                        localStorage.setItem('token', response.data.result.token);
                        localStorage.setItem('info', JSON.stringify(response.data.result));
                        // this.setState({ isload: false });
                        this.setState({ redirect: true });
                        setTimeout(function() { 
                            window.location = "/";
                        }, 4000);
                    }
                }
                
            })


            .catch(function (error) {
                console.log(error);
            });

    }

    render() {

        return (
            <div>
                <NotificationContainer />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
                <link href="/login_theme/style.css" rel="stylesheet" />
                <link href="/login_theme/main.css" rel="stylesheet" />
                <Helmet>
                    <title>Đăng nhập </title>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossOrigin="anonymous" />
                </Helmet>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h5 className="card-title text-center">ĐĂNG NHẬP</h5>
                                    <form onSubmit={this.handleSubmit.bind(this)} className="form-signin  mt-5">
                                        <div className="form-label-group">
                                            <input type='email' id="inputEmail" className="form-control" placeholder="Email address" required autoFocus ref="email" />
                                            <label htmlFor="inputEmail">Email address</label>
                                        </div>
                                        <div className="form-label-group">
                                            <input type='password' id="inputPassword" className="form-control" placeholder="Mật khẩu" required ref="password" />
                                            <label htmlFor="inputPassword">Mật khẩu</label>
                                        </div>
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input name="ghinho" type="checkbox" className="custom-control-input" id="customCheck1" ref="ghinho" />
                                            <label className="custom-control-label" htmlFor="customCheck1">Ghi nhớ</label>
                                        </div>
                                        {
                                             this.state.redirect?
                                            
                                        <button disabled className="btn btn-lg btn-block text-uppercase dangnhap" type="submit">Đang chuyển trang... </button>
                                        :
                                            this.state.isload ?
                                                <button disabled className="btn btn-lg btn-block text-uppercase dangnhap" type="submit">Đăng nhập <i className="fa fa-spinner fa-spin" style={{ fontSize: 18 }} /> </button>
                                                :
                                                <button className="btn btn-lg btn-block text-uppercase dangnhap" type="submit">Đăng nhập </button>
                                              }

                                    </form>
                                    <hr className="my-4" />
                                    {/* <button className="btn btn-lg btn-google btn-block text-uppercase" type="button"><i className="fa fa-google mr-2" />
                                        Đăng nhập bằng Google</button>
                                    <button className="btn btn-lg btn-facebook btn-block text-uppercase mb-20" type="button"><i className="fa fa-facebook-f mr-2" />
                                        Đăng nhập bằng Facebook</button> */}

                                    <h6 className="text-center txt-dark mt-20">Hoặc <NavLink to="/register"> Đăng ký ngay</NavLink></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}
export default Login