import React, { Component } from 'react';
import MonthBox from "./MonthBox";
import Picker from 'react-month-picker'
import { connect } from 'react-redux';
class List extends Component {
    constructor(props, context) {
        super(props, context)
        var now       = new Date();
        this.state = {
            mvalue: { year: now.getFullYear(), month: now.getMonth()+1 },
            mvalue2: { year: 2016, month: 7 },
            mrange: { from: { year: 2014, month: 8 }, to: { year: 2015, month: 5 } },
            mrange2: { from: { year: 2013, month: 11 }, to: { year: 2016, month: 3 } },
        }

        this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
        this.handleAMonthChange = this.handleAMonthChange.bind(this)
        this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this)

        this.handleClickMonthBox2 = this.handleClickMonthBox2.bind(this)
        this.handleAMonthChange2 = this.handleAMonthChange2.bind(this)
        this.handleAMonthDissmis2 = this.handleAMonthDissmis2.bind(this)

        this._handleClickRangeBox = this._handleClickRangeBox.bind(this)
        this.handleRangeChange = this.handleRangeChange.bind(this)
        this.handleRangeDissmis = this.handleRangeDissmis.bind(this)

        this._handleClickRangeBox2 = this._handleClickRangeBox2.bind(this)
        this.handleRangeChange2 = this.handleRangeChange2.bind(this)
        this.handleRangeDissmis2 = this.handleRangeDissmis2.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.mvalue !== this.state.mvalue){
            this.props.pickDate(this.state.mvalue)
        }
    }
    


    render() {
// console.log(this.state.mvalue)
        const pickerLang = {
            months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
            from: 'Từ', to: 'Tới',
        }
        const mvalue = this.state.mvalue
            , mvalue2 = this.state.mvalue2
            , mrange = this.state.mrange
            , mrange2 = this.state.mrange2

        const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month - 1] + '. ' + m.year)
            return '?'
        }

        return (
            <div className="edit">
            <Picker
                ref="pickAMonth"
                years={[2019, 2020, 2021, 2022, 2023]}
                value={mvalue}
                lang={pickerLang.months}
                onChange={this.handleAMonthChange}
                onDismiss={this.handleAMonthDissmis}
            >
                <MonthBox value={makeText(mvalue)} onClick={this.handleClickMonthBox} />
            </Picker>

                    
        </div>
        )
    }

    handleClickMonthBox(e) {
        this.refs.pickAMonth.show()
    }
    handleAMonthChange(value, text) {
        //
    }
    handleAMonthDissmis(value) {
        this.setState({ mvalue: value })
    }

    handleClickMonthBox2(e) {
        this.refs.pickAMonth2.show()
    }
    handleAMonthChange2(value, text) {
        //
    }
    handleAMonthDissmis2(value) {
        this.setState({ mvalue2: value })
    }

    _handleClickRangeBox(e) {
        this.refs.pickRange.show()
    }
    handleRangeChange(value, text, listIndex) {
        //
    }
    handleRangeDissmis(value) {
        this.setState({ mrange: value })
    }

    _handleClickRangeBox2(e) {
        this.refs.pickRange2.show()
    }
    handleRangeChange2(value, text, listIndex) {
        //
    }
    handleRangeDissmis2(value) {
        this.setState({ mrange2: value })
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        prop: state.prop
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        pickDate: (pickDate) => {
			dispatch({
				type:"PICK_DATE",
				pickDate
			})
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
