import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 
class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
          trangThai: '',
          search: '',
          loading: true,
          dateFrom: '',
          dateTo: '',
          website: '',
          khac: '',
          data: [],
          activePage: 0,
          itemsCountPerPage: 0,
          totalItemsCount: 0,
          pageRangeDisplayed: 5,
          page:1,
          nhanthanhtoan:false,
          Loader: false,
          openChuanBi: false, // Modal
          noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
          t_id: '',
        };
        this.handlePageChange = this.handlePageChange.bind(this);
      }

      handlePageChange(pageNumber) {
        this.setState({
          page:pageNumber
        })
        window.scrollTo(0, 0);
      }


      timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
          [name]: value
        });
    
    
      }



      componentWillMount() {
        this.setState({
          trangThai: queryString.parse(window.location.search).trangThai,
          search: queryString.parse(window.location.search).search,
          website: queryString.parse(window.location.search).website,
          dateFrom: queryString.parse(window.location.search).dateFrom,
          dateTo: queryString.parse(window.location.search).dateTo,
          khac: queryString.parse(window.location.search).khac,
          page: queryString.parse(window.location.search).page,
        })
      }
    
    
    
      
      componentDidUpdate = (prevProps, prevState) => {
    
        const query = {
          trangThai: this.state.trangThai,
          search: this.state.search,
          website: this.state.website,
          dateFrom: this.state.dateFrom,
          dateTo: this.state.dateTo,
          khac: this.state.khac,
          page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
          {
            pathname: '/products',
            search: searchString,
          }
        )
    
        // Get data
        if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan) {
    
    
          if(prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
            this.setState({
              page:1
            })
          }
          this.setState({
            loading: true
          })
          var trangThai = '';
          if (this.state.trangThai) {
            trangThai = this.state.trangThai;
          }
          var search = '';
          if (this.state.search) {
            search = this.state.search;
          }
          var website = '';
          if (this.state.website) {
            website = this.state.website;
          }
          var khac = '';
          if (this.state.khac) {
            khac = this.state.khac;
          }
          var dateFrom = '';
          if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
          }
          var dateTo = '';
          if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
          }
          let token = localStorage.getItem('token');
          axios.get(Config.API_URL + 'items?ma_don=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
            .then(response => {
              this.setState({ 
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
               });
            })
            .catch(function (error) {
              // this.setState({ loading: false });
              console.log(error);
            });
    
        }
        // End Get data
    
    
      }

      componentDidMount = () => {
        this.setState({
          loading: true
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
           website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
         khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.dateFrom) {
           dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = '';
        if (this.state.dateTo) {
          dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }
    
        
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'items?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
              this.setState({
                  items: response.data.data,
                  loading: false,
                  activePage: response.data.current_page,
                  itemsCountPerPage: response.data.per_page,
                  totalItemsCount: response.data.total,
                  path: response.data.path,
            })
          })
          .catch(function (error) {
            console.log(error);
          });
    
    
        // End Get data
    
    
      }

    delProduct(id) {
        var ma_don = '';
        if (this.state.ma_don) {
            var ma_don = this.state.ma_don;
        }
        var ma_giao_dich = '';
        if (this.state.ma_giao_dich) {
            var ma_giao_dich = this.state.ma_giao_dich;
        }
        var date_from = '';
        if (this.state.date_from) {
            var date_from = Moment(this.state.date_from).format('DD-MM-YYYY');
        }
        var date_to = '';
        if (this.state.date_to) {
            var date_to = Moment(this.state.date_to).format('DD-MM-YYYY');
        }
        var trangThai = '';
        if (this.state.trangThai) {
            var trangThai = this.state.trangThai;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'delItems?masp=' + id + "&token=" + token)
            .then(response => {
                axios.get(Config.API_URL + 'items?ma_don=' + ma_don + '&ma_giao_dich=' + ma_giao_dich + '&date_from=' + date_from + '&date_to=' + date_to + '&trangThai=' + trangThai + '&page=' + this.state.page + "&token=" + token)
                    .then(response => {
                        NotificationManager.success('Xóa thành công', '', 2000, false);
                        this.setState({
                            items: response.data.data,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            loading: false,
                            path: response.data.path,
                        });
                    });
            });
    }

    isChange = (event,id) => {
      var name = event.target.name;
      var value = event.target.value;
      this.setState({
          [name]: value,
          id
      });
  }


  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        loading: true
      })

      // console.log(this.state.gia_nhap);
      // console.log(this.state.id);

      if (this.state.id) {

        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'cap-nhat-gia-nhap?id=' + this.state.id + "&gia_nhap=" + this.state.gia_nhap + "&gia_ban=" + this.state.gia_ban+ "&trong_luong=" + this.state.trong_luong+ "&so_ngay_nhap=" + this.state.so_ngay_nhap + "&token=" + token)
          .then(response => {

            NotificationManager.success('Cập nhật thành công', '', 2000, false);
            // this.setState({ 
            //   data: response.data, 
            //   loading: false,
            //   activePage: response.data.dontongid.current_page,
            //   itemsCountPerPage: response.data.dontongid.per_page,
            //   totalItemsCount: response.data.dontongid.total,
            //   path: response.data.dontongid.path,
            // })

          })
          .catch(function (error) {
            console.log(error);
          });


      }

      
      
  

      // End Get data
    }
  }

    render() {
      
        return (
            <div id="content">
                 <NotificationContainer />
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    <h1 className="ui-title-bar__title">Sản phẩm</h1>
                                </div>
                                <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                    <div className="action-bar__item action-bar__item--link-container">
                                    </div>
                                    <div className="ui-title-bar__mobile-primary-actions">
                                        <div className="ui-title-bar__actions">
                                            <Link to="/add-product" className="ui-button ui-button--primary ui-title-bar__action">Thêm sản phẩm</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                                <div className="ui-title-bar__actions">
                                    <Link to="/add-product" className="ui-button ui-button--primary ui-title-bar__action">Thêm sản phẩm</Link>
                                </div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li className="filter-tab-item" data-tab-index={1}>
                                                        <a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab next-tab--is-active">Tất cả sản phẩm</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                                <th><span>Mã</span></th>
                                                                <th><span>Ảnh</span></th>
                                                                <th><span>Sản phẩm</span></th>
                                                                <th><span>Giá nhập</span></th>
                                                                <th><span>Giá bán</span></th>
                                                                <th><span>Trọng lượng</span></th>
                                                                <th><span>Số ngày nhập</span></th>
                                                                <th><span>Link Nguồn	</span></th>
                                                                {/* <th><span>Chi tiết</span></th> */}
                                                                <th><span>Action</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.items?
                                                                this.state.items.map( (value,key)=>{
                                                                    var html = "<img src="+value.image+" width='400px' />"
                                                                    return <tr>
                                                                    
                                                                    <td className="total">
                                                                        <p>{value.itemid}</p>
                                                                    </td>
                                                                    <td>
                                                                        <a className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" data-tip={html}>
                                                                        <img title={value.title} className="aspect-ratio__content" src={value.image} alt={value.title} />
                                                                        <ReactTooltip html={true} place={'right'}/>
                                                                        </a>
                                                                    </td>
                                                                    <td className="total">
                                                                        <p>{value.name}</p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <p>
                                                                        <input onKeyDown={this._handleKeyDown} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="gia_nhap" defaultValue={value.gia_nhap} />
                                                                        </p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <p><input onKeyDown={this._handleKeyDown} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="gia_ban" defaultValue={value.price} /></p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <p><input onKeyDown={this._handleKeyDown} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="trong_luong" defaultValue={value.trong_luong} /></p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <p><input onKeyDown={this._handleKeyDown} onChange={(event,id) => this.isChange(event,value.id)} type="text" name="so_ngay_nhap" defaultValue={value.so_ngay_nhap} /></p>
                                                                    </td>

                                                                    <td className="total">
                                                                    <a target="_blank" href={value.link_nguon}><label class="badge badge--status-success badge--progress-complete">Xem Nguồn</label></a>
                                                                    </td>

                                                                    {/* <td className="total">
                                                                    <a target="_blank" href={Config.HOME_URL+"/product/"+value.path}><label class="badge badge--status-warning badge--progress-complete">Chi tiết</label></a>
                                                                    </td> */}

                                                                    <td className="vendor">
                                                                    <label onClick={(event) => this.delProduct(value.itemid)} class="badge badge-success">Xóa</label>
                                                                    </td>
                                                                   

                                                                </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Products;