import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Modal from 'react-responsive-modal';
import { checkThe } from '../../functions/function.js';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from 'react-select'
import Showdate from './includes/Showdate';

var moment = require('moment');
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 

class ToDay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            acount: [],
            payment: [],
            loading: false,
            time: '',


            startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            from: undefined,
            to: undefined,
            show: false,
        };
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }

    tongGiaNhap() {
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'tong-gia-nhap-hom-nay?token=' + token + '&from=' + this.state.startDate + '&to=' + this.state.endDate)
            .then(tonggianhap => {
                this.setState({
                    tonggianhap: tonggianhap.data,
                })
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    tongDoanhSo() {
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'tong-doanh-so-hom-nay?token=' + token + '&from=' + this.state.startDate + '&to=' + this.state.endDate)
        .then(tongdoanhso => {

          this.setState({
            tongdoanhso: tongdoanhso.data,
          })
        })
        .catch(function (error) {
        //   console.log(error);
        });
    }

    tongSoDon() {
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'tong-so-don?token=' + token + '&from=' + this.state.startDate + '&to=' + this.state.endDate)
        .then(tongsodon => {

          this.setState({
            tongsodon: tongsodon.data,
          })
        })
        .catch(function (error) {
        //   console.log(error);
        });
    }


    getTaiKhoanAds() { // Get tài khoản, chi tiêu tổng cho tài khoản
        this.setState({loading: true})
        localStorage.removeItem('TODAY_LOCAL');
        axios.get("https://graph.facebook.com/v10.0/me/adaccounts?limit=1000&fields=account_status,age,name,amount_spent,balance,business_name,timezone_name,timezone_offset_hours_utc,currency,current_addrafts&access_token=" + Config.TOKEN_ADS)
        .then(response => {
            if (response && response.data && response.data.data) { // Nếu có tài khoản ads
                this.setState({
                    acount: response.data.data,
                })
                response.data.data.map((value, key) => {
                    setTimeout(() => {
                    this.getChiTietMotTk(
                        value.id,
                        value.account_status,
                        value.age,
                        value.name,
                        value.amount_spent, // Đã chi tiêu => Đạt ngưỡng chi tiêu
                        // value.balance, // Đã chi tiêu => Đạt ngưỡng thanh toán
                        value.timezone_offset_hours_utc, // Múi giờ
                        value.currency, // Tiền tệ
                    );
                    
                        if (key + 1 >= response.data.data.length) {
                            let tokenLaravel = localStorage.getItem('token');
                            axios.post(Config.API_URL + 'cap-nhat-tai-khoan-ads?token=' + tokenLaravel,
                                {
                                    "data": response.data.data,
                                },
                                {
                                    headers: { "Content-Type": "application/json" }
                                })
                                .then(responsads => {
                                    // console.log(responsads);
                                })
                                .catch(function (error) { });

                            response.data.data.map((value1, key1) => {
                                setTimeout(() => {
                                    this.getSoTienDaTieuToDay(value1.id);
                                }, key1 * 2000);
                                
                                if (key1+1 >= response.data.data.length) {
                                    response.data.data.map((value2, key2) => {
                                        setTimeout(() => {
                                            this.getTheThanhToan(value2.id);
                                        }, key2 * 2000);
                                    })
                                }

                                if (key1+1 >= response.data.data.length) {
                                    response.data.data.map((value3, key3) => {
                                        setTimeout(() => {
                                            this.getGioiHanThanhToan(value3.id);
                                        }, key3 * 2000);
                                    })
                                }

                                if (key1+1 >= response.data.data.length) {
                                    response.data.data.map((value4, key4) => {
                                        setTimeout(() => {
                                            this.getDatNguongThanhToan(value4.id);
                                        }, key4 * 2000);
                                    })
                                }

                                // Get tất cả nhóm tài khoản
                                // Tắt nhóm quảng cáo
                                // if (key1+1 >= response.data.data.length) {
                                //     response.data.data.map((value4, key4) => {
                                //         setTimeout(() => {
                                //             this.getKetQuaQC(value4.name,value4.id,value4.current_addrafts.data[0].id);
                                //         }, key4 * 2000);
                                //     })
                                // }

                              

                                if (key1+1 >= response.data.data.length) {
                                    response.data.data.map((value5, key5) => {
                                        setTimeout(() => {
                                            this.getNguongThanhToan(value5.id);
                                            if(key5+1>=response.data.data.length){
                                                this.setState({
                                                    loading: false,
                                                    time: new Date().getTime()
                                                })
                                            }
                                        }, key5 * 2000);
                                    })
                                }
                            })
                        }


                }, key * 1);
                })
            }
        })
            .catch(err => {
                NotificationManager.warning('Token Die', '', 5000);
            });
    }

    // getNhomQuangCao(id_tk,addraft){
    //     // axios.get('https://graph.facebook.com/v10.0/'+id_tk+'/light_adsets?access_token='+Config.TOKEN_ADS)
    //     axios.get('https://graph.facebook.com/v10.0/'+id_tk+'/adsets?fields=name&access_token='+Config.TOKEN_ADS)
    //     .then(response => {
    //         if (response.data) { // Nếu nhóm tài khoản quảng cáo
    //             console.log(response.data);
    //             // setTimeout(() => {
    //             //     this.getKetQuaQC(response.data.data.data.name,id_tk,addraft);
    //             // }, 1000);
    //         }
    //     })
    //     .catch(err => { });
    // }

    
    getKetQuaQC(name,id_tk,addraft){
        // axios.get('https://graph.facebook.com/v10.0/'+id_tk+'/am_tabular?column_fields=[%22created_time%22,%22spend%22,%22reach%22,%22objective%22,%22results%22,%22cost_per_result%22,%22conversion_annotations%22,%22adset_id%22]&date_preset=yesterday&include_headers=false&level=adset&limit=5000&access_token='+Config.TOKEN_ADS)
        
        axios.get('https://graph.facebook.com/v10.0/'+id_tk+'/am_tabular?column_fields=[%22created_time%22,%22spend%22,%22reach%22,%22objective%22,%22results%22,%22cost_per_result%22,%22conversion_annotations%22,%22adset_id%22]&date_preset=today&include_headers=false&level=adset&limit=5000&access_token='+Config.TOKEN_ADS)
        .then(response => {

            


            if (response.data.data) { // Nếu nhóm tài khoản quảng cáo
                
                response.data.data[0].rows.map((valuex, keyx) => {
                    function checkTime(i) {
                        if (i < 10) {
                            i = "0" + i;
                        }
                        return i;
                    }
                    
                    // Lấy Object ngày hiện tại
                    var today = new Date();

                    // Giờ, phút, giây hiện tại
                    var h = today.getHours();
                    var m = today.getMinutes();
                    var s = today.getSeconds();

                    // Chuyển đổi sang dạng 01, 02, 03
                    h = checkTime(h);
                    m = checkTime(m);
                    s = checkTime(s);
                    var fulltime = h+":"+m+":"+s;


                    var idnhom = valuex.dimension_values[1];
                    var tongchitieu = valuex.atomic_values[1] > 0 ? valuex.atomic_values[1] : "0";
                    var ketqua = valuex.result_values[0].value ? valuex.result_values[0].value : "0";
                    var chiphi1ketqua = valuex.result_values[1].value > 0 ? valuex.result_values[1].value : "0";

                    console.log(fulltime);
                    if(fulltime> "00:01:00" && fulltime < "00:30:00"){
                        
                        setTimeout(() => {
                        this.batLaiCamp(name,id_tk,addraft,idnhom);
                    }, keyx * 2000);
                    }else{
                        console.log("Ngoài giờ reset lại nhóm QC "+name);
                    }

                    if(valuex.atomic_values[1]>0){ // Nếu có chi tiêu tiền
                        

                        

                        if(tongchitieu>100000 && ketqua<1){


                            axios.post("https://graph.facebook.com/v10.0/" + addraft + "/addraft_fragments_with_publish?_app=ADS_MANAGER&_reqName=object%3Aaddraft%2Faddraft_fragments_with_publish&access_token=" + Config.TOKEN_ADS,
                                {
                                    "account_id": id_tk.replace("act_", ""),
                                    "ad_object_id": idnhom, // ID Camp hoặc ID Nhóm
                                    "publish": "true",
                                    "values": '[{"field":"status","old_value":"ACTIVE","new_value":"PAUSED"}]',
                                },
                                {
                                    headers: { "Content-Type": "application/json" }
                                })
                                .then(responsads => {
                                    // console.log(responsads);
                                })
                                .catch(function (error) { });

                            // TH1: Tiêu hết 100K không ra kết quả
                            console.log('Đóng do hết 100k chưa có KQ | ' + name + ' | ' + idnhom + ' ' + tongchitieu + ' ' +  ketqua + ' ' +  chiphi1ketqua);
                        }else if(ketqua>=1 && ketqua<3 && chiphi1ketqua>100000){

                            axios.post("https://graph.facebook.com/v10.0/" + addraft + "/addraft_fragments_with_publish?_app=ADS_MANAGER&_reqName=object%3Aaddraft%2Faddraft_fragments_with_publish&access_token=" + Config.TOKEN_ADS,
                                {
                                    "account_id": id_tk.replace("act_", ""),
                                    "ad_object_id": idnhom, // ID Camp hoặc ID Nhóm
                                    "publish": "true",
                                    "values": '[{"field":"status","old_value":"ACTIVE","new_value":"PAUSED"}]',
                                },
                                {
                                    headers: { "Content-Type": "application/json" }
                                })
                                .then(responsads => {
                                    // console.log(responsads);
                                })
                                .catch(function (error) { });

                            // TH2: Ra 1-3 kết quả, trên 100K => tắt
                            console.log('Đóng do trên 100k / 1 KQ | ' + name + ' | ' + idnhom + ' ' + tongchitieu + ' ' +  ketqua + ' ' +  chiphi1ketqua);
                        }else if(ketqua>=3 && chiphi1ketqua<100000){
                            // TH3: Từ 3 kết quả, dưới 100K => nhân bản
                            console.log('Nhân bản  | ' + name + ' | ' + idnhom + ' ' + tongchitieu + ' ' +  ketqua + ' ' +  chiphi1ketqua);
                        }else{
                            console.log('Không làm gì  | ' + name + ' | ' + idnhom + ' ' + tongchitieu + ' ' +  ketqua + ' ' +  chiphi1ketqua);
                        }
                    }else{ // Nếu không chi tiêu tiền
                        console.log("Không chi tiêu tiền | " + name);
                    }
                })
                

             
            }else{
                console.log('Không có dữ liệu | ' + name);
            }
        })
        .catch(err => {});
    }

    batLaiCamp(name,id_tk,addraft,idnhom) {

        axios.post("https://graph.facebook.com/v10.0/"+addraft+"/addraft_fragments_with_publish?_app=ADS_MANAGER&_reqName=object%3Aaddraft%2Faddraft_fragments_with_publish&access_token=" + Config.TOKEN_ADS,
            {
                "account_id": id_tk.replace("act_", ""),
                "ad_object_id": idnhom, // ID Camp hoặc ID Nhóm
                "publish": true,
                "values": '[{"field":"status","old_value":"PAUSED","new_value":"ACTIVE"}]',
            },
            {
                headers: { 
                    // "Content-Type": "application/json",
                }
            })
            .then(responsads => {
                console.log("Đã bật lại camp "+name);
             })
            .catch(function (error) { });

    }
    
    tatNhomQuangCao(){

    }

    batNhomQuangCao(){

    }
    nhanBanNhomQuangCao(){

    }

    // Lặp qua từng tài khoản
    getChiTietMotTk(id_tk,trangthai,tuoi,ten,dachitieu_gioihan,mui_gio,tien_te) {
        var trangthai1 = '';
        if(trangthai == '1'){
            var trangthai1 = 'Hoạt động';
        }
        if(trangthai == '2'){
            var trangthai1 = 'Vi phạm CS';
        }
        if(trangthai == '3'){
            var trangthai1 = 'Chưa thanh toán';
        }
        if(trangthai == '4'){
            var trangthai1 = 'Vi phạm CS';
        }
        var item = {};
        item.id = id_tk;
        item.trangthai = trangthai1;
        item.tuoi = tuoi;
        item.ten = ten;
        item.dachitieu_gioihan = dachitieu_gioihan;
        item.mui_gio = mui_gio;
        item.tien_te = tien_te;
        
        var CART_LOCAL = JSON.parse(localStorage.getItem('TODAY_LOCAL'));
        if (localStorage.getItem('TODAY_LOCAL')) {
            var CART_LOCAL = JSON.parse(localStorage.getItem('TODAY_LOCAL'));
        } else {
            var CART_LOCAL = [];
        }
        if (CART_LOCAL.length > 0) {
            var checkTrung = false;
            JSON.parse(localStorage.getItem('TODAY_LOCAL')).map((value, key) => {
                if (JSON.parse(JSON.stringify(JSON.parse(value))).id === item.id) {
                    checkTrung = true;
                    var itemnew = {};
                    itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                    itemnew.trangthai = Number(JSON.parse(JSON.stringify(JSON.parse(value))).trangthai);
                    itemnew.tuoi = Number(JSON.parse(JSON.stringify(JSON.parse(value))).tuoi);
                    itemnew.ten = Number(JSON.parse(JSON.stringify(JSON.parse(value))).ten);
                    // itemnew.dachitieu_gioihan = Number(JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan);
                    itemnew.mui_gio = Number(JSON.parse(JSON.stringify(JSON.parse(value))).mui_gio);
                    itemnew.tien_te = Number(JSON.parse(JSON.stringify(JSON.parse(value))).tien_te);
                    itemnew.comment = (JSON.parse(JSON.stringify(JSON.parse(value))).comment);
                    itemnew.mess = (JSON.parse(JSON.stringify(JSON.parse(value))).mess);
                    itemnew.ladi = (JSON.parse(JSON.stringify(JSON.parse(value))).ladi);
                    CART_LOCAL[key] = JSON.stringify(itemnew);
                }
            })
            if (checkTrung === false) {
                CART_LOCAL.push(JSON.stringify(item));
            }

        } else {
            CART_LOCAL.push(JSON.stringify(item));
        }
        localStorage.setItem('TODAY_LOCAL', JSON.stringify(CART_LOCAL));
        this.setState({
            CART_LOCAL
        })

    }

    getSoTienDaTieuToDay(id) { // Số tiền đã tiêu, số cmt, mess, ladi
        // axios.get('https://graph.facebook.com/v3.3/'+id+'/insights?access_token='+Config.TOKEN_ADS+'&__activeScenarios=["page_load_visible_rows","page_load"]&__business_id=1143415902345331&_app=ADS_MANAGER&_priority=LOW&_reqName=adaccount/insights&_reqSrc=AdsPETableDataFetchingPolicy.fetchFooter>fetchAsyncAfterSyncFails&_sessionID=78b49d1fe2708b0e&action_attribution_windows=["default"]&date_preset=today&fields=["created_time","spend","reach","results","objective","cost_per_result","actions","cost_per_action_type","cpc","ctr","cpm","frequency","cost_per_unique_action_type"]&filtering=[{"field":"campaign.delivery_info","operator":"IN","value":["active","completed","inactive","limited","not_delivering","not_published","pending_review","recently_completed","recently_rejected","rejected","scheduled"]}]&include_headers=false&level=account&limit=5000&locale=vi_VN&method=get&pretty=0&suppress_http_code=1&xref=f2854b566dd09e8')
        axios.get('https://graph.facebook.com/v10.0/'+id+'/insights?access_token='+Config.TOKEN_ADS+'&__activeScenarios=["page_load_visible_rows","page_load"]&__business_id=&_app=ADS_MANAGER&_priority=LOW&_reqName=adaccount/insights&_reqSrc=AdsPETableDataFetchingPolicy.fetchFooter>fetchAsyncAfterSyncFails&_sessionID=78b49d1fe2708b0e&action_attribution_windows=["default"]&time_range={"since":"'+this.state.startDate+'","until":"'+this.state.endDate+'"}&fields=["created_time","spend","reach","results","objective","cost_per_result","actions","cost_per_action_type","cpc","ctr","cpm","frequency","cost_per_unique_action_type"]&filtering=[{"field":"campaign.delivery_info","operator":"IN","value":["active","completed","inactive","limited","not_delivering","not_published","pending_review","recently_completed","recently_rejected","rejected","scheduled"]}]&include_headers=false&level=account&limit=5000&locale=vi_VN&method=get&pretty=0&suppress_http_code=1&xref=f2854b566dd09e8')
            .then(response => {
                if (response.data && response.data.data && response.data.data[0] && response.data.data[0].spend) { // Nếu có tiêu tiền
                    var CART_LOCAL = JSON.parse(localStorage.getItem('TODAY_LOCAL'));
                    if (CART_LOCAL.length > 0) {
                        JSON.parse(localStorage.getItem('TODAY_LOCAL')).map((value, key) => {
                            if (JSON.parse(JSON.stringify(JSON.parse(value))).id === id) {

                                if(response.data.data[0].actions){
                                    var comment = response.data.data[0].actions.filter(function (returnableObjects) {
                                        if (returnableObjects) {
                                            return returnableObjects.action_type === "comment";
                                        } else {
                                            return null;
                                        }

                                    })
                                    var mess = response.data.data[0].actions.filter(function (returnableObjects) {
                                        if (returnableObjects) {
                                            return returnableObjects.action_type === "onsite_conversion.messaging_first_reply";
                                        } else {
                                            return null;
                                        }

                                    })
                                    var ladi = response.data.data[0].actions.filter(function (returnableObjects) {
                                        if (returnableObjects) {
                                            return returnableObjects.action_type === "complete_registration";
                                        } else {
                                            return null;
                                        }

                                    })
                                    
                                }

                                var itemnew = {};
                                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                                itemnew.trangthai = (JSON.parse(JSON.stringify(JSON.parse(value))).trangthai);
                                itemnew.tuoi = Number(JSON.parse(JSON.stringify(JSON.parse(value))).tuoi);
                                itemnew.ten = (JSON.parse(JSON.stringify(JSON.parse(value))).ten);
                                // itemnew.dachitieu_gioihan = Number(JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan);
                                itemnew.mui_gio = Number(JSON.parse(JSON.stringify(JSON.parse(value))).mui_gio);
                                itemnew.tien_te = (JSON.parse(JSON.stringify(JSON.parse(value))).tien_te);
                                itemnew.comment = comment[0];
                                itemnew.mess = mess[0];
                                itemnew.ladi = ladi[0];
                                itemnew.dachitieu = response.data.data[0].spend;
                                itemnew.tan_suat = response.data.data[0].frequency;
                                itemnew.the = JSON.parse(JSON.stringify(JSON.parse(value))).the;
                                itemnew.gioi_han_chitieu = JSON.parse(JSON.stringify(JSON.parse(value))).gioi_han_chitieu;
                                itemnew.dachitieu_gioihan = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan;
                                itemnew.gioi_han_nguong = JSON.parse(JSON.stringify(JSON.parse(value))).gioi_han_nguong;
                                itemnew.dachitieu_nguong = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_nguong;
                                itemnew.ngay_tt = JSON.parse(JSON.stringify(JSON.parse(value))).ngay_tt;
                                CART_LOCAL[key] = JSON.stringify(itemnew);
                            }
                        })
                    }
                    localStorage.setItem('TODAY_LOCAL', JSON.stringify(CART_LOCAL));
                    this.setState({
                        CART_LOCAL
                    })
                }
            })
            .catch(err => {
                // NotificationManager.warning('Token Die', '', 5000);
            });
    }

    getTheThanhToan(id) {
        axios.get('https://graph.facebook.com/v10.0/'+id+'?access_token='+Config.TOKEN_ADS+'&__business_id=&_reqName=adaccount&_reqSrc=AdsPaymentMethodsDataLoader&_sessionID=18b67a9a4d579d0f&fields=["funding_source_details"]&include_headers=false&locale=vi_VN&method=get&pretty=0&suppress_http_code=1')
        .then(response => {
            if (response) { // Nếu có thẻ
                
                var CART_LOCAL = JSON.parse(localStorage.getItem('TODAY_LOCAL'));
                if (CART_LOCAL.length > 0) {
                    JSON.parse(localStorage.getItem('TODAY_LOCAL')).map((value, key) => {
                        if (JSON.parse(JSON.stringify(JSON.parse(value))).id === id) {
                            var itemnew = {};
                            itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                            itemnew.trangthai = (JSON.parse(JSON.stringify(JSON.parse(value))).trangthai);
                            itemnew.tuoi = Number(JSON.parse(JSON.stringify(JSON.parse(value))).tuoi);
                            itemnew.ten = (JSON.parse(JSON.stringify(JSON.parse(value))).ten);
                            // itemnew.dachitieu_gioihan = Number(JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan);
                            itemnew.mui_gio = Number(JSON.parse(JSON.stringify(JSON.parse(value))).mui_gio);
                            itemnew.tien_te = (JSON.parse(JSON.stringify(JSON.parse(value))).tien_te);
                            itemnew.comment = (JSON.parse(JSON.stringify(JSON.parse(value))).comment);
                            itemnew.mess = (JSON.parse(JSON.stringify(JSON.parse(value))).mess);
                            itemnew.ladi = (JSON.parse(JSON.stringify(JSON.parse(value))).ladi);
                            itemnew.dachitieu = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu;
                            itemnew.tan_suat = JSON.parse(JSON.stringify(JSON.parse(value))).tan_suat;
                            itemnew.the = response.data.funding_source_details.display_string;
                            itemnew.gioi_han_chitieu = JSON.parse(JSON.stringify(JSON.parse(value))).gioi_han_chitieu;
                            itemnew.dachitieu_gioihan = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan;
                            itemnew.gioi_han_nguong = JSON.parse(JSON.stringify(JSON.parse(value))).gioi_han_nguong;
                            itemnew.dachitieu_nguong = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_nguong;
                            itemnew.ngay_tt = JSON.parse(JSON.stringify(JSON.parse(value))).ngay_tt;
                            CART_LOCAL[key] = JSON.stringify(itemnew);
                        }
                    })
                }
                localStorage.setItem('TODAY_LOCAL', JSON.stringify(CART_LOCAL));
                this.setState({
                    CART_LOCAL
                })
            }
        })
        .catch(err => {
            // NotificationManager.warning('Token Die', '', 5000);
        });
    }

    getGioiHanThanhToan(id) {
        axios.get('https://graph.facebook.com/v10.0/' + id + '?access_token=' + Config.TOKEN_ADS + '&__business_id=&_priority=HIGH&_reqName=adaccount&_reqSrc=AdsCMAccountSpendLimitDataLoader&_sessionID=72e2c55b3efb4325&fields=["spend_cap","amount_spent"]&include_headers=false&locale=vi_VN&method=get&pretty=0&suppress_http_code=1')
            .then(response => {
                if (response) { // Nếu có thẻ

                    var CART_LOCAL = JSON.parse(localStorage.getItem('TODAY_LOCAL'));
                    if (CART_LOCAL.length > 0) {
                        JSON.parse(localStorage.getItem('TODAY_LOCAL')).map((value, key) => {
                            if (JSON.parse(JSON.stringify(JSON.parse(value))).id === id) {
                                var itemnew = {};
                                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                                if( parseInt(response.data.amount_spent) !==0 && parseInt(response.data.spend_cap)-parseInt(response.data.amount_spent)==0 ){
                                    itemnew.trangthai = 'Đạt giới hạn'
                                }else{
                                    itemnew.trangthai = (JSON.parse(JSON.stringify(JSON.parse(value))).trangthai);
                                }
                                
                                itemnew.tuoi = Number(JSON.parse(JSON.stringify(JSON.parse(value))).tuoi);
                                itemnew.ten = (JSON.parse(JSON.stringify(JSON.parse(value))).ten);
                                // itemnew.dachitieu_gioihan = Number(JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan);
                                itemnew.mui_gio = Number(JSON.parse(JSON.stringify(JSON.parse(value))).mui_gio);
                                itemnew.tien_te = (JSON.parse(JSON.stringify(JSON.parse(value))).tien_te);
                                itemnew.comment = (JSON.parse(JSON.stringify(JSON.parse(value))).comment);
                                itemnew.mess = (JSON.parse(JSON.stringify(JSON.parse(value))).mess);
                                itemnew.ladi = (JSON.parse(JSON.stringify(JSON.parse(value))).ladi);
                                itemnew.dachitieu = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu;
                                itemnew.tan_suat = JSON.parse(JSON.stringify(JSON.parse(value))).tan_suat;
                                itemnew.the = JSON.parse(JSON.stringify(JSON.parse(value))).the;
                                itemnew.gioi_han_chitieu = response.data.spend_cap;
                                itemnew.dachitieu_gioihan = response.data.amount_spent;
                                itemnew.gioi_han_nguong = JSON.parse(JSON.stringify(JSON.parse(value))).gioi_han_nguong;
                                itemnew.dachitieu_nguong = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_nguong;
                                itemnew.ngay_tt = JSON.parse(JSON.stringify(JSON.parse(value))).ngay_tt;
                                CART_LOCAL[key] = JSON.stringify(itemnew);
                            }
                        })
                    }
                    localStorage.setItem('TODAY_LOCAL', JSON.stringify(CART_LOCAL));
                    this.setState({
                        CART_LOCAL
                    })
                }
            })
            .catch(err => {
                // NotificationManager.warning('Token Die', '', 5000);
            });
    }

    getDatNguongThanhToan(id) {
        axios.get('https://graph.facebook.com/v10.0/' + id + '?access_token=' + Config.TOKEN_ADS + '&__business_id=&_reqName=adaccount&_reqSrc=AdsCMPaymentsAccountDataDispatcher&_sessionID=18b67a9a4d579d0f&fields=["current_unbilled_spend","max_billing_threshold","next_bill_date"]&include_headers=false&locale=vi_VN&method=get')
            .then(response => {
                if (response) { // Nếu có thẻ

                    var CART_LOCAL = JSON.parse(localStorage.getItem('TODAY_LOCAL'));
                    if (CART_LOCAL.length > 0) {
                        JSON.parse(localStorage.getItem('TODAY_LOCAL')).map((value, key) => {
                            if (JSON.parse(JSON.stringify(JSON.parse(value))).id === id) {
                                var itemnew = {};
                                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                                itemnew.trangthai = (JSON.parse(JSON.stringify(JSON.parse(value))).trangthai);
                                itemnew.tuoi = Number(JSON.parse(JSON.stringify(JSON.parse(value))).tuoi);
                                itemnew.ten = (JSON.parse(JSON.stringify(JSON.parse(value))).ten);
                                // itemnew.dachitieu_gioihan = Number(JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan);
                                itemnew.mui_gio = Number(JSON.parse(JSON.stringify(JSON.parse(value))).mui_gio);
                                itemnew.tien_te = (JSON.parse(JSON.stringify(JSON.parse(value))).tien_te);
                                itemnew.comment = (JSON.parse(JSON.stringify(JSON.parse(value))).comment);
                                itemnew.mess = (JSON.parse(JSON.stringify(JSON.parse(value))).mess);
                                itemnew.ladi = (JSON.parse(JSON.stringify(JSON.parse(value))).ladi);
                                itemnew.dachitieu = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu;
                                itemnew.tan_suat = JSON.parse(JSON.stringify(JSON.parse(value))).tan_suat;
                                itemnew.the = JSON.parse(JSON.stringify(JSON.parse(value))).the;
                                itemnew.gioi_han_chitieu = JSON.parse(JSON.stringify(JSON.parse(value))).gioi_han_chitieu;
                                itemnew.dachitieu_gioihan = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan;
                                itemnew.gioi_han_nguong = JSON.parse(JSON.stringify(JSON.parse(value))).gioi_han_nguong;
                                itemnew.dachitieu_nguong = response.data.current_unbilled_spend.amount_in_hundredths/100;
                                itemnew.ngay_tt = response.data.next_bill_date;
                                CART_LOCAL[key] = JSON.stringify(itemnew);
                            }
                        })
                    }
                    localStorage.setItem('TODAY_LOCAL', JSON.stringify(CART_LOCAL));
                    this.setState({
                        CART_LOCAL
                    })
                }
            })
            .catch(err => {
                // NotificationManager.warning('Token Die', '', 5000);
            });
    }
    getNguongThanhToan(id) {
        axios.get('https://graph.facebook.com/v10.0/'+id+'/adspaymentcycle?access_token='+Config.TOKEN_ADS+'&__business_id=1811971562390709&_reqName=adaccount/adspaymentcycle&_reqSrc=AdsCMBillingTransactionsDataLoader&_sessionID=26310a90197fe6f8&include_headers=false&locale=vi_VN&method=get&pretty=0&suppress_http_code=1')
            .then(response => {
                if (response) { // Nếu có thẻ
                    let tokenLaravel = localStorage.getItem('token');

                    axios.post(Config.API_URL + 'cap-nhat-nguong-ads?token=' + tokenLaravel,
                    {
                        "data": response.data.data[0],
                    },
                    {
                        headers: { "Content-Type": "application/json" }
                    })
                    .then(responsads => {
                        // console.log(responsads);
                    })
                    .catch(function (error) { });

                    var CART_LOCAL = JSON.parse(localStorage.getItem('TODAY_LOCAL'));
                    if (CART_LOCAL.length > 0) {
                        JSON.parse(localStorage.getItem('TODAY_LOCAL')).map((value, key) => {
                            if (JSON.parse(JSON.stringify(JSON.parse(value))).id === id) {
                                var itemnew = {};
                                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                                itemnew.trangthai = (JSON.parse(JSON.stringify(JSON.parse(value))).trangthai);
                                itemnew.tuoi = Number(JSON.parse(JSON.stringify(JSON.parse(value))).tuoi);
                                itemnew.ten = (JSON.parse(JSON.stringify(JSON.parse(value))).ten);
                                // itemnew.dachitieu_gioihan = Number(JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan);
                                itemnew.mui_gio = Number(JSON.parse(JSON.stringify(JSON.parse(value))).mui_gio);
                                itemnew.tien_te = (JSON.parse(JSON.stringify(JSON.parse(value))).tien_te);
                                itemnew.comment = (JSON.parse(JSON.stringify(JSON.parse(value))).comment);
                                itemnew.mess = (JSON.parse(JSON.stringify(JSON.parse(value))).mess);
                                itemnew.ladi = (JSON.parse(JSON.stringify(JSON.parse(value))).ladi);
                                itemnew.dachitieu = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu;
                                itemnew.tan_suat = JSON.parse(JSON.stringify(JSON.parse(value))).tan_suat;
                                itemnew.the = JSON.parse(JSON.stringify(JSON.parse(value))).the;
                                itemnew.gioi_han_chitieu = JSON.parse(JSON.stringify(JSON.parse(value))).gioi_han_chitieu;
                                itemnew.dachitieu_gioihan = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_gioihan;
                                itemnew.gioi_han_nguong = response.data.data[0].threshold_amount;
                                itemnew.dachitieu_nguong = JSON.parse(JSON.stringify(JSON.parse(value))).dachitieu_nguong;
                                itemnew.ngay_tt = JSON.parse(JSON.stringify(JSON.parse(value))).ngay_tt;
                                CART_LOCAL[key] = JSON.stringify(itemnew);
                            }
                        })
                    }
                    localStorage.setItem('TODAY_LOCAL', JSON.stringify(CART_LOCAL));
                    this.setState({
                        CART_LOCAL
                    })
                }
            })
            .catch(err => {
                // NotificationManager.warning('Token Die', '', 5000);
            });
    }

    CapNhat() {
        this.tongSoDon();
        this.tongDoanhSo();
        this.tongGiaNhap();
        this.getTaiKhoanAds();
    }

    componentWillMount() {
       this.loadData();
    }

    loadData(){
        for (let i = 0; i < 100; i++) {
            setTimeout(() => {
                this.tongSoDon();
                this.tongDoanhSo();
                this.tongGiaNhap();
                this.getTaiKhoanAds();
            }, i * 1000*60*2*60);//10 phút
        }
    }

    tinhTongTienChiTieuADS() {
        var tongchitieu = 0;
        if (this.state.CART_LOCAL) {
            this.state.CART_LOCAL.map((value, key) => {
                if (JSON.parse(value).dachitieu) {
                    tongchitieu += parseInt(JSON.parse(value).dachitieu);
                }

            })

            this.setState({ tongchitieu })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.CART_LOCAL !== this.state.CART_LOCAL){
            this.tinhTongTienChiTieuADS();
        }
    }
    



    show(){
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }
    
    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        },()=>{this.loadData();this.show()});
    }
    onDateRangeSelectChange(event){
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate =  moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate =  moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate =  moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate =  moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate =  moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-')+moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate =  moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate =  moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate =  moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;
        
            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        },() => { this.loadData() }
        )  
    }


    showCalendar(){
        const from =  new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{marginBottom:10,width:350}}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> - 
                <span className="InputFromTo-to">
                <DayPickerInput
                    ref={el => (this.to = el)}
                    value={to}
                    placeholder="To"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: to,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                    }}
                    onDayChange={this.handleToChange}
                />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }



    render() {

        // console.log(this.state.startDate);
        // console.log( moment.utc().format('YYYY-MM-DD HH:mm:ss') );
        // console.log( moment.utc().utcOffset(-7).format('YYYY-MM-DD HH:mm') );
        // console.log( moment.utc().format('YYYY-MM-DD HH:mm') );
        return (
            <div id="content">
                <div>
                    <NotificationContainer />
                </div>
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                
                                    {/* Múi giờ +7: {moment.utc().format('YYYY-MM-DD HH:mm')} <br /> */}
                                <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" /></svg></span>
                                    
                                    <h1 className="ui-title-bar__title">Thống kê </h1>
                                    <div id="reportrangereceived" className="form-group" style={{ position: 'relative' }}>
                                        <span onClick={this.show} style={{ position: 'absolute', top: '5px', width: '100%' }}>
                                            {moment(this.state.startDate).format('DD/MM/YYYY')} đến {moment(this.state.endDate).format('DD/MM/YYYY')}</span>
                                        {this.state.show ? <this.showCalendar /> : ""} 
                                    </div>
                                     (GMT-7): {moment.utc().utcOffset(-7).format('HH:mm  DD/MM/YYYY')} <br />
                                </div>
                                    
                                <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                    <div className="action-bar__item action-bar__item--link-container" />
                                    <div className="ui-title-bar__mobile-primary-actions">
                                        <div className="ui-title-bar__actions">
                                            

                                        <Link className="ui-button ui-button--primary ui-title-bar__action" to="/analytics"><svg className="next-icon next-icon--size-20 next-icon--no-nudge"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" /> </svg> Thống kê</Link></div>
                                    </div>

                                    <div className="ui-title-bar__mobile-primary-actions">
                                        <div className="ui-title-bar__actions">
                                            Làm mới
                                       </div>
                                    </div>

                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                            </div> 

                            <div className="ui-title-bar__actions">
                            {this.state.time?
                            moment(this.state.time).fromNow()
                        //    setInterval( moment(this.state.time).fromNow(),100 )
                            :
                            ''
                            }
                                {
                                    this.state.loading?
                                    <a className="ui-button ui-button--primary ui-title-bar__action"><i className='fa fa-spinner fa-spin' style={{ fontSize: 12 }} /></a>
                                    :
                                    <a onClick={()=>this.CapNhat()} className="ui-button ui-button--primary ui-title-bar__action"> Cập nhật</a> 
                                }
                                
                            </div>
                                                            
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab next-tab--is-active">Tất cả tài khoản</a></li>
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab">Hoạt động</a></li>
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab">Vi phạm CS</a></li>
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab">Chưa TT</a></li>
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab">Sắp đạt GH chi tiêu</a></li>
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab">Đạt GH chi tiêu</a></li>
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab">Sắp đạt ngưỡng</a></li>
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab">Sắp tới ngày TT</a></li>
                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">
                                                                <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" name="search" placeholder="Nhập token ADS" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                                <th><span>#</span></th>
                                                                <th><span>Tên tk</span></th>
                                                                {/* <th><span>Ngày tạo</span></th> */}
                                                                <th><span>Tuổi</span></th>
                                                                <th><span>zone</span></th>
                                                                <th><span>Payment</span></th>
                                                                {/* <th><span>currency</span></th> */}
                                                                <th><span>Đã chi tiêu</span></th>
                                                                <th><span>Cmt</span></th>
                                                                {/* <th><span>Mess</span></th> */}
                                                                <th><span>Ladi</span></th>
                                                                <th><span>T.Suất</span></th>
                                                                <th><span>GH chi tiêu(100)</span></th>
                                                                <th><span>Ngưỡng thanh toán(200)</span></th>
                                                                <th><span>Ngày t.toán</span></th>
                                                                <th><span>Trạng thái</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                
                                                                this.state.CART_LOCAL?
                                                                this.state.CART_LOCAL.map( (value,key)=>{

                                                                    return <tr key={key}>
                                                                    <td className="total">
                                                                        {key+1}
                                                                    </td>
                                                                    <td className="total">
                                                                        <a target="_blank" href={'https://business.facebook.com/adsmanager/manage/campaigns?act='+JSON.parse(value).id.replace("act_",'')+'&business_id=&columns=name%2Ccreated_time%2Cdelivery%2Ccampaign_name%2Cbudget%2Cspend%2Creach%2Cresults%2Ccost_per_result%2Cactions%3Acomment%2Ccost_per_action_type%3Acomment%2Cactions%3Aonsite_conversion.messaging_first_reply%2Ccost_per_action_type%3Aonsite_conversion.messaging_first_reply%2Ccpc%2Crelevance_score%3Ascore%2Cctr%2Ccpm%2Cfrequency%2Cschedule%2Cstop_time%2Ccost_per_unique_action_type%3Aomni_complete_registration&attribution_windows=default&date=2019-06-05_2019-06-06%2Ctoday&filter_set=CAMPAIGN_GROUP_DELIVERY_INFO-STRING_SET%1EIN%1E[%22active%22%2C%22pending_review%22]%1DCAMPAIGN_DELIVERY_INFO-STRING_SET%1EIN%1E[%22active%22%2C%22pending_review%22]%1DADGROUP_DELIVERY_INFO-STRING_SET%1EIN%1E[%22active%22%2C%22pending_review%22]&root_level=ad'}>{JSON.parse(value).ten}</a>
                                                                    </td>
                                                                    {/* <td className="total">
                                                                        {
                                                                            nguong[0].data?
                                                                            dateFormat( nguong[0].data.created_time, "dd-mm-yyyy" )
                                                                            :
                                                                            ""}
                                                                    </td> */}
                                                                    <td className="total">
                                                                    {Math.ceil(JSON.parse(value).tuoi)}
                                                                    </td>
                                                                    <td className="vendor">
                                                                        {
                                                                            value && JSON.parse(value).mui_gio>0?
                                                                            "+"+JSON.parse(value).mui_gio
                                                                            :
                                                                            value && JSON.parse(value).mui_gio
                                                                            }
                                                                    </td>
                                                                    <td className="type">
                                                                            {
                                                                                JSON.parse(value).the?
                                                                                checkThe(JSON.parse(value).the)
                                                                                :
                                                                                ""
                                                                            }
                                                                    </td>
                                                                    {/* <td className="type">
                                                                        {JSON.parse(value).tien_te}
                                                                    </td> */}
                                                                    <td className="type">
                                                                            {
                                                                                JSON.parse(value).dachitieu ?
                                                                                JSON.parse(value).dachitieu>1000?parseInt(JSON.parse(value).dachitieu).toLocaleString():JSON.parse(value).dachitieu
                                                                                    :
                                                                                    ""
                                                                            }
                                                                    </td>
                                                                    <td className="type">
                                                                            {
                                                                                JSON.parse(value).comment && JSON.parse(value).comment.value ?
                                                                                    <span data-tip={"Giá cmt: " + (parseInt(JSON.parse(value).dachitieu) / JSON.parse(value).comment.value).toLocaleString()}>
                                                                                        {JSON.parse(value).comment.value}
                                                                                        <ReactTooltip place={'right'} />
                                                                                    </span>

                                                                                    :
                                                                                    ""
                                                                            }
                                                                            
                                                                    </td>
                                                                    {/* <td className="type">

                                                                    {
                                                                                JSON.parse(value).mess && JSON.parse(value).mess.value?
                                                                                <span data-tip={"Giá tin nhắn: " + (parseInt(JSON.parse(value).dachitieu) / JSON.parse(value).mess.value).toLocaleString()}> 
                                                                                {JSON.parse(value).mess.value}
                                                                                <ReactTooltip place={'right'}/>
                                                                                </span>
                                                                               
                                                                                    :
                                                                                    ""
                                                                            }

                                                                    </td> */}
                                                                    <td className="type">

                                                                    {
                                                                                JSON.parse(value).ladi && JSON.parse(value).ladi.value?
                                                                                <span data-tip={"Giá chuyển đổi: " + (parseInt(JSON.parse(value).dachitieu) / JSON.parse(value).ladi.value).toLocaleString()}> 
                                                                                {
                                                                                    (parseInt(JSON.parse(value).dachitieu) / JSON.parse(value).ladi.value) > 100000?
                                                                                    <span style={{color: 'red'}}>{JSON.parse(value).ladi.value}</span>
                                                                                    :
                                                                                    <span style={{color: 'blue'}}>{JSON.parse(value).ladi.value}</span>
                                                                                
                                                                                }
                                                                                <ReactTooltip place={'right'}/>
                                                                                </span>
                                                                               
                                                                                    :
                                                                                    ""
                                                                            }

                                                                    </td>
                                                                    <td className="type">
                                                                        {
                                                                            JSON.parse(value).tan_suat?
                                                                            Math.round(JSON.parse(value).tan_suat * 100) / 100
                                                                            :
                                                                            ""
                                                                        }
                                                                        </td>
                                                                    <td className="type">
    
    <a target="_blank" href="">

                                                                           
                                                                            {/* {
                                                                                 parseInt(JSON.parse(value).gioi_han_chitieu) && parseInt(JSON.parse(value).dachitieu_gioihan)?
                                                                                 parseInt(JSON.parse(value).dachitieu_gioihan) - parseInt(JSON.parse(value).gioi_han_chitieu) <= 100000 ?
                                                                                <span style={{color: 'red'}}>{parseInt(parseInt(JSON.parse(value).dachitieu_gioihan).toLocaleString() + " / " + parseInt(JSON.parse(value).gioi_han_chitieu)).toLocaleString()}</span>
                                                                                    :
                                                                                    <span style={{color: 'blue'}}>{parseInt(JSON.parse(value).dachitieu_gioihan).toLocaleString() + " / " + parseInt(JSON.parse(value).gioi_han_chitieu).toLocaleString()}</span>
                                                                                    :
                                                                                    ""
                                                                            } */}

                                                                                {
                                                                                    JSON.parse(value).dachitieu_gioihan && JSON.parse(value).gioi_han_chitieu ?
                                                                                        <span style={{ color: 'blue' }}>{parseInt(JSON.parse(value).dachitieu_gioihan).toLocaleString() + " / " + parseInt(JSON.parse(value).gioi_han_chitieu).toLocaleString()}</span>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </a>

                                                                            <br></br>
                                                                            {
                                                                                JSON.parse(value).gioi_han_chitieu && JSON.parse(value).gioi_han_chitieu > 0?
                                                                                JSON.parse(value).dachitieu_gioihan && JSON.parse(value).gioi_han_chitieu && (JSON.parse(value).gioi_han_chitieu-JSON.parse(value).dachitieu_gioihan) <100000 ?
                                                                                <span style={{ color: 'red' }}>{"Còn lại: " + (parseInt(JSON.parse(value).gioi_han_chitieu) - parseInt(JSON.parse(value).dachitieu_gioihan)).toLocaleString()}</span>
                                                                                    :
                                                                                    <span style={{ color: 'blue' }}>{"Còn lại: " + (parseInt(JSON.parse(value).gioi_han_chitieu) - parseInt(JSON.parse(value).dachitieu_gioihan)).toLocaleString()}</span>
                                                                            :
                                                                            ""
                                                                                }
                                                                        </td>
                                                                        <td className="vendor">
                                                                            {
                                                                                JSON.parse(value).dachitieu_nguong && JSON.parse(value).gioi_han_nguong ?
                                                                                    <span style={{ color: 'blue' }}>{parseInt(JSON.parse(value).dachitieu_nguong).toLocaleString() + " / " + parseInt(JSON.parse(value).gioi_han_nguong).toLocaleString()}</span>
                                                                                    :
                                                                                    ""
                                                                            }

                                                                    {/* {
                                                                              nothanhtoan && nothanhtoan[0] && nothanhtoan[0].data && nguong && nguong[0] && nguong[0].data ?
                                                                                 parseInt(parseInt(nguong[0].data.threshold_amount) - nothanhtoan[0].data.current_unbilled_spend.amount_in_hundredths/100) <= 500000 ?
                                                                                <span style={{color: 'red'}}>{(nothanhtoan[0].data.current_unbilled_spend.amount_in_hundredths/100).toLocaleString() + " / " + nguong[0].data.threshold_amount.toLocaleString()}</span>
                                                                                    :
                                                                                    <span style={{color: 'blue'}}>{(nothanhtoan[0].data.current_unbilled_spend.amount_in_hundredths/100).toLocaleString() + " / " + nguong[0].data.threshold_amount.toLocaleString()}</span>
                                                                                    :
                                                                                    ""
                                                                            } */}
<br></br>


                                                                            {
                                                                                JSON.parse(value).dachitieu_nguong && JSON.parse(value).gioi_han_nguong ?
                                                                                    (parseInt(JSON.parse(value).gioi_han_nguong) - parseInt(JSON.parse(value).dachitieu_nguong)) < 200000 ?
                                                                                        <span style={{ color: 'red' }}>{"Còn lại: " + (parseInt(JSON.parse(value).gioi_han_nguong) - parseInt(JSON.parse(value).dachitieu_nguong)).toLocaleString()}</span>
                                                                                        :
                                                                                        <span style={{ color: 'blue' }}>{"Còn lại: " + (parseInt(JSON.parse(value).gioi_han_nguong) - parseInt(JSON.parse(value).dachitieu_nguong)).toLocaleString()}</span>
                                                                                    :
                                                                                    ""
                                                                            }

{/* {nothanhtoan && nothanhtoan[0] && nothanhtoan[0].data && nguong && nguong[0] && nguong[0].data ?(nguong[0].data.threshold_amount-(nothanhtoan[0].data.current_unbilled_spend.amount_in_hundredths/100)).toLocaleString():""} */}
                                                                    </td>
                                                                    <td className="vendor">
                                                                            {/* { 
                                                                                JSON.parse(value).ngay_tt? 
                                                                                dateFormat(JSON.parse(value).ngay_tt, "HH:mm:ss dd-mm-yyyy" ) 
                                                                                :
                                                                                ""
                                                                                } */}

                                                                            {
                                                                                JSON.parse(value).ngay_tt ?
                                                                                    moment(JSON.parse(value).ngay_tt).fromNow()
                                                                                    :
                                                                                    ""
                                                                            }


                                                                    </td>
                                                                    <td className="total">
                                                                    {
                                                                            JSON.parse(value).trangthai ==='Chưa thanh toán'?
                                                                            <a target="_blank" href={"https://business.facebook.com/ads/manager/account_settings/account_billing/?act="+JSON.parse(value).id.replace('act_','')+"&pid=p2&page=account_settings&tab=account_billing_settings"}>
                                                                            <label className="badge badge--status-warning badge--progress-complete">Chưa TT</label>
                                                                            </a>
                                                                            :""
                                                                        }
                                                                    {
                                                                            JSON.parse(value).trangthai ==='Đạt giới hạn'?
                                                                            <a target="_blank" href={"https://business.facebook.com/ads/manager/account_settings/account_billing/?act="+JSON.parse(value).id.replace('act_','')+"&pid=p2&page=account_settings&tab=account_billing_settings"}>
                                                                            <label className="badge badge--status-warning badge--progress-complete">Đạt giới hạn</label>
                                                                            </a>
                                                                            :""
                                                                        }
                                                                    {
                                                                            JSON.parse(value).trangthai ==='Hoạt động'?
                                                                            <a target="_blank" href={"https://business.facebook.com/ads/manager/account_settings/account_billing/?act="+JSON.parse(value).id.replace('act_','')+"&pid=p2&page=account_settings&tab=account_billing_settings"}>
                                                                            <label className="badge badge--status-success badge--progress-complete">Hoạt động</label>
                                                                            </a>
                                                                            :""
                                                                        }
                                                                        {
                                                                            JSON.parse(value).trangthai ==='Vi phạm CS'?
                                                                            <a target="_blank" href={"https://business.facebook.com/ads/manager/account_settings/account_billing/?act="+JSON.parse(value).id.replace('act_','')+"&pid=p2&page=account_settings&tab=account_billing_settings"}>
                                                                            <label className="badge badge--status-danger badge--progress-complete">Vi phạm CS</label>
                                                                            </a>
                                                                            :""
                                                                        }
                                                                        
                                                                    </td>
                                                                </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    <ul className="">
                                        Tổng Số đơn: {parseInt(this.state.tongsodon).toLocaleString()}
                                    </ul>
                                    <br></br>
                                    <ul className="">
                                        Tổng doanh số: {parseInt(this.state.tongdoanhso).toLocaleString()}
                                    </ul>
                                    <ul className="">
                                        Tổng giá nhập: {parseInt(this.state.tonggianhap).toLocaleString()}
                                    </ul>
                                    <ul className="">
                                        Phí vận chuyển dự kiến 40k/ đơn: {parseInt(this.state.tongsodon * 40000).toLocaleString()}
                                    </ul>
                                    <ul className="">
                                        ADS đã chi tiêu: {parseInt(this.state.tongchitieu).toLocaleString()}
                                    </ul>
                                    <br></br>
                                    <ul className="">
                                        Lãi: {(parseInt(this.state.tongdoanhso) - parseInt(this.state.tonggianhap) - parseInt(this.state.tongsodon * 40000) - parseInt(this.state.tongchitieu)).toLocaleString()}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ToDay;