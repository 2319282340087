import firebase from 'firebase/app';
import 'firebase/storage';

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDVdajkNdJe46E3qiJd0G_oKTAFIa3Q-58",
  authDomain: "upload-images-fb.firebaseapp.com",
  databaseURL: "https://upload-images-fb.firebaseio.com",
  projectId: "upload-images-fb",
  storageBucket: "upload-images-fb.appspot.com",
  messagingSenderId: "554739024717"
};
firebase.initializeApp(config);
const storage = firebase.storage();

export {
  storage, firebase as default
}