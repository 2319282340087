import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-responsive-modal';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { lam_tron } from '../../functions/function.js';
import { validate } from '@babel/types';
import Pusher from 'pusher-js';
import { SlowBuffer } from 'buffer';
import Select from 'react-select'
import { check_phone } from '../../functions/function.js';
import { json } from 'body-parser';
const moment = require('moment');
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');
export default class DanhSachTKQLBM extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trangThai: 'tat_ca',
            search: '',
            loading: true,
            dateFrom: '',
            dateTo: '',
            website: '',
            khac: '',
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
            nhanthanhtoan: false,
            Loader: false,
            openChuanBi: false, // Modal
            openNhapVanDon: false, // Modal
            openChiTietVanDon: false, // Modal
            openNhapKho: false, // Modal
            openModalGhiChu: false, // Modal
            noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
            t_id: '',
            so_luong: '',
              startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            // startDate: moment().format('YYYY-MM-01'),
            endDate: moment().format('YYYY-MM-DD'),
            selectedLyDo: null,
            ly_do:'',
            giatritron: []
        };
        // this.handlePageChange = this.handlePageChange.bind(this);

        // this.showCalendar = this.showCalendar.bind(this);
        // this.show = this.show.bind(this);
        // this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        // this.handleFromChange = this.handleFromChange.bind(this);
        // this.handleToChange = this.handleToChange.bind(this);
        // this.checkOne = this.checkOne.bind(this);
        // this.checkAll = this.checkAll.bind(this);
        // this.unCheckAll = this.unCheckAll.bind(this);
        // this.saveBuy = this.saveBuy.bind(this);
    }


     // Nhập kho
     onOpenModalNhapKho = () => {
        this.setState({ chitietdon: '' });
        this.setState({ loading: false });
        this.setState({ giatridachon: [] })
        this.setState({
            openNhapKho: true,
        });

    };

    onCloseModalNhapKho = () => {
        this.setState({ openNhapKho: false });
    };

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    CapNhatTokenADS() {
        
        if (this.state.token_ads) {
            this.setState({loading: true})
            axios.get('https://graph.facebook.com/v7.0/me?fields=picture%2Cname,personal_ad_accounts.limit(1)&access_token=' + this.state.token_ads)
                .then(respon1 => {
                    // this.GetTrangThaiAccAds(respon1);
                    this.UpdateTokenADSData(respon1,'Hoạt động')
            })
            .catch(function (error) {
                alert("Lỗi TOKEN")
              });
        }else{
            alert("Chưa nhập TOKEN")
        }
    }

    GetTrangThaiAccAds(respon1) {
        var id_tkqc = respon1.data.personal_ad_accounts.data[0].id;
        axios.get('https://graph.facebook.com/v7.0/'+id_tkqc+'?fields=account_status&access_token=' + this.state.token_ads)
            .then(respon2 => {
                // this.UpdateTokenADSData(respon1);
                var trang_thai_tk = respon2.data.account_status;
                this.UpdateTokenADSData(respon1,trang_thai_tk)
                
            })
            .catch(function (error) {
                alert("Lỗi TOKEN")
            });
    }

    UpdateTokenADSData(respon1,trang_thai_tk) {
        var order = {};
        order.id = respon1.data.id;
        order.name = respon1.data.name;
        order.picture = respon1.data.picture.data.url;
        order.token = this.state.token_ads;
        if (trang_thai_tk==2) {
            order.trang_thai = 'Bị hạn chế';
        } else {
            order.trang_thai = 'Hoạt động';
        }
        let token = localStorage.getItem('token');
        axios.post(Config.API_URL + 'facebook/cap-nhat-tai-khoan-ads?token=' + token, qs.stringify(order))
            .then(response => {
                this.setState({
                    tong: response.data.data.length,
                    items: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                    openNhapKho: false
                });
            })
            .catch(function (error) {
                console.log(error);
                alert("Lỗi")
            });
    }

    componentDidMount() {
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'facebook/danh-sach-tai-khoan-ads?token=' + token)
            .then(response => {
                this.setState({
                    tong: response.data.data.length,
                    items: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                    openNhapKho: false
                });
            })
            .catch(function (error) {
                console.log(error);
                alert("Lỗi")
            });
    }
    

    CapNhatBM(){
        this.setState({ loading: true })
        this.CapNhatBmData()
    }

    CapNhatBmData() {
        
        this.state.items.map((value, key) => {
            setTimeout(() => { 
                this.CheckTrangThai(value);
                if ((key + 1) >= this.state.items.length) {
                    this.setState({ loading: false })
                    NotificationManager.success('Cập nhật xong dữ liệu mới', '', 5000);
                }
            }, key * 3000);
        })
    }

    CheckTrangThai(value){
        
        axios.get('https://graph.facebook.com/v7.0/me?fields=personal_ad_accounts{account_status,account_id,disable_reason},name&access_token=' + value.token)
            .then(respon3 => {

                console.log(respon3.data.personal_ad_accounts.data[0].account_status);
                // var trang_thai_tk = respon3.data.account_status;
                // this.UpdateTokenADSData(respon1,trang_thai_tk);
                if(respon3.data.personal_ad_accounts && respon3.data.personal_ad_accounts.data && respon3.data.personal_ad_accounts.data[0] && respon3.data.personal_ad_accounts.data[0].account_status && respon3.data.personal_ad_accounts.data[0].account_status == '2'){
                    this.CapNhatTaiKhoanFb(value.id,value.uid,'Hoạt động','Hạn chế');
                }else if(respon3.data.error && respon3.data.error.code == '190'){
                    this.CapNhatTaiKhoanFb(value.id,value.uid,'Die','Die');
                }
                else{
                    this.CapNhatTaiKhoanFb(value.id,value.uid,'Hoạt động','Live');
                }
                
            })
            .catch( ()=>{
                this.CapNhatTaiKhoanFb(value.id,value.uid,'Die','Die');
                    // alert("Lỗi TOKEN")
            } );
    }

    CapNhatTaiKhoanFb(id,uid,trang_thai,quyen_ads) {
        var order = {};
        order.id_tk = id;
        order.uid = uid;
        order.trang_thai = trang_thai;
        order.quyen_ads = quyen_ads;
        let token = localStorage.getItem('token');
        axios.post(Config.API_URL + 'facebook/cap-nhat-tai-khoan-fb?token=' + token, qs.stringify(order))
            .then(response => {
                this.setState({
                    tong: response.data.data.length,
                    items: response.data.data,
                    // loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                    openNhapKho: false
                });
            })
            .catch(function (error) {
                console.log(error);
                // alert("Lỗi")
            });
    }

    // Nhập Vận Đơn
    render() {
        const { openNhapKho } = this.state;
        return (
            <div>
                <div id="content">
                    <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                        <header className="ui-title-bar-container ui-title-bar-container--full-width">
                            <div className="ui-title-bar ">
                                <div className="ui-title-bar__main-group">

                                <div className="ui-title-bar__navigation">
                                    <div className="ui-breadcrumbs">
                                        <Link className="ui-button ui-button--transparent ui-breadcrumb"  to="/analytics">
                                            <svg className="next-icon next-icon--size-20 next-icon--no-nudge">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" />
                                            </svg><span className="ui-breadcrumb__item">Thống kê</span></Link>
                                    </div>
                                    <div className="ui-title-bar__pagination" />
                                </div>
                                
                                    <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" /></svg></span>
                                        <h1 className="ui-title-bar__title">DANH SÁCH TÀI KHOẢN FACEBOOK</h1></div>
                                    <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                        <div className="action-bar__item action-bar__item--link-container" />
                                        <div className="ui-title-bar__mobile-primary-actions">
                                            <div className="ui-title-bar__actions"><a onClick={() => this.onOpenModalNhapKho()} className="ui-button ui-button--primary ui-title-bar__action" >Thêm tài khoản</a></div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="ui-title-bar__actions-group">
                                    <div className="ui-title-bar__actions"><a onClick={() => this.onOpenModalNhapKho()} className="ui-button ui-button--primary ui-title-bar__action" >Thêm tài khoản</a></div>
                                    {
                                        this.state.loading ?
                                            <div className="ui-title-bar__actions"><a className="ui-button ui-button--primary ui-title-bar__action" ><i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} /></a></div>
                                            :
                                            <div className="ui-title-bar__actions"><a onClick={() => this.CapNhatBM()} className="ui-button ui-button--primary ui-title-bar__action" >LÀM MỚI</a></div>
                                    }
                                </div>
                            </div>
                            <div className="collapsible-header">
                                <div className="collapsible-header__heading" />
                            </div>
                        </header>
                        <div className="ui-layout ui-layout--full-width">
                            <div className="ui-layout__sections">
                                <div className="ui-layout__section">
                                    <div className="ui-layout__item">
                                        <section className="ui-card">
                                            <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                                <div className="next-tab__container ">
                                                    <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                        <li className="filter-tab-item"><a className="filter-tab next-tab">Tất cả (<span id="donmoi">{this.state.tong}</span>)</a></li>
                                                        {/* <li className="filter-tab-item"><a className="filter-tab  next-tab">Cần nhập (0)</a></li> */}
                                                    </ul>
                                                </div>
                                                {/* <div className="next-card__section next-card__section--no-bottom-spacing">
                                                    <div className="obj-filter hide-when-printing table-filter-container">
                                                        <div className="next-input-wrapper">
                                                            <div className="next-field__connected-wrapper">
                                                                <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">
                                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                    <div className="next-input--stylized next-field--connected"><span className="next-input__add-on next-input__add-on--before"><svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg></span>
                                                                        <input type="text" name="search" placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" defaultValue />
                                                                    </div>
                                                                </form>
                                                                <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                                <div className="ui-type-container clearfix">
                                                    <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                        <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                            <thead>
                                                                <tr>
                                                                    <th><span>#STT</span></th>
                                                                    <th><span>Avatar</span></th>
                                                                    <th><span>Full name</span></th>
                                                                    <th><span>UID</span></th>
                                                                    <th><span>Token</span></th>
                                                                    <th><span>Quyền ADS</span></th>
                                                                    <th><span>Số BM</span></th>
                                                                    <th><span>Chi tiết</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                    {
                        this.state.items ?
                            this.state.items.map((value,key) => {
                                return <tr>
                                    <td className="total">
                            <p>{key+1}</p>
                                    </td>
                                    <td>
                                        <a className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" currentitem="false">
                                            <img className="aspect-ratio__content" src={"https://graph.facebook.com/v2.10/"+value.uid+"/picture?type=large&redirect=true"} />
                                            <div className="__react_component_tooltip place-right type-dark " data-id="tooltip" />
                                        </a>
                                    </td>
                                    <td className="total">
                                        <p><a href={"https://fb.com/"+value.uid} target="_blank">{value.full_name}</a></p>
                                    </td>
                                    <td className="total">
                                        <p>{value.uid}</p>
                                    </td>
                                    <td className="total">
                                        {
                                            value.trang_thai == 'Hoạt động'?
                                            <label className="badge badge--status-success badge--progress-complete">{value.trang_thai}</label>
                                            :
                                            <label className="badge badge--status-warning badge--progress-complete">{value.trang_thai}</label>
                                        }
                                        
                                    </td>

                                    <td className="total">
                                        {
                                            value.quyen_ads == 'Live'?
                                            <label className="badge badge--status-success badge--progress-complete">{value.quyen_ads}</label>
                                            :value.quyen_ads == 'Die'?
                                            <label className="badge badge--status-warning badge--progress-complete">{value.quyen_ads}</label>
                                            :
                                            <label className="badge badge--status-warning badge--progress-complete hanche">{value.quyen_ads}</label>
                                        }
                                        
                                    </td>

                                    <td className="total">
                                        <p>{value.so_luong_bm}</p>
                                    </td>
                                    <td className="total">
                                        <p><Link to={"chi-tiet-tai-khoan-quan-ly-bm?uid="+value.uid+"&token="+value.token}> Xem</Link></p>
                                    </td>
                                </tr>

                            })
                            :
                            ""
                    }
                                                                
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* Modal Nhập kho */}
                <Modal open={openNhapKho} onClose={this.onCloseModalNhapKho} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '750px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">NHẬP TOKEN ADS EAAG</h2>
                                <p>https://www.facebook.com/adsmanager/manage</p>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">

                                <textarea ref={(input) => { this.vanDonInput = input; }} onKeyDown={this._handleKeyDown} onChange={(event) => this.isChange(event)} autoComplete="off" className="next-input" value={this.state.token_ads} placeholder="Nhập Token ADS" name="token_ads" type="text" />
                                <br></br>



                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                                <div className="form-group mt-2">
                                    {
                                        this.state.loading ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                            :
                                            <button type="submit" onClick={() => this.CapNhatTokenADS()} className="btn btn-primary">Cập nhật</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Modal nhập vận đơn*/}




            </div>
        )
    }
}
