// Chọn đơn vị, vùng, mã sản phẩm,
// Get danh sách
// Check tồn kho
// Check trùng
// Xuất kho
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
import { formatDate, parseDate } from 'react-day-picker/moment';
import * as Config from '../../config.js';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import OverlayLoader from 'react-overlay-loading/lib/OverlayLoader'
import Modal from 'react-responsive-modal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReactTooltip from 'react-tooltip'
import { check_phone } from '../../functions/function.js';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';

import ReactChartkick, { ColumnChart } from 'react-chartkick';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Showdate from './Showdate';

import ReactExport from "react-data-export";
import { API_URL } from '../../config';
const axios = require('axios');
var dateFormat = require('dateformat');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var moment = require('moment');

const danhMuc = [
    { value: 'GHTK', label: 'GHTK' },
    { value: 'Bưu điện', label: 'Bưu điện' },
];

const trangThai = [
    { value: 'chua_in', label: 'Chưa in' },
    { value: 'da_in', label: 'Đã in' },
];

const khuVuc = [
    { value: 'tat_ca', label: 'Tất cả khu vực' },
    { value: 'KV0', label: 'Hà Nội' },
    { value: 'KV1', label: 'Các tỉnh KV1' },
    { value: 'KV2', label: 'Các tỉnh KV2' },
    { value: 'KV3', label: 'Các tỉnh KV3' },
];

class XuatKho extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // danhmuc:{ value: 'tat_ca', label: 'Tất cả đơn vị vận chuyển' },
            trangThai:{ value: 'chua_in', label: 'Chưa in' },
            sanPham:{ value: 'tat_ca', label: 'Tất cả sản phẩm' },
            khuVuc:{ value: 'tat_ca', label: 'Tất cả khu vực' },
            pageChon: '',
            startDate: moment().format('YYYY-MM-DD'),
            endDate:  moment().format('YYYY-MM-DD'),
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        // this.Chart = this.Chart.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.checkOne = this.checkOne.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.unCheckAll = this.unCheckAll.bind(this);
    }
    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    danhMuc = (e) => {
        this.setState({
          danhmuc: e,
          ten_file: e.value+' '+moment().format('DD-MM-YYYY'),
        });
      }

      trangThai = (e) => {
        this.setState({
          trangThai: e,
        });
      }

      sanPham = (e) => {
        this.setState({
          sanPham: e,
        });
      }

      khuVuc = (e) => {
        this.setState({
          khuVuc: e,
        });
      }

    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }
    show() {
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }

    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        }, () => { this.loadData(); this.show() });
    }
    onDateRangeSelectChange(event) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate = moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-') + moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate = moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate = moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate = moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;

            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        }, () => { this.loadData() }
        )
    }
    showCalendar() {
        const from = new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{ marginBottom: 10, width: 350 }}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> -
                <span className="InputFromTo-to">
                    <DayPickerInput
                        ref={el => (this.to = el)}
                        value={to}
                        placeholder="To"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers,
                            month: to,
                            months: Config.MONTHS,
                            numberOfMonths: 2,
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }

    componentWillMount() {
        let token = localStorage.getItem('token');

        axios.get(Config.API_URL + 'danh-sach-ma-san-pham?token=' + token)
            .then(rstrangthai => {

                this.setState({
                    sanPhamFull: rstrangthai.data,
                });

            });
    }

    componentDidMount() {
        
    }

    XuatKhoAll() {
        if(!this.state.danhmuc){
            alert('Chưa chọn đơn vị vận chuyển')
        }else{
            this.setState({loading: true,trang_thai_xk:'',data_don:''})
            let token = localStorage.getItem('token');
            axios.get(API_URL + 'xuat-kho/danh-sach-don-hang', {
                params: {
                    sanPham: this.state.sanPham.value,
                    khuVuc: this.state.khuVuc.value,
                }
            })
                .then(success => {

                    this.setState({data_don:success.data})
    if(success.data.length>0){
        success.data.map((value, key) => {
            setTimeout(() => {
                this.CheckTrung(value.id, value.phone, value.ma_san_pham_full, value, key, success.data.length)
            }, key * 1000);
        })
    }else{
        this.setState({loading: false})
    }
                    
    
                })
                .catch(err => {
    
                })
                .finally(done => {
    
                });
        }
       
    }

    CheckTrung(id,phone,ma_san_pham_full,don_tong,so_key,so_don){
        axios.get(API_URL + 'xuat-kho/check-trung?phone='+phone)
            .then(success => {
                // console.log(success.data);
                if(success.data<2){
                    this.CheckTonKho(id,ma_san_pham_full,don_tong,so_key,so_don)
                }
                

            })
            .catch(err => {

            })
            .finally(done => {

            });
    }

    CheckTonKho(id,ma_san_pham_full,don_tong,so_key,so_don){
        
        var x = JSON.parse(ma_san_pham_full);
        axios.post(API_URL + 'xuat-kho/check-ton-kho',{modelid: x,cs: don_tong.cs})
            .then(success => {
                
                if(success.data.tt == 'true'){
                    // console.log('xuaats');
                    this.XuatKho(id,don_tong,success.data.phone_cs,so_key,so_don);
                }else{
                    console.log(id+" hết hàng");
                }
                

            });

    }

    XuatKho(id, don_tong, phone_cs,so_key,so_don) {

        axios.post(API_URL + 'xuat-kho/chi-tiet-don', { id_order: id })
            .then(success => {
                if(this.state.danhmuc.value == 'GHTK'){
                    this.TaoDonGHTK(id,don_tong, success.data, phone_cs,so_key,so_don);
                }else{
                    this.TaoDonVnPost(id,don_tong, success.data, phone_cs,so_key,so_don);
                }
            });

    }

    TaoDonVnPost(id,don_tong, don_chi_tiet, phone_cs,so_key,so_don){
        var trangThai = '';
        var search = '';
        var dateFrom = '';
        var dateTo = '';
        var page = 1;

        var order = {};
        order.ma_don = id; // Mã đơn
        let token = localStorage.getItem('token');
        axios.post(Config.API_URL + 'vnpost/chuan-bi?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + "&page=" + page, qs.stringify(order))
            .then(response => {
                this.GetDanhSachChuaIn(id);
                if (so_key + 1 >= so_don) {
                    this.setState({ loading: false, trang_thai_xk: "Đã xuất kho xong" })
                }
                // window.open(Config.API_URL + "vnpost/in?id=" + id, '_blank');
                // this.setState({
                //     data: response.data,
                //     loading: false,
                //     activePage: response.data.dontongid.current_page,
                //     itemsCountPerPage: response.data.dontongid.per_page,
                //     totalItemsCount: response.data.dontongid.total,
                //     path: response.data.dontongid.path,
                //     openModalGhiChu: false
                // });
            })
            .catch(function (error) {
                console.log(error);
            });
        

    }
    TaoDonGHTK(id,don_tong, don_chi_tiet, phone_cs,so_key,so_don) {
        // this.setState({ loading: true })
        if(phone_cs){
            phone_cs = phone_cs;
        }else{
            phone_cs = '0356525305';
        }
        let token = localStorage.getItem('token');
        var products = [];
        var sp_dagui = [];
        for (var i = 0; i < don_chi_tiet.length; i++) {
            sp_dagui.push("\n+" + don_chi_tiet[i].name);
            products.push(
                {
                    "name": don_chi_tiet[i].name + " / " + don_chi_tiet[i].thuoctinh,
                    "weight": 0.3,
                    "quantity": don_chi_tiet[i].quantity
                }
            )
        }
        var ma_don = id;
        var phone_gui = phone_cs;
        var ten_shop = 'ROZA PK';

        var buoi_lay = { value: '2', label: 'Buổi chiều' };

        // alert(phone_gui)
        var data = {
            "products": products,
            "order": {
                "id": 'R'+id,

                // Thông tin lấy hàng
                "pick_name": ten_shop,
                "pick_address": 'Số 19, Ngõ 106, Đường La Nội, Phường Dương Nội, Quận Hà Đông, Hà Nội',
                "pick_province": 'Hà Nội',
                "pick_district": 'Quận Hà Đông',
                "pick_tel": phone_gui,
                // Hết thông tin lấy hàng

                // Thông tin giao hàng
                "tel": don_tong.phone,
                "name": don_tong.full_name,
                "address": don_tong.address,
                "province": don_tong.tinh,
                "district": don_tong.huyen,
                "ward": this.state.address, // Thêm yêu cầu bắt buộc địa cấp cấp 4 (thôn/ấp/xóm/tổ/khu/…)
                "hamlet": "Khác",
                "is_freeship": "1",
                // "pick_date": "2016-09-30",
                "pick_money": don_tong.sumTotal,
                "note": 'Cho xem.Không giao được lưu kho 3 ngày & báo shop.Không tự ý hoàn.Khách đã xem mà không lấy liên hệ người gửi',
                "value": parseInt(don_tong.sumTotal),
                "pick_work_shift": buoi_lay,
                "transport": "fly", // Liên miền tự chọn bay, nội miền tự động chọn bộ 
                // Hết thông tin giao hàng

                // Phần thông tin hoàn hàng

                "use_return_address": 1, // 0 là giống với lấy hàng, 1 là khác.
                "return_name": "ROZA",
                "return_address": "Số 19, Ngõ 106, Đường La Nội, Phường Dương Nội, Quận Hà Đông, Hà Nội",
                "return_street": "Ngõ 106, Đường La Nội", //Tên đường/phố của người nhận hàng hóa
                "return_ward": "Phường Dương Nội",
                "return_district": "Quận Hà Đông",
                "return_province": "Hà Nội",
                "return_tel": phone_gui,
                "return_email": "rozavn.shop@gmail.com",

                // Hết thông tin hoàn hàng
            },
            'ma_don': ma_don,
            // 'van_don': this.state.van_don,
        }


        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
            trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
            search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
            website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
            khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = '';
        if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }


        axios.post(Config.API_URL + 'tao-don-ghtk-full?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token, qs.stringify(data))
            .then((response) => {
                this.GetDanhSachChuaIn(id);
                if (so_key + 1 >= so_don) {
                    this.setState({ loading: false, trang_thai_xk: "Đã xuất kho xong" })
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    GetDanhSachChuaIn(id) {
        axios.post(API_URL + 'xuat-kho/update-in', { 
            id: id, 
            trang_thai_in: this.state.trangThai.value,
            van_chuyen: this.state.danhmuc.value,
            startDate: this.state.startDate,
            endDate: this.state.endDate, 
        })
            .then(success => {

                this.setState({
                    data_full: success.data
                })


            });
    }

    GetDanhSachDaIn(){

    }


    componentDidUpdate(prevProps, prevState) {
        if(prevState.trangThai!==this.state.trangThai && this.state.danhmuc || prevState.danhmuc!==this.state.danhmuc || prevState.startDate!==this.state.startDate && this.state.danhmuc){
            console.log(this.state.trangThai);
            // console.log(this.state.danhmuc);
            console.log(this.state.startDate);
            console.log(this.state.endDate);
            this.setState({
                giatridachon:'',
                data_full:''
            })

            axios.post(API_URL + 'xuat-kho/danh-sach-in', { 
                trang_thai_in: this.state.trangThai.value,
                van_chuyen: this.state.danhmuc.value,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            })
            .then(success => {

                this.setState({
                    data_full: success.data
                })

                // if (success.data.tt == 'true') {
                //     // console.log('xuaats');
                //     this.XuatKho(id, don_tong, success.data.phone_cs, so_key, so_don);
                // } else {
                //     console.log(id + " hết hàng");
                // }


            });

            
        }
    }

    loadData(){

    }

     /**
     * function Checkone
     */
    checkOne () {
        var giatridachon = [];
        var checkboxes = document.getElementsByName('name[]');
        for (var i = 0; i < checkboxes.length; i++){
            if (checkboxes[i].checked == true) {
                giatridachon.push(parseInt(checkboxes[i].value));
            }
            
        }
        //Add vào state
        this.setState({
            giatridachon: giatridachon
        })
    }

    checkAll () {
        var giatridachon = [];
        var checkboxes = document.getElementsByName('name[]');
        for (var i = 0; i < checkboxes.length; i++){
            checkboxes[i].checked = true;
            giatridachon.push(parseInt(checkboxes[i].value));
        }
        console.log(giatridachon);
        //Add vào state
        this.setState({
            giatridachon: giatridachon
        })
    }

    unCheckAll () {
        var giatridachon = [];
        //Add vào state
        this.setState({
            giatridachon: giatridachon
        })
        var checkboxes = document.getElementsByName('name[]');
        for (var i = 0; i < checkboxes.length; i++){
            checkboxes[i].checked = false;
        }
        //Add vào state
        this.setState({
            giatridachon: giatridachon
        })
    }
    // Hết check

    inDaChon(){
        if (this.state.giatridachon.length) {
            var redirectWindow = window.open(Config.API_URL+"xuat-kho/in-don-da-chon?id=" + this.state.giatridachon, '_blank');
            redirectWindow.focus();

        } else {
            alert('Chưa nhập đủ thông tin')
        }
        console.log(this.state.giatridachon);
    }
    
    render() {

        console.log(this.state.danhmuc?this.state.danhmuc:"");
        

        var data = [];

        if(this.state.data_full){
            this.state.data_full.map((value1, key1) => {
                data.push([
                    value1.time_gui, 
                    value1.full_name + ', ' + value1.phone, 
                    value1.address+ ', ' + value1.xa + ', ' + value1.huyen + ', ' + value1.tinh,
                    value1.tinh,
                    '',
                    value1.phone,
                    value1.sumTotal,
                    value1.id,
                    'Quần áo',
                    value1.trong_luong,
                    value1.hang_van_chuyen,
                    value1.phi_gui_hang,
                ]);
            })
        }

        var multiDataSet = [
            {
                columns: ["Thời gian", "Tên người nhận", "Địa chỉ nhận","Tỉnh phát","Mã tỉnh","Số ĐT","Tiền COD","Mã Đơn","Sản Phẩm","Trọng Lượng","Dịch vụ","Phí Ship"],
                data: data
            },
        ];

        
        

        return (
            <div>
 <div id="content">
                    <div id="dashboard" className="container-fluid-md">
                        <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">
                            <div className="ui-title-bar">
                                <div className="ui-title-bar__navigation">
                                    <div className="ui-breadcrumbs">
                                        <Link className="ui-button ui-button--transparent ui-breadcrumb" to="/orders">
                                            <svg className="next-icon next-icon--size-20 next-icon--no-nudge">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" />
                                            </svg><span className="ui-breadcrumb__item">Đơn hàng</span></Link>
                                    </div>
                                    <div className="ui-title-bar__pagination" />
                                </div>
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group">
                                        <h1 className="ui-title-bar__title">Xuất Kho hoàng loạt</h1></div>
                                        <p>- Xuất kho thủ công các đơn hàng ở mục Ưu tiên trước khi xuất tự động.</p>
                                        <p>- Hệ thống không tự động xuất các đơn hàng TRÙNG, cần kiểm tra và xuất thủ công các đơn hàng đó.</p>
                                        <p>- Không tắt trình duyệt trong khi đang xuất kho.</p>
                                        <p>- Rà soát lại ghi chú của từng đơn hàng trước khi in phiếu nhãn.</p>
                                        <br></br>
                                        {
                                            this.state.trang_thai_xk?
                                        <h1 className="ui-title-bar__title">{this.state.trang_thai_xk}</h1>
                                            :
                                            ""
                                        }
                                    <div className="row">

                                    <div className="col-sm-3">
                                            <Select
                                                autofocus="autofocus"
                                                closeMenuOnSelect={false}
                                                components={makeAnimated()}
                                                // isClearable
                                                // isMulti
                                                onChange={this.sanPham}
                                                options={this.state.sanPhamFull}
                                                placeholder={this.state.sanPham?this.state.sanPham.label:'Tất cả sản phẩm'}
                                            />
                                        </div>

                                        <div className="col-sm-3">
                                            <Select
                                                autofocus="autofocus"
                                                closeMenuOnSelect={false}
                                                components={makeAnimated()}
                                                // isMulti
                                                onChange={this.khuVuc}
                                                options={khuVuc}
                                                placeholder={this.state.khuVuc?this.state.khuVuc.label:'Tất cả khu vực'}
                                            />
                                        </div>

                                        <div className="col-sm-3">
                                            <Select
                                                autofocus="autofocus"
                                                closeMenuOnSelect={false}
                                                components={makeAnimated()}
                                                // isMulti
                                                onChange={this.danhMuc}
                                                options={danhMuc}
                                                placeholder={this.state.danhmuc?this.state.danhmuc.label:'Chọn đơn vị vận chuyển'}
                                            />
                                        </div>

                                        <div className="col-sm-3">
                                        {this.state.data_don?this.state.data_don.length+" Đơn ":''}
                                        {
                                            this.state.loading?
                                            <a className="ui-button ui-button--primary ui-title-bar__action">
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                            </a>
                                            :
                                            <a className="ui-button ui-button--primary ui-title-bar__action" onClick={()=>this.XuatKhoAll()}>XUẤT NGAY</a>
                                        }
                                        


                                        </div>
                                    </div>

<hr></hr>
<div className="ui-title-bar__heading-group">
                                        <h1 className="ui-title-bar__title">Danh sách đơn xuất</h1></div>
                                        <br></br>
                                        <br></br>
                                    <div className="row">

<div className="col-sm-3">
        <Select
            autofocus="autofocus"
            closeMenuOnSelect={false}
            components={makeAnimated()}
            // isClearable
            // isMulti
            onChange={this.trangThai}
            options={trangThai}
            placeholder={this.state.trangThai?this.state.trangThai.label:'Chọn trạng thái in'}
        />
    </div>

    <div className="col-sm-3">
        <Select
            autofocus="autofocus"
            closeMenuOnSelect={false}
            components={makeAnimated()}
            // isMulti
            onChange={this.danhMuc}
            options={danhMuc}
            placeholder={this.state.danhmuc?this.state.danhmuc.label:'Chọn đơn vị vận chuyển'}
        />
    </div>
    <div className="col-sm-3">
        <div id="reportrangereceived" className="form-group" style={{ position: 'relative' }}>
            <span onClick={this.show} style={{ position: 'absolute', top: '5px', width: '100%' }}>
                {moment(this.state.startDate).format('DD/MM/YYYY')} đến {moment(this.state.endDate).format('DD/MM/YYYY')}</span>
            <i onClick={this.show} className="fa fa-calendar" style={{ float: 'right', paddingTop: '7px', paddingLeft: '7px' }}></i>
            {this.state.show ? <this.showCalendar /> : ""}
        </div>
    </div>
    <div className="col-sm-3">
        <a>
                                            {
                                                this.state.data_full ?
                                                this.state.data_full.length +  ' Đơn '
                                                    :
                                                    ''
                                            }
                                            </a>
    
        {
            this.state.data_full && this.state.data_full.length > 0?
            this.state.loading?
            'Loading...'
            :
            <div className="xuat-excel" style={{ position: 'relative' }}>
            <ExcelFile filename={this.state.ten_file} element={<button>Xuất Excel</button>}>
                <ExcelSheet dataSet={multiDataSet} name="Organization" />
            </ExcelFile>
            </div>
            :
            ""
        }
            
        

    </div>
</div>


                                </div>
                            </div>
{
    this.state.data_full && this.state.data_full.length > 0?
    <div className="ui-modal__footer">
                                <div className="">

                                    <div className="form-group mt-2">
                                        <button type="button" onClick={() => this.checkAll()} className="btn btn-outline-primary mr-2">Chọn tất cả</button>
                                        {
                                            this.state.giatridachon && this.state.giatridachon.length>0?
                                            <button type="button" onClick={() => this.unCheckAll()} className="btn btn-outline-primary mr-2">Bỏ chọn tất cả</button>
                                            :
                                            ""
                                        }
                                       
                                        {
                                            this.state.giatridachon && this.state.giatridachon.length>0?
                                            this.state.loading ?
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                                :
                                                <button type="submit" onClick={() => this.inDaChon()} className="btn btn-primary">In Đã Chọn</button>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                            </div>
    :
    ""
}
                            


                            {
                                                this.state.data_full && this.state.data_full.length > 0?
                            <div className="next-card next-card--primary" style={{ position: 'relative' }}>
                                <section className="next-card__section">
                                    <div id="report-table">
                                        <table className="expanded st">
                                           
                                                    <thead>
                                                        <tr>
                                                            <th className="reports-measure is-sortable"><span className="field">Chọn</span></th>
                                                            <th className="reports-measure is-sortable"><span className="field">STT</span></th>
                                                            <th className="reports-measure is-sortable"><span className="field">Thời gian xuất</span></th>
                                                            <th className="reports-property is-sortable"><span className="field">Tên Người Nhận</span></th>
                                                            <th className="reports-property is-sortable"><span className="field">Tỉnh</span></th>
                                                            {/* <th className="reports-measure is-sortable"><span className="field">Tỉnh Phát</span></th> */}
                                                            {/* <th className="reports-measure is-sortable"><span className="field">Mã Tỉnh</span></th> */}
                                                            <th className="reports-measure is-sortable"><span className="field">Số ĐT</span></th>
                                                            <th className="reports-measure is-sortable"><span className="field">Tiền COD</span></th>
                                                            <th className="reports-measure is-sortable"><span className="field">Mã Đơn</span></th>
                                                            {/* <th className="reports-measure is-sortable"><span className="field">Sản phẩm</span></th> */}
                                                            {/* <th className="reports-measure is-sortable"><span className="field">Trọng Lượng</span></th> */}
                                                            <th className="reports-measure is-sortable"><span className="field">Vận đơn</span></th>
                                                            <th className="reports-measure is-sortable"><span className="field">Dịch Vụ</span></th>
                                                            <th className="reports-measure is-sortable"><span className="field">Trạng thái</span></th>
                                                            <th className="reports-measure is-sortable"><span className="field">Ghi chú</span></th>

                                                        </tr>
                                                    </thead>
                                                   
                                            
                                            <tbody>
                                                {
                                                    this.state.data_full?
                                                    this.state.data_full.map((value, key) => {
                                                        return <tr key={key} className="data-row" style={{ opacity: 1 }}>
                                                              <td className="reports-measure">
                                                              <input type="checkbox" name="name[]"
                                                                    onClick={() => this.checkOne()}
                                                                    id={value.id}
                                                                    defaultValue={value.id} />
                                                            </td>
                                                             <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{key+1}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.time_gui}</span></div>
                                                            </td>
                                                            <td className="reports-property">
                                                                <div className="reports-property-content--drillable"><span className="value">{value.full_name}</span></div>
                                                            </td>
                                                            <td className="reports-property">
                                                                <div className="reports-property-content--drillable"><span className="value">{value.tinh}</span></div>
                                                            </td>
                                                            {/* <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.tinh}</span></div>
                                                            </td> */}
                                                            {/* <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.san_pham_trong_kho}</span></div>
                                                            </td> */}
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.phone}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.sumTotal}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.id}</span></div>
                                                            </td>
                                                            {/* <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{'Quần áo'}</span></div>
                                                            </td> */}
                                                            {/* <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.trong_luong}</span></div>
                                                            </td> */}
                                                             <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">
                                                                    {
                                                                    value.van_don !== 'Chưa cập nhật'?
                                                                    value.van_don
                                                                    :
                                                                    ""
                                                                    }
                                                                    </span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.hang_van_chuyen}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.giao_hang}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.note}</span></div>
                                                            </td>

                                                        </tr>
                                                    })
                                                    :''
                                                }

                                            </tbody>
                                            {/* <tfoot>
                                                <tr>
                                                    <th className="reports-property is-sortable"><span className="field">TỔNG</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">28.624.523</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">317</span></th>
                                                </tr>
                                            </tfoot> */}
                                        </table>
                                    </div>
                                </section>

                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>

                            </div>
                             :
                             ""
                     }
                        </div>
                    </div>
                </div>

            
            </div>
        );
    }
}

export default XuatKho;