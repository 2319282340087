import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Modal from 'react-responsive-modal';
import './baocao.css';
import './month-picker.css';
import List from './List.js';
import { connect } from 'react-redux';
import { getDaysInMonth } from '../../functions/function.js';
import { thisExpression } from '@babel/types';
import { NotificationContainer, NotificationManager } from 'react-notifications';

var dateFormat = require('dateformat');

const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');
class CapNhatQuangCao extends Component {
    constructor(props, context) {
        super(props, context)

        // Month
        this.state = {
            value: this.props.value
        }

        var now = new Date();
        this.state = {
            trangThai: '',
            search: '',
            loading: true,
            dateFrom: '',
            dateTo: '',
            website: '',
            khac: '',
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
            nhanthanhtoan: false,
            Loader: false,
            openChuanBi: false, // Modal
            noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
            t_id: '',
            so_luong: '',
            acountADS: [],
            year: now.getFullYear(),
            month: now.getMonth() + 1,
            date: now.getDate()
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }


    // Month
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        })
    }

    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }


    timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });


    }



    componentWillMount() {
        this.setState({
            trangThai: queryString.parse(window.location.search).trangThai,
            search: queryString.parse(window.location.search).search,
            website: queryString.parse(window.location.search).website,
            dateFrom: queryString.parse(window.location.search).dateFrom,
            dateTo: queryString.parse(window.location.search).dateTo,
            khac: queryString.parse(window.location.search).khac,
            page: queryString.parse(window.location.search).page,
        })
        this.getTaiKhoanQC();
    }




    componentDidUpdate = (prevProps, prevState) => {

        const query = {
            trangThai: this.state.trangThai,
            search: this.state.search,
            website: this.state.website,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            khac: this.state.khac,
            page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
            {
                pathname: '/cap-nhat-quang-cao',
                search: searchString,
            }
        )

        // Get data
        if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan) {


            if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
                this.setState({
                    page: 1
                })
            }
            this.setState({
                loading: true
            })
            var trangThai = '';
            if (this.state.trangThai) {
                trangThai = this.state.trangThai;
            }
            var search = '';
            if (this.state.search) {
                search = this.state.search;
            }
            var website = '';
            if (this.state.website) {
                website = this.state.website;
            }
            var khac = '';
            if (this.state.khac) {
                khac = this.state.khac;
            }
            var dateFrom = '';
            if (this.state.dateFrom) {
                dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
            }
            var dateTo = '';
            if (this.state.dateTo) {
                dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
            }
            let token = localStorage.getItem('token');
            axios.get(Config.API_URL + 'items?ma_don=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
                .then(response => {
                    this.setState({
                        items: response.data.data,
                        loading: false,
                        activePage: response.data.current_page,
                        itemsCountPerPage: response.data.per_page,
                        totalItemsCount: response.data.total,
                        path: response.data.path,
                    });
                })
                .catch(function (error) {
                    // this.setState({ loading: false });
                    console.log(error);
                });

        }
        // End Get data


        // Lặp qua tất cả mã sản phẩm. Sau đó lặp qua tất cả tài khoản QC để get phí
        if (prevState.items !== this.state.items || prevState.acountADS !== this.state.acountADS && this.state.items || prevState.year !== this.state.year || prevState.month !== this.state.month) {
            this.state.items.map((value, key) => {
                // console.log(value.itemid);
                localStorage.removeItem('CHI_PHI_ADS');
                if (this.state.month) {
                    for (let i = 1; i <= getDaysInMonth(this.state.month, this.state.year); i++) {
                        this.getChiPhiQC(value.itemid, this.state.year + '-' + this.state.month + '-' + i);
                        this.setState({ songay: getDaysInMonth(this.state.month, this.state.year) })
                    }
                }

            })
        }

        if (this.props.chonThang) {
            if (prevState.year !== this.props.chonThang.year || prevState.month !== this.props.chonThang.month) {
                this.setState({
                    year: this.props.chonThang.year,
                    month: this.props.chonThang.month
                })
            }

        }





    }


    getTaiKhoanQC() {
        // Phần 1: Get tất cả tài khoản ADS

        axios.get("https://graph.facebook.com/v3.3/me/adaccounts?fields=account_status,age,name,amount_spent,balance,business_name,timezone_name,timezone_offset_hours_utc,currency&access_token=" + Config.TOKEN_ADS)
            .then(response => {

                if (response && response.data && response.data.data) {
                    this.setState({
                        acountADS: response.data.data,
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getChiPhiQC(masp, ngay) { // Phần 2: Lặp qua từng sản phẩm, Get Chi phí ADS trong tất cả tài khoản, có lọc theo mã sản phẩm


        let token = localStorage.getItem('token');
                        var soluongdon = 0;
                        axios.get(Config.API_URL + 'so-luong-don?ngay=' + ngay + "&masp=" + masp + "&token=" + token)
                            .then(responsesodon => {
                                soluongdon = responsesodon.data;
                            })
                            .catch(function (error) {
                                // console.log(error);
                            });


        // Lặp qua từng tài khoản quảng cáo , lọc mã sản phẩm rồi cộng dồn chi phí
        if (this.state.acountADS) {

            // this.setState({loading: true})

            var chiphi_ma = 0;
            var checkdone = 0;
            this.state.acountADS.map((value, key) => {
                checkdone = checkdone + 1
                // console.log("xxxaa "+ value.id.replace("act_",''));
                // setTimeout(function() {
                axios.get('https://graph.facebook.com/v3.3/' + value.id + '/insights?access_token=' + Config.TOKEN_ADS + '&time_range={"since":"' + ngay + '","until":"' + ngay + '"}&__activeScenarios=["insightsTable.view","table_insights_footer_dd","table_insights_body_dd"]&__business_id=1143415902345331&_app=ADS_MANAGER&_priority=LOW&_reqName=adaccount/insights&_reqSrc=AdsPETableDataFetchingPolicy.fetchFooter>fetchAsyncAfterSyncFails&_sessionID=216f7f9cdde85334&action_attribution_windows=["default"]&fields=["created_time","spend"]&filtering=[{"field":"campaign.name","operator":"CONTAIN","value":"' + masp + '"}]&include_headers=false&level=account&limit=5000&locale=vi_VN&method=get&pretty=0&suppress_http_code=1&xref=f1b7ae7a51b7918')
                    .then(response => {


                        if (response && response.data && response.data.data && response.data.data[0] && response.data.data[0].spend) {
                            // Chi phí theo mã & ngày
                            chiphi_ma += parseInt(response.data.data[0].spend);

                            var item = {};
                            item.key = masp;
                            item.chiphi = chiphi_ma;
                            item.ngay = ngay;
                            item.soluongdon = soluongdon;

                            if (localStorage.getItem('CHI_PHI_ADS')) {
                                var CHI_PHI_ADS_LOCAL = JSON.parse(localStorage.getItem('CHI_PHI_ADS'));
                            } else {
                                var CHI_PHI_ADS_LOCAL = [];
                            }



                            if (CHI_PHI_ADS_LOCAL.length > 0) {
                                var checkTrung = false;
                                JSON.parse(localStorage.getItem('CHI_PHI_ADS')).map((value, key) => {
                                    if (JSON.parse(JSON.stringify(JSON.parse(value))).key === masp && JSON.parse(JSON.stringify(JSON.parse(value))).ngay === ngay) {
                                        checkTrung = true;
                                        var itemnew = {};
                                        itemnew.key = JSON.parse(JSON.stringify(JSON.parse(value))).key;
                                        itemnew.chiphi = chiphi_ma;
                                        itemnew.ngay = ngay;
                                        itemnew.soluongdon = soluongdon;
                                        CHI_PHI_ADS_LOCAL[key] = JSON.stringify(itemnew);
                                    }
                                })
                                if (checkTrung === false) {
                                    CHI_PHI_ADS_LOCAL.push(JSON.stringify(item));
                                }

                            } else {
                                CHI_PHI_ADS_LOCAL.push(JSON.stringify(item));
                            }
                            localStorage.setItem('CHI_PHI_ADS', JSON.stringify(CHI_PHI_ADS_LOCAL));



                            // Get lại CHI_PHI_ADS để tính phí ship

                            if (localStorage.getItem('CHI_PHI_ADS')) {
                                var CHI_PHI_ADS = JSON.parse(localStorage.getItem('CHI_PHI_ADS'));

                                if (CHI_PHI_ADS) {
                                    var countChiPhiNgay = 0;
                                    CHI_PHI_ADS.forEach(function (record) {
                                        countChiPhiNgay += parseInt(JSON.parse(record).chiphi);
                                    });
                                    // console.log("countChiPhiNgay " + countChiPhiNgay);

                                    var itemngay = {};
                                    itemngay.chiphi = countChiPhiNgay;
                                    itemngay.ngay = ngay;

                                    if (localStorage.getItem('CHI_PHI_ADS_NGAY')) {
                                        var CHI_PHI_ADS_NGAY_LOCAL = JSON.parse(localStorage.getItem('CHI_PHI_ADS_NGAY'));
                                    } else {
                                        var CHI_PHI_ADS_NGAY_LOCAL = [];
                                    }



                                    if (CHI_PHI_ADS_NGAY_LOCAL.length > 0) {
                                        var checkTrung = false;
                                        JSON.parse(localStorage.getItem('CHI_PHI_ADS_NGAY')).map((value, key) => {
                                            if (JSON.parse(JSON.stringify(JSON.parse(value))).ngay === ngay) {
                                                checkTrung = true;
                                                var itemnewngay = {};
                                                itemnewngay.chiphi = countChiPhiNgay;
                                                itemnewngay.ngay = ngay;
                                                CHI_PHI_ADS_NGAY_LOCAL[key] = JSON.stringify(itemnewngay);
                                            }
                                        })
                                        if (checkTrung === false) {
                                            CHI_PHI_ADS_NGAY_LOCAL.push(JSON.stringify(itemngay));
                                        }

                                    } else {
                                        CHI_PHI_ADS_NGAY_LOCAL.push(JSON.stringify(itemngay));
                                    }
                                    localStorage.setItem('CHI_PHI_ADS_NGAY', JSON.stringify(CHI_PHI_ADS_NGAY_LOCAL));


                                }

                                this.setState({
                                    CHI_PHI_ADS,
                                })

                            }







                        }

                    })
                    .catch(function (error) {
                        // console.log(error);
                    });
                // }, 1000*(key+1))

                if(checkdone>=key){
                    this.setState({loading: false})
                }
            })








        }


    }


    componentDidMount = () => {

        this.setState({
            loading: true
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
            trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
            search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
            website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
            khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = '';
        if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }


        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'items?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
            .then(response => {
                this.setState({
                    items: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                })
            })
            .catch(function (error) {
                // console.log(error);
            });


        // End Get data
    }


    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }


    chiPhiNgay(ngay,masp){
        var chiphi = 0
        if(localStorage.getItem('CHI_PHI_ADS') && this.state.year && this.state.month){


            JSON.parse(localStorage.getItem('CHI_PHI_ADS')).map(() => {
                
                var date = this.state.year + '-' + this.state.month + '-' + ngay;
                var ketqua = JSON.parse(localStorage.getItem('CHI_PHI_ADS')).filter(function (returnableObjects) {
                    // console.log(date);

                    if (returnableObjects) {
                        return JSON.parse(returnableObjects).ngay === date;
                    } else {
                        return null;
                    }

                })

                // Filter dữ liệu theo mã sản phẩm
                if (ketqua.length > 0) {

                    ketqua.map(() => {
                        var sanpham = ketqua.filter(function (returnableObjects) {
                            if (returnableObjects) {
                                return JSON.parse(returnableObjects).key === masp;
                            } else {
                                return null;
                            }

                        })

                        if (sanpham && sanpham[0]) {
                            chiphi = JSON.parse(sanpham[0]).chiphi;
                        }




                    })

                   

                }





            })


        }

        if (chiphi) {
            return <td className="vendor">
                <p><b>{chiphi.toLocaleString()}</b></p>
            </td>
        } else {
            return <td className="vendor">
                <p><b>-</b></p>
               
            </td>
        }

    }

    soLuongDon(ngay,masp){
        var soluongdon = 0
        if(localStorage.getItem('CHI_PHI_ADS') && this.state.year && this.state.month){


            JSON.parse(localStorage.getItem('CHI_PHI_ADS')).map(() => {
                
                var date = this.state.year + '-' + this.state.month + '-' + ngay;
                var ketqua = JSON.parse(localStorage.getItem('CHI_PHI_ADS')).filter(function (returnableObjects) {
                    // console.log(date);

                    if (returnableObjects) {
                        return JSON.parse(returnableObjects).ngay === date;
                    } else {
                        return null;
                    }

                })

                // Filter dữ liệu theo mã sản phẩm
                if (ketqua.length > 0) {

                    ketqua.map(() => {
                        var sanpham = ketqua.filter(function (returnableObjects) {

                            console.log(returnableObjects);
                            
                            if (returnableObjects) {
                                return JSON.parse(returnableObjects).key === masp;
                            } else {
                                return null;
                            }

                        })

                        if (sanpham && sanpham[0]) {
                            soluongdon = JSON.parse(sanpham[0]).soluongdon;
                        }




                    })

                   

                }





            })


        }

        if (soluongdon) {
            console.log(soluongdon);
            
            return <td className="vendor">
                <p><b>{soluongdon.toLocaleString()}</b></p>
            </td>
        } else {
            return <td className="vendor">
                <p><b>-</b></p>
               
            </td>
        }

    }


    capNhatQC(image, name, masp, price) {
        let token = localStorage.getItem('token');

        if(localStorage.getItem('CHI_PHI_ADS')){
            var CHI_PHI_ADS = JSON.parse(localStorage.getItem('CHI_PHI_ADS'))
        }else{
            var CHI_PHI_ADS = ""
        }


        var data = {};
        data.image = image;
        data.name = name;
        data.masp = masp;
        data.price = price;
        data.time = this.state.year +'-'+ this.state.month;
        data.CHI_PHI_ADS = CHI_PHI_ADS;

        axios.post(Config.API_URL + 'cap-nhat-quang-cao?token=' + token, qs.stringify(data))
            .then((res) => {
                NotificationManager.success('Cập nhật kết quả QC thành công', '', 2000, false);
            })
            .catch(function (error) {
                // console.log(error);
                NotificationManager.error('Tạo đơn không thành công', '', 2000, false);
                // return state
            });
    }

    render() {

        // console.log(this.state.month);



        const ngay = [];
        const TCP = [];
        const SLD = [];
        const CPD = [];

        for (let i = 1; i <= getDaysInMonth(this.state.month, this.state.year); i++) {
            ngay.push(<th colSpan="3"><center>{i}/{this.state.month}/{this.state.year}</center></th>);
            TCP.push(
                <th><span>TCP</span></th>
            );
            SLD.push(
                <th><span>SLĐ</span></th>
            );
            CPD.push(
                <th><span>CPĐ</span></th>
            );
        }

       

        return (
            <div id="content">

<NotificationContainer />


                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container  ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" /></svg></span>
                                    <h1 className="ui-title-bar__title">Cập nhật Quảng Cáo</h1></div>
                                <div className="action-bar">
                                    <div className="ui-title-bar__mobile-primary-actions">
                                        <div className="ui-title-bar__actions" onClick={()=>{this.capNhatQC()}}><a className="ui-button ui-button--primary ui-title-bar__action">Cập nhật</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                                <div className="ui-title-bar__actions" onClick={()=>{this.capNhatQC()}}><a className="ui-button ui-button--primary ui-title-bar__action">Cập nhật</a></div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>

                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li className="filter-tab-item" data-tab-index={1}>
                                                        <a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab next-tab--is-active">Tất cả sản phẩm</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-bordered bulk-action-context expanded baocao">
                                                        <thead>

                                                            <tr>

                                                                <th colSpan="4"><center>Sản phẩm</center></th>
                                                                <th colSpan="3"><center>Tháng {this.state.month}</center></th>
                                                                {ngay}

                                                            </tr>

                                                            <tr>
                                                                <th><span>Update</span></th>
                                                                <th><span>IMG</span></th>
                                                                <th><span style={{ width: '50px' }}>MÃ</span></th>
                                                                <th><span style={{ width: '150px' }}>TÊN</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>


                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                {
                                                                    this.state.songay && this.state.songay > 28 ?
                                                                        <th><span>TCP</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 28 ?
                                                                        <th><span>SLĐ</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 28 ?
                                                                        <th><span>CPĐ</span></th>
                                                                        :
                                                                        ""
                                                                }



                                                                {
                                                                    this.state.songay && this.state.songay > 29 ?
                                                                        <th><span>TCP</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 29 ?
                                                                        <th><span>SLĐ</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 29 ?
                                                                        <th><span>CPĐ</span></th>
                                                                        :
                                                                        ""
                                                                }



                                                                {
                                                                    this.state.songay && this.state.songay > 30 ?
                                                                        <th><span>TCP</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 30 ?
                                                                        <th><span>SLĐ</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 30 ?
                                                                        <th><span>CPĐ</span></th>
                                                                        :
                                                                        ""
                                                                }



                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.items ?
                                                                    this.state.items.map((value, key) => {
                                                                        var html = "<img src=" + value.image + " width='400px' />"
                                                                        return <tr>
                                                                            <td>
                                                                                <div className="ui-title-bar__actions-group">
                                                                                    <div className="ui-title-bar__actions" onClick={() => { this.capNhatQC(value.image,value.name,value.itemid,value.price) }}><a className="ui-button ui-button--primary ui-title-bar__action">Cập nhật</a></div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <a className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" data-tip={html}>
                                                                                    <img title={value.title} className="aspect-ratio__content" src={value.image} alt={value.title} />
                                                                                    <ReactTooltip html={true} place={'right'} />
                                                                                </a>
                                                                            </td>
                                                                            <td className="name">
                                                                                <div className="ui-stack ui-stack--wrap">
                                                                                    <div className="ui-stack-item">
                                                                                        {value.itemid}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="total">
                                                                                <p>{value.name}</p>
                                                                            </td>

                                                                            <td className="vendor">
                                                                                <p><b>1</b></p>
                                                                            </td>
                                                                            <td className="vendor">
                                                                                <p><b>2</b></p>
                                                                            </td>
                                                                            <td className="vendor">
                                                                                <p><b>3</b></p>
                                                                            </td>


                                                                            {this.chiPhiNgay(1, value.itemid)}
                                                                            {this.soLuongDon(1, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(2, value.itemid)}
                                                                            {this.soLuongDon(2, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(3, value.itemid)}
                                                                            {this.soLuongDon(3, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            
                                                                            {this.chiPhiNgay(4, value.itemid)}
                                                                            {this.soLuongDon(4, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            
                                                                            {this.chiPhiNgay(5, value.itemid)}
                                                                            {this.soLuongDon(5, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            
                                                                            {this.chiPhiNgay(6, value.itemid)}
                                                                            {this.soLuongDon(6, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(7, value.itemid)}
                                                                            {this.soLuongDon(7, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(8, value.itemid)}
                                                                            {this.soLuongDon(8, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(9, value.itemid)}
                                                                            {this.soLuongDon(9, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(10, value.itemid)}
                                                                            {this.soLuongDon(10, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(11, value.itemid)}
                                                                            {this.soLuongDon(11, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(12, value.itemid)}
                                                                            {this.soLuongDon(12, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(13, value.itemid)}
                                                                            {this.soLuongDon(13, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(14, value.itemid)}
                                                                            {this.soLuongDon(14, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(15, value.itemid)}
                                                                            {this.soLuongDon(15, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(16, value.itemid)}
                                                                            {this.soLuongDon(16, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(17, value.itemid)}
                                                                            {this.soLuongDon(17, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(18, value.itemid)}
                                                                            {this.soLuongDon(18, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(19, value.itemid)}
                                                                            {this.soLuongDon(19, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(20, value.itemid)}
                                                                            {this.soLuongDon(20, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(21, value.itemid)}
                                                                            {this.soLuongDon(21, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(22, value.itemid)}
                                                                            {this.soLuongDon(22, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(23, value.itemid)}
                                                                            {this.soLuongDon(23, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(24, value.itemid)}
                                                                            {this.soLuongDon(24, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(25, value.itemid)}
                                                                            {this.soLuongDon(25, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(26, value.itemid)}
                                                                            {this.soLuongDon(26, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(27, value.itemid)}
                                                                            {this.soLuongDon(27, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.chiPhiNgay(28, value.itemid)}
                                                                            {this.soLuongDon(28, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.state.songay && this.state.songay > 28 ?this.chiPhiNgay(29, value.itemid):""}
                                                                            {this.soLuongDon(29, value.itemid)}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.state.songay && this.state.songay > 29 ?this.chiPhiNgay(30, value.itemid):""}
                                                                            {this.state.songay && this.state.songay > 29 ?this.soLuongDon(30, value.itemid):""}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>

                                                                            {this.state.songay && this.state.songay > 30 ? this.chiPhiNgay(31, value.itemid) : ""}
                                                                            {this.state.songay && this.state.songay > 30 ? this.soLuongDon(31, value.itemid) : ""}
                                                                            <td className="vendor">
                                                                                <p><b>y</b></p>
                                                                            </td>


                                                                            

                                                                        </tr>
                                                                    })
                                                                    :
                                                                    ""
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        chonThang: state.PickDate.chonThang
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        pickDate: (pickDate) => {
            dispatch({
                type: "PICK_DATE",
                pickDate
            })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CapNhatQuangCao)