import React, { Component } from 'react';
import * as Func from '../../functions/function.js';
import * as Config from '../../config.js';
import ReactChartkick, { ColumnChart  } from 'react-chartkick';
import DayPicker,{ DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import {  Link } from "react-router-dom";
import Select from 'react-select'
import Showdate from './includes/Showdate';
import Helmet from 'react-helmet';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';

const lydohoan = [
    { value: 'Không liên lạc được', label: 'Không liên lạc được' },
    { value: 'Phát lại nhiều lần không được', label: 'Phát lại nhiều lần không được' },
    { value: 'Nhầm số', label: 'Nhầm số' },
    { value: 'Khách báo hủy sau khi gửi', label: 'Khách báo hủy sau khi gửi' },
    { value: 'Không nhận hàng do không tin tưởng', label: 'Không nhận hàng do không tin tưởng' },
    { value: 'Khách lừa đảo( đi công tác, ko nhận nữa, ko thích )', label: 'Khách lừa đảo( đi công tác, ko nhận nữa, ko thích )' },
    { value: 'Trùng đơn', label: 'Trùng đơn' },
    { value: 'Đã nhận hàng của shop khác', label: 'Đã nhận hàng của shop khác' },
    { value: 'Sản phẩm lỗi', label: 'Sản phẩm lỗi' },
    { value: 'Gửi sai sản phẩm(Lỗi kho)', label: 'Gửi sai sản phẩm(Lỗi kho)' },
    { value: 'Lỗi NVCS(Tạo nhầm khách, nhầm sản phẩm)', label: 'Lỗi NVCS(Tạo nhầm khách, nhầm sản phẩm)' },
    { value: 'Chê sản phẩm xấu, ko ưng(Do khách)', label: 'Chê sản phẩm xấu, ko ưng(Do khách)' },
    { value: 'Không đúng Quảng Cáo(Do Shop)', label: 'Không đúng Quảng Cáo(Do Shop)' },
    { value: 'Giao không kịp do khách hẹn ngày', label: 'Giao không kịp do khách hẹn ngày' },
    { value: 'Gửi hàng quá lâu', label: 'Gửi hàng quá lâu' },
    { value: 'Không vừa', label: 'Không vừa' },
    { value: 'Lí do khác', label: 'Lí do khác' },
  ];


const axios = require('axios');
var moment = require('moment');

class HoanLyDo extends Component {
    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            chartData:[],
            // startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            // endDate:  moment().subtract(1, 'days').format('YYYY-MM-DD'),
            // startDate: moment().subtract(10, 'days').format('YYYY-MM-DD'),
            // endDate: moment().format('YYYY-MM-DD'), 
            startDate: moment().format('YYYY-MM-01'),
            endDate: moment().format('YYYY-MM-DD'),
            from: undefined,
            to: undefined,
            show: false,
            selectedLyDo: null,
            trangthai: [],
            tong_chi_tieu: 0,
            so_don: 0,
            hieuquaon: false
        };
        // this.Chart = this.Chart.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }

    handleChangeLyDo = (selectedLyDo) => {
        this.setState({ selectedLyDo });
        this.setState({
            trangThaiDaChon: selectedLyDo.value,
        });
    }

   




    loadData() {
        let token = localStorage.getItem('token');
var ma_sp = this.state.selectedLyDo?this.state.selectedLyDo.value:"";
        axios.get(Config.API_URL + 'bao-cao-hoan-hang?token=' + token + '&ma_sp=' + ma_sp + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate)
            .then(rs => {
                this.setState({data: rs.data})
            });

        axios.get(Config.API_URL + 'danh-sach-ma-san-pham?token=' + token)
            .then(rstrangthai => {
                this.setState({
                    trangthai: rstrangthai.data,
                });
            });
    }



    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.selectedLyDo !== prevState.selectedLyDo){
            this.loadData();
        }
    }
    


    
    tmplNv(item,key){
        return(
            <tr className="data-row" style={{opacity: 1}}>
                <td className="reports-property">
                    <div className="reports-property-content--drillable"><a className="value">{item}</a></div>
                </td>

            </tr>
        )
    }

    show(){
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }
    
    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        },()=>{this.loadData();this.show()});
    }
    onDateRangeSelectChange(event){
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate =  moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate =  moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate =  moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate =  moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate =  moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-')+moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate =  moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate =  moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate =  moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;
        
            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        },() => { this.loadData() }
        )  
    }
    showCalendar(){
        const from =  new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{marginBottom:10,width:350}}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> - 
                <span className="InputFromTo-to">
                <DayPickerInput
                    ref={el => (this.to = el)}
                    value={to}
                    placeholder="To"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: to,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                    }}
                    onDayChange={this.handleToChange}
                />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }

    render() {
        if(this.state.selectedLyDo){
            console.log(this.state.selectedLyDo.value);
        }
        
        let tong_don_hoan = 0;
        let tong_don = 0;
        let tong_phan_hoan = 0;
        let tong_thanh_cong = 0;
        const { selectedLyDo } = this.state;
        return (
            <div id="content">
                <div id="dashboard" className="container-fluid-md">
                    <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">
                        <div className="ui-title-bar">
                            <div className="ui-title-bar__navigation">
                                <div className="ui-breadcrumbs">
                                <Link to="/analytics" className="ui-button ui-button--transparent ui-breadcrumb">
                                    <svg className="next-icon next-icon--size-20 next-icon--no-nudge"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" /> </svg>
                                    <span className="ui-breadcrumb__item">Báo cáo</span>
                                </Link>
                                </div>
                                    <div className="ui-title-bar__pagination">
                                </div>
                            </div>
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <h1 className="ui-title-bar__title">Lý do hoàn hàng</h1>
                                    <h2>Lưu ý: Thời gian hoàn hàng tính theo ngày xuất kho, nên có thể khác bảng thống kê theo ngày tạo.</h2>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div id="reportrangereceived" className="form-group" style={{ position: 'relative' }}>
                                            <span onClick={this.show} style={{ position: 'absolute', top: '5px', width: '100%' }}>
                                                {moment(this.state.startDate).format('DD/MM/YYYY')} đến {moment(this.state.endDate).format('DD/MM/YYYY')}</span>
                                            <i onClick={this.show} className="fa fa-calendar" style={{ float: 'right', paddingTop: '7px', paddingLeft: '7px' }}></i>
                                            {this.state.show ? <this.showCalendar /> : ""}
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-3">

                                        <Select
                                            value={selectedLyDo}
                                            onChange={this.handleChangeLyDo}
                                            options={this.state.trangthai}
                                            placeholder='Chọn mã sản phẩm'
                                        />
                                    </div>
                                </div>



                            </div>
                        </div>


                        <div className="next-card next-card--primary" style={{position: 'relative'}}>
                            <section className="next-card__section">
                                <div id="report-table">
                                    <table className="expanded st">
                                       

                                        {
                                            this.state.data ?
                                                <thead>
                                                    <tr>
                                                        <th className="reports-property is-sortable"><span className="field">STT</span></th>
                                                        <th className="reports-property is-sortable"><span className="field">Lý do hoàn</span></th>
                                                        <th className="reports-property is-sortable"><span className="field">Số đơn hoàn</span></th>
                                                        <th className="reports-property is-sortable"><span className="field">% đơn hoàn</span></th>
                                                        <th className="reports-property is-sortable"><span className="field">% tổng đơn</span></th>


                                                    </tr>
                                                </thead>
                                                :
                                                ""
                                        }

                                        <tbody>


                                        {
                                                this.state.data?
                                                this.state.data.map((value, key) => {
                                                    tong_don_hoan += parseFloat(value.value);
                                                    tong_don = parseInt(value.value+value.thanh_cong);
                                                    tong_thanh_cong = parseInt(value.value+value.tong_thanh_cong);
                                                    // tong_don = parseFloat(Func.LamTronPhanTram((value.value/tong_don)*100,2));
                                                    tong_phan_hoan += parseFloat(Func.LamTronPhanTram((value.value/value.hoan_hang)*100,2));
                                                    console.log(tong_phan_hoan);
                                                    
                                                    return  <tr key={key} className="data-row" style={{opacity: 1}}>
                                                        <td className="reports-property">
                                                        <div className="reports-property-content--drillable">{key+1}</div>
                                                    </td>
                                                    <td className="reports-property">
                                                        <div className="reports-property-content--drillable">{value.name}</div>
                                                    </td>
                                                    <td className="reports-property">
                                                        <div className="reports-property-content--drillable">{value.value}</div>
                                                    </td>
                                                    <td className="reports-property">
                                                        <div className="reports-property-content--drillable">
                                                            {/* { Math.round( (value.value/value.hoan_hang)*1000 )/1000 } % */}
                                                            {parseFloat(Func.LamTronPhanTram((value.value/value.hoan_hang)*100,2))} %
                                                            </div>
                                                    </td>
                                                    <td className="reports-property">
 <div className="reports-property-content--drillable">{parseFloat(Func.LamTronPhanTram((value.value/tong_don)*100,2))} %</div>
                                                    </td>
                                                </tr>
                                                })
                                                :
                                                ""
                                            }


                                        </tbody>
                                        {
                                            this.state.data ?

                                                <tfoot>
                                                    

                                                    <tr>
                                                        <th className="reports-property is-sortable"><span className="field">TỔNG</span></th>

                                                        <th className="reports-measure is-sortable"><span className="field"></span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">ĐƠN HOÀN: {Func.FormatNumber(tong_don_hoan)}</span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">ĐƠN THÀNH CÔNG: {tong_don-tong_don_hoan}</span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">TỔNG ĐƠN: {tong_don}</span></th>
                                                        {/* <th className="reports-measure is-sortable"><span className="field">{parseFloat(tong_don_hoan/tong_don).toFixed(2)*100}%</span></th> */}


                                                    </tr>

                                                    <tr>
                                                        <th className="reports-property is-sortable"><span className="field">CHIẾM</span></th>

                                                        <th className="reports-measure is-sortable"><span className="field"></span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">{(parseFloat((tong_don_hoan/tong_don))*100).toFixed(2)} %</span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">{(parseFloat(((tong_don-tong_don_hoan)/tong_don))*100).toFixed(2)} %</span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">Hoàn/tổng {(parseFloat(((tong_don_hoan)/tong_thanh_cong))*100).toFixed(2)} %</span></th>
                                                        {/* <th className="reports-measure is-sortable"><span className="field">{parseFloat(Func.FormatNumber(tong_phan_hoan))} %</span></th> */}
                                                        {/* <th className="reports-measure is-sortable"><span className="field">{parseFloat(tong_don_hoan/tong_don).toFixed(2)*100}%</span></th> */}


                                                    </tr>


                                                </tfoot>
                                                :
                                                ""
                                        }

                                    </table>
                                </div>
                            </section>
                        </div>
                        

                        
                    </div>
                </div>
            </div>
        );
    }
}

export default HoanLyDo;