import React, { Component } from 'react';
import Login from '../Login/Login';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Order from '../Orders/Order';
import OrderDetail from '../Orders/OrderDetail';
import CreateOrder from '../Orders/CreateOrder';
import Products from '../Products/Products';
import Dashboard from '../Home/Dashboard';
import AddProduct from '../Products/AddProduct';
import QuanLyKho from '../Products/QuanLyKho';
import LichSuNhapKho from '../Products/LichSuNhapKho';
import ToDay from '../ThongKe/ToDay';
import Register from '../Login/Register';
import BaoCaoQC from '../BaoCao/BaoCaoQC';
import CapNhatQuangCao from '../BaoCao/CapNhatQuangCao';
import Setting from '../Setting/Setting';
import BaoCaoQCHomNay from '../BaoCao/BaoCaoQCHomNay';
import NhapHang from '../Products/NhapHang';
import Dashboardanalytics from '../ThongKe/Dashboardanalytics';
import Saleranalytics from '../ThongKe/Saleranalytics';
import Indexanalytics from '../ThongKe/Indexanalytics';
import Routeranalytics from '../ThongKe/Routeranalytics'; 
import ViDienTu from '../ViDienTu/ViDienTu';
import DoiSoat from '../ViDienTu/DoiSoat';
import ThongKeTheoNhanVien from '../ThongKe/ThongKeTheoNhanVien';
import QuanLyDoiSoat from '../ThongKe/QuanLyDoiSoat';
import XacNhanDon from '../Orders/XacNhanDon';
import HieuQuaQuangCao from '../BaoCao/HieuQuaQuangCao';
import QuanLyNhapHang from '../Products/QuanLyNhapHang';
import DanhSachNhanVien from '../BaoCao/DanhSachNhanVien';
import HoTroGiaoHang from '../Orders/HoTroGiaoHang';
import CheckInfo from '../Orders/CheckInfo';
import DangFanPage from '../Fanpage/DangFanPage';
import HoanLyDo from '../ThongKe/HoanLyDo';
import HieuQuaQuangCaoTheoSanPham from '../ThongKe/HieuQuaQuangCaoTheoSanPham';
import CopyFanpage from '../Fanpage/CopyFanpage';
import TaiChinhTonKho from '../BaoCao/TaiChinhTonKho';
import XuatExcel from '../ThongKe/XuatExcel';
import NhomTaiKhoanFacebook from '../Facebok/NhomTaiKhoanFacebook';
import ChiTietNhomFacebook from '../Facebok/ChiTietNhomFacebook';
import ApVanDon from '../ThongKe/ApVanDon';
import ConvertPhoneUid from '../ThongKe/ConvertPhoneUid';
import DoiSoatVnpost from '../ThongKe/DoiSoatVnpost';
import DanhSachTKQLBM from '../ThongKe/DanhSachTKQLBM';
import ChiTietTaiKhoanQuanLyBm from '../ThongKe/ChiTietTaiKhoanQuanLyBm';
import ScanGroup from '../Facebok/ScanGroup';
import UidToFb from '../Facebok/UidToFb';
import GetAllPhone from '../Facebok/GetAllPhone';
import XuatKho from '../XuatKho/XuatKho';
import TKQuangCao from '../ThongKe/TKQuangCao';
import NoPage from '../Home/NoPage';
import DanhSachHoan from '../BaoCao/DanhSachHoan';
import DanhSachDon from '../BaoCao/DanhSachDon';
import ApVanDonEMS from '../ThongKe/EMS';
import DaDoiSoatVnpot from '../BaoCao/DaDoiSoatVnpost';
import SanPhamTongKho from '../Products/SanPhamTongKho';

const OrderDetailDH = ({ match }) => (
    <OrderDetail id={match.params.id} />
)

class DieuHuong extends Component {
    render() {
        let tokenLocal = localStorage.getItem('token');
            return (
                <div>
                     <Route exact path="/" render={() => (tokenLocal ? (<Dashboard />) : (<Redirect to="/login" />))} />
                     <Route exact path="/products" render={() => (tokenLocal ? (<Products />) : (<Redirect to="/login" />))} />
                     <Route exact path="/quan-ly-kho" render={() => (tokenLocal ? (<QuanLyKho />) : (<Redirect to="/login" />))} />
                     <Route exact path="/san-pham-tong-kho" render={() => (tokenLocal ? (<SanPhamTongKho />) : (<Redirect to="/login" />))} />
                     <Route exact path="/lich-su-nhap-kho" render={() => (tokenLocal ? (<LichSuNhapKho />) : (<Redirect to="/login" />))} />
                     <Route exact path="/to-day" render={() => (tokenLocal ? (<ToDay />) : (<Redirect to="/login" />))} />
                     <Route exact path="/bao-cao-quang-cao-hom-nay" render={() => (tokenLocal ? (<BaoCaoQCHomNay />) : (<Redirect to="/login" />))} />
                     <Route exact path="/add-product" render={() => (tokenLocal ? (<AddProduct />) : (<Redirect to="/login" />))} />
                     {/* <Route exact path="/dashboard" render={() => (tokenLocal ? (<Dashboard />) : (<Redirect to="/login" />))} /> */}
                     <Route exact path="/orders" render={() => (tokenLocal ? (<Order />) : (<Redirect to="/login" />))} />
                     <Route exact path="/create" render={() => (tokenLocal ? (<CreateOrder />) : (<Redirect to="/login" />))} />
                     <Route exact path="/nhap-hang" render={() => (tokenLocal ? (<NhapHang />) : (<Redirect to="/login" />))} />
                     <Route path="/order/:id" component={OrderDetail} />
                     {/* <Route exact path="/order/:id" component={OrderDetailDH} /> */}
                     <Route exact path="/login" render={() => (!tokenLocal? (<Login />) : (<Redirect to="/" />))} />
                     <Route exact path="/register" render={() => (!tokenLocal ? (<Register />) : (<Redirect to="/" />))} />
                     <Route exact path="/cap-nhat-quang-cao" render={() => (tokenLocal ? (<CapNhatQuangCao />) : (<Redirect to="/login" />))} />
                     <Route exact path="/bao-cao-quang-cao" render={() => (tokenLocal ? (<BaoCaoQC />) : (<Redirect to="/login" />))} />
                     <Route exact path="/settings" render={() => (tokenLocal ? (<Setting />) : (<Redirect to="/login" />))} />
                     <Route exact path="/vi-dien-tu" render={() => (tokenLocal ? (<ViDienTu />) : (<Redirect to="/login" />))} />
                     <Route exact path="/doi-soat" render={() => (tokenLocal ? (<DoiSoat />) : (<Redirect to="/login" />))} />
                     <Route exact path="/quan-ly-doi-soat" render={() => (tokenLocal ? (<QuanLyDoiSoat />) : (<Redirect to="/login" />))} />
                     <Route exact path="/xac-nhan-don" render={() => (tokenLocal ? (<XacNhanDon />) : (<Redirect to="/login" />))} />
                     <Route exact path="/quan-ly-nhap-hang" render={() => (tokenLocal ? (<QuanLyNhapHang />) : (<Redirect to="/login" />))} />
                     {/* by huudv: add router for analytics */}
                     <Route exact path="/analytics" render={() => (tokenLocal ? (<Indexanalytics />) : (<Redirect to="/login" />))} />
                     <Route exact path="/analytics/dashboard" render={() => (tokenLocal ? (<Dashboardanalytics />) : (<Redirect to="/login" />))} />
                     <Route exact path="/analytics/revenuebyaccount" render={() => (tokenLocal ? (<Saleranalytics />) : (<Redirect to="/login" />))} />
                     {/*Từ phần bên dưới này dùng chung form cho analytics do mới nghĩ ra nên ko dùng toàn bộ */}
                     <Route  exact path="/analyticsnew/*" {...this.props} render={() => (tokenLocal ? (<Routeranalytics />) : (<Redirect to="/login" />))} />
                     {/* by huudv: add router for analytics */}
    
                     <Route exact path="/thong-ke-theo-nhan-vien" render={() => (tokenLocal ? (<ThongKeTheoNhanVien />) : (<Redirect to="/login" />))} />
                     <Route exact path="/hieu-qua-quang-cao" render={() => (tokenLocal ? (<HieuQuaQuangCao />) : (<Redirect to="/login" />))} />
                     <Route exact path="/hieu-qua-quang-cao-theo-san-pham" render={() => (tokenLocal ? (<HieuQuaQuangCaoTheoSanPham />) : (<Redirect to="/login" />))} />
                     <Route exact path="/danh-sach-nhan-vien" render={() => (tokenLocal ? (<DanhSachNhanVien />) : (<Redirect to="/login" />))} />
    
                     <Route exact path="/ho-tro-giao-hang" render={() => (tokenLocal ? (<HoTroGiaoHang />) : (<Redirect to="/login" />))} />
                     <Route exact path="/check-info" render={() => (tokenLocal ? (<CheckInfo />) : (<Redirect to="/login" />))} />
                     <Route exact path="/dang-fanpage" render={() => (tokenLocal ? (<DangFanPage />) : (<Redirect to="/login" />))} />
                     <Route exact path="/bao-cao-hoan" render={() => (tokenLocal ? (<HoanLyDo />) : (<Redirect to="/login" />))} />
                     <Route exact path="/copy-fanpage" render={() => (tokenLocal ? (<CopyFanpage />) : (<Redirect to="/login" />))} />
                     <Route exact path="/tai-chinh-ton-kho" render={() => (tokenLocal ? (<TaiChinhTonKho />) : (<Redirect to="/login" />))} />
                     <Route exact path="/xuat-excel" render={() => (tokenLocal ? (<XuatExcel />) : (<Redirect to="/login" />))} />
                     <Route exact path="/nhom-tai-khoan-facebook" render={() => (tokenLocal ? (<NhomTaiKhoanFacebook />) : (<Redirect to="/login" />))} />
                     <Route exact path="/chi-tiet-nhom-facebook" render={() => (tokenLocal ? (<ChiTietNhomFacebook />) : (<Redirect to="/login" />))} />
                     <Route exact path="/ap-van-don-vnpost" render={() => (tokenLocal ? (<ApVanDon />) : (<Redirect to="/login" />))} />
                     <Route exact path="/ap-van-don-ems" render={() => (tokenLocal ? (<ApVanDonEMS />) : (<Redirect to="/login" />))} />
                     <Route exact path="/convert" render={() => (tokenLocal ? (<ConvertPhoneUid />) : (<Redirect to="/login" />))} />
                     <Route exact path="/doi-soat-vnpost" render={() => (tokenLocal ? (<DoiSoatVnpost />) : (<Redirect to="/login" />))} />
                     <Route exact path="/danh-sach-tai-khoan-quan-ly-bm" render={() => (tokenLocal ? (<DanhSachTKQLBM />) : (<Redirect to="/login" />))} />
                     <Route exact path="/chi-tiet-tai-khoan-quan-ly-bm" render={() => (tokenLocal ? (<ChiTietTaiKhoanQuanLyBm />) : (<Redirect to="/login" />))} />
                     <Route exact path="/scan-group" render={() => (tokenLocal ? (<ScanGroup />) : (<Redirect to="/login" />))} />
                     <Route exact path="/uid-to-phone" render={() => (tokenLocal ? (<UidToFb />) : (<Redirect to="/login" />))} />
                     <Route exact path="/get-all-phone" render={() => (tokenLocal ? (<GetAllPhone />) : (<Redirect to="/login" />))} />
                     <Route exact path="/xuat-kho" render={() => (tokenLocal ? (<XuatKho />) : (<Redirect to="/login" />))} />
    
                     <Route exact path="/tai-khoan-quang-cao" render={() => (tokenLocal ? (<TKQuangCao />) : (<Redirect to="/login" />))} />
                     <Route exact path="/danh-sach-hoan" render={() => (tokenLocal ? (<DanhSachHoan />) : (<Redirect to="/login" />))} />
                     <Route exact path="/danh-sach-don" render={() => (tokenLocal ? (<DanhSachDon />) : (<Redirect to="/login" />))} />
                     <Route exact path="/da-doi-soat-vnpost" render={() => (tokenLocal ? (<DaDoiSoatVnpot />) : (<Redirect to="/login" />))} />
                </div>
            );

       
    }
}

export default DieuHuong;