import React, { Component } from 'react'

export default class NoPage extends Component {
    render() {
        return (
            <div>
                <br></br>
                <br></br>
                <p><center>Tài khoản đang được đăng ký vào hệ thống!</center></p>
            </div>
        )
    }
}
