import React, { Component } from 'react';
const axios = require('axios');
class ScanGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            danhmuc:'',
            pageChon:'',
        }
    }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }


    setToken1() {
        if (this.state.TOKEN_1) {
            localStorage.setItem('TOKEN_1', this.state.TOKEN_1);
        }
    }

    setToken2() {
        if (this.state.TOKEN_2) {
            localStorage.setItem('TOKEN_2', this.state.TOKEN_2);
        }
    }

    idGroup() {
        if (this.state.id_group) {
            localStorage.setItem('id_group', this.state.id_group);
        }
    }

    soPost() {
        if (this.state.so_post) {
            localStorage.setItem('so_post', this.state.so_post);
        }
    }

    componentDidMount() {
        if (localStorage.getItem('TOKEN_1')) {
            var TOKEN_1 = localStorage.getItem('TOKEN_1');
            this.setState({ TOKEN_1: TOKEN_1 })
        }
        if (localStorage.getItem('TOKEN_2')) {
            var TOKEN_2 = localStorage.getItem('TOKEN_2');
            this.setState({ TOKEN_2: TOKEN_2 })
        }
        if (localStorage.getItem('id_group')) {
            var id_group = localStorage.getItem('id_group');
            this.setState({ id_group: id_group })
        }
        if (localStorage.getItem('DATA_UID_GROUP')) {
            var tep = JSON.parse(localStorage.getItem('DATA_UID_GROUP'));
            this.setState({ so_luong: tep.length })
        }
        if (localStorage.getItem('so_post')) {
            var so_post = localStorage.getItem('so_post');
            this.setState({ so_post: so_post })
        }
    }

    scanNow(){
        this.setState({ loading: true })
        this.getFeed();
    }

    getFeed() { 
        
        var url = 'https://graph.facebook.com/v2.12/' + this.state.id_group + '?fields=feed.limit('+this.state.so_post+'){id}&access_token=' + this.state.TOKEN_1;
        axios.get(url)
            .then(response => {

                if(localStorage.getItem('PHAN_TU_HIEN_TAI') && localStorage.getItem('PHAN_TU_HIEN_TAI') > 0){
                    var PHAN_TU_HIEN_TAI = parseInt(localStorage.getItem('PHAN_TU_HIEN_TAI'));
                    var data_new = response.data.feed.data.splice(parseInt(localStorage.getItem('PHAN_TU_HIEN_TAI'))); // Bỏ đi x giá trị đầu
                }else{
                    var PHAN_TU_HIEN_TAI = 0
                    var data_new = response.data.feed.data;
                }

              console.log(data_new);
              
                
                
                data_new.map( (value,key)=>{

                    // localStorage.setItem('PHAN_TU_HIEN_TAI', (parseInt(PHAN_TU_HIEN_TAI)+parseInt(key)+1));
                    
                    
                    setTimeout(() => {

                        this.setState({
                            PHAN_TU_HIEN_TAI: PHAN_TU_HIEN_TAI + key + 1
                        })

                        this.getLike(value.id);

                        if (key + 1 >= data_new.length) {

                            this.setState({ loading: false, trang_thai: "Đã quét xong" })
                        }

                }, key*8000);
                } )


            })
            .catch(err => {
                // alert('Lỗi')
                this.setState({trang_thai: "Lỗi Quét. Thử lại."})
                this.setState({ loading: false })
            });
    }

    chunkArray(myArray, chunk_size) {
        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];

        for (index = 0; index < arrayLength; index += chunk_size) {
            var myChunk = myArray.slice(index, index + chunk_size);
            // Do something if you want with the group
            tempArray.push(myChunk);
        }

        return tempArray;
    }

    getLike(id_post){

        
        
        var url1 = 'https://graph.facebook.com/v2.12/' + id_post + '?fields=likes.limit(500){id}&access_token=' + this.state.TOKEN_2;
        axios.get(url1)
            .then(response1 => {
                
                if(localStorage.getItem('DATA_UID_GROUP')){
                    var DATA_UID_GROUP = JSON.parse(localStorage.getItem('DATA_UID_GROUP'));
                }else{
                    var DATA_UID_GROUP = [];
                }

                // var result = this.chunkArray(response1.data.likes.data, 3);

                response1.data.likes.data.map((value, key) => {
                    if( !DATA_UID_GROUP.includes(value.id) ){
                        DATA_UID_GROUP.push(value.id);
                        localStorage.setItem('DATA_UID_GROUP', JSON.stringify(DATA_UID_GROUP));
                        this.setState({ 
                            trang_thai: 'Quét UID: '+value.id + ' từ post '+id_post,
                            so_luong: DATA_UID_GROUP.length,
                            // PHAN_TU_HIEN_TAI: key+1
                    })
                    }
                   
                    
                    // if(DATA_UID_GROUP.length>50000){
                    //     this.setState({pause: true})
                    //     this.setState({trang_thai: "Tạm dừng do đã đạt được 50k UID"})
                    //     this.setState({bao_loi: "Tạm dừng do đã đạt được 50k UID, Xóa bộ nhớ để cải thiện tốc độ quét"})
                    // }
                })


            })
            .catch(err => {
                // alert('Lỗi')
            });
    }

    taoTep(){
        var tep = JSON.parse(localStorage.getItem('DATA_UID_GROUP'));
        var toString = tep.toString();
        var xuongDong = toString.replace(/,/g, '\n');
        this.setState({tep: xuongDong})
        
    }

    clearUID(){
        localStorage.removeItem('DATA_UID_GROUP');
        localStorage.removeItem('PHAN_TU_HIEN_TAI');
        this.setState({tep: ''});
        this.setState({ so_luong: '0' });
    }


    componentDidUpdate(prevProps, prevState) {

        if(prevState.PHAN_TU_HIEN_TAI!==this.state.PHAN_TU_HIEN_TAI){
            localStorage.setItem('PHAN_TU_HIEN_TAI', this.state.PHAN_TU_HIEN_TAI);
        }

        // var count = 1;
        // if(prevState.next!==this.state.next){
        //     setTimeout(() => { 
        //         this.getLike();
        //         var count = parseInt(count) + 1;
        //     }, count*1);
        // }
    }
    
    render() {
        console.log(this.state.PHAN_TU_HIEN_TAI);
        
        return (
            <div id="content">
                <div>
                    <div className="notification-container notification-container-empty"><span /></div>
                </div>
                <div className="modal" data-tg-refresh="modal" id="modal_container" aria-hidden="true" aria-labelledby="ModalTitle" tabIndex={-1} style={{ display: 'none' }} />
                <div className="modal-bg" data-tg-refresh="modal" id="modal_backdrop" />
                <div id="products-new" className="product-detail page">
                    <div className="aviary-modal-bg">
                        <div className="aviary-modal" />
                    </div>
                    <div autoComplete="off" data-context="form">
                        <header className="ui-title-bar-container">
                            <div className="ui-title-bar">
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-products" /></svg></span>
                                        <h1 className="ui-title-bar__title">QUÉT TƯƠNG TÁC NHÓM</h1></div>
                                        {this.state.trang_thai}. <b>{this.state.so_luong?"Đã quét được: "+this.state.so_luong:""}</b>
                                </div>
                            </div>
                            <div className="collapsible-header">
                                <div className="collapsible-header__heading" />
                            </div>
                        </header>
                        <div className="ui-layout">
                            <div className="ui-layout__sections">
                                <div className="ui-layout__section ui-layout__section--primary">
                                    <div className="ui-layout__item">
                                        <section className="ui-card" id="product-form-container">
                                            <div className="ui-card__section">
                                                <div className="ui-type-container">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Content">Token EAAAAZ</label>
                                                        <input value={this.state.TOKEN_1} onBlur={(event) => this.setToken1()} onChange={(event) => this.isChange(event)} placeholder="EAAAAZ..." className="next-input" size={30} type="text" name="TOKEN_1"/>
                                                    </div>
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Content">Token EAAci</label>
                                                        <input value={this.state.TOKEN_2} onBlur={(event) => this.setToken2()} onChange={(event) => this.isChange(event)} placeholder="EAAci..." className="next-input" size={30} type="text" name="TOKEN_2"/>
                                                    </div>
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Content">ID Nhóm</label>
                                                        <input value={this.state.id_group} onBlur={(event) => this.idGroup()} onChange={(event) => this.isChange(event)} placeholder="Nhập ID Nhóm" className="next-input" size={30} type="text" name="id_group" />
                                                    </div>

                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Content">Số POST</label>
                                                        <input value={this.state.so_post} onBlur={(event) => this.soPost()} onChange={(event) => this.isChange(event)} placeholder="Nhập khoảng 1000, nếu không quét được thì giảm xuống." className="next-input" size={30} type="text" name="so_post" />
                                                    </div>

                                                    {
                                                        this.state.tep ?
                                                            <div className="next-input-wrapper">
                                                                <label className="next-label" htmlFor="product-name"> UID</label>
                                                                <textarea rows={15} defaultValue={this.state.tep} className="next-input" type="text" rows={15} defaultValue={this.state.tep} />
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-page-actions">
                            <div className="ui-page-actions__container">
                                <div className="ui-page-actions__actions ui-page-actions__actions--primary">
                                    <div className="ui-page-actions__button-group">
                                        {
                                            this.state.loading?
                                            this.state.PHAN_TU_HIEN_TAI?"Đang scan " + this.state.PHAN_TU_HIEN_TAI:"Đang scan"
                                            :
                                            <div>
                                            <a onClick={() => this.clearUID()} className="btn" data-allow-default={1}>Xóa Bộ Nhớ</a>

                                            <button onClick={() => this.taoTep()} className="btn js-btn-primary js-btn-loadable btn-primary has-loading">TẠO TỆP</button>
                                            <button onClick={()=>this.scanNow()} className="btn js-btn-primary js-btn-loadable btn-primary has-loading">
                                                {
                                                    localStorage.getItem('PHAN_TU_HIEN_TAI')?
                                                    "TIẾP TỤC"
                                                    :
                                                    'QUÉT NGAY'
                                                }
                                               
                                                </button>
                                            </div>
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ScanGroup;