import React, { Component } from 'react';
const axios = require('axios');
class UidToFb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            danhmuc:'',
            pageChon:'',
        }
    }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    scanNow(){
        if(this.state.uid){
            var uid_full = this.state.uid.split("\n");
            uid_full.map( (value,key)=>{
                setTimeout(() => { this.conVert(value) }, key*250);
            } )
        }
    }

    conVert(uid) {
        var url = 'http://210.245.80.4:3003/bomhang/check?uids=' + uid;
        axios.get(url)
            .then(response => {

                // console.log(response.data.data[0].phone);

                if (response.data && response.data.data && response.data.data[0].phone) {
                    if(localStorage.getItem('PHONE_CONVERT')){
                        var PHONE_CONVERT = JSON.parse(localStorage.getItem('PHONE_CONVERT'));
                    }else{
                        var PHONE_CONVERT = [];
                    }
                    if (!PHONE_CONVERT.includes(response.data.data[0].phone)) {
                        PHONE_CONVERT.push(response.data.data[0].phone);
                        localStorage.setItem('PHONE_CONVERT', JSON.stringify(PHONE_CONVERT));
                        this.setState({
                            trang_thai: 'Quét UID: ' + uid,
                        })
                    }
                }
                

               


            })
            .catch(err => {
                // alert('Lỗi')
            });
    }

    clearPHONE(){
        localStorage.removeItem('PHONE_CONVERT');
    }



    render() {
        console.log(JSON.parse(localStorage.getItem('PHONE_CONVERT')));
        
        return (
            <div id="content">
                <div>
                    <div className="notification-container notification-container-empty"><span /></div>
                </div>
                <div className="modal" data-tg-refresh="modal" id="modal_container" aria-hidden="true" aria-labelledby="ModalTitle" tabIndex={-1} style={{ display: 'none' }} />
                <div className="modal-bg" data-tg-refresh="modal" id="modal_backdrop" />
                <div id="products-new" className="product-detail page">
                    <div className="aviary-modal-bg">
                        <div className="aviary-modal" />
                    </div>
                    <div autoComplete="off" data-context="form">
                        <header className="ui-title-bar-container">
                            <div className="ui-title-bar">
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-products" /></svg></span>
                                        <h1 className="ui-title-bar__title">CONVERT UID TO PHONE</h1></div>
                                        {this.state.trang_thai?this.state.trang_thai:""}
                                        </div>
                            </div>
                            <div className="collapsible-header">
                                <div className="collapsible-header__heading" />
                            </div>
                        </header>
                        <div className="ui-layout">
                            <div className="ui-layout__sections">
                                <div className="ui-layout__section ui-layout__section--primary">
                                    <div className="ui-layout__item">
                                        <section className="ui-card" id="product-form-container">
                                            <div className="ui-card__section">
                                                <div className="ui-type-container">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="product-name"> UID</label>
                                                        <textarea value={this.state.uid} onChange={(event) => this.isChange(event)} name="uid" rows={8} className="next-input" type="text" />
                                                    </div>
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="product-name">PHONE</label>
                                                        <textarea rows={8} className="next-input" type="text" value={JSON.parse(localStorage.getItem('PHONE_CONVERT'))} />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-page-actions">
                            <div className="ui-page-actions__container">
                                <div className="ui-page-actions__actions ui-page-actions__actions--primary">
                                    <div className="ui-page-actions__button-group">
                                        <div><a onClick={() => this.clearPHONE()} className="btn" data-allow-default={1}>Xóa Bộ Nhớ</a>
                                            <button className="btn js-btn-primary js-btn-loadable btn-primary has-loading">TẠO TỆP</button>
                                            <button onClick={()=>this.scanNow()} className="btn js-btn-primary js-btn-loadable btn-primary has-loading">CONVERT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default UidToFb;