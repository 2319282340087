import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import '../Login/Login.css';
import { NavLink } from "react-router-dom";
import * as Config from '../../config.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';

const qs = require('qs');

export default class Register extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            isload: false,
            phone:'',
            ho_ten:''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        var { email, phone, password, ho_ten } = this.refs;
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT fefege...'
        }

        this.setState({ isload: true });

        axios.post(Config.API_URL + 'register', qs.stringify({
            email: email.value,
            phone: phone.value,
            password: password.value,
            ho_ten: ho_ten.value
        }, { "headers": headers }))
            .then(response => {
                if (JSON.parse(JSON.stringify(response.data)).response === 'error') {
                    NotificationManager.error('Tài khoản đã tồn tại', '', 5000, false);
                } else {
                    NotificationManager.success('Đăng ký thành công', '', 5000, false);
                    setInterval(window.location = "/", 3000);
                    if (JSON.parse(JSON.stringify(response.data)).token) {
                        localStorage.setItem('token', JSON.parse(JSON.stringify(response.data)).token);
                        localStorage.setItem('info', JSON.stringify(response.data));
                    }
                }
                this.setState({ isload: false });
            })


            .catch(function (error) {
                console.log(error);
            });

    }

  render() {
    return (
        <div>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
                <link href="/login_theme/style.css" rel="stylesheet" />
                <link href="/login_theme/main-register.css" rel="stylesheet" />
        <NotificationContainer />
         <Helmet>
             <meta charSet="utf-8" />
             <title>Đăng ký</title>
             <meta charSet="utf-8" />
             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
             <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossOrigin="anonymous" />
             
         </Helmet>
         <div className="container">
             <div className="row">
                 <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                     <div className="card card-signin my-5">
                         <div className="card-body">
                             <h5 className="card-title text-center">ĐĂNG KÝ</h5>
                             <form onSubmit={this.handleSubmit.bind(this)} className="form-signin">
                                 {/* <div className="form-label-group">
                                     <input type="username" id="inputUsername" className="form-control" placeholder="Tên tài khoản" required autofocus />
                                     <label htmlFor="inputUsername">Tên tài khoản</label>
                                 </div> */}
                                 <div className="form-label-group">
                                     <input autoComplete="off" type="email" id="inputEmail" className="form-control" placeholder="Email" required autofocus  ref="email"/>
                                     <label htmlFor="inputEmail">Email</label>
                                 </div>
                                 
                                 <div className="form-label-group">
                                     <input minlength='6' autoComplete="off" type="password" id="inputPassword" className="form-control" placeholder="Mật khẩu" required ref="password" />
                                     <label htmlFor="inputPassword">Mật khẩu</label>
                                 </div>

                                 <div className="form-label-group">
                                     <input minlength='10' maxlength='11' autoComplete="off" type="phone" id="inputPhone" className="form-control" placeholder="Số điện thoại liên hệ" required autofocus ref="phone" />
                                     <label htmlFor="inputPhone">Số điện thoại liên hệ</label>
                                 </div>

                                 <div className="form-label-group">
                                     <input minlength='3' autoComplete="off" type="name" id="inputName" className="form-control" placeholder="Họ và tên" required ref="ho_ten" />
                                     <label htmlFor="inputName">Họ và tên</label>
                                 </div>
                                 {/* <div className="custom-control custom-checkbox mb-3">
                                     <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                     <label className="custom-control-label" htmlFor="customCheck1">Ghi nhớ</label>
                                 </div> */}
                                    {
                                        this.state.isload ?
                                            <button className="btn btn-lg btn-block text-uppercase dangnhap" type="submit">Đăng ký <i className="fa fa-spinner fa-spin" style={{ fontSize: 18 }} /></button>
                                            :
                                            <button className="btn btn-lg btn-block text-uppercase dangnhap" type="submit">Đăng ký</button>
                                    }

                                 <hr className="my-4" />
                                 {/* <button className="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i className="fab fa-google mr-2" />
                                     Đăng nhập bằng Google</button>
                                 <button className="btn btn-lg btn-facebook btn-block text-uppercase mb-20" type="submit"><i className="fab fa-facebook-f mr-2" />
                                     Đăng nhập bằng Facebook</button> */}
                             </form>
                             <h6 className="text-center txt-dark mt-20">Hoặc <NavLink to="/login"> Đăng nhập</NavLink></h6>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
    )
  }
}
