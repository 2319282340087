// Phí giao dịch hiện đang để cả 2 bên Reactjs và Laravel API
// Tỷ giá chỉ để ở API

const axios = require('axios');
const qs = require('qs');
const queryString = require('query-string');

export  function Quydoi(tygia,price){
	let price1 =  parseFloat(price);
	return  Math.round(price1*tygia);
}

export function FormatNumber(nStr) {
	nStr += '';
	var x = nStr.split(',');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + '.' + '$2');
	}
	return x1 + x2;
}

export function PhiGiaoDich(tien) {
	if (tien < 200000) {
		return Math.ceil(10000);
	}else if (tien >= 200000 && tien < 5000000) {
		return Math.ceil(tien*3/100);
	}else if (tien >= 5000000 && tien < 100000000 ) {
		return Math.ceil(tien*2/100);
	}else if (tien >= 100000000) {
		return Math.ceil(tien*1/100);
	}
}

export function diaChiKhoHN() {
	return 'Số 40 ngõ 87 Nguyễn Khang, Yên Hòa, Cầu Giấy, Hà Nội';
}

export function retnum(str) { 
    var num = str.match(/\d+/)[0]; 
    return parseInt(num); 
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    // return [year, month, day].join('-');
    return [month, year].join('-');
}

export function convertTime(vl){
	var fbDateFix = function(date){ 
		var local = new Date(date.replace(/-/g,'/').replace('T',' ').replace('+0000',''));
		local.setSeconds(local.getSeconds() + 25200);
		return local;
	}
	var padZero = function(t){
		if(t<10){
			return '0' + t;
		}
		return t;
	}
	var d = fbDateFix(vl);
	var month = padZero(+d.getMonth()+1);
	var date = padZero(+d.getDate());
	var hour = padZero(+d.getHours());
	var min = padZero (+d.getMinutes());
	var sec = padZero (+d.getSeconds());
	return hour + ':' + min+' '+date+'/'+month+'/'+d.getFullYear();
}

export function delay(callback, ms) {
	var timer = 0;
	return function() {
	  var context = this, args = arguments;
	  clearTimeout(timer);
	  timer = setTimeout(function () {
		callback.apply(context, args);
	  }, ms || 0);
	};
  }

  export function getDaysInMonth(month,year) {
	// Here January is 1 based
	//Day 0 is the last day in the previous month
   return new Date(year, month, 0).getDate();
  // Here January is 0 based
  // return new Date(year, month+1, 0).getDate();
  };



export function custom_sort(a, b) { // Sắp xếp theo ngày
	return new Date(JSON.parse(a).ngay).getTime() - new Date(JSON.parse(b).ngay).getTime();
}


// Check đầu số nhà mạng
export function check_phone(phone) { // Sắp xếp theo ngày
	//Get 3 số đầu tiên
	var three_phone = phone.substring(0, 3);
	var viettel = ["096", "097", "098", "086", "032", "033", "034", "035", "036", "037", "038", "039"];
	var vinaphone = ["088", "091", "094", "081", "082", "083", "084", "085"];
	var mobifone = ["089", "090", "093", "070", "077", "076", "078", "079"];
	var Vietnamobile = ["092", "056", "058"];
	var Gmobile = ["099", "059"];
	if( viettel.indexOf(three_phone) >= 0 ){
		return 'Viettel'
	}else if( vinaphone.indexOf(three_phone) >= 0 ){
		return 'Vinaphone'
	}else if( mobifone.indexOf(three_phone) >= 0 ){
		return 'Vietnamobile'
	}else if( Vietnamobile.indexOf(three_phone) >= 0 ){
		return 'Vietnamobile'
	}else if( Gmobile.indexOf(three_phone) >= 0 ){
		return 'Gmobile'
	}else{
		return "Không rõ"
	}
}


export function lam_tron(x) { // Làm tròn 2 số sau dấu ,
	//Get 2 số đầu tiên
	var n = parseFloat(x); 
	x = Math.round(n * 100)/100;
	return x;
}


export function checkThe(x) { // Làm tròn 2 số sau dấu ,
	//Get 2 số đầu tiên
	if(x === 'VISA *0242'){
		return 'Vietin *0242';
	}else if(x === 'VISA *6944'){
		return 'ACB *6944';
	}else if(x === 'VISA *8886'){
		return 'Tech *8886';
	}else if(x === 'VISA *4626'){
		return 'TechTD *4626';
	}else if(x === 'Mastercard *1182'){
		return 'ACB *1182';
	}else if(x === 'Visa *4294'){
		return 'ACB *4294';
	}else if(x === 'VISA *9302'){
		return 'HSBC *9302';
	}else if(x === 'VISA *1809'){
		return 'SHIN *1809';
	}else if(x === 'Mastercard *1028'){
		return 'VpM *1028';
	}else if(x === 'Mastercard *7131'){
		return 'VpHue *7131';
	}else if(x === 'VISA *3528'){
		return 'VpHuu *3528';
	}else if(x === 'VISA *0226'){
		return 'HongLe *0226';
	} else {
		return x;
	}
	
}

export function GetUsernameFromFacebookURL(url) {
	if (url.indexOf("id")>0) {
		var url1 = new URL(url);
		var uid = url1.searchParams.get("id");
		return uid;
	}
	else{
		// bẻ https://www.facebook.com/ lấy phần tử 1
		var url = url.split("https://www.facebook.com/")[1];
		// Bẻ / lấy phần tử 0
		var url = url.split("/")[0];
		// Bẻ ? lấy phần tử 0
		var url = url.split("?")[0];
		return url;
	}
}

export function removeA(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export function getToken(idpage) {
	var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_PAGE'));
    var CART_LOCAL_NEW = CART_LOCAL.filter(function (returnableObjects) {
		return returnableObjects.id == idpage;
	});
	return CART_LOCAL_NEW;
}

export function getBaiViet(idpage) {
	var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
    var CART_LOCAL_NEW = CART_LOCAL.filter(function (returnableObjects) {
		return JSON.parse(returnableObjects).masp == idpage;
	});
	return CART_LOCAL_NEW;
}

export function dangMotAnh(token, image) {
	var order = {};
	order.url = image;
	order.caption = '1';
	order.published = 'false';
	axios.post('https://graph.facebook.com/v2.11/me/photos?access_token=' + token, qs.stringify(order))
		.then(response => {
			return response.data.id;
		});
		
}
export function LamTron(num){
	var chia_100 = num/10000;
	var lam_tron = Math.ceil(chia_100);
	var nhan_100 = lam_tron*10000;
	return nhan_100;
}

export function LamTronNghin(num){
	var chia_100 = num/1000;
	var lam_tron = Math.ceil(chia_100);
	var nhan_100 = lam_tron*1000;
	return nhan_100;
}

export function LamTronPhanTram(num, precision) {
    var rounder = Math.pow(10, precision);
    return (Math.round(num * rounder) / rounder).toFixed(precision)
};
