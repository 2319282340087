import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import * as Config from '../../config.js';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
const axios = require('axios');
var moment = require('moment');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');

class ChiTietNhomFacebook extends Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
            loading: false
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    // Modal

    state = {
        open: false,
    };

    onOpenModal = (uid,ten,email,mat_khau,token_user,ghi_chu) => {
        this.setState({ open: true, uid, ten, email, mat_khau, token_user, ghi_chu });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    // End Modal

    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.setState({
            name: queryString.parse(window.location.search).name,
            page: queryString.parse(window.location.search).page,
        })
    }


    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const query = {
            name: this.state.name,
            page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
            {
                pathname: '/chi-tiet-nhom-facebook',
                search: searchString,
            }
        )
        if (prevState.page !== this.state.page) {
            this.setState({ loading: true });
            this.loadData();
        }
    }

    loadData() {
        let token = localStorage.getItem('token');
        this.setState({ loading: true })
        axios.get(Config.API_URL + 'facebook/chi-tiet-nhom-facebook?token=' + token + "&page=" + this.state.page + '&name=' + this.state.name)
            .then(response => {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            });
    }

    BackUpOne(uid,token,stt,tong){
        this.setState({ loading: true })
        // var url = 'https://graph.facebook.com/v7.0/me?fields=id,name,birthday,email,adaccounts,businesses{id,name},friends.limit(5000){id,name},gender,hometown,location,groups.limit(5000){id,name,member_count},likes.limit(5000)&access_token=' + token;
        var url = 'https://graph.facebook.com/v7.0/me?fields=id,name,birthday,email,adaccounts,businesses{id,name},friends.limit(5000){id,name},gender,hometown,location&access_token=' + token;
        axios.get(url)
            .then(response => {
                this.CapNhatInfo(response.data, token, stt, tong);
            })
            .catch(()=>{
                this.CapNhatTrangThai(uid,'Die',stt,tong);
            });
            if( stt >= tong ){
                this.setState({ loading: false })
            }

    }

    BackUpAll() {
        this.setState({ loading: true })
        this.state.data.map((value, key) => {
            setTimeout(() => {
                this.BackUpOne(value.uid, value.token,(key+1),this.state.data.length);
            }, key * 3000);
        })
    }

    CapNhatInfo(info,token_fb,stt,tong){
        var order = {};
        order.data = info;
        order.token_fb = token_fb;
        order.name = this.state.name;

        let token = localStorage.getItem('token');
            // axios.post(Config.API_URL + 'facebook/cap-nhat-info?token=' + token + "&page=" + this.state.page, qs.stringify(order))
            axios.post(Config.API_URL + 'facebook/cap-nhat-info?token=' + token + "&page=" + this.state.page, (order))
                .then(response => {
                    this.setState({ stt, tong })
                    this.setState({
                        data: response.data.data,
                        loading: false,
                        activePage: response.data.current_page,
                        itemsCountPerPage: response.data.per_page,
                        totalItemsCount: response.data.total,
                        path: response.data.path,
                    });
                })
                .catch(()=>{
                    this.setState({stt,tong})
                });
        
    }
    CapNhatTrangThai(uid, trangthai,stt,tong) {
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'facebook/cap-nhat-trang-thai?token=' + token + '&uid=' + uid + '&trangthai=' + trangthai + '&name=' + this.state.name)
            .then(response => {
                this.setState({stt,tong})
                this.setState({
                    data: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            });
    }

    CapNhatThongTin(){

        var order = {};
        order.uid = this.state.uid;
        order.mat_khau = this.state.mat_khau;
        order.token = this.state.token_user;
        order.ghi_chu = this.state.ghi_chu;

        let token = localStorage.getItem('token');
        axios.post(Config.API_URL + 'facebook/cap-nhat-thong-tin?token=' + token + + "&page=" + this.state.page + '&name=' + this.state.name, qs.stringify(order))
            .then(response => {
                this.setState({ open: false });
                this.setState({
                    data: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
                this.BackUpOne(this.state.uid, this.state.token_user);
            })
            .catch(() => {
                this.setState({ open: false });
            });
        
    }

    XoaTaiKhoan(){
        var order = {};
        order.uid = this.state.uid;

        let token = localStorage.getItem('token');
        axios.post(Config.API_URL + 'facebook/xoa-tai-khoan?token=' + token + "&page=" + this.state.page + '&name=' + this.state.name, qs.stringify(order))
            .then(response => {
                this.setState({ open: false });
                this.setState({
                    data: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
                this.BackUpOne(this.state.uid, this.state.token_user);
            })
            .catch(() => {
                this.setState({ open: false });
            });
    }
    

    render() {

        // Modal
        const { open } = this.state;
        // End Modal


        return (
            <div id="content">
                <div id="dashboard" className="container-fluid-md">
                    <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">

                        <header className="ui-title-bar-container ui-title-bar-container--full-width">
                            <div className="ui-title-bar ">

                            <div className="ui-title-bar__navigation">
                                    <div className="ui-breadcrumbs">
                                        <Link className="ui-button ui-button--transparent ui-breadcrumb"  to="/analytics">
                                            <svg className="next-icon next-icon--size-20 next-icon--no-nudge">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" />
                                            </svg><span className="ui-breadcrumb__item">Thống kê</span></Link>
                                    </div>
                                    <div className="ui-title-bar__pagination" />
                                </div>
                                
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" /></svg></span>
                                        <h1 className="ui-title-bar__title">Chi tiết nhóm tài khoản: {this.state.name}</h1></div>
                                </div>
                                <div className="collapsible-header">
                                    <div className="collapsible-header__heading" />
                                </div>
                                </div>


                                <div className="row button-list">
                                    <div className="col-xs-6 col-md-2">
                                        <a target="_blank">
    <button onClick={()=>this.BackUpAll()} type="button" className="btn btn-dark btn-outline col-md-12">Backup toàn bộ {this.state.stt?this.state.stt+'/':''} {this.state.tong} {this.state.loading?<i className='fa fa-spinner fa-spin' style={{ fontSize: 12 }} />:''}</button>
                                        </a>
                                    </div>
                                    <div className="col-xs-6 col-md-2">
                                        <a className href="/auto-dang-stt?nhom=clone-1&id_user=11" target="_blank">
                                            <button type="button" className="btn btn-dark btn-outline col-md-12">Check Live</button>
                                        </a>
                                    </div>
                                    <div className="col-xs-6 col-md-2">
                                        <a className href="/auto-share?nhom=clone-1" target="_blank">
                                            <button type="button" className="btn btn-dark btn-outline col-md-12">Auto đăng STT</button>
                                        </a>
                                    </div>
                                    <div className="col-xs-6 col-md-2">
                                        <a href="/auto-ket-ban?nhom=clone-1&id_user=11" target="_blank">
                                            <button type="button" className="btn btn-dark btn-outline col-md-12">Auto gừi mời kết bạn</button>
                                        </a>
                                    </div>
                                    <div className="col-xs-6 col-md-2">
                                        <a href="auto-like?id_user=11&nhom=clone-1" target="_blank">
                                            <button type="button" className="btn btn-dark btn-outline col-md-12">Auto Like</button>
                                        </a>
                                    </div>
                                    <div className="col-xs-6 col-md-2">
                                        <a href="/auto-comment?id=11&nhom=clone-1" target="_blank">
                                            <button type="button" className="btn btn-dark btn-outline col-md-12">Auto Comment</button>
                                        </a>
                                    </div>
                                </div>
                                

                            </header>

                            <div className="next-card next-card--primary" style={{ position: 'relative' }}>
                                <section className="next-card__section">
                                    <div id="report-table">
                                        <table className="expanded st">
                                            <thead>
                                                <tr>
                                                    <th className="reports-measure is-sortable"><span className="field">#STT</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Trạng thái</span></th>
                                                    <th className="reports-property is-sortable"><span className="field">Ảnh</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Tên</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">UID</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Email</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Ads</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">BM</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Q.lý BM</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Ngày sinh</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Bạn bè</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Giới tính</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Nhóm</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Time Backup</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    this.state.data ?
                                                        this.state.data.map((value, key) => {
                                                            return <tr key={key} className="data-row" style={{ opacity: 1 }}>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{key + 1}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content">
                                                                        <span onClick={()=>this.onOpenModal(value.uid,value.ten,value.email,value.mat_khau,value.token,value.ghi_chu)} className={value.trang_thai=='Die'?"badge badge--status-warning badge--progress-complete":"badge badge--status-success badge--progress-complete"}>{value.trang_thai}</span>
                                                                        </div>
                                                                </td>
                                                                <td className="reports-property">
                                                                    <div className="reports-property-content--drillable">
                                                                        <img style={{ width: 41 }} src={"https://graph.facebook.com/v2.10/" + value.uid + "/picture?type=large&redirect=true"} className="rounded-circle img-20" />
                                                                    </div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.ten}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.uid}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.email}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.tk_ads}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.businesses}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.quan_ly_bm}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.ngay_sinh}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content">
                                                                        <a href={"http://api.roza.vn/api/backup-ban-be?id="+value.uid} target="_blank"><span className="value">{Number(value.ban_be).toLocaleString()} </span></a>
                                                                    </div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.gioi_tinh}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content"><span className="value">{value.group}</span></div>
                                                                </td>
                                                                <td className="reports-measure">
                                                                    <div className="reports-measure-content">
                                                                        <span className="value">{moment(value.time_cap_nhat).fromNow()} <i onClick={()=>this.BackUpOne(value.uid,value.token)} className="fa fa-retweet" aria-hidden="true" /></span>
                                                                        </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                        : ""
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                    </div>
                    </div>

                    {/* Modal  */}

                <Modal open={open} onClose={this.onCloseModal} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ width: 500 }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">Chi tiết</h2></div>
                        </div>



                        <div className="ui-modal__body">
                            <div className="ui-modal__section">
                                <label className="next-label">Tên: {this.state.ten} </label>
                                <label className="next-label">UID: {this.state.uid} </label>
                                <label className="next-label">Email: {this.state.email} </label>
                                <div>
                                    <label className="next-label">Mật khẩu:</label>
                                    <input  onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập mật khẩu" name="mat_khau" type="text" defaultValue={this.state.mat_khau} />
                                    <br></br>
                                    <label className="next-label">Ghi chú:</label>
                                    <input  onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập ghi chú" name="ghi_chu" type="text" defaultValue={this.state.ghi_chu} />
                                    <br></br>
                                    <label className="next-label">TOKEN:</label>
                                    <textarea  onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập Token" name="token_user" type="text" defaultValue={this.state.token_user} />
                                </div>
                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">
                                <div className="ui-modal__secondary-actions">
                                    <div className="button-group">
                                        <button className="ui-button close-modal" type="button" onClick={()=>this.XoaTaiKhoan()}>Xóa Tài Khoản</button>
                                    </div>
                                </div>
                                <div className="ui-modal__secondary-actions">
                                    <div className="button-group">
                                        <button className="ui-button close-modal" type="button" onClick={this.onCloseModal}>Đóng</button>
                                    </div>
                                </div>
                                <div className="ui-modal__primary-actions">
                                    <div className="button-group button-group--right-aligned">
                                        <button onClick={()=>this.CapNhatThongTin()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Cập nhật</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                    {/* End Modal  */}
                </div>

                );
            }
        }
        
export default ChiTietNhomFacebook;