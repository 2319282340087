import React, { Component } from 'react';
import Login from '../Login/Login';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";

import * as Func from '../../functions/function.js';
import * as Config from '../../config.js';
import ReactChartkick, { ColumnChart  } from 'react-chartkick';
import DayPicker,{ DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Showdate from './includes/Showdate';
import Helmet from 'react-helmet';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';

import Revenuebymonth from './Revenuebymonth';
import Revenuebychannel from './Revenuebychannel';
import Revenuebyvariant from './Revenuebyvariant';
import Revenuebyinventory from './Revenuebyinventory';


const axios = require('axios');
const moment = require('moment');
const tokenLocal = localStorage.getItem('token');

class Routeranalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Báo cáo',
            data:[],
            chartData:[],
            startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            from: undefined,
            to: undefined,
            show: false
        };
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }
    componentDidMount(){
        switch (this.props.location.pathname){
            case '/analyticsnew/revenuebymonth':
                this.setState({
                    title: 'Doanh thu theo thời gian',
                    startDate: moment().subtract(12, 'months').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                })
                break;
            case '/analyticsnew/revenuebychannel':
                this.setState({
                    title: 'Doanh thu theo kênh bán hàng',
                    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                })
                break;
            case '/analyticsnew/revenuebyvariant':
                this.setState({
                    title: 'Doanh thu theo sản phẩm',
                    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                })
                break;
            case '/analyticsnew/revenuebyinventory':
                this.setState({
                    title: 'Thống kê tồn kho',
                })
                break;
        }
    }
    show(){
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }
    
    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        });
    }
    onDateRangeSelectChange(event){
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate =  moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate =  moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate =  moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate =  moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate =  moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-')+moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate =  moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate =  moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate =  moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;
        
            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        })  
    }
    showCalendar(){
        const from =  new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{marginBottom:10,width:350}}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> - 
                <span className="InputFromTo-to">
                <DayPickerInput
                    ref={el => (this.to = el)}
                    value={to}
                    placeholder="To"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: to,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                    }}
                    onDayChange={this.handleToChange}
                />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }

    render() {
        return (
            <div id="content">
                <div id="dashboard" className="container-fluid-md">
                    <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">
                        <div className="ui-title-bar">
                        <div className="ui-title-bar__navigation">
                            <div className="ui-breadcrumbs">
                            <Link to="/analytics" className="ui-button ui-button--transparent ui-breadcrumb">
                                <svg className="next-icon next-icon--size-20 next-icon--no-nudge"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" /> </svg>
                                <span className="ui-breadcrumb__item">Báo cáo</span>
                            </Link>
                            </div>
                                <div className="ui-title-bar__pagination">
                            </div>
                        </div>

                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <h1 className="ui-title-bar__title">{this.state.title}</h1>
                                </div>
                                { this.props.location.pathname != '/analyticsnew/revenuebyinventory' ? 
                                        <div>
                                            <div className="sub-title">
                                                <span>Dữ liệu được tổng hợp đến hết ngày {moment(this.state.endDate).format('DD/MM/YYYY')} </span><span data-bind="lagAggTime"> tính theo số lượng đơn đã đối soát và thời gian tạo đơn bắt đầu từ ngày 01/09/2019</span>
                                            </div>
                                            <div id="reportrangereceived" className="form-group" style={{position:'relative'}}>
                                                <span  onClick={this.show} style={{position:'absolute',top:'5px',width:'100%'}}>{ moment(this.state.startDate).format('DD/MM/YYYY') } đến { moment(this.state.endDate).format('DD/MM/YYYY') }</span>
                                                <i  onClick={this.show} className="fa fa-calendar" style={{float:'right',paddingTop: '7px',paddingLeft:'7px'}}></i>
                                                { this.state.show  ? <this.showCalendar /> : "" }
                                            </div>
                                        </div>
                                    : ''
                                }
                            </div>
                        </div>
                        <Route  path="/analyticsnew/revenuebymonth" render={() => (tokenLocal ? (<Revenuebymonth startDate={this.state.startDate}  endDate={this.state.endDate} />) : (<Redirect to="/login" />))} />
                        <Route  path="/analyticsnew/revenuebychannel" render={() => (tokenLocal ? (<Revenuebychannel startDate={this.state.startDate}  endDate={this.state.endDate} />) : (<Redirect to="/login" />))} />
                        <Route  path="/analyticsnew/revenuebyvariant" render={() => (tokenLocal ? (<Revenuebyvariant startDate={this.state.startDate}  endDate={this.state.endDate} />) : (<Redirect to="/login" />))} />
                        <Route  path="/analyticsnew/revenuebyinventory" render={() => (tokenLocal ? (<Revenuebyinventory />) : (<Redirect to="/login" />))} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Routeranalytics);