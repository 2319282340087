import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import * as Config from '../../config.js';
import { createBrowserHistory } from 'history';

import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Showdate from './includes/Showdate';
import Helmet from 'react-helmet';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
import Pagination from "react-js-pagination";

import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';




import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const axios = require('axios');
var moment = require('moment');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');

class DoiSoatVnpost extends Component {

    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            show: false,
        };
    }



    // Hàm



    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    dangAll() {
        if (this.state.van_don) {
            this.setState({ loading: true })
            var van_don_all = this.state.van_don.split("\n");
            van_don_all.map((value, key) => {
                // var id = value.split("	")[0];
                var van_don = value.split("	")[0];
                var tien_cod = parseInt(value.split("	")[1]);
                // var phi_hoan_hang = value.split("	")[2];
                setTimeout(() => {
                    if (van_don) {
                        var full_order = {};
                        // full_order.id = id;
                        full_order.van_don = van_don;
                        full_order.tien_cod = tien_cod;
                        // full_order.phi_hoan_hang = phi_hoan_hang;
                        let token = localStorage.getItem('token');
                        axios.post(Config.API_URL + 'vnpost/doi-soat-cod?token=' + token, qs.stringify(full_order))
                            .then(response => {
                                if (key+1 >= van_don_all.length) {
                                    alert('Đã cập nhật xong')
                                    this.setState({ loading: false })
                                }
                            })
                            .catch(err => {
                                if (key+1 >= van_don_all.length) {
                                    this.setState({ loading: false });
                                }
                                alert('Lỗi '+van_don)
                            });
                    }
                }, key * 1000);
            })
        } else {
            alert('Chưa nhập vận đơn!')
        }
    }

    // End hàm

    render() {

        console.log(this.state.trangThai);
        

        var data = [];

        if(this.state.data){
            this.state.data.map((value1, key1) => {
                data.push([
                    value1.time_gui, 
                    value1.full_name + ', ' + value1.phone, 
                    value1.address+ ', ' + value1.xa + ', ' + value1.huyen + ', ' + value1.tinh,
                    value1.tinh,
                    '',
                    value1.phone,
                    value1.sumTotal,
                    value1.id,
                    'Quần áo',
                    value1.trong_luong,
                    value1.hang_van_chuyen,
                ]);
            })
        }

        

        return (
            <div>

            
                <div id="content">
                    <div id="dashboard" className="container-fluid-md">
                        <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">

                            <div className="next-card next-card--primary" style={{ position: 'relative' }}>
                                <section className="next-card__section">
                                    <div id="report-table">
                                        <table className="expanded st">
                                            <thead>
                                                <tr>
                                                    <textarea name='van_don' rows={5} placeholder="VẬN_ĐƠN	TIỀN_COD" onChange={(event) => this.isChange(event)}></textarea>

                                                </tr>
                                            </thead>
                                            </table>
                                    </div>
                                </section>
                            </div>

                            <div className="ui-page-actions">
                                <div className="ui-page-actions__container">
                                    <div className="ui-page-actions__actions ui-page-actions__actions--primary">
                                        <div className="ui-page-actions__button-group">
                                            {
                                                this.state.loading?
                                                <button name="button" className="btn js-btn-primary js-btn-loadable btn-primary has-loading"><i className="fa fa-spinner fa-spin" style={{ fontSize: 12 }} /></button>
                                                :
                                                <button name="button" onClick={() => this.dangAll()} className="btn js-btn-primary js-btn-loadable btn-primary has-loading">Đăng Tất Cả</button>

                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default DoiSoatVnpost;