import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import * as Config from '../../config.js';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
const axios = require('axios');
var moment = require('moment');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');

class NhomTaiKhoanFacebook extends Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }



    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const query = {
            page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
            {
                pathname: '/nhom-tai-khoan-facebook',
                search: searchString,
            }
        )
        if (prevState.page !== this.state.page) {
            this.setState({ loading: true });
            this.loadData();
        }
    }

    loadData() {
        let token = localStorage.getItem('token');
        this.setState({ loading: true })
        axios.get(Config.API_URL + 'facebook/nhom-tai-khoan?token=' + token + "&page=" + this.state.page)
            .then(response => {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            });
    }


    render() {
        return (
            <div id="content">
                <div id="dashboard" className="container-fluid-md">
                    <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">
                        <div className="ui-title-bar">

                           
                                <div className="ui-title-bar__navigation">
                                    <div className="ui-breadcrumbs">
                                        <Link className="ui-button ui-button--transparent ui-breadcrumb"  to="/analytics">
                                            <svg className="next-icon next-icon--size-20 next-icon--no-nudge">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" />
                                            </svg><span className="ui-breadcrumb__item">Thống kê</span></Link>
                                    </div>
                                    <div className="ui-title-bar__pagination" />
                                </div>
                            

                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <h1 className="ui-title-bar__title">Danh sách nhóm tài khoản</h1>
                                    <br></br>

                                </div>
                                <br></br>
                            </div>
                        </div>
                        <div className="next-card next-card--primary" style={{ position: 'relative' }}>
                            <section className="next-card__section">
                                <div id="report-table">
                                    <table className="expanded st">
                                        <thead>
                                            <tr>
                                                <th className="reports-measure is-sortable"><span className="field">Tên nhóm</span></th>
                                                <th className="reports-property is-sortable"><span className="field">Nội dung</span></th>
                                                <th className="reports-measure is-sortable"><span className="field">Ngày tạo</span></th>
                                                <th className="reports-measure is-sortable"><span className="field">Thao tác</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                this.state.data.map((value, key) => {
                                                    return <tr key={key} className="data-row" style={{ opacity: 1 }}>
                                                        <td className="reports-measure">
                                                            <div className="reports-measure-content"><span className="value">{value.dan}</span></div>
                                                        </td>
                                                        <td className="reports-property">
                                                            <div className="reports-property-content--drillable"><span className="value">{value.noi_dung}</span></div>
                                                        </td>
                                                        <td className="reports-measure">
                                                            <div className="reports-measure-content"><span className="value">{value.created_at}</span></div>
                                                        </td>
                                                        <td className="reports-measure">
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-default btn">
                                                                    <Link to={"chi-tiet-nhom-facebook?name="+value.dan} >
                                                                        <i className="fa fa-eye" />
                                                                    </Link>
                                                                </button>
                                                                <button type="button" className="btn btn-default btn">
                                                                    <a href="sua-nhom-tai-khoan?id=11">
                                                                        <i className="fa fa-pencil" />
                                                                    </a>
                                                                </button>
                                                                <button type="button" className="btn btn-default btn">
                                                                    <a href="xoa-nhom-tai-khoan?id=11">
                                                                        <i className="fa fa-trash" />
                                                                    </a>
                                                                </button>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                })
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </section>
                            <div className="ui-footer-help">
                                {
                                    this.state.totalItemsCount ?
                                        <Pagination
                                            className="pagination-sm"
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                        />
                                        :
                                        ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default NhomTaiKhoanFacebook;