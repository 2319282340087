import React, { Component } from 'react';

class GetAllPhone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            danhmuc:'',
            pageChon:'',
        }
    }
    componentDidMount() {
        var start = '0860000000'; // 0869999999
        var end = parseInt(start) + 5999999; //9.999.999
        var sub_array = [];
        for (var i = start; i <= end; i++) {
            sub_array.push('0' + i);
        }
        this.setState({
            phone: sub_array.toString()
        })
    }
    
    render() {
        console.log(this.state.phone);
        
        return (
            <div>
                {/* <textarea>
                    {this.state.phone?this.state.phone:""}
                </textarea> */}
                {/* <input value={this.state.phone?this.state.phone:""}></input> */}
            </div>
        );
    }
}

export default GetAllPhone;