export const API_URL = 'https://api.giaothuy.com/api/';
export const HOME_URL = '/';
export const MA_PAGE = 'R';
export const TEN_SHOP = 'KingOrder';


// export const DIA_CHI = 'Số nhà 40, Ngõ 87 Nguyễn Khang';
// export const DIA_CHI_TINH = 'Hà Nội';
// export const DIA_CHI_HUYEN = 'Cầu Giấy';


export const DIA_CHI = 'Số 19, Ngõ 106, Đường La Nội. Phường Dương Nội, Hà Đông, Hà Nội';
export const DIA_CHI_TINH = 'Hà Nội';
export const DIA_CHI_HUYEN = 'Hà Đông';

// export const DIA_CHI = 'HH2 Bắc Hà, Tố Hữu, Phường Trung Văn, Quận Nam Từ Liêm, Hà Nội';
// export const DIA_CHI_TINH = 'Hà Nội';
// export const DIA_CHI_HUYEN = 'Từ Liêm';


export const PHONE_GUI_HANG = '0356525305';


// Token cho GHN
export const TOKEN_GHN = 'd2ef51e3-fb52-11eb-bbbe-5ae8dbedafcf';



export const TOKEN_ADS = localStorage.getItem('token_ads');

export const MONTHS = [
  'Tháng một',
  'Tháng hai',
  'Tháng ba',
  'Tháng bốn',
  'Tháng năm',
  'Tháng sáu',
  'Tháng bảy',
  'Tháng tám',
  'Tháng chín',
  'Tháng mười',
  'Tháng mười một',
  'Tháng mười hai',
];
export const WEEKDAYS_LONG = [
    'Chủ nhật',
    'Thứ hai',
    'Thứ ba',
    'Thứ tư',
    'Thứ năm',
    'Thứ sáu',
    'Thứ bảy'
];
export const WEEKDAYS_SHORT = ['C','H', 'B', 'T', 'N', 'S', 'B'];