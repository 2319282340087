import React, { Component } from 'react';

class QuickSearch extends Component {
    render() {
        return (
            <section className="quick-search" define="{quickSearch: new Bizweb.QuickSearch()}">
          <section className="quick-search__box">
            <header className="quick-search__header">
              <span className="quick-search__header-icon">
                <svg className="next-icon next-icon--color-sky-dark next-icon--size-24">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" />
                </svg>
              </span>
              <input type="search" aria-controls="quick-search__results" className="quick-search__input" placeholder="Tìm kiếm..." />
              <div className="quick-search__clear-btn-wrapper">
                <div className="ui-frame ui-frame--fill ui-frame--circle ui-frame--size-12">
                  <svg className="next-icon next-icon--color-white next-icon--size-8 next-icon--no-nudge">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-remove" />
                  </svg>
                </div>
              </div>
              <button className="ui-button quick-search__close-btn" aria-label="close search" type="button" name="button">
                <svg focusable="false" className="next-icon next-icon--color-slate-lighter next-icon--size-16">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-remove" />
                </svg>
              </button>
            </header>
            <section className="quick-search__instructions-banner" id="quick-search__instructions-banner-top">
              Nhấn
        <strong>enter</strong> để chọn,
        <strong>↑ ↓</strong> hoặc
        <strong>tab</strong> để điều hướng,
        <strong>esc</strong> để tắt
      </section>
            <section className="quick-search__results-wrapper">
              <section id="quick-search__results" className="quick-search__results" role="region" aria-live="assertive" />
              <div className="quick-search__spinner quick-search__spinner--full-page">
                <div className="next-spinner">
                  <svg className="next-icon next-icon--size-20">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" />
                  </svg>
                </div>
              </div>
            </section>
          </section>
        </section>
        
        );
    }
}

export default QuickSearch;