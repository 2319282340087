import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-responsive-modal';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { lam_tron } from '../../functions/function.js';
import { validate } from '@babel/types';
import Pusher from 'pusher-js';
import { SlowBuffer } from 'buffer';
import Select from 'react-select'
import { check_phone } from '../../functions/function.js';
import { json } from 'body-parser';

const trangthai = [
    { value: 'Treo', label: 'Treo' },
    { value: 'Thành công', label: 'Thành công' },
    { value: 'Thất bại', label: 'Thất bại' },
  ];


const moment = require('moment');
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');
class QuanLyNhapHang extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trangThai: 'tat_ca',
            search: '',
            loading: true,
            dateFrom: '',
            dateTo: '',
            website: '',
            khac: '',
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
            nhanthanhtoan: false,
            Loader: false,
            openChuanBi: false, // Modal
            openNhapVanDon: false, // Modal
            openChiTietVanDon: false, // Modal
            openNhapKho: false, // Modal
            openModalGhiChu: false, // Modal
            noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
            t_id: '',
            so_luong: '',
              startDate: moment().subtract(365, 'days').format('YYYY-MM-DD'),
            // startDate: moment().format('YYYY-MM-01'),
            endDate: moment().format('YYYY-MM-DD'),
            selectedLyDo: null,
            ly_do:'',
            giatritron: []
        };
        this.handlePageChange = this.handlePageChange.bind(this);

        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.checkOne = this.checkOne.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.unCheckAll = this.unCheckAll.bind(this);
        this.saveBuy = this.saveBuy.bind(this);
    }

    handleChangeLyDo = (selectedLyDo) => {
        this.setState({ selectedLyDo });
        this.setState({
            trangThaiDaChon: selectedLyDo.value,
        });
    }

    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }

    updateTrangThai(ma_don,e) {
        this.setState({loading: true})
            let token = localStorage.getItem('token');
            axios.post(Config.API_URL + 'cap-nhat-trang-thai?token=' + token, qs.stringify({ ma_don: ma_don, trang_thai: e }))
                .then((res) => {
        this.setState({loading: false})
                  // Get data
                  var trangThai = '';
                  if (this.state.trangThai) {
                    trangThai = this.state.trangThai;
                  }
                  var search = '';
                  if (this.state.search) {
                    search = this.state.search;
                  }
                  var website = '';
                  if (this.state.website) {
                    website = this.state.website;
                  }
                  var khac = '';
                  if (this.state.khac) {
                    khac = this.state.khac;
                  }
                  var dateFrom = '';
                  if (this.state.dateFrom) {
                    dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
                  }
                  var dateTo = '';
                  if (this.state.dateTo) {
                    dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
                  }
        
        
                  let token = localStorage.getItem('token');
                  axios.get(Config.API_URL + 'ordersFull?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
                    .then(response => {
                      this.setState({
                        data: response.data,
                        loading: false,
                        activePage: response.data.dontongid.current_page,
                        itemsCountPerPage: response.data.dontongid.per_page,
                        totalItemsCount: response.data.dontongid.total,
                        path: response.data.dontongid.path,
                      })
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
        
        
                  axios.get(Config.API_URL + 'dem-so-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
                    .then(responsedem => {
        
                      this.setState({
                        demsodon: responsedem.data,
                      })
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
            // End Get data
        
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });


    }



    componentWillMount() {

        if(queryString.parse(window.location.search).trangThai){
            this.setState({
                trangThai: queryString.parse(window.location.search).trangThai,
            })
        }
        this.setState({
            // trangThai: queryString.parse(window.location.search).trangThai,
            search: queryString.parse(window.location.search).search,
            website: queryString.parse(window.location.search).website,
            dateFrom: queryString.parse(window.location.search).dateFrom,
            dateTo: queryString.parse(window.location.search).dateTo,
            khac: queryString.parse(window.location.search).khac,
            page: queryString.parse(window.location.search).page,
        })

        //config pusher
        this.pusher = new Pusher('bac587b7ba55a2b602d3', {
            encrypted: true,
            cluster: "ap1"
        });
        this.realtime = this.pusher.subscribe('kingorder');

    }




    componentDidUpdate = (prevProps, prevState) => {

        const query = {
            trangThai: this.state.trangThai,
            search: this.state.search,
            website: this.state.website,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            khac: this.state.khac,
            page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
            {
                pathname: '/quan-ly-nhap-hang',
                search: searchString,
            }
        )

        // Get data
        if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan || this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate) {


            if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
                this.setState({
                    page: 1
                })
            }
            this.setState({
                loading: true
            })
            var trangThai = 'tat_ca';
            if (this.state.trangThai) {
                trangThai = this.state.trangThai;
            }
            var search = '';
            if (this.state.search) {
                search = this.state.search;
            }
            var website = '';
            if (this.state.website) {
                website = this.state.website;
            }
            var khac = '';
            if (this.state.khac) {
                khac = this.state.khac;
            }
            var dateFrom = '';
            if (this.state.startDate) {
                dateFrom = this.state.startDate;
            }
            var dateTo = '';
            if (this.state.endDate) {
                dateTo = this.state.endDate;
            }
            let token = localStorage.getItem('token');
            if(trangThai == 'don_moi'){
                axios.get(Config.API_URL + 'quan-ly-nhap-hang?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
                .then(response => {
                    this.setState({
                        items: response.data.data,
                        loading: false,
                        activePage: response.data.current_page,
                        itemsCountPerPage: response.data.per_page,
                        totalItemsCount: response.data.total,
                        path: response.data.path,
                    });
                })
                .catch(function (error) {
                    // this.setState({ loading: false });
                    console.log(error);
                });
            }else{
                axios.get(Config.API_URL + 'quan-ly-nhap-hang?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
                    .then(response => {
                        this.setState({
                            data: response.data,
                            loading: false,
                            activePage: response.data.dontongid.current_page,
                            itemsCountPerPage: response.data.dontongid.per_page,
                            totalItemsCount: response.data.dontongid.total,
                            path: response.data.dontongid.path,
                        });
                    })
                    .catch(function (error) {
                        // this.setState({ loading: false });
                        console.log(error);
                    });
            }


           

            axios.get(Config.API_URL + 'dem-so-don-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
                .then(responsedem => {

                    this.setState({
                        demsodon: responsedem.data,
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
        // End Get data

  

    }

    componentDidMount = () => {
        this.setState({
            loading: false
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
            trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
            search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
            website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
            khac = this.state.khac;
        }
        var dateFrom = this.state.startDate;
        if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = this.state.endDate;
        if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }
        let token = localStorage.getItem('token');

        if(trangThai == 'don_moi'){
            axios.get(Config.API_URL + 'quan-ly-nhap-hang?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
            .then(response => {
                this.setState({
                    items: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            })
            .catch(function (error) {
                // this.setState({ loading: false });
                console.log(error);
            });
        }else{
            axios.get(Config.API_URL + 'quan-ly-nhap-hang?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
                .then(response => {
                    this.setState({
                        data: response.data,
                        loading: false,
                        activePage: response.data.dontongid.current_page,
                        itemsCountPerPage: response.data.dontongid.per_page,
                        totalItemsCount: response.data.dontongid.total,
                        path: response.data.dontongid.path,
                    });
                })
                .catch(function (error) {
                    // this.setState({ loading: false });
                    console.log(error);
                });
        }


        
        // axios.get(Config.API_URL + 'quan-ly-nhap-hang?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
        //     .then(response => {
        //         this.setState({
        //             items: response.data.data,
        //             loading: false,
        //             activePage: response.data.current_page,
        //             itemsCountPerPage: response.data.per_page,
        //             totalItemsCount: response.data.total,
        //             path: response.data.path,
        //         })
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });


        axios.get(Config.API_URL + 'dem-so-don-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
            .then(responsedem => {

                this.setState({
                    demsodon: responsedem.data,
                })
            })
            .catch(function (error) {
                console.log(error);
            });


        // End Get data



        this.realtime.bind('nhandonhang', function (data) {
            // alert(data.sodonmoi);
            let id = data.id;
            let items = this.state.items;
            let keyobject = 0;


            if (JSON.parse(localStorage.getItem('info')).name != data.hoten && data.trang_thai == 'success') {
                items.map(function (item, key) {
                    if (item.id == id) {
                        keyobject = key
                    }
                });
                items.splice(keyobject, 1);
                this.setState({
                    items: items
                })
                // document.getElementById("order-"+data.id).style.display = "none";
                document.getElementById("donmoi").innerHTML = data.sodonmoi;
                NotificationManager.success(data.hoten + ' đã nhận đơn ' + data.id, '', 2000, false);
            }

            // console.log(data.hoten+' đã nhận đơn' + data.id);
        }, this);



    }

    delProduct(id) {
        var ma_don = '';
        if (this.state.ma_don) {
            var ma_don = this.state.ma_don;
        }
        var ma_giao_dich = '';
        if (this.state.ma_giao_dich) {
            var ma_giao_dich = this.state.ma_giao_dich;
        }
        var date_from = '';
        if (this.state.date_from) {
            var date_from = Moment(this.state.date_from).format('DD-MM-YYYY');
        }
        var date_to = '';
        if (this.state.date_to) {
            var date_to = Moment(this.state.date_to).format('DD-MM-YYYY');
        }
        var trangThai = '';
        if (this.state.trangThai) {
            var trangThai = this.state.trangThai;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'delItems?masp=' + id + "&token=" + token)
            .then(response => {
                axios.get(Config.API_URL + 'quan-ly-nhap-hang?ma_don=' + ma_don + '&ma_giao_dich=' + ma_giao_dich + '&date_from=' + date_from + '&date_to=' + date_to + '&trangThai=' + trangThai + '&page=' + this.state.page + "&token=" + token)
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            loading: false,
                            path: response.data.path,
                        });
                    });
            });
    }



    // Chuẩn bị
    onOpenModalChuanBi = (id, cs) => {
        this.setState({chitietdon:''});
        this.setState({ loading: false });
        this.setState({giatridachon:[]})
        let token = localStorage.getItem('token');


        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
            trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
            search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
            website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
            khac = this.state.khac;
        }
        var dateFrom = this.state.startDate;
        if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = this.state.endDate;
        if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }


        axios.get(Config.API_URL + 'nhap-hang-chi-tiet?trangThai=' + trangThai + '&id=' + id + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
            .then(response => {
                this.setState({chitietdon:response.data})
                
            });


        this.setState({
            openChuanBi: true,
            id: id,
            cs: cs,
        });

    };

    onCloseModalChuanBi = () => {
        this.setState({ openChuanBi: false });
    };

    // Nhập Vận Đơn





     // Modal Ghi chú
     onOpenModalGhiChu = (id, note) => {
        this.setState({chitietdon:''});
        this.setState({ loading: false });
        this.setState({giatridachon:[]})
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'nhap-hang-chi-tiet?id=' + id + "&token=" + token)
            .then(response => {
                this.setState({chitietdon:response.data})
                
            });


        this.setState({
            openModalGhiChu: true,
            id: id,
            note: note,
        });

    };

    onCloseModalGhiChu = () => {
        this.setState({ openModalGhiChu: false });
    };



    // Nhập kho
    onOpenModalNhapKho = () => {
        this.setState({ chitietdon: '' });
        this.setState({ loading: false });
        this.setState({ giatridachon: [] })
        this.setState({
            openNhapKho: true,
        });

    };

    onCloseModalNhapKho = () => {
        this.setState({ openNhapKho: false });
    };

    // Nhập Vận Đơn


    onOpenModalNhapVanDon = (id, ma_mua) => {
        this.setState({chitietdon:''});
        this.setState({ loading: false });
        this.setState({giatridachon:[]})
                let token = localStorage.getItem('token');
                axios.get(Config.API_URL + 'ma-mua-chi-tiet?id=' + id + "&ma_mua=" + ma_mua + "&token=" + token)
                    .then(response => {
                        this.setState({chitietdon:response.data})
                        
                    });
        
        
                this.setState({
                    openNhapVanDon: true,
                    id: id,
                    ma_mua: ma_mua,
                });
        
            };
        
            onCloseModalNhapVanDon = () => {
                this.setState({ openNhapVanDon: false });
            };
            // Mở Modal
    // Nhập Vận Đơn


    onOpenModalChiTietVanDon = (id, van_don) => {
        this.setState({chitietdon:''});
        this.setState({ loading: false });
        this.setState({giatridachon:[]})
                let token = localStorage.getItem('token');
                axios.get(Config.API_URL + 'van-don-chi-tiet?id=' + id + "&van_don=" + van_don + "&token=" + token)
                    .then(response => {
                        this.setState({chitietdon:response.data})
                        
                    });
        
        
                this.setState({
                    openChiTietVanDon: true,
                    id: id,
                    van_don: van_don,
                    van_don_cu: van_don,
                });
        
            };
        
            onCloseModalChiTietVanDon = () => {
                this.setState({ openChiTietVanDon: false });
            };
            // Mở Modal

    capNhat() {
        if (this.state.so_luong) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.soluong = this.state.so_luong;
            order.id = this.state.id;
            order.modelid = this.state.modelid;
            order.title = this.state.title;
            order.search = this.state.search;
            order.ly_do = this.state.ly_do;

            if (!this.state.modelid || !this.state.so_luong || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                    openChuanBi: false

                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-ton-kho?token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }

    truDi() {
        if (this.state.so_luong) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.soluong = this.state.so_luong;
            order.id = this.state.id;
            order.modelid = this.state.modelid;
            order.title = this.state.title;
            order.search = this.state.search;
            order.ly_do = this.state.ly_do;

            if (!this.state.modelid || !this.state.so_luong || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                    openChuanBi: false

                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-ton-kho?type=trudi' + '&token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }


    CapNhatTrangThaiGoi() {
        if (this.state.trangThaiDaChon && this.state.id && this.state.cs) {
            var order = {};
            order.trang_thai = this.state.trangThaiDaChon;
            order.id = this.state.id;
            order.cs = this.state.cs;
            order.ly_do = this.state.ly_do;

            var trangThai = '';
            if (this.state.trangThai) {
                trangThai = this.state.trangThai;
            }
            var search = '';
            if (this.state.search) {
                search = this.state.search;
            }
            var website = '';
            if (this.state.website) {
                website = this.state.website;
            }
            var khac = '';
            if (this.state.khac) {
                khac = this.state.khac;
            }
            var dateFrom = this.state.startDate;
            if (this.state.dateFrom) {
                dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
            }
            var dateTo = this.state.endDate;
            if (this.state.dateTo) {
                dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
            }
            
            let token = localStorage.getItem('token');
            axios.post(Config.API_URL + 'cap-nhat-trang-thai-goi?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo, qs.stringify(order))
                .then(response => {


                    axios.get(Config.API_URL + 'dem-so-don-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
                        .then(responsedem => {

                            this.setState({
                                demsodon: responsedem.data,
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    this.setState({
                        data: response.data,
                        loading: false,
                        activePage: response.data.dontongid.current_page,
                        itemsCountPerPage: response.data.dontongid.per_page,
                        totalItemsCount: response.data.dontongid.total,
                        path: response.data.dontongid.path,
                        openChuanBi: false
                    });
                    // var redirectWindow = window.open(Config.API_URL + "in-nhan?id=" + this.state.id + "&soluong=" + this.state.so_luong + "&ghichu=", '_blank');
                    // redirectWindow.focus();
                })
                .catch(function (error) {
                    console.log(error);
                    alert("Lỗi")
                });




        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    chonTrangThai(e) {
        this.setState({
            trangThai: e,
        })
    }

    updateGiuCho(id) {
        let token = localStorage.getItem('token');
        var order = {};
        order.id = id;
        order.search = this.state.search;
        axios.post(Config.API_URL + 'cap-nhat-giu-cho?token=' + token, qs.stringify(order))
            .then(response => {
                this.setState({
                    items: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            })
            .catch(function (error) {
                console.log(error);
                alert("Lỗi")
            });
    }













    show() {
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }

    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        });
    }
    onDateRangeSelectChange(event) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate = moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-') + moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate = moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate = moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate = moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;

            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        })
    }
    showCalendar() {
        const from = new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{ marginBottom: 10, width: 350 }}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> -
            <span className="InputFromTo-to">
                    <DayPickerInput
                        ref={el => (this.to = el)}
                        value={to}
                        placeholder="To"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers,
                            month: to,
                            months: Config.MONTHS,
                            numberOfMonths: 2,
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }

    nhanDon(id) {
        this.setState({
            loading: true
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
            trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
            search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
            website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
            khac = this.state.khac;
        }
        var dateFrom = this.state.startDate;
        if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = this.state.endDate;
        if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }


        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'nhan-don?trangThai=' + trangThai + '&id=' + id + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
            .then(response => {
                console.log(response);
                // Thành công thì đếm lại đơn
                axios.get(Config.API_URL + 'dem-so-don-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
                    .then(responsedem => {

                        this.setState({
                            demsodon: responsedem.data,
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                if (response.data.trang_thai == 'success') {
                    NotificationManager.success(response.data.trang_thai_text, '', 2000, false);
                }
                if (response.data.trang_thai == 'error') {
                    NotificationManager.error(response.data.trang_thai_text, '', 2000, false);
                }


                this.setState({
                    items: response.data.data.data,
                    loading: false,
                    activePage: response.data.data.current_page,
                    itemsCountPerPage: response.data.data.per_page,
                    totalItemsCount: response.data.data.total,
                    path: response.data.data.path,
                })
            })
            .catch(function (error) {
                console.log(error);
            });



    }


    tableRow() {


        if (this.state.data.don_tong) {
          var all_product = [];
          var rows = [];
          this.state.data.dontongid.data.map((valueOrder, key) => {
            
            var soLuong = 0;
            var cohang = 0;
            var ton_kho = 0;
            rows.push(
    
              <section className="ui-card " key={key} id={"order-"+valueOrder.id} >
                <div className="ui-card__section has-bulk-actions orders ">
                  <div className="ui-type-container">
                    <div className="orderContext">
                      <table id="order-table" className="table-hover quick-view-order bulk-action-context expanded">
                        <tbody>
    
                          <tr className="ui-nested-link-container parent-quick-view">
                            {/* <td className="no-wrap col-md-2 col-sm-12 col-xs-12">
                              <Link to={"/order/" + valueOrder.id} data-nested-link-target="true">#{valueOrder.id}</Link>
                            </td> */}
                            <td className="no-wrap col-md-2 col-sm-12 col-xs-12">
                              #{valueOrder.id} <a href={"https://api.roza.vn/api/toa-nhap?id="+valueOrder.id} target="_blank">(Xem toa)</a>
                            </td>
                            <td className="no-wrap col-md-3 col-sm-12 col-xs-12 hidden-sm hidden-xs ">
                              <span>{valueOrder.created_at}</span>
                            </td>
                            
                            
    
    
                            <td className="no-wrap">
                              <span className="badge badge--status-complete badge--progress-incomplete">
                              {valueOrder.tao_don?"Nhập hàng: "+valueOrder.tao_don:""}
                              </span>
                            </td>
    
                            <td className="no-wrap">
                              <span className="badge badge--status-success badge--progress-complete">
                                {valueOrder.cs?"Chăm sóc: "+valueOrder.cs:""}
                              </span>
                            </td>




                            <td className="no-wrap col-md-3 col-sm-12 col-xs-12">
                              <span className={
                                valueOrder.trang_thai === 'Đã hủy' ? "badge badge--status-complete badge--progress-complete"
                                  :
                                  valueOrder.trang_thai === 'Đã mua' ? "badge badge--status-success badge--progress-complete"
                                    :
                                    valueOrder.trang_thai === 'Chưa mua' ? "badge badge--status-danger badge--progress-danger"
                                    :
                                    "badge badge--status-warning badge--progress-incomplete"
                              }>
                                {"Trạng thái: "+valueOrder.trang_thai}
                              </span>
                            </td>
    
                           
    
                          </tr>
    
                          <tr className="panel-quick-view panel-quick-view-open" style={{ 'backgroundColor': "#ffffff00" }}>
                            <td colSpan={7} className>
                              <div className="row" style={{ marginBottom: 0 }}>
    
    
    
    
                                <div className="line-mobile col-md-3 col-sm-12 col-xs-12 ">
                                  <div className="next-card__section next-card__section--no-vertical-spacing next-card__section_modify">
                                    <table className="table--extra-expanded table--no-side-padding table--divided">
                                      <tbody>
                                        
                                        {
                                          
                                          JSON.parse(JSON.stringify(this.state.data.don_chi_tiet))[valueOrder.id].map((value, key) => {
                                            soLuong += parseInt(value.quantity);
                                            
                                            if (this.state.ton_kho) {
                                              var tonkho = this.state.ton_kho.filter(function (returnableObjects) {
                                                if(returnableObjects){
                                                  return returnableObjects.modelid === value.key;
                                                }else{
                                                  return null;
                                                }
                                                
                                              })
    
                                              
                                            }
    
                                          //  console.log("tonkho "+tonkho);
                                           
                                            
                                            if( this.state.ton_kho && tonkho &&  tonkho[0] && tonkho[0].stock) {
                                              cohang +=  parseInt(tonkho[0].stock - tonkho[0].giu_cho);
                                              ton_kho =   parseInt(tonkho[0].stock);
                                            }
                                            var html = "<img src="+value.image+" width='400px' />"
                                            return (
                                              <tr key={key}   >
                                                <td width="50px" className="hide-when-printing">
                                                  <div className="aspect-ratio aspect-ratio--square aspect-ratio--square--50" data-tip={html}>
                                                    <img className="aspect-ratio__content" src={value.image} />
                                                    <ReactTooltip html={true} place={'right'}/>
                                                  </div>
                                                </td>
                                                <td width="100%" style={{ paddingLeft: 10 }}>
                                                  <a href={Config.HOME_URL + value.url} target="_blank">{value.name}</a>
                                                  <p>
                                                    <span>{value.color?value.color+" / ":""}{value.size?value.size:""}{value.thuoctinh?value.thuoctinh:""}</span>
                                                  </p>
                                                  <p>
                                                    <span>SKU: {value.key?value.key:""}</span>
                                                  </p>
                                                  <p>
                                                    <span>{parseInt(value.price).toLocaleString()}₫</span> x <span>{value.quantity}</span>
                                                  </p>
    
                                                </td>
                                              </tr>
                                            )
                                          })}
    
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
    
    
    
    
                                <div className="col-md-2 col-sm-12 col-xs-12" style={{ paddingTop: 5 }}>
                                  <table className="grid-order-table-info">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Số lượng: {soLuong}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Tiền hàng: {parseInt(valueOrder.countTotal).toLocaleString() + "₫"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Phí VC: {parseInt(valueOrder.feeShip).toLocaleString() + "₫"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <b>Tổng cộng: {parseInt(valueOrder.sumTotal).toLocaleString() + "₫"}</b>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
    
    
                                <div className="col-md-3 col-sm-12 col-xs-12" style={{ paddingTop: 5 }}>
                                  <table className="grid-order-table-info">
                                    <tbody>
                                      <tr>
                                        <td className="grid-order-cus-info-icon"><i className="fa fa-user" aria-hidden="true" /></td>
                                        <td style={{ paddingLeft: 5 }}>
                                          {valueOrder.full_name}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="grid-order-cus-info-icon"><i className="fa fa-map-marker" aria-hidden="true" /></td>
                                        <td style={{ paddingLeft: 5 }}>
                                          <span>{valueOrder.address}</span><br />
                                          <span>{valueOrder.xa}</span><br />
                                          <span>{valueOrder.huyen}</span><br />
                                          <span>{valueOrder.tinh}</span>
                                        </td>
                                      </tr>
    
                                      {/* {
                                        valueOrder.ma_mua ?
                                          <tr>
                                            
                                            <td className="grid-order-cus-info-icon"><i className="fa fa-plane" aria-hidden="true" /></td>
                                            <td >
                                            <a href={"https://khachhang.giaohangtietkiem.vn/khachhang?customer_created_from=2018-01-01+00:00:00&code="+valueOrder.ma_mua} target="_blank">{valueOrder.ma_mua} </a>
                                            
                                            (<a href={"https://api.morigirl.vn/api/update-ghtk?label_id="+valueOrder.ma_mua} target="_blank">UPDATE</a>)
                                            </td>
    
                                            
                                           
                                          </tr>
    
                                          :
                                          ""
                                      } */}


    
                                    {
                                        valueOrder.ma_mua_full ?

                                            JSON.parse(valueOrder.ma_mua_full).map((value, key ) => {
                                                return <tr key={key} onClick={() => this.onOpenModalNhapVanDon(valueOrder.id, value)}>
                                                   <td className="grid-order-cus-info-icon"><i className="fa fa-cart-plus" aria-hidden="true" /></td>
                                                    <td >{value}</td>
                                                </tr>
                                            })



                                            :
                                            ""
                                    }
                                      {
                                        valueOrder.phi_gui_hang?
                                        <tr>
                                          <td className="grid-order-cus-info-icon"><i className="fa fa-plane" aria-hidden="true" /></td>
                                          <td >{"Phí gửi hàng: "+valueOrder.phi_gui_hang.toLocaleString()+"đ"}</td>
                                          </tr>
                                        :
                                        ""
                                      }
    
                                    </tbody></table>
                                </div>
    
                                <div className="line-mobile col-md-2">
    
                                  <div>
                                
                                    <div className="has-error">
                                        {
                                            valueOrder.note?
                                            <span onClick={() => this.onOpenModalGhiChu(valueOrder.id, valueOrder.note)} style={{backgroundColor: 'rgb(254, 237, 186)', paddingBottom: '3px', paddingRight: '10px'}}><span className="field-validation-valid" data-valmsg-for="Note" data-valmsg-replace="true" />{valueOrder.note}</span>
                                            :
                                            <span onClick={() => this.onOpenModalGhiChu(valueOrder.id, valueOrder.note)} style={{paddingBottom: '3px', paddingRight: '10px'}}><span className="field-validation-valid" data-valmsg-for="Note" data-valmsg-replace="true" /><i className="fa fa-pencil-square-o" aria-hidden="true" /> Thêm ghi chú</span>

                                        }
                                     
                                    </div>
                                   
    <br></br>
    
                                    {
                                      this.state.check_trung ?
                                        this.state.check_trung.map((value, key) => {
    
                                          if(parseInt(value[0]) === parseInt(valueOrder.phone) && value[1] >= 2){
                                            return <div>
                                              <a href={'orders?search='+valueOrder.phone} target="_blank">
                                              <p><button className="ui-button ui-button--xemtrung quick-view-order-btn-refund" href="javascript:void(0);"><i className="fa fa-exclamation-triangle" aria-hidden="true" /> Xem đơn trùng</button></p>
                                              </a>
                                       </div>
                                          }
    
                                        })
                                        :
                                        ""
                                    }
    
    
                                                            {
                                                                valueOrder.trang_thai == 'Chưa mua' ?
                                                                    !this.state.loading ?
                                                                        <button onClick={() => this.onOpenModalChuanBi(valueOrder.id, valueOrder.cs)} type="button" className="btn btn-default btn-xs">Mua hàng</button>
                                                                        :
                                                                        "Loading..."
                                                                    :
                                                                    ""
                                                            }



{
                                        valueOrder.van_don_full ?

                                            JSON.parse(valueOrder.van_don_full).map((value, key ) => {
                                                return <tr key={key} onClick={() => this.onOpenModalChiTietVanDon(valueOrder.id, value)}>
                                                   <td className="grid-order-cus-info-icon"><i className="fa fa-car" aria-hidden="true" /></td>
                                                    <td >{value}</td>
                                                </tr>
                                            })



                                            :
                                            ""
                                    }
    
                                   
    
    
                                  </div>
                                </div>
    
                                <div className="col-md-2 panel-quick-view-action">
    
                                  
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
    
    
            )
          })
    
          if (rows.length < 1) {
            return <div className="col-sm-12" style={{marginTop:"20px"}}>
              <center><h6>KHÔNG CÓ ĐƠN HÀNG THỎA MÃN</h6></center>
            </div>;
          } else {
            return <div style={{ marginTop: "20px" }}>{rows}</div>;
          }
        }
    
      }

      
    /**
     * function Checkone
     */
    checkOne () {
        var giatridachon = [];
        var checkboxes = document.getElementsByName('name[]');
        for (var i = 0; i < checkboxes.length; i++){
            if (checkboxes[i].checked == true) {
                giatridachon.push(parseInt(checkboxes[i].value));
            }
            
        }
        //Add vào state
        this.setState({
            giatridachon: giatridachon
        })
    }

    checkAll () {
        var giatridachon = [];
        var checkboxes = document.getElementsByName('name[]');
        for (var i = 0; i < checkboxes.length; i++){
            checkboxes[i].checked = true;
            giatridachon.push(parseInt(checkboxes[i].value));
        }
        console.log(giatridachon);
        //Add vào state
        this.setState({
            giatridachon: giatridachon
        })
    }

    unCheckAll () {
        var giatridachon = [];
        //Add vào state
        this.setState({
            giatridachon: giatridachon
        })
        var checkboxes = document.getElementsByName('name[]');
        for (var i = 0; i < checkboxes.length; i++){
            checkboxes[i].checked = false;
        }
        //Add vào state
        this.setState({
            giatridachon: giatridachon
        })
    }
    
    saveBuy () {
        
        if (this.state.giatridachon.length > 0 && this.state.id && this.state.van_don) {
            this.setState({ loading: true });
            var order = {};
            order.id = this.state.id; // Mã đơn
            order.id_detail = this.state.giatridachon; // Mã ID chi tiết đơn
            order.van_don = this.state.van_don;

            var trangThai = '';
                  if (this.state.trangThai) {
                    trangThai = this.state.trangThai;
                  }
                  var search = '';
                  if (this.state.search) {
                    search = this.state.search;
                  }
                  var website = '';
                  if (this.state.website) {
                    website = this.state.website;
                  }
                  var khac = '';
                  if (this.state.khac) {
                    khac = this.state.khac;
                  }
                  var dateFrom = '';
                  if (this.state.dateFrom) {
                    dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
                  }
                  var dateTo = '';
                  if (this.state.dateTo) {
                    dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
                  }
            
            let token = localStorage.getItem('token');
            axios.post(Config.API_URL + 'cap-nhat-mua-hang?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + "&page=" + this.state.page, qs.stringify(order))
                .then(response => {


                    axios.get(Config.API_URL + 'dem-so-don-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
                        .then(responsedem => {

                            this.setState({
                                van_don: '',
                                demsodon: responsedem.data,
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    this.setState({
                        data: response.data,
                        loading: false,
                        activePage: response.data.dontongid.current_page,
                        itemsCountPerPage: response.data.dontongid.per_page,
                        totalItemsCount: response.data.dontongid.total,
                        path: response.data.dontongid.path,
                        openChuanBi: false
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    alert("Lỗi")
                });




        } else {
            alert('Chưa nhập đủ thông tin')
        }
        console.log(this.state.giatridachon);
    }


    saveVanDon () {
       
        if (this.state.giatridachon.length > 0 && this.state.id && this.state.van_don) {
            this.setState({ loading: true });
            var order = {};
            order.id = this.state.id; // Mã đơn
            order.id_detail = this.state.giatridachon; // Mã ID chi tiết đơn
            order.van_don = this.state.van_don;

            var trangThai = '';
                  if (this.state.trangThai) {
                    trangThai = this.state.trangThai;
                  }
                  var search = '';
                  if (this.state.search) {
                    search = this.state.search;
                  }
                  var website = '';
                  if (this.state.website) {
                    website = this.state.website;
                  }
                  var khac = '';
                  if (this.state.khac) {
                    khac = this.state.khac;
                  }
                  var dateFrom = '';
                  if (this.state.dateFrom) {
                    dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
                  }
                  var dateTo = '';
                  if (this.state.dateTo) {
                    dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
                  }
            
            let token = localStorage.getItem('token');
            axios.post(Config.API_URL + 'cap-nhat-van-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + "&page=" + this.state.page, qs.stringify(order))
                .then(response => {


                    axios.get(Config.API_URL + 'dem-so-don-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
                        .then(responsedem => {

                            this.setState({
                                van_don: '',
                                demsodon: responsedem.data,
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    this.setState({
                        data: response.data,
                        loading: false,
                        activePage: response.data.dontongid.current_page,
                        itemsCountPerPage: response.data.dontongid.per_page,
                        totalItemsCount: response.data.dontongid.total,
                        path: response.data.dontongid.path,
                        openNhapVanDon: false
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    alert("Lỗi")
                });




        } else {
            alert('Chưa nhập đủ thông tin')
        }
        console.log(this.state.giatridachon);
    }
    


    saveVanDonMoi () {
        
        if (this.state.giatridachon.length > 0 && this.state.id && this.state.van_don) {
            this.setState({ loading: true });
            var order = {};
            order.id = this.state.id; // Mã đơn
            order.id_detail = this.state.giatridachon; // Mã ID chi tiết đơn
            order.van_don = this.state.van_don;
            order.van_don_cu = this.state.van_don_cu;

            var trangThai = '';
                  if (this.state.trangThai) {
                    trangThai = this.state.trangThai;
                  }
                  var search = '';
                  if (this.state.search) {
                    search = this.state.search;
                  }
                  var website = '';
                  if (this.state.website) {
                    website = this.state.website;
                  }
                  var khac = '';
                  if (this.state.khac) {
                    khac = this.state.khac;
                  }
                  var dateFrom = '';
                  if (this.state.dateFrom) {
                    dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
                  }
                  var dateTo = '';
                  if (this.state.dateTo) {
                    dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
                  }
            
            let token = localStorage.getItem('token');
            axios.post(Config.API_URL + 'cap-nhat-van-don-moi?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo, qs.stringify(order))
                .then(response => {


                    axios.get(Config.API_URL + 'dem-so-don-nhap?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo)
                        .then(responsedem => {

                            this.setState({
                                van_don: '',
                                demsodon: responsedem.data,
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    this.setState({
                        data: response.data,
                        loading: false,
                        activePage: response.data.dontongid.current_page,
                        itemsCountPerPage: response.data.dontongid.per_page,
                        totalItemsCount: response.data.dontongid.total,
                        path: response.data.dontongid.path,
                        openChiTietVanDon: false
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    alert("Lỗi")
                });




        } else {
            alert('Chưa nhập đủ thông tin')
        }
        console.log(this.state.giatridachon);
    }

    inDaChon(){
        if (this.state.giatridachon.length) {
            var redirectWindow = window.open(Config.API_URL+"in-nhan-da-chon?id=" + this.state.giatridachon, '_blank');
            redirectWindow.focus();

        } else {
            alert('Chưa nhập đủ thông tin')
        }
        console.log(this.state.giatridachon);
    }

    capNhatGhiChu(){
        this.setState({ loading: true });
        if(this.state.id && this.state.note){





            this.setState({ loading: true });
            var order = {};
            order.id = this.state.id; // Mã đơn
            order.note = this.state.note;

            var trangThai = '';
                  if (this.state.trangThai) {
                    trangThai = this.state.trangThai;
                  }
                  var search = '';
                  if (this.state.search) {
                    search = this.state.search;
                  }
                  var website = '';
                  if (this.state.website) {
                    website = this.state.website;
                  }
                  var khac = '';
                  if (this.state.khac) {
                    khac = this.state.khac;
                  }
                  var dateFrom = '';
                  if (this.state.dateFrom) {
                    dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
                  }
                  var dateTo = '';
                  if (this.state.dateTo) {
                    dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
                  }
            
            let token = localStorage.getItem('token');
            axios.post(Config.API_URL + 'cap-nhat-ghi-chu-nhap-hang?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + "&page=" + this.state.page, qs.stringify(order))
                .then(response => {


                    this.setState({
                        data: response.data,
                        loading: false,
                        activePage: response.data.dontongid.current_page,
                        itemsCountPerPage: response.data.dontongid.per_page,
                        totalItemsCount: response.data.dontongid.total,
                        path: response.data.dontongid.path,
                        openModalGhiChu: false
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    alert("Lỗi")
                });




                
        }else{
            alert('Chưa nhập đủ thông tin')
        }
    }



    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.setState({
            loading: true,
            van_don_nhap: this.state.van_don,
            giatridachon:[]
          })
      
          
          let token = localStorage.getItem('token');
          axios.get(Config.API_URL + 'get-chi-tiet-van-don?token=' + token + '&van_don=' + this.state.van_don)
            .then(response => {
                this.trongLuongInput.focus();
              this.setState({ 
                data_van_don: response.data, 
                loading: false,
              })
            })
            .catch(function (error) {
              console.log(error);
            });
      
        }
      }

      nhapKho = (e) => {
        if (e.key === 'Enter') {
          this.setState({
            loading: true,
            van_don_nhap: this.state.van_don,
            trong_luong: this.state.trong_luong,
            van_don:'',
            giatridachon:[]
          })
          this.vanDonInput.focus();
          
          let token = localStorage.getItem('token');
          axios.get(Config.API_URL + 'nhap-kho?token=' + token + '&van_don=' + this.state.van_don + '&trong_luong=' + this.state.trong_luong)
            .then(response => {
              this.setState({ 
                van_don:'',
                trong_luong:'',
                data_van_don: response.data, 
                loading: false,
                trang_thai_nhap_kho:'Nhập kho thành công',
                
              })
            })
            .catch(function (error) {
              console.log(error);
            });
      
        }
      }
    
    render() {
        console.log(this.state.data_van_don);
        
        // Modal
        const { openChuanBi } = this.state;
        const { openNhapVanDon } = this.state;
        const { openChiTietVanDon } = this.state;
        const { openNhapKho } = this.state;
        const { openModalGhiChu } = this.state;
        const { selectedLyDo } = this.state;
        // Modal

        //console.log(this.state.selectedLyDo);
        

        return (
            <div id="content">
                <NotificationContainer />
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    <h1 className="ui-title-bar__title">DANH SÁCH ĐƠN NHẬP</h1>
                                    <div id="reportrangereceived" className="form-group" style={{ position: 'relative' }}>
                                        <span onClick={this.show} style={{ position: 'absolute', top: '5px', width: '100%', fontSize: '18px' }}>{moment(this.state.startDate).format('DD/MM/YYYY')} đến {moment(this.state.endDate).format('DD/MM/YYYY')}</span>
                                        <i onClick={this.show} className="fa fa-calendar" style={{ float: 'right', paddingTop: '7px', paddingLeft: '7px' }}></i>
                                        {this.state.show ? <this.showCalendar /> : ""}
                                    </div>

                                    <div className="ui-title-bar__actions-group">
                                        <div className="ui-title-bar__actions">
                                            <a onClick={() => this.onOpenModalNhapKho()} className="ui-button ui-button--primary ui-title-bar__action">Nhập kho</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">

                                                    <li onClick={() => this.chonTrangThai('tat_ca')} className="filter-tab-item">
                                                        <a className={this.state.trangThai === 'tat_ca' ? "filter-tab filter-tab-active show-all-items next-tab next-tab--is-active" : "filter-tab next-tab"}>Tất cả (<span id="donmoi">{this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).tat_ca : ""}</span>)</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('chua_mua')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'chua_mua' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Chưa mua ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).chua_mua : ""})</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('da_mua')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'da_mua' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Đã mua ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).da_mua : ""})</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('ncc_gui')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'ncc_gui' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>NCC gửi ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).ncc_gui : ""})</a>
                                                    </li>
                                                    {/* <li onClick={() => this.chonTrangThai('tq_nhan')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'tq_nhan' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>TQ Nhận ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).tq_nhan : ""})</a>
                                                    </li> */}
                                                    <li onClick={() => this.chonTrangThai('ke_don')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'ke_don' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Ke đơn ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).ke_don : ""})</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('ve_kho')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 've_kho' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Về kho ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).ve_kho : ""})</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('khieu_nai')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'khieu_nai' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Khiếu nại ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).khieu_nai : ""})</a>
                                                    </li>
                                                    <li onClick={() => this.chonTrangThai('huy')} className="filter-tab-item">
                                                        <a className={this.state.trangThai && this.state.trangThai === 'huy' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Hủy ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).huy : ""})</a>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                        <div className="ui-card__section has-bulk-actions orders " />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </section>

{
    this.state.trangThai == 'don_moi'?
    <section className="ui-card">
    <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                                <th><span>Đơn hàng</span></th>
                                                                <th><span>Họ tên</span></th>
                                                                <th><span>Điện thoại</span></th>
                                                                <th ><span>Người tạo</span></th>
                                                                <th><span>Ngày tạo</span></th>
                                                                <th><span>Trạng thái giao</span></th>
                                                                <th><span>Hành động</span></th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>






                                                            {
                                                                this.state.items ?
                                                                    this.state.items.map((value, key) => {
                                                                        return <tr key={key} id={"order-" + value.id} data-toggle="collapse" data-target={"#"+value.id}>

                                                                            <td className="name">
                                                                                <div className="ui-stack ui-stack--wrap">
                                                                                    <div className="ui-stack-item">
                                                                                        <a href={"orders?search=" + value.van_don} target="_blank" >#{value.id}</a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="total">
                                                                                <p>{value.full_name}</p>
                                                                            </td>
                                                                            <td className="vendor">
                                                                                <p><b>{value.phone}</b></p>
                                                                            </td>
                                                                            <td className="type">
                                                                            <p>{value.tao_don}</p>
                                                                        </td>

                                                                            <td className="vendor">
                                                                                <p>{value.time_tao}</p>
                                                                            </td>



                                                                            <td class="total">
                                                                                {
                                                                                    value.giao_hang === 'Đã đối soát' ?
                                                                                        <label class="badge badge--status-success badge--progress-complete">{value.giao_hang}</label>
                                                                                        :
                                                                                        value.giao_hang === 'Đã đối soát trả hàng' ?
                                                                                            <label class="badge badge--status-critical badge--progress-complete">{value.giao_hang}</label>
                                                                                            :
                                                                                            <label class="badge badge--status-warning badge--progress-complete">{value.giao_hang}</label>
                                                                                }

                                                                            </td>


                                                                            <td className="vendor">
                                                                                {
                                                                                    value.telesale == 'Đơn mới' ?

                                                                                        this.state.loading ?
                                                                                            "Loading..."

                                                                                            :
                                                                                            <button onClick={() => this.nhanDon(value.id)} type="button" className="btn btn-default btn-xs">Nhận đơn</button>


                                                                                        :
                                                                                        ""
                                                                                }

                                                                                {
                                                                                    value.telesale == 'Đã nhận' || value.telesale == 'Treo' || value.telesale == 'Thành công' || value.telesale == 'Thất bại' ?
                                                                                    !this.state.loading?
                                                                                    JSON.parse(localStorage.getItem('info')).name === value.cs?
                                                                                        <button onClick={() => this.onOpenModalChuanBi(value.id, value.cs)} type="button" className="btn btn-default btn-xs">Gọi điện</button>
                                                                                        :
                                                                                        value.cs + " nhận: " + value.telesale
                                                                                        :
                                                                                        "Loading..."
                                                                                        :
                                                                                        ""
                                                                                }


                                                                            </td>



                                                                            {/* <div className="card-body collapse" data-toggle="collapse" aria-expanded="false" id={value.id}>
                                                                        <h5 className="card-title">Tiêu đề Card 2</h5>
                                                                        <h6 className="card-subtitle mb-1 text-muted">Ví dụ mẫu sử dụng Card</h6>
                                                                        <p className="card-text">Nội dung văn bản trong <code>.card-body</code> này sử dụng <code>.card-text</code>.</p>
                                                                        <a href="#" className="btn btn-primary">Nút bấm</a>
                                                                    </div> */}


                                                                        </tr>
                                                                        
                                                                    })
                                                                    :
                                                                    ""
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        </section>
                                        :
this.tableRow()
}
                                    


                                </div>
                                
                                

                                
                                
                                
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Chuẩn bị */}
                <Modal open={openChuanBi} onClose={this.onCloseModalChuanBi} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '450px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">MUA HÀNG #{this.state.id}</h2>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">
                            {/* <Select
                                    value={selectedLyDo}
                                    onChange={this.handleChangeLyDo}
                                    options={trangthai}
                                    placeholder='Chọn trạng trái'
                                />
                                <br></br> */}
                                {/* <textarea autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập nội dung ghi chú cho trạng thái trên" name="ly_do" type="text" /> */}

                                <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập mã mua" name="van_don" type="text" />

                                <br></br>




<div style={{overflow: 'scroll', height: '300px', maxHeight: '500px'}}>



                                <table border={1} cellSpacing={0} cellPadding={10}>

                                <thead>
                                                            <tr>
                                                                <th><span>Chọn</span></th>
                                                                <th><span>Thuộc tính</span></th>
                                                                <th><span>Số lượng</span></th>
                                                            </tr>
                                                        </thead>

                                    <tbody>

                                        {
                                            this.state.chitietdon && this.state.chitietdon.nhap_hang_chi_tiet && this.state.chitietdon.nhap_hang_chi_tiet.length > 0 ?
                                                this.state.chitietdon.nhap_hang_chi_tiet.map((value, key) => {
                                                    return   <tr key={key} style={{ fontWeight: 'bold' }}>
                                                    <td>
                                                        
                                                    {/* <input type="checkbox" name="name[]"
                                                                    onClick={() => this.checkOne()}
                                                                    id={value.id}
                                                                    defaultValue={value.id} /> */}

                                                            {value.ma_mua ?
                                                                // <input type="checkbox" 
                                                                // id={value.id}
                                                                // defaultValue={value.id}
                                                                //     checked
                                                                //     disabled/>
                                                                value.ma_mua
                                                                :
                                                                <input type="checkbox" name="name[]"
                                                                    onClick={() => this.checkOne()}
                                                                    id={value.id}
                                                                    defaultValue={value.id} />
                                                            } 
                                                        </td>
                                                    <td>{value.key} / {value.thuoctinh}</td>
                                                    <td>{value.quantity}</td>
                                                </tr>
                                                })
                                                :
                                                ""
                                        }

                                      


                                    </tbody>
                                </table>

                                </div>


                                <br></br>
                                
                                
                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                            <div className="form-group mt-2">
                                <button type="button" onClick={()=>this.checkAll()} className="btn btn-outline-primary mr-2">Chọn tất cả</button>
                                <button type="button" onClick={()=>this.unCheckAll()} className="btn btn-outline-primary mr-2">Bỏ chọn tất cả</button>
                                {
                                    this.state.loading?
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                    :
                                    <button type="submit" onClick={()=>this.saveBuy()}  className="btn btn-primary">Lưu</button>
                                }
                                </div>



                                {/* <div className="ui-modal__primary-actions">
                                    <button onClick={() => this.CapNhatTrangThaiGoi()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Cập nhật</button>
                                </div> */}

                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Modal */}




                 {/* Modal nhập vận đơn */}
                 <Modal open={openNhapVanDon} onClose={this.onCloseModalNhapVanDon} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '450px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">VẬN ĐƠN CHO MÃ MUA #{this.state.ma_mua}</h2>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">

                                <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập vận đơn" name="van_don" type="text" />

                                <br></br>


                                <div style={{overflow: 'scroll', height: '300px', maxHeight: '500px'}}>



                                <table border={1} cellSpacing={0} cellPadding={10}>
                                    <tbody>

                                    <tr style={{ fontWeight: 'bold' }}>
                                                    <td>Vận đơn</td>
                                                    <td>Sản phẩm</td>
                                                </tr>

                                        {
                                            this.state.chitietdon && this.state.chitietdon.nhap_hang_chi_tiet && this.state.chitietdon.nhap_hang_chi_tiet.length > 0 ?
                                                this.state.chitietdon.nhap_hang_chi_tiet.map((value, key) => {
                                                    return   <tr key={key} style={{ fontWeight: 'bold' }}>
                                                    <td>
                                                        
                                                            {value.van_don ?
                                                                value.van_don
                                                                :
                                                                <input type="checkbox" name="name[]"
                                                                    onClick={() => this.checkOne()}
                                                                    id={value.id}
                                                                    defaultValue={value.id} />
                                                            } 
                                                        </td>
                                                    <td>{value.key} / {value.thuoctinh}</td>
                                                </tr>
                                                })
                                                :
                                                ""
                                        }

                                      


                                    </tbody>
                                </table>

</div>


                                <br></br>
                                
                                
                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                            <div className="form-group mt-2">
                                <button type="button" onClick={()=>this.checkAll()} className="btn btn-outline-primary mr-2">Chọn tất cả</button>
                                <button type="button" onClick={()=>this.unCheckAll()} className="btn btn-outline-primary mr-2">Bỏ chọn tất cả</button>
                                {
                                    this.state.loading?
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                    :
<button type="submit" onClick={()=>this.saveVanDon()}  className="btn btn-primary">Lưu</button>
                                }
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Modal nhập vận đơn*/}

                 {/* Modal Chi tiết vận đơn */}
                 <Modal open={openChiTietVanDon} onClose={this.onCloseModalChiTietVanDon} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '750px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">CHI TIẾT VẬN ĐƠN #{this.state.van_don_cu}</h2>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">

                                <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Cập nhập vận mới nếu cần" name="van_don" type="text" />

                                <br></br>



                                <div style={{overflow: 'scroll', height: '300px', maxHeight: '500px'}}>


                                <table border={1} cellSpacing={0} cellPadding={10}>
                                    <tbody>

                                    <tr style={{ fontWeight: 'bold' }}>
                                                    <td>Chọn</td>
                                                    <td>Mã đơn</td>
                                                    <td>Mã mua</td>
                                                    <td>Sản phẩm</td>
                                                    <td>Trọng lượng</td>
                                                </tr>

                                        {
                                            this.state.chitietdon && this.state.chitietdon.nhap_hang_chi_tiet && this.state.chitietdon.nhap_hang_chi_tiet.length > 0 ?
                                                this.state.chitietdon.nhap_hang_chi_tiet.map((value, key) => {
                                                    return   <tr key={key} style={{ fontWeight: 'bold' }}>

                                                        <td>
                                                            <input type="checkbox" name="name[]"
                                                                onClick={() => this.checkOne()}
                                                                id={value.id}
                                                                defaultValue={value.id} />
                                                        </td>

                                                    <td>{value.id_order} 
                                                    </td>

                                                    <td>
                                                        
                                                            {value.ma_mua ?
                                                                value.ma_mua
                                                                :
                                                                ""
                                                            } 
                                                        </td>
                                                    <td>{value.key} / {value.thuoctinh}</td>
                                                    <td>{value.trong_luong}</td>
                                                </tr>
                                                })
                                                :
                                                ""
                                        }

                                      


                                    </tbody>
                                </table>
</div>



                                <br></br>
                                
                                
                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                            <div className="form-group mt-2">
                                <button type="button" onClick={()=>this.checkAll()} className="btn btn-outline-primary mr-2">Chọn tất cả</button>
                                <button type="button" onClick={()=>this.unCheckAll()} className="btn btn-outline-primary mr-2">Bỏ chọn tất cả</button>
                                {
                                    this.state.loading?
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                    :
<button type="submit" onClick={()=>this.saveVanDonMoi()}  className="btn btn-primary">Cập nhật vận đơn</button>
                                }
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Modal nhập vận đơn*/}






                 {/* Modal Nhập kho */}
                 <Modal open={openNhapKho} onClose={this.onCloseModalNhapKho} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '750px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">NHẬP KHO {this.state.van_don_nhap}</h2>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">

                                <input ref={(input) => { this.vanDonInput = input; }} onKeyDown={this._handleKeyDown} onChange={(event) => this.isChange(event)} autoComplete="off" className="next-input" value={this.state.van_don} placeholder="Nhập vận đơn" name="van_don" type="text" />
                                <br></br>
                                <input ref={(input) => { this.trongLuongInput = input; }} onKeyDown={this.nhapKho} onChange={(event) => this.isChange(event)} autoComplete="off" className="next-input" value={this.state.trong_luong} placeholder="Nhập trọng lượng" name="trong_luong" type="text" />
                                <br></br>
                            {
                                this.state.trang_thai_nhap_kho?
                                "Nhập kho thành công vận đơn: "+this.state.van_don_nhap
                                :
                                "Enter để nhập kho."
                            }
                                <br></br>
                                <hr />

                                <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">IN NHÃN SẢN PHẨM</h2>
                                <br></br>
                            </div>


                                <div style={{overflow: 'scroll', height: '200px', maxHeight: '400px'}}>

                                <table border={1} cellSpacing={0} cellPadding={10}>
                                    <tbody>

                                    <tr style={{ fontWeight: 'bold' }}>
                                                    <td>Chọn</td>
                                                    <td>Mã đơn</td>
                                                    <td>Mã mua</td>
                                                    <td>Hình ảnh</td>
                                                    <td>Sản phẩm</td>
                                                    <td>Số lượng</td>
                                                    <td>Trọng lượng</td>
                                                </tr>

                                        {
                                            this.state.data_van_don && this.state.data_van_don.data ?
                                            this.state.data_van_don.data.map((value, key) => {
                                                    return   <tr key={key} style={{ fontWeight: 'bold' }}>

                                                        <td>
                                                            <input type="checkbox" name="name[]"
                                                                onClick={() => this.checkOne()}
                                                                id={value.id}
                                                                defaultValue={value.id} />
                                                        </td>

                                                    <td>{value.id_order} 
                                                    </td>

                                                    <td>
                                                        
                                                            {value.ma_mua ?
                                                                value.ma_mua
                                                                :
                                                                ""
                                                            } 
                                                        </td>
                                                    <td><img src={value.image} height='70px' /></td>
                                                    <td>{value.key} / {value.thuoctinh}</td>
                                                    <td>{value.quantity}</td>
                                                    <td>{value.trong_luong}</td>
                                                </tr>
                                                })
                                                :
                                                ""
                                        }

                                      


                                    </tbody>
                                </table>

</div>


                                <br></br>
                                
                                
                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                            <div className="form-group mt-2">
                                <button type="button" onClick={()=>this.checkAll()} className="btn btn-outline-primary mr-2">Chọn tất cả</button>
                                <button type="button" onClick={()=>this.unCheckAll()} className="btn btn-outline-primary mr-2">Bỏ chọn tất cả</button>
                                {
                                    this.state.loading?
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                    :
<button type="submit" onClick={()=>this.inDaChon()}  className="btn btn-primary">In Đã Chọn</button>
                                }
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Modal nhập vận đơn*/}








                


                {/* Modal ghi chú */}
                <Modal open={openModalGhiChu} onClose={this.onCloseModalGhiChu} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '750px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">GHI CHÚ ĐƠN {this.state.id}</h2>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">

                                <textarea onChange={(event) => this.isChange(event)} autoComplete="off" className="next-input" value={this.state.note} placeholder="Nhập ghi chú" name="note" type="text" />


                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                                <div className="form-group mt-2">
                                    {
                                        this.state.loading ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                            :
                                            <button type="submit" onClick={() => this.capNhatGhiChu()} className="btn btn-primary">Cập nhật</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Modal ghi chú*/}

            </div>

        );
    }
}

export default QuanLyNhapHang;