import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-responsive-modal';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Moment from 'moment'
import * as Config from '../../config.js';
import renderHTML from 'react-render-html';
import Select from 'react-select'
import { convertTime } from '../../functions/function.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
const axios = require('axios');
const qs = require('qs');


class OrderDetail extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            openIdFacebook: false, // Modal
            openVanChuyen: false, // Modal
            openChuanBi: false, // Modal
            openEditNote: false, // Modal
            openEditDiaChi: false, // Modal
            chonFanpage: '',
            infoFacebook: "",
            token_page: "",
            text_chat: "",
            noteShip: "Cho xem hàng, cho thử, không giao được vui lòng liên hệ người gửi.",
            ma_don: Config.MA_PAGE + this.props.match.params.id,
            cod: 0,
            van_don: '',
            note: '',


            selectedTinh: null,
            selectedHuyen: null,
            selectedXa: null,
            tinh: [],
            huyen: [],
            xa: [],
            dia_chi: [],
            tinhOld: {},

            edit_full_name: '',
            edit_address: '',


        };
    }


    // Mở Modal
    onOpenModalIdFacebook = () => {
        this.setState({ openIdFacebook: true });
    };

    onCloseModalIdFacebook = () => {
        this.setState({ openIdFacebook: false });
    };
    // Thêm phí vận chuyển
    onOpenModalVanChuyen = () => {
        this.setState({ openVanChuyen: true });
    };

    onCloseModalVanChuyen = () => {
        this.setState({ openVanChuyen: false });
    };

    // Chuẩn bị
    onOpenModalChuanBi = () => {
        this.setState({ openChuanBi: true });
    };

    onCloseModalChuanBi = () => {
        this.setState({ openChuanBi: false });
    };
    // Mở Modal


      // Hoàn hàng
      onOpenModalHoanHang(key,thuoctinh,quantity,id_order_detail) {
        this.setState({ openHoanHang: true,key_hoan:key,thuoctinh_hoan: thuoctinh,quantity_hoan: quantity, quantity_now: quantity, id_order_detail:id_order_detail  });
    };

    onCloseModalHoanHang = () => {
        this.setState({ openHoanHang: false });
    };
    // Mở Modal

    // openEditNote
    onOpenModalEditNote = () => {
        this.setState({ openEditNote: true });
    };

    onCloseModalEditNote = () => {
        this.setState({ openEditNote: false });
    };
    // Mở Modal openEditNote

    // EditDiaChi
    onOpenModalEditDiaChi = () => {
        this.setState({ openEditDiaChi: true });
    };

    onCloseModalEditDiaChi = () => {
        this.setState({ openEditDiaChi: false });
    };
    // Mở Modal EditDiaChi



    componentWillMount() { 
        // localStorage.setItem('TOKEN_PAGE', JSON.stringify(TOKEN_PAGE));
    }


    componentDidMount() {

        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'order-detail?id=' + this.props.match.params.id + "&token=" + token)
            .then(response => {
                window.scrollTo(0, 0);
                this.setState({
                    data: response.data,
                    loading: false,
                    order_detail: response.data.order_detail,
                    chonFanpage: response.data.order.nguon,
                    t_id: response.data.order.t_id,
                    t_id_num: response.data.order.t_id ? response.data.order.t_id.replace('t_', '') : "",
                    feeShip: response.data.order.feeShip,
                    van_don: response.data.order.van_don,
                    trong_luong: response.data.order.trong_luong,
                    lich_su: response.data.lich_su,

                    edit_full_name: response.data.order.full_name,
                    edit_phone: response.data.order.phone,
                    edit_address: response.data.order.address,
                    edit_email: response.data.order.email,
                    


                });
                var sumTotal = 0;
                if (response.data.order_detail) {
                    response.data.order_detail.forEach(function (record) {
                        sumTotal += parseInt(record.quantity) * parseInt(record.price);
                    });
                    this.setState({
                        sumTotal: sumTotal,
                        cod: parseInt(sumTotal) + parseInt(response.data.order.feeShip)
                    })
                }

              
                  // Get địa chỉ
            if (response.data.order) {

                axios.get(Config.API_URL + 'tinh')
                .then(getinh => {
                    this.setState({
                        tinh: getinh.data,
                    });
                });

                this.setState({
                    selectedTinh: {"value":response.data.order.ma_tinh,"label":response.data.order.tinh}
                })
                axios.get(Config.API_URL + 'huyen?ma_tinh=' + response.data.order.ma_tinh)
                    .then(responsehuyen => {
                        this.setState({
                            huyen: responsehuyen.data,
                        });
                    });


                this.setState({
                    selectedHuyen: {"value":response.data.order.ma_huyen,"label":response.data.order.huyen}
                })
                axios.get(Config.API_URL + 'xa?ma_huyen=' + response.data.order.ma_huyen)
                    .then(responsexa => {
                        this.setState({
                            xa: responsexa.data,
                        });
                    });

                    this.setState({
                        selectedXa: {"value":response.data.order.ma_xa,"label":response.data.order.xa}
                    })
            }


            });



          



    }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

  


    capNhatPhiVanChuyen() {
        let token = localStorage.getItem('token');
        axios.post(Config.API_URL + 'cap-nhat-phi-van-chuyen?token=' + token, qs.stringify({
            'ma_don': this.props.match.params.id,
            'feeShip': this.state.feeShip,
        }))

            .then(response => {
                this.setState({
                    data: response.data,
                    loading: false,
                    order_detail: response.data.order_detail,
                    openVanChuyen: false,
                    loadUpdateIdFb: false,
                    chonFanpage: response.data.order.nguon,
                    t_id: response.data.order.t_id,
                    lich_su: response.data.lich_su,
                });
                var sumTotal = 0;
                if (response.data.order_detail) {
                    response.data.order_detail.forEach(function (record) {
                        sumTotal += parseInt(record.quantity) * parseInt(record.price);
                    });
                    this.setState({ sumTotal: sumTotal })
                }


            })
            .catch(() => {
                this.setState({ loading: false });
                alert('Có lỗi xảy ra')
            });

    }




    handleChange = (selectedTinh) => {
        let token = localStorage.getItem('token');
        this.setState({ selectedTinh });
        axios.get(Config.API_URL + 'huyen?ma_tinh=' + selectedTinh.value)
            .then(response => {
                this.setState({
                    huyen: response.data,
                    selectedHuyen: null,
                    selectedXa: null,
                });
            });
    }

    handleChangeHuyen = (selectedHuyen) => {
        let token = localStorage.getItem('token');
        this.setState({ selectedHuyen });
        axios.get(Config.API_URL + 'xa?ma_huyen=' + selectedHuyen.value)
            .then(response => {
                this.setState({
                    xa: response.data,
                    selectedXa: null,
                });
            });
    }

    handleChangeXa = (selectedXa) => {
        this.setState({ selectedXa });
    }


    editNote(){
        let token = localStorage.getItem('token');
        axios.post(Config.API_URL + 'cap-nhat-ghi-chu?token=' + token, qs.stringify({
            'ma_don': this.props.match.params.id,
            'note': this.state.editNote,
        }))

            .then(response => {
                this.setState({
                    data: response.data,
                    loading: false,
                    order_detail: response.data.order_detail,
                    openEditNote: false,
                    loadUpdateIdFb: false,
                    chonFanpage: response.data.order.nguon,
                    t_id: response.data.order.t_id,
                    lich_su: response.data.lich_su,
                });
            })
            .catch(() => {
                this.setState({ loading: false });
                alert('Có lỗi xảy ra')
            });
    }


    EditDiaChi(){
        let token = localStorage.getItem('token');

        if(this.state.edit_full_name && this.state.edit_phone && this.state.edit_address && this.state.selectedTinh && this.state.selectedHuyen && this.state.selectedXa){
            axios.post(Config.API_URL + 'cap-nhat-dia-chi?token=' + token, qs.stringify({
                'ma_don': this.props.match.params.id,
                'edit_full_name': this.state.edit_full_name,
                'edit_phone': this.state.edit_phone,
                'edit_email': this.state.edit_email,
                'edit_address': this.state.edit_address,
                'selectedTinh': this.state.selectedTinh,
                'selectedHuyen': this.state.selectedHuyen,
                'selectedXa': this.state.selectedXa,
            }))
                .then(response => {
                    this.setState({
                        data: response.data,
                        loading: false,
                        order_detail: response.data.order_detail,
                        openEditDiaChi: false,
                        loadUpdateIdFb: false,
                        chonFanpage: response.data.order.nguon,
                        t_id: response.data.order.t_id,
                        lich_su: response.data.lich_su,
                    });
                })
                .catch(() => {
                    this.setState({ loading: false });
                    alert('Có lỗi xảy ra')
                });
        }else{
            alert('Chưa điền đủ thông tin')
        }
        
    }

    guiHoanHang(){
        if( this.state.quantity_hoan> this.state.quantity_now){
            alert("Vượt quá số lượng hoàn cho phép")
        }else if(this.state.key_hoan && this.state.quantity_hoan && this.state.van_don && this.props.match.params.id){
            this.setState({
                loading: true,
                openHoanHang: false,
            });
            let token = localStorage.getItem('token');
            axios.post(Config.API_URL + 'cap-nhat-hoan?token=' + token, qs.stringify({
                'ma_don': this.props.match.params.id,
                'van_don': this.state.van_don,
                'key_hoan': this.state.key_hoan,
                'quantity_hoan': this.state.quantity_hoan,
                'id_order_detail': this.state.id_order_detail,
                
            }))
                .then(response => {
                    this.setState({
                        openHoanHang: false,
                        data: response.data,
                        loading: false,
                        order_detail: response.data.order_detail,
                        chonFanpage: response.data.order.nguon,
                        t_id: response.data.order.t_id,
                        t_id_num: response.data.order.t_id ? response.data.order.t_id.replace('t_', '') : "",
                        feeShip: response.data.order.feeShip,
                        van_don: response.data.order.van_don,
                        trong_luong: response.data.order.trong_luong,
                        lich_su: response.data.lich_su,

                        edit_full_name: response.data.order.full_name,
                        edit_phone: response.data.order.phone,
                        edit_address: response.data.order.address,
                        edit_email: response.data.order.email,



                    });
                    var sumTotal = 0;
                    if (response.data.order_detail) {
                        response.data.order_detail.forEach(function (record) {
                            sumTotal += parseInt(record.quantity) * parseInt(record.price);
                        });
                        this.setState({
                            sumTotal: sumTotal,
                            cod: parseInt(sumTotal) + parseInt(response.data.order.feeShip)
                        })
                    }

                    if (localStorage.getItem('TOKEN_PAGE') && response.data) {

                        var nguon = response.data.order.nguon;
                        var token_page = '';
                        Object.keys(JSON.parse(localStorage.getItem('TOKEN_PAGE'))).forEach(function (k) {
                            if (nguon === k) {
                                token_page = JSON.parse(localStorage.getItem('TOKEN_PAGE'))[k];
                            }
                        });
                        this.setState({ token_page: token_page })

                    }

                    

                    // Get địa chỉ
                    if (response.data.order) {

                        axios.get(Config.API_URL + 'tinh')
                            .then(getinh => {
                                this.setState({
                                    tinh: getinh.data,
                                });
                            });

                        this.setState({
                            selectedTinh: { "value": response.data.order.ma_tinh, "label": response.data.order.tinh }
                        })
                        axios.get(Config.API_URL + 'huyen?ma_tinh=' + response.data.order.ma_tinh)
                            .then(responsehuyen => {
                                this.setState({
                                    huyen: responsehuyen.data,
                                });
                            });


                        this.setState({
                            selectedHuyen: { "value": response.data.order.ma_huyen, "label": response.data.order.huyen }
                        })
                        axios.get(Config.API_URL + 'xa?ma_huyen=' + response.data.order.ma_huyen)
                            .then(responsexa => {
                                this.setState({
                                    xa: responsexa.data,
                                });
                            });

                        this.setState({
                            selectedXa: { "value": response.data.order.ma_xa, "label": response.data.order.xa }
                        })
                    }
                })
                .catch(() => {
                    this.setState({ loading: false });
                    alert('Có lỗi xảy ra')
                });
        }else{
            alert('Không đủ thông tin sản phẩm hoàn')
        }
    }


    render() {



        // Modal
       
        const { openVanChuyen } = this.state;
        const { openChuanBi } = this.state;
        const { openHoanHang } = this.state;
        const { openEditNote } = this.state;
        const { openEditDiaChi } = this.state;
        // Modal


        // Sửa địa chỉ
        const tinh = this.state.tinh;
        const huyen = this.state.huyen;
        const xa = this.state.xa;
        const { selectedTinh } = this.state;
        const { selectedHuyen } = this.state;
        const { selectedXa } = this.state;
        // Sửa địa chỉ

        return (
            <div>
                <NotificationContainer />

                <div id="content">

                   
                    <div className="orderContext" id="orders-show">
                        <div className="js-tags-form" id="edit_order_5773149" >
                            <header className="ui-title-bar-container">
                                <div className="ui-title-bar">
                                    <div className="ui-title-bar__navigation">
                                        <div className="ui-breadcrumbs">
                                            <Link to="/orders" className="ui-button ui-button--transparent ui-breadcrumb">
                                                <svg className="next-icon next-icon--size-20 next-icon--no-nudge"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" /> </svg>
                                                <span className="ui-breadcrumb__item">Đơn hàng</span>
                                            </Link>
                                        </div>
                                    
                                    </div>
                                    <div className="ui-title-bar__main-group">
                                        <div className="ui-title-bar__heading-group">
                                            <span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-orders" /> </svg></span>
                                            <h1 className="ui-title-bar__title">#{this.props.match.params.id}</h1>
                                            <span className="ui-title-bar__metadata">{this.state.data ? this.state.data.order.created_at : ""}</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </header>
                            <div className="ui-layout">
                                <div className="ui-layout__sections">
                                    <div className="ui-layout__section ui-layout__section--primary">
                                        <div className="ui-layout__item">


                                            <section className="ui-card" id="order_card">
                                                <header className="ui-card__header">
                                                    <div className="ui-stack ui-stack--wrap">
                                                        <div className="ui-stack-item ui-stack-item--fill">
                                                            <h2 className="ui-heading">
                                                                <div className="ui-stack ui-stack--wrap ui-stack--alignment-center">
                                                                    <h2 className="ui-heading">Trạng thái</h2>
                                                                    {
                                                                        this.state.data ?
                                                                            <span className="badge badge--status-attention badge--progress-incomplete"><span className="badge__pip" />{this.state.data ? this.state.data.order.giao_hang : ""}</span>
                                                                            :
                                                                            ""
                                                                    }
                                                                </div>
                                                            </h2>
                                                        </div>
                                                   
                                                    </div>
                                                </header>
                                                <div id="order-line-items">
                                                    <div className="next-card__section hide-when-printing">
                                                        <h3 className="ui-subheading">{this.state.data ? this.state.data.order.trang_thai : ""}</h3>
                                                    </div>

                                                    <div className="next-card__section next-card__section--no-vertical-spacing">
                                                        <table className="table--no-side-padding table--divided">
                                                            <tbody>


                                                                {
                                                                    this.state.order_detail ?
                                                                        this.state.order_detail.map((value, key) => {
                                                                            if(this.state.data.ton_kho){
                                                                                var tonkho = this.state.data.ton_kho.filter(function (returnableObjects) {
                                                                                    if(returnableObjects){
                                                                                        return returnableObjects.modelid === value.key;
                                                                                    }else{
                                                                                        return null;
                                                                                    }
                                                                                    
                                                                                })
                                                                            }
                                                                           
                                                                            return <tr className="orders-line-item">
                                                                                <td className="orders-line-item__image hide-when-printing">
                                                                                    <div className="aspect-ratio aspect-ratio--square aspect-ratio--square--50">
                                                                                        <img title={value.name} className="aspect-ratio__content" src={value.image} />
                                                                                    </div>
                                                                                </td>
                                                                                <td className="orders-line-item__description">
                                                                                    <a href={Config.HOME_URL + value.url} target="_blank" rel="noreferrer noopener">{value.name}</a>
                                                                                    <p className="type--subdued">{value.color?value.color+" / ":""}{value.size?value.size:""}{value.thuoctinh?value.thuoctinh:""}</p>
                                                                                    <p className="type--subdued">SKU : {value.key}</p>
                                                                                    <p className="type--subdued">TỒN KHO HIỆN TẠI : { tonkho && tonkho.length > 0?tonkho[0].stock:"Đã bị xóa"}</p>
                                                                                </td>
                                                                                <td className="orders-line-item__price"> 
                                                                                <p className="type--subdued">{parseInt(value.price).toLocaleString() + "₫"}</p>
                                                                                <p className="type--subdued">
                                                                                {
                                                                                    value.hoan_hang?
                                                                                    <span style={{ 'color': 'red' }}>(Hoàn: {value.hoan_hang})</span>
                                                                                    :
                                                                                    ""
                                                                                    } 
                                                                                </p>
                                                                                
                                                                                 </td>
                                                                                <td className="orders-line-item__times-sign"> × </td>
                                                                                <td className="orders-line-item__quantity"> 
                                                                                {parseInt(value.quantity).toLocaleString()} 
                                                                                </td>
                                                                                <td className="orders-line-item__total"> {(parseInt(value.price) * parseInt(value.quantity)).toLocaleString() + "₫"}  </td>
                                                                                <td>
                                                                                    
                                                                                    {

                                                                                        tonkho && tonkho.length > 0 && this.state.van_don && value.hoan_hang < value.quantity  ?
                                                                                        this.state.loading?
                                                                                        <a href="javascript: void(0);" className="ui-button btn-save has-loading btn-paid btn-save-draft-order" ><i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} /></a>
                                                                                        :
                                                                                            <button onClick={()=>this.onOpenModalHoanHang(value.key,value.thuoctinh,value.quantity,value.id)} className="badge badge--status-attention badge--progress-incomplete">Hoàn hàng</button>
                                                                                            :
                                                                                            ""
                                                                                    }
                                                                                    
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                        :
                                                                        ""
                                                                }



                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="ui-card__section">
                                                    <div className="ui-stack ui-stack--wrap ui-stack--distribution-fill">
                                                        <div id="transaction_summary">
                                                            <table className="table--nested table--no-border type--right">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="type--subdued">Giá</td>
                                                                        <td>{this.state.sumTotal ? this.state.sumTotal.toLocaleString() + "₫" : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="type--subdued">
                                                                            <div>
                                                                                <a href="javascript:void(0)" className="btn--link" onClick={this.onOpenModalVanChuyen}>
                                                                                    <span> Phí vận chuyển </span>
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                {this.state.data ? parseInt(this.state.data.order.feeShip).toLocaleString() + "₫" : ""}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>Tổng cộng</strong></td>
                                                                        <td><strong>{this.state.data ? (parseInt(this.state.data.order.feeShip) + parseInt(this.state.sumTotal)).toLocaleString() + "₫" : ""}</strong></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                
                                            </section>



                                            <div id="timeline-desktop-container" className="timeline">

                                                <div className="timeline__pane">
                                                    <header className="timeline__header hide-when-printing">
                                                        <div className="next-grid next-grid--no-outside-padding next-grid--space-between">
                                                            <div className="next-grid__cell">
                                                                <h3 className="next-heading next-heading--no-margin">Hoạt động</h3>
                                                            </div>
                                                        </div>
                                                    </header>


                                                    {
                                                        this.state.lich_su ?
                                                            this.state.lich_su.map((value, key) => {
                                                                return <div id="entire_timeline" className="timeline__entire">
                                                                    <div className="ui-feed">
                                                                        <div id="timeline_container" className="ui-feed__section">
                                                                            <div id="event_72061231" className="ui-feed__timeline ui-accordion__toggler timeline-content">
                                                                                <div className="ui-feed__item ui-feed__item--message ">
                                                                                    <span className="ui-feed__marker" />
                                                                                    <span className="ui-feed__message">
                                                                                        <div className="timeline__message-container">
                                                                                            <span className="timeline__inner-message">
                                                                                                <span>{value.noi_dung}</span>
                                                                                            </span>
                                                                                            <time id="timeline__time-72061231" className="timeline__time">
                                                                                                <span className="timeline__time-link">{value.created_at}</span>
                                                                                            </time>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="show-timeline-pagination-buttons">
                                                                    </div>
                                                                </div>


                                                            })
                                                            :
                                                            ""
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui-layout__section ui-layout__section--secondary" id="order-sidebar">
                                        <div className="ui-layout__item">
                                            <section className="ui-card ">
                                                <div className="ui-card__section hide-when-printing" id="order-notes" data-tg-refresh="order-notes">
                                                    <div className="ui-stack ui-stack--wrap ui-stack--vertical">
                                                        <div className="ui-stack-item">
                                                            <div className="ui-stack ui-stack--wrap ui-stack--alignment-baseline">
                                                                <div className="ui-stack-item ui-stack-item--fill">
                                                                    <h2 className="ui-heading">Ghi chú</h2>
                                                                </div>
                                                                <div className="ui-stack-item">
                                                                    <button className="ui-button btn--link hide-when-printing" type="button" onClick={this.onOpenModalEditNote} name="editNote">Sửa</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ui-stack-item">
                                                            <p className="order-notes__default-text type--subdued">{this.state.data && this.state.data.order.note ? this.state.data.order.note : "Không có ghi chú"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                        <div className="ui-layout__item">
                                            <section className="ui-card" id="customer-card" data-tg-refresh="customer-card">
                                               
                                               
                                                <div className="ui-card__section">
                                                    <div className="ui-type-container">
                                                        <div className="hide">
                                                        </div>
                                                        <div className="ui-stack ui-stack--wrap ui-stack--alignment-baseline">
                                                            <div className="ui-stack-item ui-stack-item--fill">
                                                                <h3 className="ui-subheading">Địa chỉ giao hàng</h3>
                                                            </div>
                                                            <div className="ui-stack-item">
                                                                <button className="ui-button btn--link" type="button" onClick={this.onOpenModalEditDiaChi}>Sửa</button>
                                                            </div>
                                                        </div>
                                                        <p className="type--subdued word_break__content">
                                                            {
                                                                this.state.data ?
                                                                    this.state.data.order.full_name
                                                                    :
                                                                    ""
                                                            }
                                                            <br />
                                                            {
                                                                this.state.data ?
                                                                    this.state.data.order.phone
                                                                    :
                                                                    ""
                                                            }
                                                            <br />
                                                            {
                                                                this.state.data ?
                                                                    this.state.data.order.address
                                                                    :
                                                                    ""
                                                            }
                                                            <br />
                                                            {
                                                                this.state.data ?
                                                                    this.state.data.order.xa
                                                                    :
                                                                    ""
                                                            }
                                                            <br />
                                                            {
                                                                this.state.data ?
                                                                    this.state.data.order.huyen
                                                                    :
                                                                    ""
                                                            }
                                                            <br />
                                                            {
                                                                this.state.data ?
                                                                    this.state.data.order.tinh
                                                                    :
                                                                    ""
                                                            }



                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="ui-card__section">
                                                    <div className="ui-type-container ui-type-container--spacing-tight">
                                                        <div>
                                                            <p><a href="/admin/customers/9544225">{
                                                                this.state.data ?
                                                                    this.state.data.order.full_name
                                                                    :
                                                                    ""
                                                            }</a></p>
                                                            <p><a href="/admin/orders?customerId=9544225">{this.state.data?this.state.data.countorder+" đơn hàng":""}</a></p>
                                                        </div>
                                                    </div>
                                                </div>

                                               

                                                {
                                                    this.state.van_don ?
                                                        <div className="ui-card__section">
                                                            <div className="ui-type-container">

                                                                <p className="type--subdued">Vận đơn: <span className="bold">{this.state.van_don}</span></p>
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                }
                                                {
                                                    this.state.trong_luong ?
                                                        <p className="type--subdued">Khối lượng: <span className="bold">{this.state.trong_luong}kg</span></p>
                                                        :
                                                        ""
                                                }

                                                 
                                            </section>
                                        </div>





                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>




                    {/* Modal */}
                    <Modal open={openVanChuyen} onClose={this.onCloseModalVanChuyen} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content">
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Phí vận chuyển</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">
                                    <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập phí vận chuyển" name="feeShip" type="text" defaultValue={this.state.data ? parseInt(this.state.data.order.feeShip) : ""} style={{ width: 300 }} />
                                </div>
                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">
                                    <div className="ui-modal__secondary-actions">
                                        <div className="button-group">
                                            <button onClick={this.onCloseModalVanChuyen} className="ui-button close-modal" type="button">Đóng</button>
                                        </div>
                                    </div>
                                    <div className="ui-modal__primary-actions">
                                        <div className="button-group button-group--right-aligned">
                                            {
                                                this.state.loadUpdateIdFb ?
                                                    <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                                                        <span className="next-spinner" bind-show="isCalculating">
                                                            <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                                                        </span>

                                                    </button>
                                                    :
                                                    <button onClick={() => this.capNhatPhiVanChuyen()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}


                    {/* Modal Chuẩn bị */}
                    <Modal open={openChuanBi} onClose={this.onCloseModalChuanBi} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content" style={{ 'width': '450px' }}>
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Chuẩn bị hàng</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">
                                    <label class="next-label">Mã đơn</label>
                                    <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập mã đơn" name="ma_don" type="text" defaultValue={this.state.ma_don} />

                                    <label class="next-label">Mã vận đơn</label>
                                    <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập vận đơn hoặc bỏ trống để tạo mới" name="van_don" type="text" defaultValue={this.state.van_don} />

                                    <label class="next-label">Tiền thu hộ(COD)</label>
                                    <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số tiền thu hộ" name="cod" type="text" defaultValue={this.state.data ? (parseInt(this.state.data.order.feeShip) + parseInt(this.state.sumTotal)) : ""} />

                                    <label className="next-label">Ghi chú</label>
                                    <textarea onChange={(event) => this.isChange(event)} name="noteShip" className="next-input" rows={3} defaultValue={this.state.noteShip} />
                                </div>
                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">
                                    <div className="ui-modal__secondary-actions">
                                        <div className="button-group">
                                            <button onClick={this.onCloseModalChuanBi} className="ui-button close-modal" type="button">Đóng</button>
                                        </div>
                                    </div>
                                    <div className="ui-modal__primary-actions">
                                        <div className="button-group button-group--right-aligned">
                                            {
                                                this.state.loadUpdateIdFb ?
                                                    <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                                                        <span className="next-spinner" bind-show="isCalculating">
                                                            <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                                                        </span>

                                                    </button>
                                                    :
                                                    <button onClick={() => this.guiChuanBi()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}

                    {/* Modal onOpenModalEditNote */}
                    <Modal open={openEditNote} onClose={this.onCloseModalEditNote} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content" style={{ 'width': '450px' }}>
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Cập nhập nội dung ghi chú</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">

                                    <label className="next-label">Ghi chú</label>
                                    <textarea onChange={(event) => this.isChange(event)} name="editNote" className="next-input" rows={3} defaultValue={this.state.data && this.state.data.order.note ? this.state.data.order.note : ""} />
                                </div>
                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">
                                    <div className="ui-modal__secondary-actions">
                                        <div className="button-group">
                                            <button onClick={this.onCloseModalEditNote} className="ui-button close-modal" type="button">Đóng</button>
                                        </div>
                                    </div>
                                    <div className="ui-modal__primary-actions">
                                        <div className="button-group button-group--right-aligned">
                                            {
                                                this.state.loadUpdateIdFb ?
                                                    <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                                                        <span className="next-spinner" bind-show="isCalculating">
                                                            <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                                                        </span>

                                                    </button>
                                                    :
                                                    <button onClick={() => this.editNote()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}

                     {/* Modal DiaChi */}
                     <Modal open={openEditDiaChi} onClose={this.onCloseModalEditDiaChi} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content" style={{ 'width': '450px' }}>
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Cập nhập địa chỉ</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">
                                    <div>
                                        <section className="next-card__section next-card__section--no-padding">
                                           
                                            <div className="next-grid next-grid--no-outside-padding">
                                                <div className="next-grid__cell">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_LastName">Họ và Tên</label>
                                                        <input onChange={(event) => this.isChange(event)} className="next-input" name="edit_full_name" placeholder="Nhập Họ và Tên" type="text" defaultValue={this.state.edit_full_name ? this.state.edit_full_name:""} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="next-grid next-grid--no-outside-padding">
                                                <div className="next-grid__cell">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_Company">Email</label>
                                                        <input onChange={(event) => this.isChange(event)} className="next-input" name="edit_email" placeholder="Nhập Email" type="email" defaultValue={this.state.edit_email ? this.state.edit_email:""} />
                                                        <div>
                                                            <span className="help-block"><span className="field-validation-valid" data-valmsg-for="Address.Company" data-valmsg-replace="true" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="next-grid__cell">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_Phone">Điện thoại</label>
                                                        <input onChange={(event) => this.isChange(event)} bind="address_phone" className="next-input" name="edit_phone" placeholder="Nhập số điện thoại" type="text" defaultValue={this.state.edit_phone ? this.state.edit_phone:""} />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="next-grid next-grid--no-outside-padding">
                                                <div className="next-grid__cell">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_City">Tỉnh / Thành phố</label>
                                                        <Select
                                                            value={selectedTinh}
                                                            onChange={this.handleChange}
                                                            options={tinh}
                                                            placeholder='Chọn Tỉnh/Thành phố'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="next-grid__cell" bind-show="have_city">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_DistrictId">Quận/Huyện</label>
                                                        <Select
                                                            value={selectedHuyen}
                                                            onChange={this.handleChangeHuyen}
                                                            options={huyen}
                                                            placeholder='Chọn Huyện/Quận'
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="next-grid next-grid--no-outside-padding">
                                                <div className="next-grid__cell">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_Address1">Xã/Phường</label>
                                                        <Select
                                                            value={selectedXa}
                                                            onChange={this.handleChangeXa}
                                                            options={xa}
                                                            placeholder='Chọn Xã/Phường'
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="next-grid next-grid--no-outside-padding">
                                                <div className="next-grid__cell">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_Address1">Địa chỉ</label>
                                                        <input onChange={(event) => this.isChange(event)} className="next-input" data-val="true" data-val-length="Địa chỉ tối đa 255 ký tự" data-val-length-max={255} id="Address_Address1" name="edit_address" placeholder="Nhập Địa chỉ" type="text" defaultValue={this.state.data ? this.state.data.order.address:""} />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>

                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">
                                    <div className="ui-modal__secondary-actions">
                                        <div className="button-group">
                                            <button onClick={this.onCloseModalEditDiaChi} className="ui-button close-modal" type="button">Đóng</button>
                                        </div>
                                    </div>
                                    <div className="ui-modal__primary-actions">
                                        <div className="button-group button-group--right-aligned">
                                            {
                                                this.state.loadUpdateIdFb ?
                                                    <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                                                        <span className="next-spinner" bind-show="isCalculating">
                                                            <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                                                        </span>

                                                    </button>
                                                    :
                                                    <button onClick={() => this.EditDiaChi()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}




                      {/* Modal Hoàn hàng */}
                      <Modal open={openHoanHang} onClose={this.onCloseModalHoanHang} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content" style={{ 'width': '450px' }}>
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Hoàn hàng</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">
                                    <label class="next-label">Mã sản phẩm: {this.state.key_hoan} / {this.state.thuoctinh_hoan}</label>
                                    <label class="next-label">Số lượng</label>
                                    <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số lượng hoàn" name="quantity_hoan" type="text" defaultValue={this.state.quantity_hoan ? parseInt(this.state.quantity_hoan) : ""} />
                                </div>
                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">
                                    <div className="ui-modal__secondary-actions">
                                        <div className="button-group">
                                            <button onClick={this.onCloseModalHoanHang} className="ui-button close-modal" type="button">Đóng</button>
                                        </div>
                                    </div>
                                    <div className="ui-modal__primary-actions">
                                        <div className="button-group button-group--right-aligned">
                                            {
                                                this.state.loadUpdateIdFb ?
                                                    <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                                                        <span className="next-spinner" bind-show="isCalculating">
                                                            <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                                                        </span>

                                                    </button>
                                                    :
                                                    <button onClick={() => this.guiHoanHang()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}


                </div>
            </div>

        );
    }
}

export default OrderDetail;