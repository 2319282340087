import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import NumericInput from 'react-numeric-input';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Moment from 'moment'
import * as Config from '../../config.js';
import Modal from 'react-responsive-modal';
import Select from 'react-select'
import { delay } from 'q';

const axios = require('axios');
const qs = require('qs');
class NhapHang extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countCart: 0,
            countTotal: 0,
            countGiaNhap: 0,
            selectedTinh: null,
            selectedHuyen: null,
            selectedXa: null,
            tinh: [],
            huyen: [],
            xa: [],
            dia_chi: [],
            tinhOld: {},
            coupon_code: '',
            sumTotal: 0,
            discount: 0,
            success: false,
            openEditDiaChi: false, // Modal
            full_name:'',
            phone:'',
            address:"",
            feeShip: 0,
            loading: false,
        };
    }
    componentWillMount() {
        window.scrollTo(0, 0);
    }

    handleChange = (selectedTinh) => {
        let token = localStorage.getItem('token');
        this.setState({ selectedTinh });
        axios.get(Config.API_URL + 'huyen?ma_tinh=' + selectedTinh.value)
            .then(response => {
                this.setState({
                    huyen: response.data,
                    selectedHuyen: null,
                    selectedXa: null,
                });
            });
    }

    handleChangeHuyen = (selectedHuyen) => {
        let token = localStorage.getItem('token');
        this.setState({ selectedHuyen });
        axios.get(Config.API_URL + 'xa?ma_huyen=' + selectedHuyen.value)
            .then(response => {
                this.setState({
                    xa: response.data,
                    selectedXa: null,
                });
            });
    }

    handleChangeXa = (selectedXa) => {
        this.setState({ selectedXa });
    }


    componentDidMount() {

        var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
        var countCart = 0;
        var countTotal = 0;
        var countGiaNhap = 0;
        if (CART_LOCAL) {
            CART_LOCAL.forEach(function (record) {
                countCart += parseInt(JSON.parse(record).quantity);
                countTotal += JSON.parse(record).quantity * JSON.parse(record).price;
                countGiaNhap += JSON.parse(record).quantity * JSON.parse(record).gia_nhap;
            });
        }
        this.setState({
            countCart,
            countTotal,
            countGiaNhap,
            CART_LOCAL
        })


        var ma_don = '';
        if (this.state.ma_don) {
            var ma_don = this.state.ma_don;
        }
        var ma_giao_dich = '';
        if (this.state.ma_giao_dich) {
            var ma_giao_dich = this.state.ma_giao_dich;
        }
        var date_from = '';
        if (this.state.date_from) {
            var date_from = Moment(this.state.date_from).format('DD-MM-YYYY');
        }
        var date_to = '';
        if (this.state.date_to) {
            var date_to = Moment(this.state.date_to).format('DD-MM-YYYY');
        }
        var trangThai = '';
        if (this.state.trangThai) {
            var trangThai = this.state.trangThai;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'products-admin?search=' + ma_don + '&limit=20&ma_giao_dich=' + ma_giao_dich + '&date_from=' + date_from + '&date_to=' + date_to + '&trangThai=' + trangThai + '&page=' + this.state.page + "&token=" + token)
            .then(response => {
                this.setState({
                    items: response.data.data,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    loading: false,
                    path: response.data.path,
                });
            });
        axios.get(Config.API_URL + 'ncc?search_info=' + ma_don + '&limit=20&ma_giao_dich=' + ma_giao_dich + '&date_from=' + date_from + '&date_to=' + date_to + '&trangThai=' + trangThai + '&page=' + this.state.page + "&token=" + token)
            .then(response => {
                this.setState({
                    info: response.data.data,
                });
            });



        var ADDRESS_LOCAL = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
        this.setState({
            ADDRESS_LOCAL,
        })

        if (ADDRESS_LOCAL && ADDRESS_LOCAL.full_name) {
            this.setState({
                full_name : ADDRESS_LOCAL.full_name,
            })
        }
        if (ADDRESS_LOCAL && ADDRESS_LOCAL.address) {
            this.setState({
                address : ADDRESS_LOCAL.address,
            })
        }
        

            if(ADDRESS_LOCAL && ADDRESS_LOCAL.full_name){
                axios.get(Config.API_URL + 'ncc-check?full_name=' + ADDRESS_LOCAL.full_name)
                .then(response => {
                    this.setState({
                        countOrder: response.data.countorder,
                    });
                });
            }

            


    }

    removeItem(key) {
        var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
        var CART_LOCAL_NEW = CART_LOCAL.filter(function (returnableObjects) {
            return JSON.parse(returnableObjects).key !== key;
        });
        localStorage.setItem('CART_ITEM_NHAP_HANG', JSON.stringify(CART_LOCAL_NEW));
        // Get lại CART để tính phí ship
        var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
        var countCart = 0;
        var countTotal = 0;
        var countGiaNhap = 0;
        if (CART_LOCAL) {
            CART_LOCAL.forEach(function (record) {
                countCart += parseInt(JSON.parse(record).quantity);
                countTotal += parseInt(JSON.parse(record).quantity) * parseInt(JSON.parse(record).price);
                countGiaNhap += parseInt(JSON.parse(record).quantity) * parseInt(JSON.parse(record).gia_nhap);
            });
            
        }
        this.setState({
            countCart,
            countTotal,
            countGiaNhap,
            CART_LOCAL
        })
    }

    changeQuantity(value, name) {
        this.setState({
            CART_LOCAL: []
        })
        var CART_ITEM_NHAP_HANG = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
        if (value < 1) { // Xóa khi số lượng < 1
            // NotificationManager.error('Số lượng phải lớn hơn hoặc bằng 1', '', 2000, false);
            // var CART_ITEM_NHAP_HANG = CART_ITEM_NHAP_HANG.filter(function (returnableObjects) {
            //     return JSON.parse(returnableObjects).key !== key;
            // });
        } else {
            CART_ITEM_NHAP_HANG.forEach(function (element, key) {
                if (JSON.parse(JSON.stringify(JSON.parse(element))).key === name) {

                    var item = {};
                    item.key = JSON.parse(JSON.stringify(JSON.parse(element))).key;
                    item.id = JSON.parse(JSON.stringify(JSON.parse(element))).id;
                    item.name = JSON.parse(JSON.stringify(JSON.parse(element))).name;
                    item.image = JSON.parse(JSON.stringify(JSON.parse(element))).image;
                    item.thuoctinh = JSON.parse(JSON.stringify(JSON.parse(element))).thuoctinh;
                    // item.colorKey = JSON.parse(JSON.stringify(JSON.parse(element))).colorKey;
                    // item.sizeKey = JSON.parse(JSON.stringify(JSON.parse(element))).sizeKey;
                    item.price = JSON.parse(JSON.stringify(JSON.parse(element))).price;
                    item.gia_nhap = JSON.parse(JSON.stringify(JSON.parse(element))).gia_nhap;
                    item.quantity = value;
                    item.url = JSON.parse(JSON.stringify(JSON.parse(element))).url;
                    CART_ITEM_NHAP_HANG[key] = JSON.stringify(item)

                }
            })
        }
        localStorage.setItem('CART_ITEM_NHAP_HANG', JSON.stringify(CART_ITEM_NHAP_HANG));
        // Get lại CART để tính phí ship
        var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
        var countCart = 0;
        var countTotal = 0;
        var countGiaNhap = 0;
        if (CART_LOCAL) {
            CART_LOCAL.forEach(function (record) {
                countCart += parseInt(JSON.parse(record).quantity);
                countTotal += parseInt(JSON.parse(record).quantity) * parseInt(JSON.parse(record).price);
                countGiaNhap += parseInt(JSON.parse(record).quantity) * parseInt(JSON.parse(record).gia_nhap);
            });
            
        }
        this.setState({
            countCart,
            countTotal,
            countGiaNhap,
            CART_LOCAL
        })
    }

    addCart(key, id, name, image, thuoctinh, price, quantity, url, gia_nhap) {
        var item = {};
        item.key = key;
        item.id = id;
        item.name = name;
        item.image = image;
        item.thuoctinh = thuoctinh;
        item.price = price;
        item.gia_nhap = gia_nhap;
        if(quantity<1){
            var quantity = 1
        }
        item.quantity = parseInt(quantity);
        item.url = url;
        // this.props.addCart((item)); // CartReducer



        if (localStorage.getItem('CART_ITEM_NHAP_HANG')) {
            var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
        } else {
            var CART_LOCAL = [];
        }
        if (CART_LOCAL.length > 0) {
            var checkTrung = false;
            JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG')).map((value, key) => {
                if (JSON.parse(JSON.stringify(JSON.parse(value))).key === item.key) {
                    checkTrung = true;
                    var itemnew = {};
                    itemnew.key = JSON.parse(JSON.stringify(JSON.parse(value))).key;
                    itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                    itemnew.name = JSON.parse(JSON.stringify(JSON.parse(value))).name;
                    itemnew.image = JSON.parse(JSON.stringify(JSON.parse(value))).image;
                    itemnew.thuoctinh = JSON.parse(JSON.stringify(JSON.parse(value))).thuoctinh;
                    // itemnew.size = JSON.parse(JSON.stringify(JSON.parse(value))).size;
                    // itemnew.colorKey = JSON.parse(JSON.stringify(JSON.parse(value))).colorKey;
                    // itemnew.sizeKey = JSON.parse(JSON.stringify(JSON.parse(value))).sizeKey;
                    itemnew.price = JSON.parse(JSON.stringify(JSON.parse(value))).price;
                    itemnew.gia_nhap = JSON.parse(JSON.stringify(JSON.parse(value))).gia_nhap;
                    itemnew.quantity = parseInt(JSON.parse(JSON.stringify(JSON.parse(value))).quantity) + item.quantity;
                    itemnew.url = JSON.parse(JSON.stringify(JSON.parse(value))).url;
                    CART_LOCAL[key] = JSON.stringify(itemnew);
                }
            })
            if (checkTrung === false) {
                CART_LOCAL.push(JSON.stringify(item));
            }

        } else {
            CART_LOCAL.push(JSON.stringify(item));
        }
        localStorage.setItem('CART_ITEM_NHAP_HANG', JSON.stringify(CART_LOCAL));
        
        // Get lại CART để tính phí ship
        var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
        var countCart = 0;
        var countTotal = 0;
        var countGiaNhap = 0;
        if (CART_LOCAL) {
            CART_LOCAL.forEach(function (record) {
                countCart += parseInt(JSON.parse(record).quantity);
                countTotal += parseInt(JSON.parse(record).quantity) * parseInt(JSON.parse(record).price);
                countGiaNhap += parseInt(JSON.parse(record).quantity) * parseInt(JSON.parse(record).gia_nhap);
            });
            
        }
       
        this.setState({
            countCart,
            countTotal,
            countGiaNhap,
            CART_LOCAL
        })

        


    }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.searchProduct !== prevState.searchProduct) {
            let token = localStorage.getItem('token');
            axios.get(Config.API_URL + 'products-admin?search=' + this.state.searchProduct + "&limit=20&token=" + token)
                .then(response => {
                    this.setState({
                        items: response.data.data,
                        activePage: response.data.current_page,
                        itemsCountPerPage: response.data.per_page,
                        totalItemsCount: response.data.total,
                        loading: false,
                        path: response.data.path,
                    });
                });
        }

        if (this.state.search_info !== prevState.search_info) {
            let token = localStorage.getItem('token');
            axios.get(Config.API_URL + 'ncc?search_info=' + this.state.search_info + "&limit=20&token=" + token)
                .then(response => {
                    this.setState({
                        info: response.data.data,
                    });
                });
        }



        if (prevState.note !== this.state.note) {
            if (localStorage.getItem('ADDRESS_NCC')) {
                var address = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
            } else {
                var address = {};
            }
            address.note = this.state.note;
            localStorage.setItem('ADDRESS_NCC', JSON.stringify(address));
        }


       
        if (prevState.countTotal != this.state.countTotal) {
            if( parseInt(this.state.countTotal) <= 1 ){
                this.setState({feeShip: 0});
            }else{
                this.setState({feeShip: 0});
            }
        }

        if (prevState.feeShip != this.state.feeShip || prevState.countTotal != this.state.countTotal) {
            this.setState({
                sumTotal: parseInt(this.state.countTotal) + parseInt(this.state.feeShip)
            })
        }

        if (prevState.discountTotal != this.state.discountTotal) {
            this.setState({
                sumTotal: parseInt(this.state.discountTotal) + parseInt(this.state.feeShip)
            })
        }


    }

    selectInfo(full_name,addressselect){
        if (localStorage.getItem('ADDRESS_NCC')) {
            var address = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
        } else {
            var address = {};
        }
        address.full_name = full_name;
        address.address = addressselect;
        localStorage.setItem('ADDRESS_NCC', JSON.stringify(address));

        this.setState({
            full_name : full_name,
            address : addressselect,
        })



        var ADDRESS_LOCAL = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
        this.setState({
            ADDRESS_LOCAL,
        })







        axios.get(Config.API_URL + 'ncc-check?full_name=' + full_name)
                .then(response => {
                    this.setState({
                        countOrder: response.data.countorder,
                    });
                });


        var ADDRESS_LOCAL = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
        this.setState({
            ADDRESS_LOCAL,
        })
        var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
        this.setState({
            CART_LOCAL
        })
        

    }

    EditDiaChi() {
        if(this.state.full_name && this.state.address) {

            if (localStorage.getItem('ADDRESS_NCC')) {
                var address = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
            } else {
                var address = {};
            }
            address.full_name = this.state.full_name;
            address.address = this.state.address;
            localStorage.setItem('ADDRESS_NCC', JSON.stringify(address));
            var ADDRESS_LOCAL = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
            this.setState({
                ADDRESS_LOCAL,
            })
            this.setState({ openEditDiaChi: false });
            console.log(address);
            
        } else {
            alert('Chưa điền đủ thông tin')
        }
    }

    xoaInfo(){
        localStorage.removeItem('ADDRESS_NCC');
        var ADDRESS_LOCAL = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
        this.setState({
            ADDRESS_LOCAL,
        })
    }

      // EditDiaChi
      onOpenModalEditDiaChi = () => {
        this.setState({ openEditDiaChi: true });
    };

    onCloseModalEditDiaChi = () => {
        this.setState({ openEditDiaChi: false });
    };
    // Mở Modal EditDiaChi


    buyNow() {

       
        var order = {};
        
        if(localStorage.getItem('ADDRESS_NCC')){
            var note = JSON.parse(localStorage.getItem('ADDRESS_NCC')).note
        }else{
            var note = ""
        }
        order.product = localStorage.getItem('CART_ITEM_NHAP_HANG');
        order.address = localStorage.getItem('ADDRESS_NCC');
        order.voucher = this.state.coupon_code;
        order.countTotal = this.state.countTotal;
        order.countGiaNhap = this.state.countGiaNhap;
        order.discount = this.state.discount;
        order.feeShip = this.state.feeShip;
        order.sumTotal = this.state.sumTotal;
        order.note = note;
        
        if (!localStorage.getItem('ADDRESS_NCC') || !JSON.parse(localStorage.getItem('ADDRESS_NCC')).full_name) {
           alert('Chưa điền đủ thông tin')
        } else {
            this.setState({
                loading: true
            })

            let token = localStorage.getItem('token');
            // this.props.checkOut(order); // Reducer OrderReducer
            axios.post(Config.API_URL + 'tao-don-nhap?token=' + token, qs.stringify(order)) 
                .then((res) => {
                    localStorage.removeItem('CART_ITEM_NHAP_HANG');
                    localStorage.removeItem('ADDRESS_NCC');
                    NotificationManager.success('Tạo đơn thành công: '+JSON.parse(JSON.stringify(res.data)).id_order, '', 2000, false);
                    window.scrollTo(0, 0);
                    this.setState({
                        success: true,
                        madon: JSON.parse(JSON.stringify(res.data)).id_order,
                        loading: false
                    })

                    var ADDRESS_LOCAL = JSON.parse(localStorage.getItem('ADDRESS_NCC'));
                    this.setState({
                        ADDRESS_LOCAL,
                    })
                    var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM_NHAP_HANG'));
                    this.setState({
                        CART_LOCAL
                    })
                    this.setState({
                        countCart:0,
                        countTotal:0,
                        countGiaNhap:0,
                    })
                    

                })
                .catch(function (error) {
                    console.log(error);
                    NotificationManager.error('Tạo đơn không thành công', '', 2000, false);
                    // return state
                });

            
        }


    }

    render() {

        if(this.state.countGiaNhap){
            console.log(this.state.countGiaNhap);
        }
        
        
         // Modal
         const { openEditDiaChi } = this.state;
         // Modal

         // Sửa địa chỉ
        const tinh = this.state.tinh;
        const huyen = this.state.huyen;
        const xa = this.state.xa;
        const { selectedTinh } = this.state;
        const { selectedHuyen } = this.state;
        const { selectedXa } = this.state;
        // Sửa địa chỉ

        if (JSON.parse(localStorage.getItem('ADDRESS_NCC'))) {
            var note = JSON.parse(localStorage.getItem('ADDRESS_NCC')).note;
        } else {
            var note = '';
        }
        return (
            <div id="content">
                <NotificationContainer />
                <div context="draftOrder" define="{draftOrder: new Bizweb.DraftBuilder(this,{&quot;money_format&quot;:&quot;{{amount_no_decimals_with_comma_separator}}₫&quot;,&quot;customer_id&quot;:0,&quot;copy_order&quot;:null,&quot;price_rules&quot;:[]})}" className="next-ui">
                    <div className="modal" data-tg-refresh="modal" id="modal_container" style={{ display: 'none' }} aria-hidden="true" aria-labelledby="ModalTitle" tabIndex={-1} />
                    <div className="modal-bg" data-tg-refresh="modal" id="modal_backdrop" tabIndex={-1} />
                    <div id="draft-refresh-section">
                        <header className="ui-title-bar-container">
                            <div className="ui-title-bar">
                                <div className="ui-title-bar__navigation">
                                    <div className="ui-breadcrumbs">
                                        <Link to="/orders" className="ui-button ui-button--transparent ui-breadcrumb">
                                            <svg className="next-icon next-icon--size-20 next-icon--no-nudge"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" /> </svg>
                                            <span className="ui-breadcrumb__item">Danh sách đơn hàng</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group">
                                        <span className="ui-title-bar__icon">
                                            <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-orders" /> </svg>
                                        </span>
                                        <h1 className="ui-title-bar__title">Tạo đơn nhập hàng</h1>
                                    </div>
                                    <div data-define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                        <div className="ui-title-bar__mobile-primary-actions">
                                            <div className="ui-title-bar__actions">
                                                <button className="ui-button ui-button--primary js-btn-loadable js-btn-primary ui-title-bar__action has-loading btn-disabled" id="draft_save" bind-event-click="createDraftOrder()" bind-class="{'disabled' : !isValid()}" type="button" name="button">Lưu đơn hàng nháp</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ui-title-bar__actions-group">
                                    <div className="ui-title-bar__actions">
                                        <button className="ui-button ui-button--primary js-btn-loadable js-btn-primary ui-title-bar__action has-loading btn-disabled" id="draft_save" bind-event-click="createDraftOrder()" bind-class="{'disabled' : !isValid()}" type="button" name="button">Lưu đơn hàng nháp</button>
                                    </div>
                                </div>
                            </div>
                            <div className="collapsible-header">
                                <div className="collapsible-header__heading" />
                            </div>
                        </header>
                        <form id="draftForm">
                            <div className="ui-layout">
                                <div className="ui-layout__sections">
                                    <div className="ui-layout__section ui-layout__section--primary">
                                        <div className="ui-layout__item">
                                            <div className="next-card relative">
                                                <section>




                                                    <div className="next-card__section next-card__section--no-vertical-spacing">
                                                        <div className="next-table--line-items">


                                                            {/* Start Product */}
                                                            {
                                                                this.state.CART_LOCAL ?
                                                                    this.state.CART_LOCAL.map((value, key) => {
                                                                        return <div key={key} id="line_item_rows">
                                                                            <div style={{ display: 'flex', padding: '20px 0', borderBottom: '1px solid #eee' }} className="line_item_1 variant_id_24462661" item-index={1}>
                                                                                <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                                                                    <div className="next-table__image-cell hide-when-printing" style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <div className="order-line-image-mobile">
                                                                                            <Link to={JSON.parse(value).url} >
                                                                                                <img src={JSON.parse(value).image} width="40px" alt={JSON.parse(value).name} />
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="next-table__cell--item-name" style={{ margin: '0 10px' }}>
                                                                                        <a target="_blank" rel="noreferrer noopener">{JSON.parse(value).name}</a>
                                                                                        <p className="subdued">{JSON.parse(value).thuoctinh}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <div style={{ margin: '0 5px' }} className="type--left next-table__cell--grow-when-condensed">
                                                                                        <div className="ui-popover__container">
                                                                                            <span className="line_item_1_price" >{parseInt(JSON.parse(value).price).toLocaleString()}₫</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="subdued" style={{ margin: '0 5px' }}>x</div>
                                                                                    <div>
                                                                                        <div className="next-input-wrapper" style={{ width: '120px' }}>
                                                                                            <NumericInput onChange={(e, name) => this.changeQuantity(e, JSON.parse(value).key)} name={JSON.parse(value).key} mobile className="form-control" min={1} value={JSON.parse(value).quantity} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ margin: '0 5px', minWidth: '5em' }} className="type--right">{(JSON.parse(value).price * JSON.parse(value).quantity).toLocaleString()}₫</div>
                                                                                    <div style={{ margin: '0 5px' }}>
                                                                                        <a className="remove-line-item" onClick={() => this.removeItem(JSON.parse(value).key)}>
                                                                                            <svg className="next-icon next-icon--10 next-icon--slate-lighter">
                                                                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-remove" />
                                                                                            </svg>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                    :
                                                                    ""
                                                            }
                                                            {/* End Product */}





                                                        </div>
                                                    </div>





                                                    <div className="next-card__section next-card__section--no-bottom-spacing">
                                                        <div className="next-input-wrapper full-width-search">
                                                            <div className="ui-popover__container ui-popover__container--full-width">

                                                                <div className="next-input--stylized">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--16"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-16" /></svg>
                                                                    </span>
                                                                    <input onChange={(event) => this.isChange(event)}  name="searchProduct" className="next-input next-input--invisible js-no-dirty" autoComplete="off" maxLength={50} placeholder="Tìm kiếm sản phẩm..." type="text" aria-expanded="false" />
                                                                </div>

                                                                <div className="ui-popover--is-active ui-popover ui-popover--full-width ui-popover--full-height ui-popover--half-spacing ui-popover--is-positioned-beneath" style={{ zIndex: 1, marginRight: 0, marginLeft: 0, transformOrigin: '50% -5px' }} id="ui-popover--1">
                                                                    <div className="ui-popover__content-wrapper">
                                                                        <div className="ui-popover__content" id="product_results">
                                                                            <div className="ui-popover__pane scroll-shadow--bottom" id="product_list">
                                                                                <ul className="clear-ul next-list--divided next-list--divided-half-padding clear-ul">


                                                                                    {/* Start product Search */}
                                                                                    {
                                                                                        this.state.items ?
                                                                                            this.state.items.map((value, key) => {
                                                                                                return <li className="resource-search-result" onClick={() => this.addCart(value.modelid, value.id, value.title, value.image, value.name, value.gia_nhap, value.can_nhap, "/product/i-" + value.itemid, value.gia_nhap)} >
                                                                                                    <a href="javascript:void(0)" className="js-selectable-variant">
                                                                                                        <div className="next-grid next-grid--compact next-grid--no-outside-padding next-grid--vertically-centered">
                                                                                                            <div className="next-grid__cell next-grid__cell--no-flex">
                                                                                                                <label htmlFor="product-851904065" className="s-none">
                                                                                                                    <div className="thumb">
                                                                                                                        <img className="block s-none thumb-30" src={value.image} width="30px" />
                                                                                                                    </div>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="next-grid__cell"> {value.title} - {value.modelid} - {value.name} </div>

                                                                                                            <div className="next-grid__cell next-grid__cell--no-flex pr">
                                                                                                                <p>Khả dụng: {
                                                                            value.stock-value.giu_cho >= 1 ?
                                                                            <span style={{color: 'blue'}}>{value.stock-value.giu_cho}</span>
                                                                            :
                                                                            <span style={{color: 'red'}}>{value.stock-value.giu_cho}</span>
                                                                            }</p>
                                                                                                            </div>

                                                                                                            <div className="next-grid__cell next-grid__cell--no-flex pr">
                                                                                                                <p>Nhập: {
                                                                            value.can_nhap <= 0 ?
                                                                            <span style={{color: 'blue'}}>{value.can_nhap}</span>
                                                                            :
                                                                            <span style={{color: 'red'}}>{value.can_nhap}</span>
                                                                            }</p>
                                                                                                            </div>
                                                                                                            <div className="next-grid__cell next-grid__cell--no-flex pr">
                                                                                                                <p>{value.gia_nhap?parseInt(value.gia_nhap).toLocaleString():""}₫</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </a>
                                                                                                </li>

                                                                                            })
                                                                                            :
                                                                                            ""
                                                                                    }
                                                                                    {/* End product */}



                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="next-card__section">
                                                        <div className="wrappable">
                                                            <div className="wrappable__item">
                                                                <div className="next-input-wrapper">
                                                                    <label className="control-label"> Ghi chú </label>
                                                                    <textarea onChange={(event) => this.isChange(event)} className="form-control textarea-mobile next-input" cols={20} name="note" placeholder="Nhập Ghi chú" rows={3} style={{ resize: 'none' }}  defaultValue={note} />

                                                                </div>
                                                            </div>
                                                            <div className="wrappable__item">
                                                                <div>
                                                                    <table className="table--no-border table borderless table-price">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="type--right subdued"> Tổng sản phẩm </td>
                                                                                <td className="type--right"><span>{this.state.countCart.toLocaleString()}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="type--right subdued"> Tạm tính </td>
                                                                                <td className="type--right"><span>{this.state.countTotal.toLocaleString()}</span>₫</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="type--right">
                                                                                    <div className="shipping-method-container">
                                                                                        <a href="javascript:void(0)" className="btn--link">
                                                                                            <span>Giảm giá</span>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="type--right">
                                                                                    <span className="subdued">—</span>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="type--right">
                                                                                    <div className="shipping-method-container">
                                                                                        <a href="javascript:void(0)" className="btn--link">
                                                                                            <span>Phí vận chuyển</span>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="type--right">
                                                                                    <span className="subdued">{this.state.feeShip > 0 ? parseInt(this.state.feeShip).toLocaleString() + " ₫" : "Miễn phí!"}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="type--right" style={{ width: '50%' }}> 
                                                                                    <p className="next-heading">Tổng cộng</p>
                                                                                </td>
                                                                                <td className="type--right" style={{ width: '50%' }}>
                                                                                    <p className="next-heading"><span>{this.state.countTotal ? (this.state.countTotal+parseInt(this.state.feeShip)).toLocaleString() : ""}</span>₫</p>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div className="next-card__section next-card__section--bordered">
                                                    <div className="wrappable wrappable--half-spacing wrappable--vertically-centered wrappable--right-aligned">
                                                        <div className="wrappable__item wrappable__item--no-flex">
                                                            <svg className="next-icon next-icon--24 next-icon--blue next-icon--no-nudge"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-credit-card" /></svg>
                                                        </div>
                                                        <div className="wrappable__item">
                                                            {/* <h2 className="next-heading next-heading--no-margin"> Xác nhận thanh toán </h2> */}
                                                        </div>
                                                        <div className="wrappable__item wrappable__item--no-flex">
                                                            <div className="button-group">
                                                                {
                                                                    this.state.loading ?
                                                                        <a href="javascript: void(0);" className="ui-button btn-save has-loading btn-paid btn-save-draft-order" ><i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} /></a>
                                                                        :
                                                                        <a href="javascript: void(0);" onClick={() => this.buyNow()} className="ui-button btn-save has-loading btn-paid btn-save-draft-order" >Tạo đơn nhập</a>
                                                                }
                                                                
                                                                {/* <a href="javascript: void(0);" className="ui-button has-loading btn-save btn-pending btn-save-draft-order" >Thanh toán sau</a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="ui-layout__section ui-layout__section--secondary">
                                        <div className="order-sidebar" id="customer-sidebar" refresh="order-sidebar">
                                        
{
    this.state.ADDRESS_LOCAL?
    <div className="ui-layout__item">
                                            
                                                <section className="ui-card" id="customer-card" data-tg-refresh="customer-card">
                                                    <div className="ui-card__section">
                                                        <div className="ui-type-container">
                                                            <div className="ui-stack ui-stack--wrap ui-stack--alignment-baseline">
                                                                <div className="ui-stack-item ui-stack-item--fill">
                                                                    <h3 className="ui-subheading">Địa chỉ giao hàng</h3></div>
                                                                <div className="ui-stack-item">
                                                                    <button className="ui-button btn--link" type="button" onClick={this.onOpenModalEditDiaChi}>Sửa</button>
                                                                    <a onClick={()=>this.xoaInfo()} style={{marginLeft:"10px"}} ><svg className="next-icon next-icon--12 next-icon--blue"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-remove" /></svg></a>

                                                                </div>
                                                            </div>
                                                            <p className="type--subdued word_break__content">
                                                                {this.state.ADDRESS_LOCAL ? this.state.ADDRESS_LOCAL.full_name : ""}
                                                                <br />{this.state.ADDRESS_LOCAL ? this.state.ADDRESS_LOCAL.address : ""}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="ui-card__section">
                                                        <div className="ui-type-container ui-type-container--spacing-tight">
                                                            <div>
                                                                <p><a href="javascript:voi(0)">{this.state.ADDRESS_LOCAL ? this.state.ADDRESS_LOCAL.full_name : ""}</a></p>
                                                                <p><a target="_blank" href={this.state.ADDRESS_LOCAL ? "/orders?search="+this.state.ADDRESS_LOCAL.full_name:""}>{this.state.countOrder?this.state.countOrder:"0"} đơn hàng</a></p>
                                                                        {
                                                                            this.state.countHoan ?
                                                                                <p><a style={{color:'red'}} target="_blank" href={this.state.ADDRESS_LOCAL ? "/orders?search=" + this.state.ADDRESS_LOCAL.full_name + "&trangThai=hoan_hang" : ""}>{this.state.countHoan} đơn hoàn</a></p>
                                                                                :
                                                                                ""
                                                                        }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </section>
                                                
                                            </div>
    :
    <div className="ui-layout__item">
                                                <div id="customer-sidebar" className="order-sidebar">
                                                    <aside bind-show="!hasCustomerInfo" className="next-card">
                                                        <section>
                                                            <header className="next-card__header">
                                                                <div className="wrappable wrappable--half-spacing">
                                                                    <div className="wrappable__item">
                                                                        <h3 className="next-heading">
                                                                            <span style={{ fontWeight: 500 }}>Tìm hay thêm nhà cung cấp</span>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </header>
                                                            <div className="next-card__section">
                                                                <div className="next-input-wrapper full-width-search">
                                                                    <div className="ui-popover__container ui-popover__container--full-width">


                                                                        <div className="next-input--stylized">
                                                                            <span bind-event-click="customerSearch.renderDropdown(event)" className="next-input__add-on next-input__add-on--before">
                                                                                <svg className="next-icon next-icon--16"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-16" /></svg>
                                                                            </span>
                                                                            <input onChange={(event) => this.isChange(event)} name="search_info" autoComplete="off" bind-event-focus="customerSearch.renderDropdown(event)" className="next-input next-input--invisible js-no-dirty" placeholder="Tìm kiếm NCC..." type="text" />
                                                                        </div>




                                                                        <div data-popover-css-vertical-margin={13} data-popover-css-horizontal-margin={16} data-popover-css-max-height={300} className="ui-popover ui-popover--full-width ui-popover--half-spacing ui-popover--is-positioned-beneath" data-popover-horizontally-relative-to-closest=".next-card" data-popover-css-max-width={10000} id="ui-popover--3" aria-labelledby="ui-popover-activator--27" aria-expanded="true" role="dialog" style={{ marginRight: 0, marginLeft: 0, transformOrigin: '50% -5px' }}>
                                                                            <div className="ui-popover__tooltip" />
                                                                            <div className="ui-popover__tooltip" />
                                                                            <div className="ui-popover__content-wrapper">
                                                                                <div className="ui-popover__content" id="customer_results" style={{ maxHeight: '291.5px' }}><style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .gravatar {\n        width: 40px;\n        height: 40px;\n        border-radius: 50%;\n    }\n\n    .gravatar--size-30 {\n        width: 30px;\n        height: 30px;\n    }\n\n    .customer-email {\n        display: inline-block;\n        max-width: 100%;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n        word-wrap: normal;\n    }\n" }} />
                                                                                    <div className="ui-popover__pane" id="customer_list" refresh="customers">
                                                                                        <ul className="clear-ul js-autocomplete-suggestions next-list next-list--compact next-list--toggles">
                                                                                            <li  onClick={this.onOpenModalEditDiaChi}>
                                                                                                <a href="javascript:void(0)" className="next-list__item next-list__item--promoted-action" selectable>
                                                                                                    <div className="next-grid next-grid--compact next-grid--no-outside-padding next-grid--vertically-centered">
                                                                                                        <div className="next-grid__cell next-grid__cell--no-flex">
                                                                                                            <div className="gravatar--size-30">
                                                                                                                <img alt="Next create customer" src="//bizweb.dktcdn.net/assets/admin/images/create_customer.svg" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="next-grid__cell">
                                                                                                            <p>Thêm mới NCC</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </a>
                                                                                            </li>

                                                                                            {
                                                                                                this.state.info ?
                                                                                                    this.state.info.map((value2, key2) => {
                                                                                                        return <li key={key2} onClick={()=>this.selectInfo(value2.full_name,value2.address)}>
                                                                                                            <a className="next-list__item">
                                                                                                                <div className="next-grid next-grid--no-outside-padding">
                                                                                                                    <div className="next-grid next-grid--no-outside-padding next-grid--vertically-centered">
                                                                                                                        <div className="next-grid__cell next-grid__cell--no-flex">
                                                                                                                            <img alt="null null" className="gravatar--size-30 gravatar" src="https://secure.gravatar.com/avatar/344fe37ade6f760c0701c75fe3647b48.jpg?s=30&d=https%3a%2f%2fbizweb.dktcdn.net%2fassets%2fimages%2fcustomper-noavatar.png" />
                                                                                                                        </div>
                                                                                                                        <div className="next-grid__cell" style={{ lineHeight: 1, paddingLeft: 0 }}>
                                                                                                                            <p style={{ paddingBottom: 4 }}>
                                                                                                                                {value2.full_name}
                                                                                                                            </p>
                                                                                                                            <p className="subdued customer-email">
                                                                                                                                {value2.phone}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    })
                                                                                                    :
                                                                                                    ""
                                                                                            }




                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>





                                                                        <div data-popover-css-vertical-margin={15} data-popover-css-horizontal-margin={20} data-popover-css-max-height={300} className="ui-popover ui-popover--full-width ui-popover--half-spacing" data-popover-horizontally-relative-to-closest=".next-card">
                                                                            <div className="ui-popover__tooltip" />
                                                                            <div className="ui-popover__tooltip" />
                                                                            <div className="ui-popover__content-wrapper">
                                                                                <div className="ui-popover__content" id="customer_results">
                                                                                    <div className="ui-popover__pane">
                                                                                        <div className="ui-popover__section">
                                                                                            <p className="type--subdued tc">
                                                                                                <span className="next-spinner">
                                                                                                    <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                                                                                                </span>Loading…
                                                                                                </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </aside>



                                                </div>
                                            </div>
}
                                            



                                            


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                {/* Modal DiaChi */}
                <Modal open={openEditDiaChi} onClose={this.onCloseModalEditDiaChi} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content" style={{ 'width': '450px' }}>
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Cập nhập thông tin NCC</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">
                                    <div>
                                        <section className="next-card__section next-card__section--no-padding">
                                           
                                            <div className="next-grid next-grid--no-outside-padding">
                                                <div className="next-grid__cell">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_LastName">Tên NCC</label>
                                                        <input onChange={(event) => this.isChange(event)} className="next-input" name="full_name" placeholder="Nhập Tên NCC" type="text" defaultValue={this.state.ADDRESS_LOCAL ? this.state.ADDRESS_LOCAL.full_name : ""} />
                                                    </div>
                                                </div>
                                            </div>
                                            


                                            <div className="next-grid next-grid--no-outside-padding">
                                                <div className="next-grid__cell">
                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Address_Address1">Link</label>
                                                        <input onChange={(event) => this.isChange(event)} className="next-input" name="address" placeholder="Nhập Địa chỉ" type="text" defaultValue={this.state.ADDRESS_LOCAL ? this.state.ADDRESS_LOCAL.address : ""} />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>

                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">
                                    <div className="ui-modal__secondary-actions">
                                        <div className="button-group">
                                            <button onClick={this.onCloseModalEditDiaChi} className="ui-button close-modal" type="button">Đóng</button>
                                        </div>
                                    </div>
                                    <div className="ui-modal__primary-actions">
                                        <div className="button-group button-group--right-aligned">
                                            {
                                                this.state.loadUpdateIdFb ?
                                                    <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                                                        <span className="next-spinner" bind-show="isCalculating">
                                                            <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                                                        </span>

                                                    </button>
                                                    :
                                                    <button onClick={() => this.EditDiaChi()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}






            </div>

        );
    }
}

export default NhapHang;