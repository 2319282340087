import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { GetUsernameFromFacebookURL } from '../../functions/function.js';





const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 
class CheckInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          phone: '',
          loading: true,
          loadingautophone: false,
          token: '',
        };
      }



      // Khu vực viết Hàm
    PhoneToUid(phone){
            var url = "https://alita.vn/api/phone-to-uid?phone=84"+phone.substring(1);
            axios.get(url)
                .then(phonetouid => {

                    if(phonetouid.data && phonetouid.data.IsSuccess == true){
                        this.GetNewFeed(phonetouid.data.UID);
                        this.GetInFo(phonetouid.data.UID);
                    }else{
                        this.setState({phone_to_uid_loi:phonetouid.data.ErrMsg,loading: false})
                    }

                })
                .catch(function (error) {
                    // alert('Không tìm thấy tài khoản FB cần kiểm tra')
                });
    }
    GetInFo(uid){
        
            let token = localStorage.getItem('token_nick_fb');
            if(token){
                var url = "https://graph.facebook.com/v3.2/" + uid + "?access_token=" + token;
                this.setState({uid_fb:uid})
                axios.get(url)
                    .then(getinfo => {
    
                        this.setState({info_uid:getinfo.data})
    
                    })
                    .catch((e) => 
                    {
                        this.setState({phone_to_uid_loi:'Tài khoản FB cần kiểm tra có thể đã bị khóa',loading: false})
                        
                    });
            }else{
                alert('Chưa nhập token')
            }
            
                // .catch(function (error) {
                //     this.setState({phone_to_uid_loi:'Tài khoản FB cần kiểm tra có thể đã bị khóa',loading: false})
                //     // alert('Tài khoản FB cần kiểm tra có thể đã bị khóa')
                // });
        
    }
    GetNewFeed(uid) {
        localStorage.removeItem('DANH_SACH_BAN_BE');
        localStorage.removeItem('DANH_SACH_BAN_BE_SORT');

        let token = localStorage.getItem('token_nick_fb');
        

        if (token) {


            
            
            
            var url = "https://graph.facebook.com/v3.2/" + uid + "/feed?fields=id&access_token=" + token;
            axios.get(url)
                .then(getnewfeed => {

                    this.setState({loading:false})

                    if(this.state.type && this.state.type=='friend'){
                        this.GetFriends(uid);
                    }

                    if(this.state.type && this.state.type=='family'){
                        this.GetFamily(uid);
                    }

                    if(this.state.type && this.state.type=='page'){
                        this.GetPage(uid);
                    }

                    if(!this.state.type){
                        getnewfeed.data.data.map((valuenewfeed, keynewfeed) => {
                            this.GetBanBeTuongTac(valuenewfeed.id);
                        })
                    }


                    if(this.state.type && this.state.type=='like'){
                        getnewfeed.data.data.map((valuenewfeed, keynewfeed) => {
                            this.GetBanBeLike(valuenewfeed.id);
                        })
                    }

                    

                })
                .catch(function (error) {
                    // alert('Tài khoản FB cần kiểm tra có thẻ đã bị khóa')
                });
        }else{
            alert('Chưa có token');
        }

    }

    GetBanBeTuongTac(id) {
        let token = localStorage.getItem('token_nick_fb');
        if (token) {
            var url = "https://graph.facebook.com/v3.2/" + id + "?fields=comments&access_token=" + token;
            axios.get(url)
                .then(getTuongTac => {

                    if (getTuongTac.data.comments.data) {
                        getTuongTac.data.comments.data.map((valueTT, keynewTT) => {
                            
                            this.TaoDanhSachBanBe(valueTT.from.id,valueTT.from.name,1);

                        })
                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
        }else{
            alert('Chưa có token');
        }
    }

    GetBanBeLike(id) {
        let token = localStorage.getItem('token_nick_fb');
        if (token) {
            var url = "https://graph.facebook.com/v7.0/" + id + "/reactions?access_token=" + token;
            axios.get(url)
                .then(getLike => {

                    if (getLike.data.data) {
                        getLike.data.data.map((valueTT, keynewTT) => {
                            
                            this.TaoDanhSachBanBe(valueTT.id,valueTT.name,1);

                        })
                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
        }else{
            alert('Chưa có token');
        }
    }

    GetFriends(uid) {
        let token = localStorage.getItem('token_nick_fb');
        if (token) {
            var url = "https://graph.facebook.com/v7.0/" + uid + "/friends?access_token=" + token;
            axios.get(url)
                .then(getLike => {

                    if (getLike.data.data) {
                        getLike.data.data.map((valueTT, keynewTT) => {
                            
                            this.TaoDanhSachBanBe(valueTT.id,valueTT.name,1);

                        })
                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
        }else{
            alert('Chưa có token');
        }
    }
    GetFamily(uid) {
        let token = localStorage.getItem('token_nick_fb');
        if (token) {
            var url = "https://graph.facebook.com/v7.0/" + uid + "/family?access_token=" + token;
            axios.get(url)
                .then(getLike => {

                    if (getLike.data.data) {
                        getLike.data.data.map((valueTT, keynewTT) => {
                            
                            this.TaoDanhSachBanBe(valueTT.id,valueTT.name,1);

                        })
                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
        }else{
            alert('Chưa có token');
        }
    }

    GetPage(uid) {
        let token = localStorage.getItem('token_nick_fb');
        if (token) {
            var url = "https://graph.facebook.com/v7.0/" + uid + "/likes?access_token=" + token;
            axios.get(url)
                .then(getLike => {

                    if (getLike.data.data) {
                        getLike.data.data.map((valueTT, keynewTT) => {
                            
                            this.TaoDanhSachBanBe(valueTT.id,valueTT.name,1);

                        })
                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
        }else{
            alert('Chưa có token');
        }
    }

    TaoDanhSachBanBe(id,name,quantity){
        var item = {};
        item.id = id;
        item.name = name;
        item.quantity = parseInt(quantity);
        if (localStorage.getItem('DANH_SACH_BAN_BE')) {
            var CART_LOCAL = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE'));
        } else {
            var CART_LOCAL = [];
        }
        if (CART_LOCAL.length > 0) {
            var checkTrung = false;
            JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE')).map((value, key) => {
                if (JSON.parse(JSON.stringify(JSON.parse(value))).id === item.id) {
                    checkTrung = true;
                    var itemnew = {};
                    itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                    itemnew.name = JSON.parse(JSON.stringify(JSON.parse(value))).name;
                    itemnew.quantity = parseInt(JSON.parse(JSON.stringify(JSON.parse(value))).quantity) + item.quantity;
                    CART_LOCAL[key] = JSON.stringify(itemnew);
                }
            })
            if (checkTrung === false) {
                CART_LOCAL.push(JSON.stringify(item));
            }

        } else {
            CART_LOCAL.push(JSON.stringify(item));
        }

        
        localStorage.setItem('DANH_SACH_BAN_BE', JSON.stringify(CART_LOCAL));
        var CART_LOCAL = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE'));
        
        CART_LOCAL.sort(function(a,b) {
            return parseInt(JSON.parse(b).quantity) - parseInt(JSON.parse(a).quantity);
        });
        
        localStorage.setItem('DANH_SACH_BAN_BE', JSON.stringify(CART_LOCAL));

        var CART_LOCAL = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE'));

        // console.log(CART_LOCAL.length);

        if(CART_LOCAL && CART_LOCAL.length <=9 ){
            var end = CART_LOCAL.length;
        }else{
            var end = 9;
        }
        
        var newUser = [];
            for (var i = 0; i < end; i++) {
                newUser.push(CART_LOCAL[i]);
            }
            localStorage.setItem('DANH_SACH_BAN_BE_SORT', JSON.stringify(newUser));
        
        var DANH_SACH_BAN_BE_SORT = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT'));
        
        this.setState({
            CART_LOCAL,
            DANH_SACH_BAN_BE_SORT:DANH_SACH_BAN_BE_SORT,
            loading:false,
            loadingautophone: true,
        })

 

    }

    QuetSDT() {
        if ( localStorage.getItem('DANH_SACH_BAN_BE_SORT') ) {
            this.setState({loadingphone: true})
            var CART_LOCAL = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT'));
            if (CART_LOCAL.length > 0) {


                JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT')).map((value, key) => {
                    
                    var url = "https://alita.vn/api/uid-to-phone?uid=" + JSON.parse(JSON.stringify(JSON.parse(value))).id;
                    axios.get(url)
                        .then(uidtophone => {
                            var CART_LOCAL = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT'));
                            if (uidtophone.data && uidtophone.data.IsSuccess == true) {
                                var itemnew = {};
                                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                                itemnew.name = JSON.parse(JSON.stringify(JSON.parse(value))).name;
                                itemnew.phone = "0"+uidtophone.data.Mobile.substring(3);
                                itemnew.quantity = parseInt(JSON.parse(JSON.stringify(JSON.parse(value))).quantity);
                                CART_LOCAL[key] = JSON.stringify(itemnew);
                            } else {
                                var itemnew = {};
                                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                                itemnew.name = JSON.parse(JSON.stringify(JSON.parse(value))).name;
                                itemnew.phone = uidtophone.data.ErrMsg;
                                itemnew.quantity = parseInt(JSON.parse(JSON.stringify(JSON.parse(value))).quantity);
                                CART_LOCAL[key] = JSON.stringify(itemnew);
                            }

                            localStorage.setItem('DANH_SACH_BAN_BE_SORT', JSON.stringify(CART_LOCAL));

                var DANH_SACH_BAN_BE_SORT = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT'));
                // console.log(DANH_SACH_BAN_BE_SORT);
                
                this.setState({
                    CART_LOCAL,
                    DANH_SACH_BAN_BE_SORT: DANH_SACH_BAN_BE_SORT,
                    loading: false,
                    loadingphone: false,
                })


                        })
                        .catch(function (error) {
                            // alert('Không tìm thấy tài khoản FB cần kiểm tra')
                        });
                })
                
                
                



            }
           
        }
    }


    QuetSDT2() {
        if ( localStorage.getItem('DANH_SACH_BAN_BE_SORT') ) {
           
            var CART_LOCAL = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT'));
            if (CART_LOCAL.length > 0) {


                JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT')).map((value, key) => {
                    
                    var url = "https://alita.vn/api/getTel?fbid=" + JSON.parse(JSON.stringify(JSON.parse(value))).id;
                    axios.get(url)
                        .then(uidtophone => {
                            var CART_LOCAL = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT'));
                            if (uidtophone.data && uidtophone.data.status == 1) {
                                console.log(uidtophone.data);
                                
                                var itemnew = {};
                                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                                itemnew.name = JSON.parse(JSON.stringify(JSON.parse(value))).name;
                                itemnew.phone = uidtophone.data.phone;
                                itemnew.quantity = parseInt(JSON.parse(JSON.stringify(JSON.parse(value))).quantity);
                                CART_LOCAL[key] = JSON.stringify(itemnew);
                            } else {
                                var itemnew = {};
                                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                                itemnew.name = JSON.parse(JSON.stringify(JSON.parse(value))).name;
                                itemnew.phone = '';
                                itemnew.quantity = parseInt(JSON.parse(JSON.stringify(JSON.parse(value))).quantity);
                                CART_LOCAL[key] = JSON.stringify(itemnew);
                            }

                            localStorage.setItem('DANH_SACH_BAN_BE_SORT', JSON.stringify(CART_LOCAL));

                var DANH_SACH_BAN_BE_SORT = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT'));
                console.log(DANH_SACH_BAN_BE_SORT);
                
                this.setState({
                    CART_LOCAL,
                    DANH_SACH_BAN_BE_SORT: DANH_SACH_BAN_BE_SORT,
                    loading: false,
                    loadingphone: false,
                    loadingautophone: false,
                })


                        })
                        .catch(function (error) {
                            // alert('Không tìm thấy tài khoản FB cần kiểm tra')
                        });
                })
                
                
                



            }
           
        }
    }


    QuetSDT3() {
        if ( localStorage.getItem('DANH_SACH_BAN_BE_SORT') ) {
           
            var CART_LOCAL = JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT'));
            if (CART_LOCAL.length > 0) {


                JSON.parse(localStorage.getItem('DANH_SACH_BAN_BE_SORT')).map((value, key) => {
                    
                    var url = "https://marketingtool.top/wp-admin/admin-ajax.php";

                    axios.post(url, qs.stringify({ 
                        action: 'ajax_convert_to_phone_multi', 
                        listname: 'FUID' ,
                        links: JSON.parse(JSON.stringify(JSON.parse(value))).id ,
                        type: 'uid2phone' ,
                        securitymulti: 'cc35d53152' ,

                    }))
                        .then((res) => {
                        console.log(res);


                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                
                
                



            }
           
        }
    }
    
      // Khu vực viết Hàm



      componentWillMount() {
        this.setState({
          phone: queryString.parse(window.location.search).phone,
          uid: queryString.parse(window.location.search).uid,
          type: queryString.parse(window.location.search).type,
          friend: queryString.parse(window.location.search).friend,
          url: queryString.parse(window.location.search).url,
        })
        if(queryString.parse(window.location.search).phone){
            this.PhoneToUid(queryString.parse(window.location.search).phone);
        }
        if(queryString.parse(window.location.search).uid){
            this.GetNewFeed(queryString.parse(window.location.search).uid);
            this.GetInFo(queryString.parse(window.location.search).uid);
        }
        if(queryString.parse(window.location.search).url){
            var identifier = GetUsernameFromFacebookURL(queryString.parse(window.location.search).url);
            let token = localStorage.getItem('token_nick_fb');
            if (token) {
                var url = "https://graph.facebook.com/" + identifier + "?access_token=" + token;
                axios.get(url)
                    .then(getLike => {
    
                        if (getLike.data) {
                            this.GetNewFeed(getLike.data.id);
                            this.GetInFo(getLike.data.id);
                        }
    
                    })
                    .catch(function (error) {
                        // console.log(error);
                        alert('TOKEN hết hạn')
                    });
            }
        }
        
      }
    
    
      componentDidMount() {
        
      }
      
      chonTrangThai(e) {
        localStorage.removeItem('DANH_SACH_BAN_BE');
        localStorage.removeItem('DANH_SACH_BAN_BE_SORT');
        this.setState({
            loading: true,
          type: e,
          DANH_SACH_BAN_BE_SORT:'',
        })
      }
      
      componentDidUpdate = (prevProps, prevState) => {
    
        const query = {
          phone: this.state.phone,
          uid: this.state.uid,
          type: this.state.type,
          friend: this.state.friend,
          url: this.state.url,
        };
        const searchString = qs.stringify(query);
        history.push(
          {
            pathname: '/check-info',
            search: searchString,
          }
        )

        if(prevState.loadingautophone !== this.state.loadingautophone){
            this.QuetSDT2();
            // this.QuetSDT3();
        }

        if(prevState.type !== this.state.type){

            this.setState({
                phone: queryString.parse(window.location.search).phone,
                uid: queryString.parse(window.location.search).uid,
                type: queryString.parse(window.location.search).type,
                friend: queryString.parse(window.location.search).friend,
                url: queryString.parse(window.location.search).url,
              })
              if(queryString.parse(window.location.search).phone){
                  this.PhoneToUid(queryString.parse(window.location.search).phone);
              }
              if(queryString.parse(window.location.search).uid){
                  this.GetNewFeed(queryString.parse(window.location.search).uid);
                  this.GetInFo(queryString.parse(window.location.search).uid);
              }
              if(queryString.parse(window.location.search).url){
                  var identifier = GetUsernameFromFacebookURL(queryString.parse(window.location.search).url);
                  let token = localStorage.getItem('token_nick_fb');
                  if (token) {
                      var url = "https://graph.facebook.com/" + identifier + "?access_token=" + token;
                      axios.get(url)
                          .then(getLike => {
          
                              if (getLike.data) {
                                  this.GetNewFeed(getLike.data.id);
                                  this.GetInFo(getLike.data.id);
                              }
          
                          })
                          .catch(function (error) {
                              console.log(error);
                          });
                  }
              }

        }

    
      }





    render() {
// console.log(this.state.phone)
        // Modal
    const { openChuanBi } = this.state;
    // Modal

        return (
            <div id="content">
                
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width" style={{maxWidth: '55%'}}>
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    {/* <a href="javascript:if(window.location.href.indexOf('facebook.com')>-1)
                                    {window.open('/check-info?uid=?link='+location.href,'_blank')}" className="btn green btn-lg">
                                        <i className="fa fa-paw" /> Check Info 
                                        </a> */}

                                   

                                    <h1 className="ui-title-bar__title">
                                        <Link to={this.state.info_uid?"check-info?uid=" + this.state.info_uid.id:""}>
                                            <button onClick={() => this.chonTrangThai()} type="button" className="ui-button btn-default quick-view-order-btn-cancel">QUÉT BB COMMENT</button>
                                        </Link>
                                    </h1>

                                    <h1 className="ui-title-bar__title">
                                        <Link to={this.state.info_uid?"check-info?uid=" + this.state.info_uid.id + "&type=like":""}>
                                            <button onClick={() => this.chonTrangThai('like')} type="button" className="ui-button btn-default quick-view-order-btn-cancel">QUÉT BB LIKE</button>
                                        </Link>
                                    </h1>

                                    <h1 className="ui-title-bar__title">
                                        <Link to={this.state.info_uid?"check-info?uid=" + this.state.info_uid.id + "&type=friend":""}>
                                            <button onClick={() => this.chonTrangThai('friend')} type="button" className="ui-button btn-default quick-view-order-btn-cancel">QUÉT DS BẠN BÈ</button>
                                        </Link>
                                    </h1>

                                    <h1 className="ui-title-bar__title">
                                        <Link to={this.state.info_uid?"check-info?uid=" + this.state.info_uid.id + "&type=family":""}>
                                            <button onClick={() => this.chonTrangThai('family')} type="button" className="ui-button btn-default quick-view-order-btn-cancel">QUÉT GIA ĐÌNH</button>
                                        </Link>
                                    </h1>

                                    <h1 className="ui-title-bar__title">
                                        <Link to={this.state.info_uid?"check-info?uid=" + this.state.info_uid.id + "&type=page":""}>
                                            <button onClick={() => this.chonTrangThai('page')} type="button" className="ui-button btn-default quick-view-order-btn-cancel">QUÉT THÍCH</button>
                                        </Link>
                                    </h1>

                                    <h1 className="ui-title-bar__title">
                                        <a href="javascript:if(window.location.href.indexOf('facebook.com')>-1)
                                    {window.open('https://admin.alita.vn/check-info?url='+location.href,'_blank')}">
                                       <button type="button" className="ui-button btn-default quick-view-order-btn-cancel"> 👻 Bookmark Check</button> 
                                        </a>
                                    </h1>

                                    

                                    
                                </div>
                                <div className="ui-title-bar__heading-group">
                                <h1 className="ui-title-bar__title">
                                        <button onClick={() => this.QuetSDT()} type="button" className="ui-button btn-default quick-view-order-btn-cancel">QUÉT SĐT TỰ ĐỘNG</button>
                                    </h1>
                                <h1 className="ui-title-bar__title">
                                        <a href="https://uid.danseo.net" target="_blank">
                                            <button type="button" className="ui-button btn-default quick-view-order-btn-cancel">Chuyển UID > Phone</button>
                                        </a>
                                    </h1>

                                    <h1 className="ui-title-bar__title">
                                        <a href="https://uid.danseo.net/index2.php" target="_blank">
                                            <button type="button" className="ui-button btn-default quick-view-order-btn-cancel">Chuyển Phone > UID</button>
                                        </a>
                                    </h1>

                                </div>
                                <div className="ui-title-bar__heading-group" style={{paddingTop: '20px'}}>
                                    <h1 className="ui-title-bar__title">
                                        THÔNG TIN TÀI KHOẢN <a href={this.state.info_uid ? "http://fb.com/" + this.state.info_uid.id : ""} target="_blank"> {this.state.info_uid ? this.state.info_uid.name : ""}</a>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" >
                            
</div>
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width"  style={{maxWidth: '55%'}}>
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        {
                                                            !this.state.phone_to_uid_loi ?
                                                                <thead>
                                                                    <tr>
                                                                        <th><span>UID</span></th>
                                                                        <th><span>Họ tên</span></th>
                                                                        <th><span>Ảnh</span></th>
                                                                        <th><span>SĐT</span></th>
                                                                        <th><span>TT</span></th>
                                                                    </tr>
                                                                </thead>
                                                                :
                                                                ""
                                                        }
                                                        <tbody>

                                                            {
                                                                this.state.loading ?
                                                                    <div className="row">
                                                                        <div className="col-md-12 pager">
                                                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    ""

                                                            }

                                                        {
                                                                this.state.phone_to_uid_loi?
                                                                <div>
                                                                    {this.state.phone_to_uid_loi}
                                                                    <a href={this.state.uid_fb?"http://fb.com/"+this.state.uid_fb:""} target="_blank"> Kiểm tra lại</a>
                                                                </div>
                                                                
                                                                :
                                                                ""
                                                            }

                                                            {
                                                                !this.state.loading && this.state.DANH_SACH_BAN_BE_SORT?
                                                                this.state.DANH_SACH_BAN_BE_SORT.map( (value,key)=>{
                                                                    // console.log(value);
                                                                    
                                                                    return <tr>

                                                                        <td className="total">
                                                                            <p>{JSON.parse(value).id}</p>
                                                                        </td>
                                                                    
                                                                        <td className="total">
                                                                            <p><a target="_blank" href={value?"http://fb.com/"+JSON.parse(value).id:""}>{value?JSON.parse(value).name:""}</a></p>
                                                                        </td>
                                                                        <td className="vendor">
                                                                            <img src={value?"https://graph.facebook.com/"+JSON.parse(value).id+"/picture":""}></img>
                                                                        </td>

                                                                        <td className="vendor">
                                                                            <p>

                                                                                {
                                                                                    !this.state.loadingphone ?
                                                                                    value && JSON.parse(value).phone ? JSON.parse(value).phone : ""
                                                                                    :
                                                                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                                                                }
                                                                            </p>
                                                                        </td>
                                                                        <td className="vendor">
                                                                        <p>{value?JSON.parse(value).quantity:""}</p>
                                                                        </td>


                                                                    </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        );
    }
}

export default CheckInfo;