import React, { Component } from 'react';
import ReactChartkick, { ColumnChart  } from 'react-chartkick';

import * as Func from '../../functions/function.js';
import * as Config from '../../config.js';

const axios = require('axios');
var moment = require('moment');

class Revenuebyinventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data:[],
          chart: []
        };
    }

    loadData(){
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'analytics/revenue-by-inventory?token=' + token)
        .then(rs => {
            this.setState({
                chart: JSON.parse(JSON.stringify(rs.data.chartData)),
                data: JSON.parse(JSON.stringify(rs.data.data)),
            });
        });
    }

    componentDidMount(){
        this.loadData();
    }

    render() {
        let tongtonkho = 0;
        let tongdaban =  0;
        let tongtientonkho = 0;
        return (
            <div id="reports-chart" className="reports-chart" style={{opacity: 1}}>
                <div className="next-card next-card--timeline" style={{position:'relative',margin:0}} >
                    <section className="next-card__section">
                        <ColumnChart donut={true} thousands="." decimal="," data={this.state.chart} />
                    </section>
                   
                    <section className="next-card__section">
                        <div id="report-table">
                            <table className="expanded st">
                                <thead>
                                <tr>
                                    <th className="reports-property is-sortable sorted-asc"><span className="field">Mã SP</span></th>
                                    <th className="reports-measure is-sortable"><span className="field">SL đã bán</span></th>
                                    <th className="reports-measure is-sortable"><span className="field">SL tồn kho</span></th>
                                    <th className="reports-measure is-sortable"><span className="field">Tổng tiền tồn kho</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item,key)=>{
                                        tongtonkho += parseInt(item.sltonkho) > 0 ?  parseInt(item.sltonkho) : 0;
                                        tongdaban += parseInt(item.sldaban) > 0 ?  parseInt(item.sldaban) : 0;
                                        tongtientonkho += parseInt(item.tientonkho) > 0  ? parseInt(item.tientonkho) : 0;
                                        return (
                                            <tr key={key} className="summary last-row total-amount show-tr-when-printing">
                                                <td className="reports-property">{item.itemId}</td>
                                                <td className="reports-measure">{item.sldaban}</td>
                                                <td className="reports-measure">{item.sltonkho}</td>
                                                <td className="reports-measure">{Func.FormatNumber(item.tientonkho)}₫</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>

                <div className="footer-row">
                    <div className="span24">
                        <footer>
                            <div className="total-title" style={{width: '240px'}}>
                                <p className="footer-main subdued"><strong>TỔNG</strong></p>
                            </div>
                            <div className="total-cell" style={{width: '335px'}}>
                                <p className="footer-main"><strong>{tongdaban}</strong></p>
                            </div>
                            <div className="total-cell" style={{width: '330px'}}>
                                <p className="footer-main"><strong>{tongtonkho}</strong></p>
                            </div>
                            <div className="total-cell" style={{width: '330px'}}>
                                <p className="footer-main"><strong>{Func.FormatNumber(tongtientonkho)}₫</strong></p>
                            </div>
                        </footer>
                    </div>
                </div>


            </div>
        )
    }
}
export default Revenuebyinventory;