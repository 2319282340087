import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Modal from 'react-responsive-modal';
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 

class LichSuNhapKho extends Component {
    constructor(props) {
        super(props);
        this.state = {
          trangThai: '',
          search: '',
          loading: true,
          dateFrom: '',
          dateTo: '',
          website: '',
          khac: '',
          data: [],
          activePage: 0,
          itemsCountPerPage: 0,
          totalItemsCount: 0,
          pageRangeDisplayed: 5,
          page:1,
          nhanthanhtoan:false,
          Loader: false,
          openChuanBi: false, // Modal
          noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
          t_id: '',
          so_luong: '',
        };
        this.handlePageChange = this.handlePageChange.bind(this);
      }

      handlePageChange(pageNumber) {
        this.setState({
          page:pageNumber
        })
        window.scrollTo(0, 0);
      }


      timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
          [name]: value
        });
    
    
      }



      componentWillMount() {
        this.setState({
          trangThai: queryString.parse(window.location.search).trangThai,
          search: queryString.parse(window.location.search).search,
          website: queryString.parse(window.location.search).website,
          dateFrom: queryString.parse(window.location.search).dateFrom,
          dateTo: queryString.parse(window.location.search).dateTo,
          khac: queryString.parse(window.location.search).khac,
          page: queryString.parse(window.location.search).page,
          id: queryString.parse(window.location.search).id,
        })
      }
    
    
    
      
      componentDidUpdate = (prevProps, prevState) => {
    
        const query = {
          trangThai: this.state.trangThai,
          search: this.state.search,
          website: this.state.website,
          dateFrom: this.state.dateFrom,
          dateTo: this.state.dateTo,
          khac: this.state.khac,
          page: this.state.page,
          id: this.state.id,
        };
        const searchString = qs.stringify(query);
        history.push(
          {
            pathname: '/lich-su-nhap-kho',
            search: searchString,
          }
        )
    
        // Get data
        if (prevState.id !== this.state.id ||prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan) {
    
    
          if(prevState.id !== this.state.id ||prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
            this.setState({
              page:1
            })
          }
          this.setState({
            loading: true
          })
          var trangThai = '';
          if (this.state.trangThai) {
            trangThai = this.state.trangThai;
          }
          var search = '';
          if (this.state.search) {
            search = this.state.search;
          }
          var website = '';
          if (this.state.website) {
            website = this.state.website;
          }
          var khac = '';
          if (this.state.khac) {
            khac = this.state.khac;
          }
          var dateFrom = '';
          if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
          }
          var dateTo = '';
          if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
          }
          let token = localStorage.getItem('token');
          axios.get(Config.API_URL + 'lich-su-nhap-kho?ma_don=' + trangThai + '&id=' + this.state.id + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&id=" + this.state.id + "&token=" + token)
            .then(response => {
              this.setState({ 
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
               });
            })
            .catch(function (error) {
              // this.setState({ loading: false });
              console.log(error);
            });
    
        }
        // End Get data
    
    
      }

      componentDidMount = () => {
        this.setState({
          loading: true
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
           website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
         khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.dateFrom) {
           dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = '';
        if (this.state.dateTo) {
          dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }
    
        
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'lich-su-nhap-kho?trangThai=' + trangThai + '&id=' + this.state.id + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
              this.setState({
                  items: response.data.data,
                  loading: false,
                  activePage: response.data.current_page,
                  itemsCountPerPage: response.data.per_page,
                  totalItemsCount: response.data.total,
                  path: response.data.path,
            })
          })
          .catch(function (error) {
            console.log(error);
          });
    
    
        // End Get data
    
    
      }
      render() {

        // Modal
    const { openChuanBi } = this.state;
    // Modal

        return (
            <div id="content">
                
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    <h1 className="ui-title-bar__title">Lịch sử nhập kho {this.state.id}</h1>
                                </div>
                                <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li className="filter-tab-item" data-tab-index={1}>
                                                        <a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab next-tab--is-active">Tất cả lịch sử</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm nội dung" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" >
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                                <th className="image" />
                                                                <th><span>Sản phẩm</span></th>
                                                                <th><span>Nội dung</span></th>
                                                                <th><span>Số lượng</span></th>
                                                                <th><span>Tồn Kho</span></th>
                                                                <th><span>Thời gian</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.items?
                                                                this.state.items.map( (value,key)=>{
                                                                    var html = "<img src="+value.image+" width='400px' />"
                                                                    return <tr>
                                                                    
                                                                    <td>
                                                                    {/* <p data-tip="hello world"><img title={value.title} className="aspect-ratio__content" src={value.image} alt={value.title} /></p> */}
                                                            
                                                                        <a className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" data-tip={html}>
                                                                        
                                                                        <img title={value.title} className="aspect-ratio__content" src={value.image} alt={value.title} />
                                                                        <ReactTooltip html={true} place={'right'}/>
                                                                        </a>
                                                                    </td>
                                                                    <td className="name">
                                                                        <div className="ui-stack ui-stack--wrap">
                                                                            <div className="ui-stack-item">
                                                                                {value.name} - {value.modelid}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="total">
                                                                        <p>{value.noi_dung}</p>
                                                                    </td>

                                                                    <td className="type">
                                                                        <p><b>{
                                                                            value.so_luong >=1 ?
                                                                            <span style={{color: 'blue'}}>{value.so_luong}</span>
                                                                            :
                                                                            <span style={{color: 'red'}}>{value.so_luong}</span>
                                                                            }</b></p>
                                                                    </td>

                                                                    <td className="type">
                                                                        <p><b>{
                                                                            value.stock >=1 ?
                                                                            <span style={{color: 'blue'}}>{value.stock}</span>
                                                                            :
                                                                            <span style={{color: 'red'}}>{value.stock}</span>
                                                                            }</b></p>
                                                                    </td>
                                                                  
                                                                    <td className="type">
                                                                        <p>{value.created_at}</p>
                                                                    </td>
                                                                   
                                                                    

                                                                </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Chuẩn bị */}
           <Modal open={openChuanBi} onClose={this.onCloseModalChuanBi} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                        <div className="modal-content" style={{ 'width': '450px' }}>
                            <div className="ui-modal__header">
                                <div className="ui-modal__header-title">
                                    <h2 className="ui-title" id="ModalTitle">Kiểm kho</h2>
                                </div>
                            </div>
                            <div className="ui-modal__body">
                                <div className="ui-modal__section">
                                    <label class="next-label">Sản phẩm: {this.state.title} / {this.state.modelid} / {this.state.name}</label>
                                    <label class="next-label">Tồn kho: </label>

                                    <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số lượng" name="so_luong" type="text"/>
                                    <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập lý do" name="ly_do" type="text"/>

                                </div>
                            </div>
                            <div className="ui-modal__footer">
                                <div className="ui-modal__footer-actions">

                                <div className="ui-modal__secondary-actions">
                                    <div className="button-group">
                                        <button onClick={() => this.capNhat()} className="ui-button close-modal" type="button">Cập nhật làm tồn kho</button>
                                    </div>
                                </div>


                                <div className="ui-modal__secondary-actions">
                                    <div className="button-group">
                                        <button onClick={() => this.truDi()} className="ui-button close-modal" type="button">Trừ đi</button>
                                    </div>
                                </div>
                                <div className="ui-modal__primary-actions">
                                    <button onClick={() => this.themVao()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Thêm vào</button>
                                </div>

                                </div>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal */}


            </div>

        );
    }
}

export default LichSuNhapKho;