import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';


class Setting extends Component {


    constructor(props) {
        super(props);
        this.state = {
            token_ads: '',
            token_nick_fb:'',
        };
    }


    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        if(localStorage.getItem('token_ads')){
            this.setState({token_ads:localStorage.getItem('token_ads')})
        }
        if(localStorage.getItem('token_nick_fb')){
            this.setState({token_nick_fb:localStorage.getItem('token_nick_fb')})
        }
    }
    

    CapNhat(){
        if(this.state.token_ads){
            localStorage.setItem('token_ads', this.state.token_ads);
            NotificationManager.success('Cập nhật TOKEN thành công', '', 2000, false);
        }else{
            NotificationManager.error('Cập nhật TOKEN không thành công', '', 2000, false);
        }
        
    }
    

    CapNhatToken(){
        if(this.state.token_nick_fb){
            localStorage.setItem('token_nick_fb', this.state.token_nick_fb);
            NotificationManager.success('Cập nhật TOKEN thành công', '', 2000, false);
        }else{
            NotificationManager.error('Cập nhật TOKEN không thành công', '', 2000, false);
        }
        
    }


    render() {
        return (
            <div id="content">
                <NotificationContainer />
                <div className="notification-container notification-container-empty"><span /></div>
                <div context="draftOrder" className="next-ui">
                    <div id="draft-refresh-section">
                        <header className="ui-title-bar-container">
                            <div className="ui-title-bar">
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-orders" /> </svg></span>
                                        <h1 className="ui-title-bar__title">Cài đặt</h1></div>
                                    <div data-define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                        <div className="ui-title-bar__mobile-primary-actions">
                                            <div className="ui-title-bar__actions">
                                                <button className="ui-button ui-button--primary js-btn-loadable js-btn-primary ui-title-bar__action has-loading btn-disabled" id="draft_save" bind-event-click="createDraftOrder()" bind-class="{'disabled' : !isValid()}" type="button" name="button">Lưu đơn hàng nháp</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ui-title-bar__actions-group">
                                    <div className="ui-title-bar__actions">
                                        <button className="ui-button ui-button--primary js-btn-loadable js-btn-primary ui-title-bar__action has-loading btn-disabled" id="draft_save" bind-event-click="createDraftOrder()" bind-class="{'disabled' : !isValid()}" type="button" name="button">Lưu đơn hàng nháp</button>
                                    </div>
                                </div>
                            </div>
                            <div className="collapsible-header">
                                <div className="collapsible-header__heading" />
                            </div>
                        </header>
                        <form id="draftForm">
                            <div className="ui-layout">
                                <div className="ui-layout__sections">
                                    <div className="ui-layout__section ui-layout__section--primary">
                                        <div className="ui-layout__item">
                                            <div className="next-card relative">
                                                <section>
                                                    <div className="next-card__section">
                                                        <div className="wrappable">
                                                            <div className="wrappable__item">
                                                                <div className="next-input-wrapper">
                                                                    <label className="control-label"> Token </label>
                                                                    <textarea onChange={(event) => this.isChange(event)} className="form-control textarea-mobile next-input" cols={20} name="token_nick_fb" placeholder="Nhập Token dạng EAAA" rows={3} style={{ resize: 'none' }} defaultValue={localStorage.getItem('token_nick_fb')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div className="next-card__section next-card__section--bordered">
                                                    <div className="wrappable wrappable--half-spacing wrappable--vertically-centered wrappable--right-aligned">
                                                        <div className="wrappable__item wrappable__item--no-flex">
                                                            <svg className="next-icon next-icon--24 next-icon--blue next-icon--no-nudge">
                                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-credit-card" />
                                                            </svg>
                                                        </div>
                                                        <div className="wrappable__item" />
                                                        <div className="wrappable__item wrappable__item--no-flex">
                                                            <div onClick={()=>this.CapNhatToken()} className="button-group"><a href="javascript: void(0);" className="ui-button btn-save has-loading btn-paid btn-save-draft-order">Cập nhật</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ui-layout">
                                <div className="ui-layout__sections">
                                    <div className="ui-layout__section ui-layout__section--primary">
                                        <div className="ui-layout__item">
                                            <div className="next-card relative">
                                                <section>
                                                    <div className="next-card__section">
                                                        <div className="wrappable">
                                                            <div className="wrappable__item">
                                                                <div className="next-input-wrapper">
                                                                    <label className="control-label"> Token ADS </label>
                                                                    <textarea onChange={(event) => this.isChange(event)} className="form-control textarea-mobile next-input" cols={20} name="token_ads" placeholder="Nhập Token ADS" rows={3} style={{ resize: 'none' }} defaultValue={localStorage.getItem('token_ads')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div className="next-card__section next-card__section--bordered">
                                                    <div className="wrappable wrappable--half-spacing wrappable--vertically-centered wrappable--right-aligned">
                                                        <div className="wrappable__item wrappable__item--no-flex">
                                                            <svg className="next-icon next-icon--24 next-icon--blue next-icon--no-nudge">
                                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-credit-card" />
                                                            </svg>
                                                        </div>
                                                        <div className="wrappable__item" />
                                                        <div className="wrappable__item wrappable__item--no-flex">
                                                            <div onClick={()=>this.CapNhat()} className="button-group"><a href="javascript: void(0);" className="ui-button btn-save has-loading btn-paid btn-save-draft-order">Cập nhật</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

export default Setting;