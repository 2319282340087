import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-responsive-modal';
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 
class DanhSachNhanVien extends Component {
    constructor(props) {
        super(props);
        this.state = {
          trangThai: '',
          search: '',
          loading: true,
          dateFrom: '',
          dateTo: '',
          website: '',
          khac: '',
          data: [],
          activePage: 0,
          itemsCountPerPage: 0,
          totalItemsCount: 0,
          pageRangeDisplayed: 5,
          page:1,
          nhanthanhtoan:false,
          Loader: false,
          openCongTien: false, // Modal
          noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
          t_id: '',
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.checkOne = this.checkOne.bind(this);
      }

      handlePageChange(pageNumber) {
        this.setState({
          page:pageNumber
        })
        window.scrollTo(0, 0);
      }


      timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
          [name]: value
        });
    
    
      }



      componentWillMount() {
        this.setState({
          trangThai: queryString.parse(window.location.search).trangThai,
          search: queryString.parse(window.location.search).search,
          website: queryString.parse(window.location.search).website,
          dateFrom: queryString.parse(window.location.search).dateFrom,
          dateTo: queryString.parse(window.location.search).dateTo,
          khac: queryString.parse(window.location.search).khac,
          page: queryString.parse(window.location.search).page,
        })
      }
    
    
    
      
      componentDidUpdate = (prevProps, prevState) => {
    
        const query = {
          trangThai: this.state.trangThai,
          search: this.state.search,
          website: this.state.website,
          dateFrom: this.state.dateFrom,
          dateTo: this.state.dateTo,
          khac: this.state.khac,
          page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
          {
            pathname: '/danh-sach-nhan-vien',
            search: searchString,
          }
        )
    
        // Get data
        if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan) {
    
    
          if(prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
            this.setState({
              page:1
            })
          }
          this.setState({
            loading: true
          })
          var trangThai = '';
          if (this.state.trangThai) {
            trangThai = this.state.trangThai;
          }
          var search = '';
          if (this.state.search) {
            search = this.state.search;
          }
          var website = '';
          if (this.state.website) {
            website = this.state.website;
          }
          var khac = '';
          if (this.state.khac) {
            khac = this.state.khac;
          }
          var dateFrom = '';
          if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
          }
          var dateTo = '';
          if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
          }
          let token = localStorage.getItem('token');
          axios.get(Config.API_URL + 'danh-sach-nhan-vien?ma_don=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
            .then(response => {
              this.setState({ 
                items: response.data.data,
                loading: false,
                activePage: response.data.current_page,
                itemsCountPerPage: response.data.per_page,
                totalItemsCount: response.data.total,
                path: response.data.path,
               });
            })
            .catch(function (error) {
              // this.setState({ loading: false });
              console.log(error);
            });
    
        }
        // End Get data
    
    
      }

      componentDidMount = () => {
        this.setState({
          loading: true
        })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
           website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
         khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.dateFrom) {
           dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = '';
        if (this.state.dateTo) {
          dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }
    
        
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'danh-sach-nhan-vien?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
              this.setState({
                  items: response.data.data,
                  loading: false,
                  activePage: response.data.current_page,
                  itemsCountPerPage: response.data.per_page,
                  totalItemsCount: response.data.total,
                  path: response.data.path,
            })
          })
          .catch(function (error) {
            console.log(error);
          });
    
    
        // End Get data
    
    
      }

    delProduct(id) {
        var ma_don = '';
        if (this.state.ma_don) {
            var ma_don = this.state.ma_don;
        }
        var ma_giao_dich = '';
        if (this.state.ma_giao_dich) {
            var ma_giao_dich = this.state.ma_giao_dich;
        }
        var date_from = '';
        if (this.state.date_from) {
            var date_from = Moment(this.state.date_from).format('DD-MM-YYYY');
        }
        var date_to = '';
        if (this.state.date_to) {
            var date_to = Moment(this.state.date_to).format('DD-MM-YYYY');
        }
        var trangThai = '';
        if (this.state.trangThai) {
            var trangThai = this.state.trangThai;
        }
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'delItems?masp=' + id + "&token=" + token)
            .then(response => {
                axios.get(Config.API_URL + 'danh-sach-nhan-vien?ma_don=' + ma_don + '&ma_giao_dich=' + ma_giao_dich + '&date_from=' + date_from + '&date_to=' + date_to + '&trangThai=' + trangThai + '&page=' + this.state.page + "&token=" + token)
                    .then(response => {
                        NotificationManager.success('Xóa thành công', '', 2000, false);
                        this.setState({
                            items: response.data.data,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            loading: false,
                            path: response.data.path,
                        });
                    });
            });
    }


    // Cộng tiền
    onOpenModalCongTien = (id) => {

        this.setState({
            openCongTien: true,
            id: id,
        });

    };

    onCloseModalCongTien = () => {
        this.setState({ openCongTien: false });
    };
    // Mở Modal


    CapNhatCongTien() {
        if (this.state.id) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.id = this.state.id;
            order.so_tien = this.state.so_tien;
            order.ly_do = this.state.ly_do;

            if (!this.state.id || !this.state.so_tien || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-cong-tien?token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                            openCongTien: false
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }


    // Trừ tiền

    onOpenModalTruTien = (id) => {

        this.setState({
            openTruTien: true,
            id: id,
        });

    };

    onCloseModalTruTien = () => {
        this.setState({ openTruTien: false });
    };
    // Mở Modal


    CapNhatTruTien() {
        if (this.state.id) {
            var order = {};

            if (localStorage.getItem('ADDRESS')) {
                var note = JSON.parse(localStorage.getItem('ADDRESS')).note
            } else {
                var note = ""
            }
            order.id = this.state.id;
            order.so_tien = this.state.so_tien;
            order.ly_do = this.state.ly_do;

            if (!this.state.id || !this.state.so_tien || !this.state.ly_do) {
                alert('Chưa nhập đủ thông tin')
            } else {
                this.setState({
                    loading: true,
                })
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'cap-nhat-tru-tien?token=' + token, qs.stringify(order))
                    .then(response => {
                        this.setState({
                            items: response.data.data,
                            loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                            openTruTien: false
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("Lỗi")
                    });


            }
        } else {
            alert('Chưa nhập đủ thông tin')
        }
    }


    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    checkOne(id,check) {
        console.log(check);
    
    
        let token = localStorage.getItem('token');
        var trangThai = '';
            if (this.state.trangThai) {
              trangThai = this.state.trangThai;
            }
            var search = '';
            if (this.state.search) {
              search = this.state.search;
            }
            var website = '';
            if (this.state.website) {
               website = this.state.website;
            }
            var khac = '';
            if (this.state.khac) {
             khac = this.state.khac;
            }
            var dateFrom = '';
            if (this.state.dateFrom) {
               dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
            }
            var dateTo = '';
            if (this.state.dateTo) {
              dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
            }
        var order = {};
        order.id = id;
        order.check = check;
        axios.post(Config.API_URL + 'cap-nhat-dang-fanpage?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token, qs.stringify(order))
          .then(response => {
            this.setState({
              items: response.data.data,
              activePage: response.data.current_page,
              itemsCountPerPage: response.data.per_page,
              totalItemsCount: response.data.total,
              loading: false,
              path: response.data.path,
            });
          })
          .catch(function (error) {
            console.log(error);
            alert("Lỗi")
          });
        
        // var giatridachon = [];
        // var checkboxes = document.getElementsByName('name[]');
        // for (var i = 0; i < checkboxes.length; i++) {
        //   if (checkboxes[i].checked == true) {
        //     giatridachon.push(parseInt(checkboxes[i].value));
        //   }
    
        // }
        // //Add vào state
        // this.setState({
        //   giatridachon: giatridachon
        // })
      }


    render() {
        const { openCongTien } = this.state;
        const { openTruTien } = this.state;
        return (
            <div id="content">
                 <NotificationContainer />
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    <h1 className="ui-title-bar__title">Danh sách nhân viên</h1>
                                </div>
                                <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                    <div className="action-bar__item action-bar__item--link-container">
                                    </div>
                                    <div className="ui-title-bar__mobile-primary-actions">
                                        <div className="ui-title-bar__actions">
                                            <Link to="/add-product" className="ui-button ui-button--primary ui-title-bar__action">Thêm sản phẩm</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                                <div className="ui-title-bar__actions">
                                    <Link to="/add-product" className="ui-button ui-button--primary ui-title-bar__action">Thêm sản phẩm</Link>
                                </div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li className="filter-tab-item" data-tab-index={1}>
                                                        <a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab next-tab--is-active">Tất cả sản phẩm</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                                <th><span>Mã</span></th>
                                                                <th><span>Tham gia</span></th>
                                                                <th><span>User</span></th>
                                                                <th><span>Tên</span></th>
                                                                <th><span>Phone</span></th>
                                                                <th><span>Kích hoạt</span></th>
                                                                <th><span>Quản trị</span></th>
                                                                <th><span>NV Sale</span></th>
                                                                <th><span>NV Kho</span></th>
                                                                <th><span>GHTK Phone</span></th>
                                                                <th><span>Số dư</span></th>
                                                                <th><span>Cộng tiền</span></th>
                                                                <th><span>Trừ tiền</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.items?
                                                                this.state.items.map( (value,key)=>{
                                                                    var html = "<img src="+value.image+" width='400px' />"
                                                                    return <tr key={key}>
                                                                    
                                                                    <td className="total">
                                                                        <p>{value.id}</p>
                                                                    </td>
                                                                    <td className="total">
                                                                        <p>{value.created_at}</p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <p>{value.user}</p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <p>{value.name}</p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <p>{value.phone}</p>
                                                                    </td>

                                                                    <td className="total">
                                                                        <input type="checkbox" name="name[]"
                                                                          onClick={(id) => this.checkOne(value.id,value.kich_hoat)}
                                                                          defaultChecked={value.kich_hoat}
                                                                          id={value.id}
                                                                          defaultValue={value.id} />
                                                                    </td>

                                                                    <td className="total">
                                                                        <input type="checkbox" name="name[]"
                                                                          onClick={(id) => this.checkOne(value.id,value.dang_fanpage)}
                                                                          defaultChecked={value.dang_fanpage}
                                                                          id={value.id}
                                                                          defaultValue={value.id} />
                                                                    </td>

                                                                    <td className="total">
                                                                        <input type="checkbox" name="name[]"
                                                                          onClick={(id) => this.checkOne(value.id,value.dang_fanpage)}
                                                                          defaultChecked={value.dang_fanpage}
                                                                          id={value.id}
                                                                          defaultValue={value.id} />
                                                                    </td>

                                                                    <td className="total">
                                                                        <input type="checkbox" name="name[]"
                                                                          onClick={(id) => this.checkOne(value.id,value.dang_fanpage)}
                                                                          defaultChecked={value.dang_fanpage}
                                                                          id={value.id}
                                                                          defaultValue={value.id} />
                                                                    </td>

                                                                    <td className="total">
                                                                            <input defaultValue={value.sdt_cs}></input>
                                                                    </td>

                                                                    <td className="total">
                                                                            <p>{value.so_du ?value.so_du.toLocaleString() + "đ": ""}</p>
                                                                    </td>

                                                                    <td className="total">
                                                                        {
                                                                            this.state.loading?
                                                                            'Loading...'
                                                                            :
                                                                            <a onClick={() => this.onOpenModalCongTien(value.id)} ><label class="badge badge--status-success badge--progress-complete">Cộng tiền</label></a>
                                                                        }
                                                                    
                                                                    </td>

                                                                    <td className="total">
                                                                    <a onClick={() => this.onOpenModalTruTien(value.id)} ><label class="badge badge--status-warning badge--progress-complete">Trừ tiền</label></a>
                                                                    </td>


                                                                </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                 {/* Modal Chuẩn bị */}
                 <Modal open={openCongTien} onClose={this.onCloseModalCongTien} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '450px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">CỘNG TIỀN CHO TÀI KHOẢN {this.state.id}</h2>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">
                            <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số tiền" name="so_tien" type="text" />
                            <br></br>
                                <textarea autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập nội dung giao dịch" name="ly_do" type="text" />
                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                                <div className="ui-modal__primary-actions">
                                    {
                                        this.state.loading?
                                        ""
                                        :
<button onClick={() => this.CapNhatCongTien()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Cập nhật</button>
                                    }
                                    
                                </div>

                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Modal */}





                 {/* Modal Trừ tiền */}
                 <Modal open={openTruTien} onClose={this.onCloseModalTruTien} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '450px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">TRỪ TIỀN TÀI KHOẢN {this.state.id}</h2>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">
                            <input autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số tiền" name="so_tien" type="text" />
                            <br></br>
                                <textarea autoComplete="off" onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập nội dung giao dịch" name="ly_do" type="text" />
                            </div>
                        </div>
                        <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                                <div className="ui-modal__primary-actions">
                                    {
                                        this.state.loading?
                                        ""
                                        :
                                        <button onClick={() => this.CapNhatTruTien()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Cập nhật</button>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Modal */}





            </div>

        );
    }
}

export default DanhSachNhanVien;