import React from 'react';
import DieuHuong from './components/router/DieuHuong';
import Aside from './components/Layout/Aside';
import Content from './components/Layout/Content';
import Symbols from './components/Layout/Symbols';
import QuickSearch from './components/Layout/QuickSearch';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { HOME_URL } from './config';
import Header from './components/Layout/Header';
function App() {

  
  let tokenLocal = localStorage.getItem('token');
  if (tokenLocal === null) {
    return (
      <Router>
        <div>
          <DieuHuong />
        </div>
      </Router>
    )
  }

 
  
  return (
    
    <Router>
      
      <div className="page-wrapper fresh-ui next-ui">
        <div id="flash-message-component" data-tg-refresh-always="true" data-iserror="false" data-message />
        <div className="ui-app-frame" data-tg-refresh="ui-app-frame" id="ui-app-frame">
          <Header />
          <div className="ui-flash-container">
            <div className="ui-flash-wrapper" id="UIFlashWrapper">
              <div className="ui-flash ui-flash--nav-offset" id="UIFlashMessage">
                <p className="ui-flash__message" />
                <div className="ui-flash__close-button">
                  <button className="ui-button ui-button--transparent ui-button--icon-only ui-button-flash-close" aria-label="Close message" type="button" name="button">
                    <svg className="next-icon next-icon--color-white next-icon--size-12">
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-remove">
                        <svg id="next-remove" width="100%" height="100%">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                            <path d="M18.263 16l10.07-10.07c.625-.625.625-1.636 0-2.26s-1.638-.627-2.263 0L16 13.737 5.933 3.667c-.626-.624-1.637-.624-2.262 0s-.624 1.64 0 2.264L13.74 16 3.67 26.07c-.626.625-.626 1.636 0 2.26.312.313.722.47 1.13.47s.82-.157 1.132-.47l10.07-10.068 10.068 10.07c.312.31.722.468 1.13.468s.82-.157 1.132-.47c.626-.625.626-1.636 0-2.26L18.262 16z" />
                          </svg>
                        </svg>
                      </use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <style dangerouslySetInnerHTML={{ __html: "\n          .dropdown-menu2 {\n              position: absolute;\n              top: 100%;\n              right: 0;\n              z-index: 1000;\n              display: none;\n              float: left;\n              min-width: 160px;\n              padding: 5px 0;\n              margin: 2px 0 0;\n              list-style: none;\n              font-size: 13px;\n              text-align: left;\n              background-color: #fff;\n              border: 1px solid #eaeaea;\n              border: 1px solid rgba(0, 0, 0, .1);\n              border-radius: 4px;\n              -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);\n              box-shadow: 0 6px 12px rgba(0, 0, 0, .175);\n              background-clip: padding-box;\n          }\n          \n          .dropdown-menu2.arrow-style:before,\n          .dropdown-menu2.arrow-style:after {\n              border-top: none;\n              border-right: 8px solid transparent;\n              border-left: 8px solid transparent;\n              position: absolute;\n              right: 10px;\n              content: \"\";\n              width: 0;\n              height: 0;\n          }\n          \n          .dropdown-menu2.arrow-style:before {\n              top: -7px;\n              border-bottom: 7px solid #c2c2c2;\n          }\n          \n          .dropdown-menu2.arrow-style:after {\n              top: -6px;\n              border-bottom: 7px solid #fff;\n          }\n          \n          .dropdown-menu2.arrow-style:not(.pull-right):before,\n          .dropdown-menu2.arrow-style:not(.pull-right):after {\n              left: 10px;\n          }\n          \n          .dropdown-menu2.dropdown-notifications.arrow-style:not(.pull-right):before,\n          .dropdown-menu2.dropdown-notifications.arrow-style:not(.pull-right):after {\n              left: auto;\n              right: 42px;\n          }\n      " }} /> */}
          <Aside />
          <main id="AppFrameMain" className="ui-app-frame__main">
            <div className="wrapper" id="wrapper">
              <div id="body" className="page-content clearfix" data-tg-refresh="body">
              <DieuHuong />
              </div>
            </div>
          </main>
        </div>
        <div id="surveys" />
        <div className="ui-app-frame__backdrop" />
       <QuickSearch />
       <div id="sapo-modal-container" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
        </div>
        <div>
          <div className="section-footer">
          </div>
        </div>
        <Symbols />
        <div data-tg-refresh-never>
        </div>
      </div>







    </Router>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
      countCart: state.Cart
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      dispatch1: () => {
          dispatch("actionCreator")
      }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)