import React, { Component } from 'react';
import { storage } from '../../firebase';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import Dropzone from 'react-dropzone'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import * as Config from '../../config';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';
import { removeA, getToken, getBaiViet, dangMotAnh, LamTron } from '../../functions/function';
import DateTimePicker from 'react-datetime-picker';
import { saveAs } from 'file-saver';

const moment = require('moment');
const JSZip = require("jszip");
const JSZipUtils = require('jszip-utils'); 

const axios = require('axios');
const qs = require('qs');
const danhMuc = [
    { value: '1688', label: '1688' },
    { value: 'taobao', label: 'Taobao/Tmall' },
]
class DangFanPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            danhmuc:'',
            pageChon:'',
            // date: new Date(),
            date: moment().add(20, 'minutes').toDate(),
            next_date: moment().add(20, 'minutes').toDate(),
            sheetLoaded: false,
            loading_all: false
        }
    }

    danhMuc = (e) => {
        this.setState({
          danhmuc: e,
        });
      }

      pageChon = (e) => {
        this.setState({
          pageChon: e,
        });
      }

// Hàm

  onChange =  date => (
      this.setState({ date })
    )
  

onChangeOne = (key1,date1) => (
  this.setState({ key1 : date1 })
)

// onChange(date){
//   this.setState({ date })
// }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }
  
   
    dellAll(){
      localStorage.removeItem('LIST_SAN_PHAM')
      var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
                this.setState({
                    CART_LOCAL,
                    loading:false
                })
    }
   

    getFullSanPham() {
        if (this.state.ma_sp) {
          this.setState({loading: true,CART_LOCAL:'', loading_all: true})
            this.state.ma_sp.split(/\r?\n/).map((value, key) => {

              setTimeout(() => {
                this.getItemFromApi(value)
                
                if ((key + 1) >= this.state.ma_sp.split(/\r?\n/).length) {
                  this.setState({ loading_all: false })
                  alert('Get xong sản phẩm')
                }
        
              }, key * 3000);


            })
        }
    }

    getItemFromApi(masp){
        var masp = masp.replace(/\s+/g,"");
        if(this.state.danhmuc.value === '1688'){
            var url = 'https://api.roza.vn/api/1688/get-product-data?id=' + masp ;
        }else{
            var url = 'https://admin.nhimdo.com/apibackend/product/get-product-data?id=' + masp ;
        }
        axios.get(url)
            .then(rstrangthai => {
// console.log(rstrangthai.data.item.itemInfoModel.picsPath);

if(rstrangthai.data && rstrangthai.data.item){


  // var ma_rut_gon = Math.floor(Math.random() * 100000);
                var item = {};
                item.masp = masp;
                item.image = rstrangthai.data.item.item_imgs;
                item.price = rstrangthai.data.item.price;
                item.website = this.state.danhmuc.value;
                item.ma_rut_gon = masp.substr(-6, 6);
                if (localStorage.getItem('LIST_SAN_PHAM')) {
                    var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));

                    if (CART_LOCAL.length > 0) {
                      var checkTrung = false;
                      var time_add = 60;
                     
                      var today = new Date();
                      var timeNow = today.getHours() + ":" + today.getMinutes();
                      console.log('timenow '+timeNow);
                      JSON.parse(localStorage.getItem('LIST_SAN_PHAM')).map((value, key) => {
                        // var ma_rut_gon = Math.floor(Math.random() * 100000)
                          if (JSON.parse(JSON.stringify(JSON.parse(value))).masp === item.masp) {
                              checkTrung = true;
                              var itemnew = {};
                              itemnew.masp = JSON.parse(JSON.stringify(JSON.parse(value))).masp;
                              itemnew.image = JSON.parse(JSON.stringify(JSON.parse(value))).url;
                              itemnew.price = JSON.parse(JSON.stringify(JSON.parse(value))).price;
                              itemnew.time = moment(JSON.parse(JSON.stringify(JSON.parse(value)))).add(key*time_add, 'minutes');
                              itemnew.website = JSON.parse(JSON.stringify(JSON.parse(value))).website;
                              itemnew.ma_rut_gon = JSON.parse(JSON.stringify(JSON.parse(value))).ma_rut_gon;
                              CART_LOCAL[key] = JSON.stringify(itemnew);
                          }
                      })
                      if (checkTrung === false) {
                          CART_LOCAL.unshift(JSON.stringify(item));
                      }
          
                  } else {
                      CART_LOCAL.unshift(JSON.stringify(item));
                  }

                } else {
                    var CART_LOCAL = [];
                }


                localStorage.setItem('LIST_SAN_PHAM', JSON.stringify(CART_LOCAL));
                var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
                this.setState({
                    CART_LOCAL,
                    loading:false
                })
            }


                
            });
            
        // console.log(url);
        
    }

  setTag() {
    if(this.state.tag){
      localStorage.setItem('TAG', this.state.tag);
    }
  }

  getFullContent(masp,event){

    if (localStorage.getItem('LIST_SAN_PHAM')) {
      var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
    } else {
      var CART_LOCAL = [];
    }

    if (CART_LOCAL.length > 0) {
      var time_add = 60;
      JSON.parse(localStorage.getItem('LIST_SAN_PHAM')).map((value, key) => {
        if (JSON.parse(JSON.stringify(JSON.parse(value))).masp === masp) {
          var ma_rut_gon = Math.floor(Math.random() * 100000)
          var itemnew = {};
          itemnew.masp = JSON.parse(JSON.stringify(JSON.parse(value))).masp;
          itemnew.image = JSON.parse(JSON.stringify(JSON.parse(value))).image;
          itemnew.price = JSON.parse(JSON.stringify(JSON.parse(value))).price;
          itemnew.time = JSON.parse(JSON.stringify(JSON.parse(value))).time;
          itemnew.content = event.target.value;
          itemnew.ma_rut_gon = JSON.parse(JSON.stringify(JSON.parse(value))).ma_rut_gon;
          CART_LOCAL[key] = JSON.stringify(itemnew);
        }
      })
    }

    localStorage.setItem('LIST_SAN_PHAM', JSON.stringify(CART_LOCAL));
    var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
    this.setState({
      CART_LOCAL,
      // loading: false
    })
    
  }
  removeItem(key) {
localStorage.removeItem('attached_media'+key);
    var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
    var CART_LOCAL_NEW = CART_LOCAL.filter(function (returnableObjects) {
        return JSON.parse(returnableObjects).masp !== key;
    });
    localStorage.setItem('LIST_SAN_PHAM', JSON.stringify(CART_LOCAL_NEW));
    var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
    this.setState({
        CART_LOCAL
    })
}

removeImage(masp,image){
  if (localStorage.getItem('LIST_SAN_PHAM')) {
    var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
  } else {
    var CART_LOCAL = [];
  }

  if (CART_LOCAL.length > 0) {
    var time_add = 60;
    JSON.parse(localStorage.getItem('LIST_SAN_PHAM')).map((value, key) => {
      if (JSON.parse(JSON.stringify(JSON.parse(value))).masp === masp) {
        var ma_rut_gon = Math.floor(Math.random() * 100000)
        var itemnew = {};
        itemnew.masp = JSON.parse(JSON.stringify(JSON.parse(value))).masp;
        itemnew.image = removeA(JSON.parse(JSON.stringify(JSON.parse(value))).image,image);
        itemnew.price = JSON.parse(JSON.stringify(JSON.parse(value))).price;
        itemnew.time = moment(JSON.parse(JSON.stringify(JSON.parse(value)))).add(key*time_add, 'minutes');
        itemnew.content = JSON.parse(JSON.stringify(JSON.parse(value))).content;
        itemnew.ma_rut_gon = JSON.parse(JSON.stringify(JSON.parse(value))).ma_rut_gon;
        CART_LOCAL[key] = JSON.stringify(itemnew);
      }
    })
  }

  localStorage.setItem('LIST_SAN_PHAM', JSON.stringify(CART_LOCAL));
  var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
  this.setState({
    CART_LOCAL,
    loading: false
  })

}


downZIP(arr_image,masp) {
	var zip = new JSZip();
	var count = 0;
	var zipFilename = masp+".zip";
  
	arr_image.forEach(function (url, i) {
	  // var filename = arr_image[i];
    // filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpsi.imgur.com","");
    var duoi = arr_image[i].split(".").pop(-1);
	  // loading a file and add it in a zip file
	  JSZipUtils.getBinaryContent(url, function (err, data) {
		if (err) {
		  throw err; // or handle the error
		}
		zip.file((i+1)+'.'+duoi, data, { binary: true });
		count++;
		if (count == arr_image.length) {
		  zip.generateAsync({ type: 'blob' }).then(function (content) {
			saveAs(content, zipFilename);
		  });
		}
	  });
	});
  }

  setTokenFull(){
    if(this.state.TOKEN_FULL){
      localStorage.setItem('TOKEN_FULL', this.state.TOKEN_FULL);
      this.getTokenPage(this.state.TOKEN_FULL);
    }else{
      this.setState({
        OPTION_PAGE:[]
      });
    }
  }
  getTokenPage(token) {
    localStorage.removeItem('OPTION_PAGE');
    localStorage.removeItem('LIST_PAGE');
    this.setState({loading_page: true})
    var url = "https://graph.facebook.com/v7.0/me/accounts?fields=id%2Cname%2Caccess_token&limit=100&access_token=" + token;
    axios.get(url)
      .then(response => {
        var OPTION_PAGE = [];
        if(response.data && response.data.data){
          response.data.data.map( (value,key)=>{
            var itemnew = {};
            itemnew.value = value.id;
            itemnew.label = value.name;
            OPTION_PAGE.push(itemnew)
          } )

          localStorage.setItem('OPTION_PAGE', JSON.stringify(OPTION_PAGE));
          localStorage.setItem('LIST_PAGE', JSON.stringify(response.data.data));
          var OPTION_PAGE = JSON.parse(localStorage.getItem('OPTION_PAGE'));
        }
        this.setState({
          OPTION_PAGE,
          list_page: response.data.data,
          loading_page: false,
        });
      })
      .catch(err => { alert("Token đã hết hạn. "+err) })
  }


  changeTimePost(){

    if (localStorage.getItem('LIST_SAN_PHAM')) {
      var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
  } else {
      var CART_LOCAL = [];
  }


  if (CART_LOCAL.length > 0) {
      var checkTrung = false;
      var time_add = 60;
      var today = new Date();
      var timeNow = today.getHours() + ":" + today.getMinutes();
      let timeDate = this.state.date;
      let time_add_post =moment(this.state.date).add(time_add , 'minutes');
      JSON.parse(localStorage.getItem('LIST_SAN_PHAM')).map((value, key) => {
              
              time_add_post =moment(time_add_post).add(time_add , 'minutes');

              if ( (time_add_post.format('HH:mm') >= '23:00' && time_add_post.format('HH:mm') <= '23:59') || (time_add_post.format('HH:mm') >= '00:00' && time_add_post.format('HH:mm') <= '06:00'))
              {
                time_add_post =moment(time_add_post).add(8*60 , 'minutes');
              }
              var ma_rut_gon = Math.floor(Math.random() * 100000)
              checkTrung = true;
              var itemnew = {};
              itemnew.masp = JSON.parse(JSON.stringify(JSON.parse(value))).masp;
              itemnew.image = JSON.parse(JSON.stringify(JSON.parse(value))).image;
              itemnew.price = JSON.parse(JSON.stringify(JSON.parse(value))).price;
              itemnew.website = JSON.parse(JSON.stringify(JSON.parse(value))).website;
              itemnew.content = JSON.parse(JSON.stringify(JSON.parse(value))).content;
              itemnew.time = time_add_post;
              

              // console.log('cu '+this.state.date);
              // this.setState({next_date: moment(this.state.next_date).add(key*time_add, 'minutes').format("MM-DD-YYYY HH:mm:ss") })
              // console.log('moi '+this.state.next_date);
              

              // if( moment(this.state.date).add(key*time_add, 'minutes').format("MM-DD-YYYY HH:mm:ss") > moment(moment(this.state.date).add(key*time_add, 'minutes')).format("MM-DD-YYYY")+' 23:00:00'){
              //   console.log("ahihi "+ moment(this.state.date).add(key*time_add, 'minutes').format("MM-DD-YYYY HH:mm:ss") );
              //   console.log('23hhhhhh '+ moment().format("MM-DD-YYYY")+' 23:00:00'  );
              //   itemnew.time = moment(this.state.date).add(key*time_add + key*8*time_add, 'minutes');
                
              // }else{
              //   itemnew.time = moment(this.state.date).add(key*time_add, 'minutes');
              // }
              
              itemnew.ma_rut_gon = JSON.parse(JSON.stringify(JSON.parse(value))).ma_rut_gon;
              CART_LOCAL[key] = JSON.stringify(itemnew);
      })
  }

  localStorage.setItem('LIST_SAN_PHAM', JSON.stringify(CART_LOCAL));

    var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
    this.setState({
      CART_LOCAL,
      // loading: false
    })
  }

 

  postFanpage(masp,ma_rut_gon,thu_tu) {
    
    // Lấy ra các page để đăng
    if (!this.state.pageChon) {
      alert('Chưa chọn page cần đăng');
    }
    if (this.state.pageChon) {
      this.setState({loading: true})
      // Lặp qua các page đã chọn để lấy thông tin
      this.state.pageChon.map((value, key) => {
        var idp_age = value.value;
        var token_page = getToken(idp_age)[0]['access_token']
        var bai_viet = getBaiViet(masp)[0];

        // localStorage.removeItem('attached_media_'+masp)
        
        JSON.parse(bai_viet).image.map( (value1,key1)=>{{
          var order = {};
          // console.log(value1.indexOf("http"));
          
          if(value1.indexOf("http") !== -1){
            order.url = value1;
          }else{
            order.url = "http:"+value1;
          }
          // order.caption = Math.floor(Math.random() * 10);
          order.caption = '#'+ma_rut_gon;
          order.published = 'false';
          axios.post('https://graph.facebook.com/v2.11/me/photos?access_token=' + token_page, qs.stringify(order))
            .then(response => {

              if (localStorage.getItem('attached_media_'+masp)) {
                // var attached_media = localStorage.getItem('attached_media_'+masp);
                var attached_media = JSON.parse(localStorage.getItem('attached_media_'+masp));
              } else {
                var attached_media = [];
              }


              var itemnew = {};
              itemnew.media_fbid = response.data.id;
              attached_media.push(itemnew);
              localStorage.setItem('attached_media_'+masp, JSON.stringify(attached_media));

              var attached_media = JSON.parse(localStorage.getItem('attached_media_'+masp));
              this.setState({
                attached_media,
                // loading: false
              })

              
              if (attached_media.length > 1 && attached_media.length >= JSON.parse(bai_viet).image.length) {
                // Đã đủ ảnh thì đăng bài
                var full_content = {};
                full_content.attached_media = attached_media;
                // full_content.scheduled_publish_time = '1575504000';
                full_content.scheduled_publish_time = JSON.parse(bai_viet).time;
                // console.log(this.state.date);
                // console.log(JSON.parse(bai_viet).time);


                
                
                
                full_content.published = 'false';
                full_content.unpublished_content_type = 'SCHEDULED';
                var myArray = ['♥️','❤️','💛','💚','💙','💜','🖤','💖','💝','💔','❣️','💕','💞','💓','💗','💘','💟','💌','💋','👄','👗','🌼','🌸','🌺','🏵️','🌻','🌷','🌹','🥀','💐','🌾','🎋','☘','🍀','🍃','🍂','🍁','🌱','🌿','🎍','🌵','🌴','🌳','🌳','🎄','🍄','💫','⭐','🌟','✨','⚡','🔥','💥','☄️','🌞','☀️','🌤️','⛅','🌥️','🌦️','☁️','🌧️','⛈️','🌩️','🌨️','🌈','💧','💦','☂️','☔','🌊','💨','❄','🌬','⛄','☃️'];
                var rand = myArray[Math.floor(Math.random() * myArray.length)]
                var n_rand = rand + rand + rand + rand + rand;
                var content_test = '';
                // var content_test = rand+rand+"MẶC SƯỜN XÁM LỘNG LẪY ĐỂ ĐI CƯỚI, ĐI TIỆC"+rand+rand+"\n#MẪU_MỚI BỘ SƯU TẬP NHỮNG MẪU #ĐI_ĐÁM_CƯỚI SIÊU ĐẸP\n💥Các Mẫu Sườn xám quý phái, sang trọng siêu đẹp\n✅ Nhanh tay để lại #S_Đ_T ngay dưới bài viết này để được nhận Ưu Đãi hấp dẫn ạ!\n---------------------\n✔ Các mẫu được làm từ chất liệu lụa, nhung, ren, voan, cao cấp thượng hải mềm mại, tạo cảm giác thoải mái, dễ chịu.\n✔ Họa tiết sang trọng, quý phái, tạo vẻ tươi mới và dịu dàng\n✔ Thích hợp cho quý bà đi chơi, dự tiệc và vẫn đủ thanh lịch khi diện đến công sở.\n👉Đặt hàng bằng cách để lại #S_ĐT để được tư vấn các chị nhé!\n❗️LƯU Ý: SỐ LƯỢNG CÓ HẠN Ạ!\n-------------------\nSƯỜN XÁM CAO CẤP HẢI QUỲNH\n☎ Sảnh Thương Mại, Tòa nhà Bắc Hà, 15 Tố Hữu, Nam Từ Liêm, Hà Nội.\n☎ SĐT 0586099994\n";

                full_content.message = JSON.parse(bai_viet).content?JSON.parse(bai_viet).content  + '\n' + n_rand + '\n' + localStorage.getItem('TAG') + ' #' + ma_rut_gon :  content_test + n_rand + '\n' + localStorage.getItem('TAG') + ' #' + ma_rut_gon;
                axios.post('https://graph.facebook.com/v2.11/'+idp_age+'/feed?access_token=' + token_page, qs.stringify(full_content))
                  .then(response => {
                    if(JSON.parse(bai_viet).website==='1688'){
                      var gia_ban_cn = (parseInt(JSON.parse(bai_viet).price.split(/[-]+/).pop()) + parseInt(JSON.parse(bai_viet).price.split(/[-]+/)[0]))/2;
                    }else{
                      var gia_ban_cn = parseInt(JSON.parse(bai_viet).price)
                    }
                    var gia_ban_vn = LamTron(gia_ban_cn*3500+(gia_ban_cn*3500)*46/100+(gia_ban_cn*3500)*68/100+90000+90000*26/100).toLocaleString()
                    this.sendSheet(masp, ma_rut_gon, JSON.parse(bai_viet).image[0], gia_ban_vn)
                    // console.log("Đăng thành công");
                    localStorage.removeItem('attached_media_'+masp)
                    NotificationManager.success(' Đăng thành công #'+thu_tu, '', 50000, () => {
                      window.open("https://fb.com/"+response.data.id.split("_")[0] +"/posts/"+response.data.id.split("_")[1]);
                      // alert(response.data.id);
                    });
                    this.setState({loading: false})
                  })
                  .catch(err => { 
                    NotificationManager.warning('Có lỗi xảy ra, hãy đăng lại', '', 5000);
                    this.setState({loading: false});
                    localStorage.removeItem('attached_media_'+masp)
                    // this.postFanpage(masp,ma_rut_gon);
                  });

              }
              
              
              
            })
            .catch(err => { 
              NotificationManager.warning('Có lỗi xảy ra, hãy đăng lại', '', 5000);
              this.setState({loading: false});
              // localStorage.removeItem('attached_media_'+masp)
              // this.postFanpage(masp,ma_rut_gon);
            });
        }} )

      })
      // Hết lặp page
    }


    // Đăng bài
  }



  postAll() {
    this.setState({ loading_all: true })
    this.state.CART_LOCAL.map((value, key) => {

      setTimeout(() => {
        this.postFanpage(JSON.parse(value).masp,JSON.parse(value).ma_rut_gon,(key+1))
        console.log('Đã đăng '+JSON.parse(value).masp);
        
        if ((key + 1) >= this.state.CART_LOCAL.length) {
          this.setState({ loading_all: false })
          alert('Đã đăng xong!')
        }

      }, key * 20000);

      

    })
  }



  sendSheet(id, ma_rut_gon, img, price){

    var data = { 
      "form_config_id": "5dea1f5f04b877515b56a62b",
      "ladipage_id": "5ddf10a4fe9c6b02bd728818",
      "tracking_form": [],
      "form_data": [
        {
          "name": "name",
          "value": id
        },
        {
          "name": "name",
          "value": img
        }, {
          "name": "name",
          "value": ''
        }, {
          "name": "name",
          "value": ma_rut_gon
        },
        {
          "name": "name",
          "value": price
        }
      ],
      "time_zone":7
   }
    axios.post('https://api.forms.ladipage.com/2.0/send-form', (data)) 
    .then((res) => {

        console.log(res.data);

    })
    .catch(function (error) {
        console.log(error);
        NotificationManager.error('Tạo đơn không thành công', '', 2000, false);
        // return state
    });
  }
// End Hàm

componentWillMount() {


  if(localStorage.getItem('LIST_SAN_PHAM')){
    var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
    this.setState({
        CART_LOCAL,
        loading:false
    })
  }
  if (localStorage.getItem('TAG')) {
    var TAG_LOCAL = localStorage.getItem('TAG');
    this.setState({tag:TAG_LOCAL})
  }
  if (localStorage.getItem('TOKEN_FULL')) {
    var TOKEN_FULL = localStorage.getItem('TOKEN_FULL');
    this.setState({TOKEN_FULL:TOKEN_FULL})
    this.getTokenPage(TOKEN_FULL)
  }
 this.changeTimePost();
  

}

    componentDidUpdate(prevProps, prevState) {
       
    //   if(prevState.attached_media !== this.state.attached_media){
    //    console.log("ahihi ");
    //    console.log(this.state.attached_media);
       
    // }
    if(prevState.date !== this.state.date){
      this.changeTimePost()
    }


    }




    render() {
console.log(this.state.loading_all);
        // console.log("arr_sp  "+ this.state.arr_sp);
        return (





<div id="content">
  <div>
<NotificationContainer />
      </div>
  <div className="modal" data-tg-refresh="modal" id="modal_container" style={{display: 'none'}} aria-hidden="true" aria-labelledby="ModalTitle" tabIndex={-1} />
  <div className="modal-bg" data-tg-refresh="modal" id="modal_backdrop" />
  <div id="products-new" className="product-detail page">
    <div className="aviary-modal-bg"><div className="aviary-modal" /></div>
    <div autoComplete="off" data-context="form">
      <header className="ui-title-bar-container">
        <div className="ui-title-bar">
          <div className="ui-title-bar__main-group">
            <div className="ui-title-bar__heading-group">
              <span className="ui-title-bar__icon">
                <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-products" />
                </svg>
              </span>
              <h1 className="ui-title-bar__title">
                ĐĂNG BÀI LÊN FANPAGE
              </h1>
            </div>
          </div>
        </div><div className="collapsible-header"><div className="collapsible-header__heading" /></div>
      </header>
      <div className="ui-layout">
        <div className="ui-layout__sections">
          <div className="ui-layout__section ui-layout__section--primary">
                                    <div className="ui-layout__item">
                                        <section className="ui-card" id="product-form-container">
                                            <div className="ui-card__section">
                                                <div className="ui-type-container">

                                                <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Content">Website</label>
                                                        <Select
                                                        autofocus="autofocus" 
                                                            closeMenuOnSelect={false}
                                                            components={makeAnimated()}
                                                            // isMulti
                                                            onChange={this.danhMuc}
                                                            options={danhMuc}
                                                            placeholder='Chọn website'
                                                        />
                                                    </div>

                                                    {
                                                        !this.state.danhmuc ?
                                                            ""
                                                            :
                                                            <div className="next-input-wrapper">
                                                                <label className="next-label" htmlFor="product-name"> Mã sản phẩm </label>
                                                                <textarea onBlur={(event) => this.getFullSanPham()} onChange={(event) => this.isChange(event)} placeholder="Mỗi mã sản phẩm là 1 dòng" className="next-input" size={30} type="text" name="ma_sp" />
                                                            </div>
                                                    }
                                                                                                        
                                                    
                                                    
                                                                                                        
                                                    
                                                    
                                                </div>
                                            </div>
                                        </section>


                                        <section className="ui-card" id="product-form-container">
                                            <div className="ui-card__section">
                                                <div className="ui-type-container">

                                                <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Content">Token Full</label>
                                                        <input value={this.state.TOKEN_FULL} onBlur={(event) => this.setTokenFull()} onChange={(event) => this.isChange(event)} placeholder="Nhập token" className="next-input" size={30} type="text" name="TOKEN_FULL" />
                                                    </div>

                                                    <Select
                                                            closeMenuOnSelect={false}
                                                            components={makeAnimated()}
                                                            isMulti
                                                            onChange={this.pageChon}
                                                            options={this.state.OPTION_PAGE}
                                                            placeholder='Chọn Fanpage đăng bài'
                                                        />


<DateTimePicker
          onChange={this.onChange}
          value={this.state.date}
        />

                              <div className="next-input-wrapper">
                                <label className="next-label" htmlFor="product-name"> Tag sản phẩm </label>
                                <textarea value={this.state.tag} onBlur={(event) => this.setTag()} onChange={(event) => this.isChange(event)} placeholder="Danh sách các tag #" className="next-input" size={30} type="text" name="tag" />
                              </div>
                                                                                                        
                                                    
                                                    
                                                                                                        
                                                    
                                                    
                                                </div>
                                            </div>
                                        </section>

{/* 
                                        {
                                                                this.state.loading ?
                                                                    <div className="row">
                                                                        <div className="col-md-12 pager">
                                                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    ""

                                                            } */}


                        {
                          this.state.CART_LOCAL ?
                            this.state.CART_LOCAL.map((value, key) => {
                              return <section className="ui-card" id="product-form-container">
                                <div className="ui-card__section">
                                  <div className="ui-type-container">

                                    <div className="next-input-wrapper">
                                      {/* <label className="next-label" htmlFor="Content">{JSON.parse(value).masp}</label> */}


                                      <div className="">
                                        <div className="row">
                                          {
                                            JSON.parse(value).image.map((value1, key1) => {
                                              
                                              return <div className="col-md-1" key={key1}>
                                                <div className="thumbnail " >
                                                  <a href={value1} target="_blank">
                                                    <img src={value1} style={{ width: '100%' }}/>
                                                    </a>
                                                    <div className="caption pull-right">
                                                      <button onClick={ ()=>this.removeImage(JSON.parse(value).masp,value1)} className="badge badge--status-default anbutton">Xóa</button>
                                                    </div>

                                                  
                                                </div>
                                                
                                                
                                              </div>

                                            })
                                          }

          

                                        </div>
                                      </div>


<div className="next-input-wrapper">
<textarea defaultValue ={JSON.parse(value).content} onBlur={(event) => this.getFullContent(JSON.parse(value).masp,event)} onChange={(event) => this.isChange(event)} placeholder="Nội dung bài viết" className="contentfull next-input" rows={5} size={100} type="text" />
</div>
<br></br>

<div className="ui-page-actions__container">
                                        <div className="ui-page-actions__actions ui-page-actions__actions--primary">
                                          
                                              <div className="ui-page-actions__button-group">
                                                {/* <input className="ui-button btn-default quick-view-order-btn-cancel" /> */}

                                                {/* <DateTimePicker style={{marginTop: '.8rem', marginLeft: '.8rem'}}
                                                  onChange={this.onChangeOne}
                                                  value={this.state.key1}
                                                /> */}

                                                <button name="button" type="submit" className="ui-button btn-default quick-view-order-btn-cancel">
                                                  {JSON.parse(value).time?moment(JSON.parse(value).time).format('HH:mm:ss DD-MM-YYYY'):""}
                                                </button>

                                                <a target="_blank" href={JSON.parse(value).website == 'taobao' ? "https://item.taobao.com/item.htm?id=" + JSON.parse(value).masp : "https://detail.1688.com/offer/" + JSON.parse(value).masp + ".html"}>
                                                  <button name="button" type="submit" className="ui-button btn-default quick-view-order-btn-cancel">
                                                    Bài gốc
              </button>
                                                </a>
                                                <button name="button" type="submit" className="ui-button btn-default quick-view-order-btn-cancel" onClick={() => this.downZIP(JSON.parse(value).image, JSON.parse(value).masp)}>
                                                  Tải ảnh
              </button>
                                            {
                                              !this.state.loading ?
                                                <div>
                                                  <button name="button" type="submit" className="ui-button btn-default quick-view-order-btn-cancel" onClick={() => this.removeItem(JSON.parse(value).masp)}>
                                                    Xóa ngay
                                                    </button>

                                                  <button name="button" type="submit" className="ui-button btn-primary quick-view-order-btn-cancel" onClick={() => this.postFanpage(JSON.parse(value).masp, JSON.parse(value).ma_rut_gon, (key + 1))}>Đăng ngay #{key + 1}</button>

                                                </div>

                                                :
                                                <button name="button" type="submit" className="ui-button btn-primary quick-view-order-btn-cancel">
                                                  <i className="fa fa-spinner fa-spin" style={{ fontSize: 12 }} />
                                                </button>

                                            }

</div>
                                        </div>
         
        </div>






                                    </div>

                                  </div>
                                </div>
                              </section>;

                            })
                            :
                            ""
                        }

                        



               </div>
           </div>
          
          </div>
      </div>


      <div className="ui-page-actions">
        <div className="ui-page-actions__container">
          <div className="ui-page-actions__actions ui-page-actions__actions--primary">
            <div className="ui-page-actions__button-group">
              <a className="btn" data-allow-default={1} onClick={ ()=>this.dellAll()} >Xóa toàn bộ</a>
              {
                this.state.loading_all?
                <button name="button" type="submit" className="btn js-btn-primary js-btn-loadable btn-primary has-loading">
                <i className="fa fa-spinner fa-spin" style={{ fontSize: 12 }} />
              </button>
                :
                <button name="button" type="submit" className="btn js-btn-primary js-btn-loadable btn-primary has-loading" onClick={ ()=>this.postAll()}>
                Đăng Tất Cả
              </button>
              }
              
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
  
</div>







        )
    }
}

export default DangFanPage;