// Nối nhiều Reducer riêng lại bằng combine
import { combineReducers } from "redux";
import products from './products';
import Cart from "./CartReducer";
import PickDate from "./PickDate";
const allReducers = combineReducers({
    products: products,
    Cart: Cart,
    PickDate: PickDate,
});

export default allReducers;