import React, { Component } from 'react';
import { storage } from '../../firebase';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import Dropzone from 'react-dropzone'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import * as Config from '../../config';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import $ from 'jquery';
import { removeA, getToken, getBaiViet, dangMotAnh, LamTron } from '../../functions/function';
import DateTimePicker from 'react-datetime-picker';
import { saveAs } from 'file-saver';

const moment = require('moment');
const JSZip = require("jszip");
const JSZipUtils = require('jszip-utils');

const axios = require('axios');
const qs = require('qs');
const danhMuc = [
    { value: '10', label: '10' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '300', label: '300' },
    { value: '500', label: '500' },
    { value: '1000', label: '1000' },
    { value: '2000', label: '2000' },
]
class CopyFanpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            danhmuc: '',
            pageChon: '',
            // date: new Date(),
            date: moment().add(20, 'minutes').toDate(),
            next_date: moment().add(20, 'minutes').toDate(),
            sheetLoaded: false,
            loading_all: false,
            INFO_PAGE_NGUON:'',
        }
    }

    danhMuc = (e) => {
        this.setState({
            danhmuc: e,
        });
    }

    pageChon = (e) => {
        this.setState({
            pageChon: e,
        });
    }

    // Hàm

    onChange = date => (
        this.setState({ date })
    )


    onChangeOne = (key1, date1) => (
        this.setState({ key1: date1 })
    )

    // onChange(date){
    //   this.setState({ date })
    // }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }


    dellAll() {
        localStorage.removeItem('FEED_PAGE_NGUON')
        var CART_LOCAL = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
        this.setState({
            CART_LOCAL,
            loading: false
        })
    }


    getFullSanPham() {
        if (this.state.ma_sp) {
            this.setState({ loading: true, CART_LOCAL: '', loading_all: true })
            this.state.ma_sp.split(/\r?\n/).map((value, key) => {

                setTimeout(() => {
                    this.getFeedPageNguon(value)

                    // if ((key + 1) >= this.state.ma_sp.split(/\r?\n/).length) {
                    //   this.setState({ loading_all: false })
                    //   alert('Get xong sản phẩm')
                    // }

                }, key * 3000);


            })
        }
    }

    getFeedPageNguon(masp) {
        localStorage.removeItem('FEED_PAGE_NGUON');
        var masp = masp.replace(/\s+/g, "");
        var url = 'https://graph.facebook.com/v2.11/' + masp + '?fields=feed.limit(' + this.state.danhmuc.value + '){id,status_type,message},id,name&access_token=' + this.state.TOKEN_FULL;
        axios.get(url)
            .then(rstrangthai => {
                // console.log(rstrangthai.data.feed.data);

                if (rstrangthai.data && rstrangthai.data.feed && rstrangthai.data.feed.data) {
                    
                    var time_add = 60;
                    var CART_LOCAL = [];
                    
                    let time_add_post =moment(this.state.date).add(time_add , 'minutes');
                    rstrangthai.data.feed.data.map((value, key) => {
                        var CONTENT = "";
                        time_add_post = moment(time_add_post).add(time_add, 'minutes');
                        if ((time_add_post.format('HH:mm') >= '23:00' && time_add_post.format('HH:mm') <= '23:59') || (time_add_post.format('HH:mm') >= '00:00' && time_add_post.format('HH:mm') <= '06:00')) {
                            time_add_post = moment(time_add_post).add(8 * time_add, 'minutes');
                        }
                        var itemnew = {};
                        itemnew.id = value.id;
                        itemnew.status_type = value.status_type;

                        if(value.message){
                            var linesmessage = value.message.split("\n");
                            linesmessage.forEach((entry) => {
                                if(entry.includes("Sỉ") || entry.includes("sỉ") || entry.includes("si") || entry.includes("Giá") || entry.includes("giá") || entry.includes("Gia") || entry.includes("gia") || entry.includes("0x")|| entry.includes("1x")|| entry.includes("2x")|| entry.includes("3x")|| entry.includes("4x")|| entry.includes("5x")|| entry.includes("6x")|| entry.includes("7x")|| entry.includes("8x")|| entry.includes("9x")){
    
                                }else{
                                    CONTENT +=  entry+"\n";
                                }
                                
                            });
                        }

                        

                        itemnew.message = CONTENT;
                        // itemnew.message = value.message;
                        itemnew.time = time_add_post;
                        CART_LOCAL[key] = JSON.stringify(itemnew);
                    })

                    localStorage.setItem('FEED_PAGE_NGUON', JSON.stringify(CART_LOCAL));

                    var INFO_PAGE_NGUON = [];
                    var info = {};
                    info.id = rstrangthai.data.id;
                    info.name = rstrangthai.data.name;
                    localStorage.setItem('INFO_PAGE_NGUON', JSON.stringify(info) );


                    var CART_LOCAL = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
                    var INFO_PAGE_NGUON = JSON.parse(localStorage.getItem('INFO_PAGE_NGUON'));
                    this.setState({
                        CART_LOCAL,
                        INFO_PAGE_NGUON,
                        FEED_PAGE_NGUON:CART_LOCAL,
                        loading: false,
                        loading_all: false,
                    })



                }

            });

    }

    setTag() {
        if (this.state.tag) {
            localStorage.setItem('TAG', this.state.tag);
        }
    }



    setTokenFull() {
        if (this.state.TOKEN_FULL) {
            localStorage.setItem('TOKEN_FULL', this.state.TOKEN_FULL);
            this.getTokenPage(this.state.TOKEN_FULL);
        } else {
            this.setState({
                OPTION_PAGE: []
            });
        }
    }

    getTokenPage(token) {
        localStorage.removeItem('OPTION_PAGE');
        localStorage.removeItem('LIST_PAGE');
        this.setState({ loading_page: true })
        var url = "https://graph.facebook.com/v3.0/me/accounts?fields=id%2Cname%2Caccess_token&limit=100&access_token=" + token;
        axios.get(url)
            .then(response => {
                var OPTION_PAGE = [];
                if (response.data && response.data.data) {
                    response.data.data.map((value, key) => {
                        var itemnew = {};
                        itemnew.value = value.id;
                        itemnew.label = value.name;
                        OPTION_PAGE.push(itemnew)
                    })

                    localStorage.setItem('OPTION_PAGE', JSON.stringify(OPTION_PAGE));
                    localStorage.setItem('LIST_PAGE', JSON.stringify(response.data.data));
                    var OPTION_PAGE = JSON.parse(localStorage.getItem('OPTION_PAGE'));
                }
                this.setState({
                    OPTION_PAGE,
                    list_page: response.data.data,
                    loading_page: false,
                });
            })
            .catch(err => { alert("Token đã hết hạn. " + err) })
    }


    changeTimePost() {

        if (localStorage.getItem('FEED_PAGE_NGUON')) {
            var CART_LOCAL = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
        } else {
            var CART_LOCAL = [];
        }


        if (CART_LOCAL.length > 0) {
            var checkTrung = false;
            var time_add = 60;
            let time_add_post = moment(this.state.date).add(time_add, 'minutes');
            JSON.parse(localStorage.getItem('FEED_PAGE_NGUON')).map((value, key) => {
                time_add_post = moment(time_add_post).add(time_add, 'minutes');
                if ((time_add_post.format('HH:mm') >= '23:00' && time_add_post.format('HH:mm') <= '23:59') || (time_add_post.format('HH:mm') >= '00:00' && time_add_post.format('HH:mm') <= '06:00')) {
                    time_add_post = moment(time_add_post).add(8 * time_add, 'minutes');
                }
                checkTrung = true;
                var itemnew = {};
                itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                itemnew.status_type = JSON.parse(JSON.stringify(JSON.parse(value))).status_type;
                itemnew.message = JSON.parse(JSON.stringify(JSON.parse(value))).message;
                itemnew.time = time_add_post;
                CART_LOCAL[key] = JSON.stringify(itemnew);
            })
        }

        localStorage.setItem('FEED_PAGE_NGUON', JSON.stringify(CART_LOCAL));

        var CART_LOCAL = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
        this.setState({
            CART_LOCAL,
            // loading: false
        })
    }



    postFanpage(masp, ma_rut_gon, thu_tu) {

        // Lấy ra các page để đăng
        if (!this.state.pageChon) {
            alert('Chưa chọn page cần đăng');
        }
        if (this.state.pageChon) {
            this.setState({ loading: true })
            // Lặp qua các page đã chọn để lấy thông tin
            this.state.pageChon.map((value, key) => {
                var idp_age = value.value;
                var token_page = getToken(idp_age)[0]['access_token']
                var bai_viet = getBaiViet(masp)[0];

                // localStorage.removeItem('attached_media_'+masp)

                JSON.parse(bai_viet).image.map((value1, key1) => {
                    {
                        var order = {};
                        // console.log(value1.indexOf("http"));

                        if (value1.indexOf("http") !== -1) {
                            order.url = value1;
                        } else {
                            order.url = "http:" + value1;
                        }
                        // order.caption = Math.floor(Math.random() * 10);
                        order.caption = '#' + ma_rut_gon;
                        order.published = 'false';
                        axios.post('https://graph.facebook.com/v2.11/me/photos?access_token=' + token_page, qs.stringify(order))
                            .then(response => {

                                if (localStorage.getItem('attached_media_' + masp)) {
                                    // var attached_media = localStorage.getItem('attached_media_'+masp);
                                    var attached_media = JSON.parse(localStorage.getItem('attached_media_' + masp));
                                } else {
                                    var attached_media = [];
                                }


                                var itemnew = {};
                                itemnew.media_fbid = response.data.id;
                                attached_media.push(itemnew);
                                localStorage.setItem('attached_media_' + masp, JSON.stringify(attached_media));

                                var attached_media = JSON.parse(localStorage.getItem('attached_media_' + masp));
                                this.setState({
                                    attached_media,
                                    // loading: false
                                })


                                if (attached_media.length > 1 && attached_media.length >= JSON.parse(bai_viet).image.length) {
                                    // Đã đủ ảnh thì đăng bài
                                    var full_content = {};
                                    full_content.attached_media = attached_media;
                                    // full_content.scheduled_publish_time = '1575504000';
                                    full_content.scheduled_publish_time = JSON.parse(bai_viet).time;
                                    // console.log(this.state.date);
                                    // console.log(JSON.parse(bai_viet).time);





                                    full_content.published = 'false';
                                    full_content.unpublished_content_type = 'SCHEDULED';
                                    var myArray = ['♥️', '❤️', '💛', '💚', '💙', '💜', '🖤', '💖', '💝', '💔', '❣️', '💕', '💞', '💓', '💗', '💘', '💟', '💌', '💋', '👄', '👗', '🌼', '🌸', '🌺', '🏵️', '🌻', '🌷', '🌹', '🥀', '💐', '🌾', '🎋', '☘', '🍀', '🍃', '🍂', '🍁', '🌱', '🌿', '🎍', '🌵', '🌴', '🌳', '🌳', '🎄', '🍄', '💫', '⭐', '🌟', '✨', '⚡', '🔥', '💥', '☄️', '🌞', '☀️', '🌤️', '⛅', '🌥️', '🌦️', '☁️', '🌧️', '⛈️', '🌩️', '🌨️', '🌈', '💧', '💦', '☂️', '☔', '🌊', '💨', '❄', '🌬', '⛄', '☃️'];
                                    var rand = myArray[Math.floor(Math.random() * myArray.length)]
                                    var n_rand = rand + rand + rand + rand + rand;
                                    var content_test = '';
                                    // var content_test = rand+rand+"MẶC SƯỜN XÁM LỘNG LẪY ĐỂ ĐI CƯỚI, ĐI TIỆC"+rand+rand+"\n#MẪU_MỚI BỘ SƯU TẬP NHỮNG MẪU #ĐI_ĐÁM_CƯỚI SIÊU ĐẸP\n💥Các Mẫu Sườn xám quý phái, sang trọng siêu đẹp\n✅ Nhanh tay để lại #S_Đ_T ngay dưới bài viết này để được nhận Ưu Đãi hấp dẫn ạ!\n---------------------\n✔ Các mẫu được làm từ chất liệu lụa, nhung, ren, voan, cao cấp thượng hải mềm mại, tạo cảm giác thoải mái, dễ chịu.\n✔ Họa tiết sang trọng, quý phái, tạo vẻ tươi mới và dịu dàng\n✔ Thích hợp cho quý bà đi chơi, dự tiệc và vẫn đủ thanh lịch khi diện đến công sở.\n👉Đặt hàng bằng cách để lại #S_ĐT để được tư vấn các chị nhé!\n❗️LƯU Ý: SỐ LƯỢNG CÓ HẠN Ạ!\n-------------------\nSƯỜN XÁM CAO CẤP HẢI QUỲNH\n☎ Sảnh Thương Mại, Tòa nhà Bắc Hà, 15 Tố Hữu, Nam Từ Liêm, Hà Nội.\n☎ SĐT 0586099994\n";

                                    full_content.message = JSON.parse(bai_viet).content ? JSON.parse(bai_viet).content + '\n' + n_rand + '\n' + localStorage.getItem('TAG') + ' #' + ma_rut_gon : content_test + n_rand + '\n' + localStorage.getItem('TAG') + ' #' + ma_rut_gon;
                                    axios.post('https://graph.facebook.com/v2.11/' + idp_age + '/feed?access_token=' + token_page, qs.stringify(full_content))
                                        .then(response => {
                                            if (JSON.parse(bai_viet).website === '1688') {
                                                var gia_ban_cn = (parseInt(JSON.parse(bai_viet).price.split(/[-]+/).pop()) + parseInt(JSON.parse(bai_viet).price.split(/[-]+/)[0])) / 2;
                                            } else {
                                                var gia_ban_cn = parseInt(JSON.parse(bai_viet).price)
                                            }
                                            var gia_ban_vn = LamTron(gia_ban_cn * 3500 + (gia_ban_cn * 3500) * 46 / 100 + (gia_ban_cn * 3500) * 68 / 100 + 90000 + 90000 * 26 / 100).toLocaleString()
                                            this.sendSheet(masp, ma_rut_gon, JSON.parse(bai_viet).image[0], gia_ban_vn)
                                            // console.log("Đăng thành công");
                                            localStorage.removeItem('attached_media_' + masp)
                                            NotificationManager.success(' Đăng thành công #' + thu_tu, '', 50000, () => {
                                                window.open("https://fb.com/" + response.data.id.split("_")[0] + "/posts/" + response.data.id.split("_")[1]);
                                                // alert(response.data.id);
                                            });
                                            this.setState({ loading: false })
                                        })
                                        .catch(err => {
                                            NotificationManager.warning('Có lỗi xảy ra, hãy đăng lại', '', 5000);
                                            this.setState({ loading: false });
                                            localStorage.removeItem('attached_media_' + masp)
                                            // this.postFanpage(masp,ma_rut_gon);
                                        });

                                }



                            })
                            .catch(err => {
                                NotificationManager.warning('Có lỗi xảy ra, hãy đăng lại', '', 5000);
                                this.setState({ loading: false });
                                // localStorage.removeItem('attached_media_'+masp)
                                // this.postFanpage(masp,ma_rut_gon);
                            });
                    }
                })

            })
            // Hết lặp page
        }


        // Đăng bài
    }



    postAll() {
        if (!this.state.pageChon) {
            alert('Chưa chọn page cần đăng');
        }else{
        this.setState({ loading_all: true })
        this.state.FEED_PAGE_NGUON.map((value, key) => {

            setTimeout(() => {

                if( JSON.parse(value).status_type == 'added_photos' ){
                    this.postFanpagePhoto(JSON.parse(value).id,JSON.parse(value).message?JSON.parse(value).message:'',JSON.parse(value).time)
                }

                if( JSON.parse(value).status_type == 'added_video' ){
                    this.postFanpageVideo(JSON.parse(value).id,JSON.parse(value).message?JSON.parse(value).message:'',JSON.parse(value).time)
                }


                if ((key + 1) >= this.state.FEED_PAGE_NGUON.length) {
                    this.setState({ loading_all: false })
                    alert('Đã đăng xong!')
                }

            }, key * 30000);



        })
    }
}

    postFanpagePhoto(id,message,time) {
        console.log('Đăng photo');

        // Lấy ra các page để đăng
        if (!this.state.pageChon) {
            alert('Chưa chọn page cần đăng');
        }
        if (this.state.pageChon) {
            this.setState({ loading: true })
            // Lặp qua các page đã chọn để lấy thông tin
            this.state.pageChon.map((value, key) => {
                var idp_age = value.value;
                var token_page = getToken(idp_age)[0]['access_token']

                var url = "https://graph.facebook.com/v2.11/"+id+"?fields=full_picture,attachments,status_type&access_token=" + this.state.TOKEN_FULL;
                axios.get(url)
                    .then(response => {
                        if (response.data && response.data.attachments && response.data.attachments.data && response.data.attachments.data[0] ) {
                            if(response.data.attachments.data[0].media){ // 1 hình ảnh
                                this.postFanpageOnePhoto(idp_age,token_page,response.data.full_picture,message,time)
                            }
                            if(response.data.attachments.data[0].subattachments){ // Album ảnh
                                this.postFanpageAlbum(id,idp_age,token_page,response.data.attachments.data[0].subattachments.data,message,time)
                            }
                        }
                        // this.setState({
                        //     OPTION_PAGE,
                        //     list_page: response.data.data,
                        //     loading_page: false,
                        // });
                    })
                    .catch(err => { alert("Token đã hết hạn. " + err) })


                

            })
            // Hết lặp page
        }


        // Đăng bài

    }

    postFanpageOnePhoto(idp_age,token_page,full_picture,message,time){

        var full_content = {};
        full_content.url = full_picture;
        full_content.scheduled_publish_time = moment(time).unix();
        full_content.published = 'false';
        full_content.unpublished_content_type = 'SCHEDULED';
        var myArray = ['♥️', '❤️', '💛', '💚', '💙', '💜', '🖤', '💖', '💝', '💔', '❣️', '💕', '💞', '💓', '💗', '💘', '💟', '💌', '🌼', '🌸', '🌺', '🏵️', '🌻', '🌷', '🌹', '🥀', '💐', '🌾', '🎋', '☘', '🍀', '🍃', '🍂', '🍁', '🌱', '🌿', '🎍', '🌵', '🌴', '🌳', '🌳', '🎄', '🍄', '💫', '⭐', '🌟', '✨', '⚡', '🔥', '💥', '☄️', '🌞', '☀️', '🌤️', '⛅', '🌥️', '🌦️', '☁️', '🌧️', '⛈️', '🌩️', '🌨️', '🌈', '💧', '💦', '☂️', '☔', '🌊', '💨', '❄', '🌬', '⛄', '☃️'];
        var rand = myArray[Math.floor(Math.random() * myArray.length)]
        var n_rand = rand + rand + rand + rand + rand;
        full_content.message = message + '\n' + n_rand;

        axios.post('https://graph.facebook.com/v7.0/' + idp_age + '/photos?access_token=' + token_page, qs.stringify(full_content))
            .then(response => {
                console.log("Đăng thành công");
                this.setState({ loading: false })
            })
            .catch(err => {
                NotificationManager.warning('Có lỗi xảy ra, hãy đăng lại', '', 5000);
                this.setState({ loading: false });
            });
        
        
    }

    postFanpageAlbum(id,idp_age, token_page, full_picture, message, time) {
        if (this.state.pageChon) {
            this.setState({ loading: true })
// console.log(full_picture.length + 'length');


                full_picture.map((value1, key1) => {

                    setTimeout(() => {


                       
                        

                    var order = {};
                    order.url = value1.media.image.src;
                    order.caption = message;
                    order.published = 'false';



                    

                        axios.post('https://graph.facebook.com/v2.11/me/photos?access_token=' + token_page, qs.stringify(order))
                        .then(response => {

                            

                            if (localStorage.getItem('attached_media_' + id)) {
                                var attached_media = JSON.parse(localStorage.getItem('attached_media_' + id));
                            } else {
                                var attached_media = [];
                            }


                            if(value1.type == 'photo'){
                                var itemnew = {};
                                itemnew.media_fbid = response.data.id;
                                attached_media.push(itemnew);
                                localStorage.setItem('attached_media_' + id, JSON.stringify(attached_media));
                            }

                            

                            var attached_media = JSON.parse(localStorage.getItem('attached_media_' + id));
                            this.setState({
                                attached_media,
                            })


                            // if (attached_media.length > 1 && attached_media.length >= full_picture.length) {
                            if (key1+1 >= full_picture.length) {
                                // Đã đủ ảnh thì đăng bài
                                var full_content = {};
                                full_content.attached_media = attached_media;
                                full_content.scheduled_publish_time = time;
                                full_content.published = 'false';
                                full_content.unpublished_content_type = 'SCHEDULED';
                                var myArray = ['♥️', '❤️', '💛', '💚', '💙', '💜', '🖤', '💖', '💝', '💔', '❣️', '💕', '💞', '💓', '💗', '💘', '💟', '💌', '💋', '👄', '👗', '🌼', '🌸', '🌺', '🏵️', '🌻', '🌷', '🌹', '🥀', '💐', '🌾', '🎋', '☘', '🍀', '🍃', '🍂', '🍁', '🌱', '🌿', '🎍', '🌵', '🌴', '🌳', '🌳', '🎄', '🍄', '💫', '⭐', '🌟', '✨', '⚡', '🔥', '💥', '☄️', '🌞', '☀️', '🌤️', '⛅', '🌥️', '🌦️', '☁️', '🌧️', '⛈️', '🌩️', '🌨️', '🌈', '💧', '💦', '☂️', '☔', '🌊', '💨', '❄', '🌬', '⛄', '☃️'];
                                var rand = myArray[Math.floor(Math.random() * myArray.length)]
                                var n_rand = rand + rand + rand + rand + rand;

                                full_content.message = message + '\n' + n_rand;
                                axios.post('https://graph.facebook.com/v2.11/' + idp_age + '/feed?access_token=' + token_page, qs.stringify(full_content))
                                    .then(response => {
                                        localStorage.removeItem('attached_media_' + id)
                                        NotificationManager.success(' Đăng thành công #', '', 50000, () => {
                                            window.open("https://fb.com/" + response.data.id.split("_")[0] + "/posts/" + response.data.id.split("_")[1]);
                                        });
                                        this.setState({ loading: false })
                                    })
                                    .catch(err => {
                                        NotificationManager.warning('Có lỗi xảy ra, hãy đăng lại', '', 5000);
                                        this.setState({ loading: false });
                                        localStorage.removeItem('attached_media_' + id)
                                    });

                            }



                        })
                        .catch(err => {
                            // NotificationManager.warning('Có lỗi xảy ra, hãy đăng lại', '', 5000);
                            this.setState({ loading: false });
                        });
    
                    

                    







                    }, key1 * 1000);



                })
           

            

        }
    }

    postFanpageVideo(id, message, time){


        if (this.state.pageChon) {
            this.setState({ loading: true })
            // Lặp qua các page đã chọn để lấy thông tin
            this.state.pageChon.map((value, key) => {
                var idp_age = value.value;
                var token_page = getToken(idp_age)[0]['access_token'];

                var url = 'https://graph.facebook.com/v2.11/'+id+'?fields=full_picture,attachments,status_type&access_token='+token_page;
                axios.get(url)
                    .then(response => {
                        if (response.data && response.data.attachments && response.data.attachments.data && response.data.attachments.data[0] ) {
                            if(response.data.attachments.data[0].media.source){ // Link video

                                var myArray = ['♥️', '❤️', '💛', '💚', '💙', '💜', '🖤', '💖', '💝', '💔', '❣️', '💕', '💞', '💓', '💗', '💘', '💟', '💌', '💋', '👄', '👗', '🌼', '🌸', '🌺', '🏵️', '🌻', '🌷', '🌹', '🥀', '💐', '🌾', '🎋', '☘', '🍀', '🍃', '🍂', '🍁', '🌱', '🌿', '🎍', '🌵', '🌴', '🌳', '🌳', '🎄', '🍄', '💫', '⭐', '🌟', '✨', '⚡', '🔥', '💥', '☄️', '🌞', '☀️', '🌤️', '⛅', '🌥️', '🌦️', '☁️', '🌧️', '⛈️', '🌩️', '🌨️', '🌈', '💧', '💦', '☂️', '☔', '🌊', '💨', '❄', '🌬', '⛄', '☃️'];
                                var rand = myArray[Math.floor(Math.random() * myArray.length)]
                                // Đăng video
                                axios.post('https://graph-video.facebook.com/' + idp_age + '/videos',
                                    qs.stringify({
                                        access_token: token_page,
                                        title: response.data.attachments.data[0].title,
                                        file_url: response.data.attachments.data[0].media.source,
                                        published: 'false',
                                        description: message+rand,
                                        unpublished_content_type: 'SCHEDULED',
                                        scheduled_publish_time: moment(time).unix(),
                                    }), {
                                    headers: {
                                        "Content-Type": "application/x-www-form-urlencoded"
                                    }
                                }).then(function (response) {
                                    console.log(response);
                                });
                                // Hết đăng video
                                
                            }
                        }
                    })
                    .catch(err => { alert("Token đã hết hạn. " + err) })
                   

            })
        }

        

        console.log('Đăng video');
        
    }


  // End Hàm

    componentWillMount() {

        if (localStorage.getItem('INFO_PAGE_NGUON')) {
            var INFO_PAGE_NGUON = JSON.parse(localStorage.getItem('INFO_PAGE_NGUON'));
            this.setState({
                INFO_PAGE_NGUON,
                loading: false
            })
        }

        if (localStorage.getItem('FEED_PAGE_NGUON')) {
            var FEED_PAGE_NGUON = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
            this.setState({
                FEED_PAGE_NGUON,
                loading: false
            })
        }

        if (localStorage.getItem('LIST_SAN_PHAM')) {
            var CART_LOCAL = JSON.parse(localStorage.getItem('LIST_SAN_PHAM'));
            this.setState({
                CART_LOCAL,
                loading: false
            })
        }
        if (localStorage.getItem('TAG')) {
            var TAG_LOCAL = localStorage.getItem('TAG');
            this.setState({ tag: TAG_LOCAL })
        }
        if (localStorage.getItem('TOKEN_FULL')) {
            var TOKEN_FULL = localStorage.getItem('TOKEN_FULL');
            this.setState({ TOKEN_FULL: TOKEN_FULL })
            this.getTokenPage(TOKEN_FULL)
        }
        this.changeTimePost();


    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.date !== this.state.date) {
            this.changeTimePost()
        }

    }


    getFullContent(id,event){

        if (localStorage.getItem('FEED_PAGE_NGUON')) {
          var CART_LOCAL = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
        } else {
          var CART_LOCAL = [];
        }
    
        if (CART_LOCAL.length > 0) {
          var time_add = 60;
          JSON.parse(localStorage.getItem('FEED_PAGE_NGUON')).map((value, key) => {
            if (JSON.parse(JSON.stringify(JSON.parse(value))).id === id) {
              var ma_rut_gon = Math.floor(Math.random() * 100000)
              var itemnew = {};
              itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
              itemnew.status_type = JSON.parse(JSON.stringify(JSON.parse(value))).status_type;
              itemnew.message = event.target.value;
              itemnew.time = JSON.parse(JSON.stringify(JSON.parse(value))).time;
              CART_LOCAL[key] = JSON.stringify(itemnew);
            }
          })
        }
    
        localStorage.setItem('FEED_PAGE_NGUON', JSON.stringify(CART_LOCAL));
        var CART_LOCAL = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
        this.setState({
          CART_LOCAL,
          // loading: false
        })
        
      }


      removeItem(key) {
        localStorage.removeItem('attached_media'+key);
            var CART_LOCAL = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
            var CART_LOCAL_NEW = CART_LOCAL.filter(function (returnableObjects) {
                return JSON.parse(returnableObjects).id !== key;
            });
            localStorage.setItem('FEED_PAGE_NGUON', JSON.stringify(CART_LOCAL_NEW));
            var CART_LOCAL = JSON.parse(localStorage.getItem('FEED_PAGE_NGUON'));
            this.setState({
                CART_LOCAL
            })
        }

    render() {
        console.log(this.state.loading_all);
        // console.log("arr_sp  "+ this.state.arr_sp);
        return (





            <div id="content">
                <div>
                    <NotificationContainer />
                </div>
                <div className="modal" data-tg-refresh="modal" id="modal_container" style={{ display: 'none' }} aria-hidden="true" aria-labelledby="ModalTitle" tabIndex={-1} />
                <div className="modal-bg" data-tg-refresh="modal" id="modal_backdrop" />
                <div id="products-new" className="product-detail page">
                    <div className="aviary-modal-bg"><div className="aviary-modal" /></div>
                    <div autoComplete="off" data-context="form">
                        <header className="ui-title-bar-container">
                            <div className="ui-title-bar">
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group">
                                        <span className="ui-title-bar__icon">
                                            <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-products" />
                                            </svg>
                                        </span>
                                        <h1 className="ui-title-bar__title">
                                            ĐĂNG BÀI LÊN FANPAGE
              </h1>
                                    </div>
                                </div>
                            </div><div className="collapsible-header"><div className="collapsible-header__heading" /></div>
                        </header>
                        <div className="ui-layout">
                            <div className="ui-layout__sections">
                                <div className="ui-layout__section ui-layout__section--primary">
                                    <div className="ui-layout__item">
                                        <section className="ui-card" id="product-form-container">
                                            <div className="ui-card__section">
                                                <div className="ui-type-container">

                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Content">Số lượng bài muốn Copy</label>
                                                        <Select
                                                            autofocus="autofocus"
                                                            closeMenuOnSelect={false}
                                                            components={makeAnimated()}
                                                            // isMulti
                                                            onChange={this.danhMuc}
                                                            options={danhMuc}
                                                            placeholder='Chọn số lượng'
                                                        />
                                                    </div>

                                                    {
                                                        !this.state.danhmuc ?
                                                            ""
                                                            :
                                                            <div className="next-input-wrapper">
                                                                <label className="next-label" htmlFor="product-name"> Fanpage Nguồn </label>
                                                                <input onBlur={(event) => this.getFullSanPham()} onChange={(event) => this.isChange(event)} placeholder="Mỗi mã sản phẩm là 1 dòng" className="next-input" size={30} type="text" name="ma_sp" />
                                                            </div>
                                                    }






                                                </div>
                                            </div>
                                        </section>


                                        <section className="ui-card" id="product-form-container">
                                            <div className="ui-card__section">
                                                <div className="ui-type-container">

                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="Content">Token Full</label>
                                                        <input value={this.state.TOKEN_FULL} onBlur={(event) => this.setTokenFull()} onChange={(event) => this.isChange(event)} placeholder="Nhập token" className="next-input" size={30} type="text" name="TOKEN_FULL" />
                                                    </div>

                                                    <div className="next-input-wrapper">
                                                <label className="next-label" htmlFor="Content">Page Nguồn: <a href={"http://fb.com/"+this.state.INFO_PAGE_NGUON?this.state.INFO_PAGE_NGUON.id:""} target="_blank">{this.state.INFO_PAGE_NGUON?this.state.INFO_PAGE_NGUON.name:""}</a></label>
                                                <label className="next-label" htmlFor="Content">Số bài: {this.state.FEED_PAGE_NGUON?this.state.FEED_PAGE_NGUON.length:""}</label>
                                                    </div>

                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={makeAnimated()}
                                                        isMulti
                                                        onChange={this.pageChon}
                                                        options={this.state.OPTION_PAGE}
                                                        placeholder='Chọn Fanpage đăng bài'
                                                    />
 

                                                    <DateTimePicker
                                                        onChange={this.onChange}
                                                        value={this.state.date}
                                                    />

                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="product-name"> Tag sản phẩm </label>
                                                        <textarea value={this.state.tag} onBlur={(event) => this.setTag()} onChange={(event) => this.isChange(event)} placeholder="Danh sách các tag #" className="next-input" size={30} type="text" name="tag" />
                                                    </div>






                                                </div>
                                            </div>
                                        </section>




                                        {
                          this.state.CART_LOCAL ?
                            this.state.CART_LOCAL.map((value, key) => {


                              return  <section className="ui-card" id="product-form-container">
                                            <div className="ui-card__section">
                                                <div className="ui-type-container">
                                                    <div className="next-input-wrapper">
                            <p>#<a href={"http://fb.com/"+JSON.parse(value).id} target="_blank">{JSON.parse(value).id}</a> / {JSON.parse(value).status_type}</p>
                            <br></br>
                                                        
                                                        <div className="next-input-wrapper">
                                                        <textarea defaultValue ={JSON.parse(value).message} onBlur={(event) => this.getFullContent(JSON.parse(value).id,event)} onChange={(event) => this.isChange(event)} placeholder="Nội dung bài viết" className="contentfull next-input" rows={5} size={100} type="text" />
                                                            </div>
                                                        <br />
                                                        <div className="ui-page-actions__container">
                                                            <div className="ui-page-actions__actions ui-page-actions__actions--primary">
                                                                <div className="ui-page-actions__button-group">
                                                                    <button name="button" type="submit" className="ui-button btn-default quick-view-order-btn-cancel">
                                                                    {JSON.parse(value).time?moment(JSON.parse(value).time).format('HH:mm:ss DD-MM-YYYY'):""}
                                                                    </button>
                                                                    {/* <a target="_blank" href="https://detail.1688.com/offer/617468921117.html"><button name="button" type="submit" className="ui-button btn-default quick-view-order-btn-cancel">Bài gốc</button></a> */}
                                                                    {/* <button name="button" type="submit" className="ui-button btn-default quick-view-order-btn-cancel">Tải ảnh</button> */}
                                                                    <div>
                                                                        <button name="button" type="submit" className="ui-button btn-default quick-view-order-btn-cancel" onClick={() => this.removeItem(JSON.parse(value).id)}>Xóa ngay</button>
                            <button name="button" type="submit" className="ui-button btn-primary quick-view-order-btn-cancel">Đăng ngay #{key+1}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
})
:
""
}




                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="ui-page-actions">
                            <div className="ui-page-actions__container">
                                <div className="ui-page-actions__actions ui-page-actions__actions--primary">
                                    <div className="ui-page-actions__button-group">
                                        <a className="btn" data-allow-default={1} onClick={() => this.dellAll()} >Xóa toàn bộ</a>
                                        {
                                            this.state.loading_all ?
                                                <button name="button" type="submit" className="btn js-btn-primary js-btn-loadable btn-primary has-loading">
                                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 12 }} />
                                                </button>
                                                :
                                                <button name="button" type="submit" className="btn js-btn-primary js-btn-loadable btn-primary has-loading" onClick={() => this.postAll()}>
                                                    Đăng Tất Cả
              </button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>







        )
    }
}

export default CopyFanpage;