import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import * as Config from '../../config.js';
import * as Func from '../../functions/function.js';
import "react-datepicker/dist/react-datepicker.css"; 
import ReactChartkick, { LineChart, PieChart } from 'react-chartkick' 
import Chart from 'chart.js'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
ReactChartkick.addAdapter(Chart)
const axios = require('axios');
const qs = require('qs');
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        thangChon: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
   
}


handleChange(date) {
    this.setState({
      thangChon: date
    });
    let token = localStorage.getItem('token');
    axios.get(Config.API_URL + 'chart?month=' + Func.formatDate(date) + '&token=' + token)
        .then(thongke => {
            this.setState({
                thongke: JSON.parse(JSON.stringify(thongke.data)),
            });
        });
  }
componentDidMount() {
    let token = localStorage.getItem('token');
    axios.get(Config.API_URL + 'chart?month=' + Func.formatDate(this.state.thangChon) + '&token=' + token)
        .then(thongke => {
            this.setState({
                thongke: JSON.parse(JSON.stringify(thongke.data)),
            });
        });
}



    render() {
      if(this.state.thongke){
        console.log("this.state.thongke "+this.state.thongke.chart);
      }

      if(localStorage.getItem('info') && JSON.parse(localStorage.getItem('info')).kich_hoat == '1'){
      
        return (
            <div id="content">
  <div id="dashboard" className="container-fluid-md">
    <div className="modal" data-tg-refresh="modal" id="modal_container" style={{display: 'none'}} aria-hidden="true" aria-labelledby="ModalTitle" tabIndex={-1} />
    <div className="modal-bg" data-tg-refresh="modal" id="modal_backdrop" />
    <div>
      <div className="new-dashboard">
        {/* <header className="ui-title-bar-container ui-title-bar-container--full-width" style={{marginTop: 15}}>
          <div className="ui-title-bar" style={{minHeight: 0}}>
            <div className="ui-title-bar__main-group">
              <div className="ui-title-bar__heading-group">
                <h1 className="ui-title-bar__title">Xin chào Đặng An</h1>
                <button className="cd-btn ui-button btn-show-summary" type="button">
                  <svg className="next-icon next-icon--size-16">
                    <use xlinkHref="#icon-report" xmlnsXlink="http://www.w3.org/1999/xlink" />
                  </svg>&nbsp;
                  Thông tin báo cáo
                </button>
              </div>
            </div>
          </div>
          <div className="collapsible-header">
            <div className="collapsible-header__heading" />
          </div>
        </header> */}
        <div className="reports-dashboard-new">
          <div className="ui-layout ui-layout--full-width ui-layout__dashboard">
            <div className="ui-layout__sections">
              <div className="ui-layout__section">
                <div className="ui-layout__item">
                  <div className="cd-panel from-right">
                    <header className="cd-panel-header">
                      <h1>Báo cáo</h1>
                    </header>
                    <div className="cd-panel-container">
                      <div className="cd-panel-content">
                        <section className="ui-card ui-card-report-dashboard">
                          <div className="reports-dashboard clearfix">
                            {/* <div className="report-dashboard-left"> */}
                            <div className="">
                              <div className="area-graph">
                                <div className="report-graph">
                                  <div className="page__sidebar-background header-right-reports" />
                                  <div className="page__sidebar">
                                    <div className="next-card next-card--stacked next-card--home-channel-filters clearfix" >
                                      <div>
                                        <section className="next-card next-card--stacked" >
                                          <section className="next-card__section">
                                            <div className="home-graph__wrapper">
                                              <div className="report-caption">
                                                <h2 className="report-caption-h2" style={{float: 'left', marginRight: 20}}>
                                                  Doanh thu
                                                </h2>
                                                <div className="ui-popover__container ui-popover__container--full-width btn-fillter">
                                                <DatePicker
                                                    locale="vi-VN"
                                                    selected={this.state.thangChon}
                                                    onChange={this.handleChange}
                                                    dateFormat="MM-yyyy"
                                                    showMonthYearPicker
                                                    showFullMonthYearPicker
                                                    type="text"
                                                    className="form-control datepicker chosen-font"
                                                    autoComplete="off"
                                                />
                                                </div>
                                              </div>
                                              <div context="sales" className="panel-body">
                                                <div className="home-graph home-graph--sales">
                                                  {
                                                    this.state.thongke ?
                                                      <LineChart donut={true} thousands="." decimal="," messages={{ empty: "Không đủ dữ liệu thống kê" }} ytitle={"SỐ ĐƠN HÀNG " + Func.formatDate(this.state.thangChon)} discrete={true}
                                                        data={this.state.thongke ? this.state.thongke.chart : ""} />
                                                      :
                                                      ""
                                                  }
                                                 </div>
                                                <div className="next-grid next-grid--no-padding next-grid--aligned-to-baseline next-grid--space-between">
                                                  <div className="next-grid__cell next-grid__cell--no-flex report-total-price">
                                                    <p><span className="dot-total-price" />Đơn mới <span className="type--number type--number--large" id="order-total-price" style={{fontWeight: 500, color: '#7cb5ec'}}>{this.state.thongke ? this.state.thongke.da_tao.toLocaleString() : ""}</span></p>
                                                  </div>
                                                  <div className="next-grid__cell next-grid__cell--no-flex report-total-price">
                                                    <p><span className="dot-total-price" />Thành công <span className="type--number type--number--large" id="order-total-price" style={{fontWeight: 500, color: '#7cb5ec'}}>{this.state.thongke ? this.state.thongke.da_gui : ""}</span></p>
                                                  </div>
                                                  <div className="next-grid__cell next-grid__cell--no-flex report-total-price">
                                                    <p><span className="dot-total-price" />Hoàn hàng <span className="type--number type--number--large" id="order-total-price" style={{fontWeight: 500, color: '#7cb5ec'}}>{this.state.thongke ? this.state.thongke.hoan_hang : ""} ( chiếm {this.state.thongke?Func.lam_tron(this.state.thongke.hoan_hang/(this.state.thongke.da_gui+this.state.thongke.hoan_hang)*100):""}% )</span></p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </section>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="report-dashboard-right">
                              <div className="area-data">
                                <div className="next-grid report-caption">
                                  <h2 className="report-caption-h2">Sản phẩm bán chạy</h2>
                                </div>
                                <div className="panel-body">
                                  <section className="next-card__section top-product-sales">
                                    <div data-define="{topProducts: new Bizweb.HomeTopProductsReport(this,{channelId: 'all',channelName: '',homeSidebar: homeSidebar})}" data-context="topProducts">
                                      <div className="home-top-sales">
                                        <div className="home-top-sales__item">
                                          <div className="home-top-sales__number">01</div>
                                          <div className="home-top-sales__image" style={{backgroundImage: 'url(//bizweb.dktcdn.net/assets/admin/images/no-image-placeholder.png)'}}>
                                            <div className="default-image default-image--product home-image-overlay" style={{backgroundImage: 'url("/admin/services/GetImageProduct?productId=14375391&variant=compact")', backgroundSize: 'cover'}}>
                                            </div>
                                          </div>
                                          <div className="home-top-sales__name">
                                            <p className="home-top-sales__content">
                                              <a href="/admin/products/14375391" target="_blank" rel="noreferrer noopener">Gấu bông Teddy cute</a>
                                            </p>
                                            <p className="home-top-sales__content text-emphasis-subdued">
                                              4 sản phẩm
                                            </p>
                                          </div>
                                        </div>
                                        <div className="home-top-sales__item">
                                          <div className="home-top-sales__number">02</div>
                                          <div className="home-top-sales__image" style={{backgroundImage: 'url(//bizweb.dktcdn.net/assets/admin/images/no-image-placeholder.png)'}}>
                                            <div className="default-image default-image--product home-image-overlay" style={{backgroundImage: 'url("/admin/services/GetImageProduct?productId=14375390&variant=compact")', backgroundSize: 'cover'}}>
                                            </div>
                                          </div>
                                          <div className="home-top-sales__name">
                                            <p className="home-top-sales__content">
                                              <a href="/admin/products/14375390" target="_blank" rel="noreferrer noopener">Nước hoa DG</a>
                                            </p>
                                            <p className="home-top-sales__content text-emphasis-subdued">
                                              1 sản phẩm
                                            </p>
                                          </div>
                                        </div>
                                        <div className="home-top-sales__item">
                                          <div className="home-top-sales__number">03</div>
                                          <div className="home-top-sales__image" style={{backgroundImage: 'url(//bizweb.dktcdn.net/assets/admin/images/no-image-placeholder.png)'}}>
                                            <div className="default-image default-image--product home-image-overlay" style={{backgroundImage: 'url("/admin/services/GetImageProduct?productId=14391749&variant=compact")', backgroundSize: 'cover'}}>
                                            </div>
                                          </div>
                                          <div className="home-top-sales__name">
                                            <p className="home-top-sales__content">
                                              <a href="/admin/products/14391749" target="_blank" rel="noreferrer noopener">Áo nữ</a>
                                            </p>
                                            <p className="home-top-sales__content text-emphasis-subdued">
                                              0 sản phẩm
                                            </p>
                                          </div>
                                        </div>
                                        <div className="home-top-sales__item">
                                          <div className="home-top-sales__number">04</div>
                                          <div className="home-top-sales__image" style={{backgroundImage: 'url(//bizweb.dktcdn.net/assets/admin/images/no-image-placeholder.png)'}}>
                                            <div className="default-image default-image--product home-image-overlay" style={{backgroundImage: 'url("/admin/services/GetImageProduct?productId=14430526&variant=compact")', backgroundSize: 'cover'}}>
                                            </div>
                                          </div>
                                          <div className="home-top-sales__name">
                                            <p className="home-top-sales__content">
                                              <a href="/admin/products/14430526" target="_blank" rel="noreferrer noopener">Áo thun nam</a>
                                            </p>
                                            <p className="home-top-sales__content text-emphasis-subdued">
                                              0 sản phẩm
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                           */}
                          </div>
                        </section>
                        {/* <div className="dashboard-right">
                          <div className="panel clearfix panel-dasboard-ga">
                            <div className="panel-body">
                        <div className="next-grid report-caption">
                          <h2 className="report-caption-h2">Nhật ký hoạt động</h2>
                        </div>
                        <div className="panel-body panel-body-history">
                          <ul>
                            <li>
                              <div>
                                <p>
                                  <span>
                                    <svg className="next-icon">
                                      <use xlinkHref="#icon-order" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    </svg>
                                  </span>
                                  <strong>
                                    Đặng An
                                  </strong>
                                  <span className="history-date">
                                    18/05/2019 15:06
                                  </span>
                                </p>
                                <p className="history-message">
                                  Đơn hàng bị hủy bởi Đặng An. Lý do bởi: gian lận
                                </p>
                              </div>
                            </li>
                            <li>
                              <div>
                                <p>
                                  <span>
                                    <svg className="next-icon">
                                      <use xlinkHref="#icon-order" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    </svg>
                                  </span>
                                  <strong>
                                    Đặng An
                                  </strong>
                                  <span className="history-date">
                                    18/05/2019 15:06
                                  </span>
                                </p>
                                <p className="history-message">
                                  Hoàn kho 1 sản phẩm
                                </p>
                              </div>
                            </li>
                            <li>
                              <div>
                                <p>
                                  <span>
                                    <svg className="next-icon">
                                      <use xlinkHref="#icon-order" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    </svg>
                                  </span>
                                  <strong>
                                    Sapo
                                  </strong>
                                  <span className="history-date">
                                    17/05/2019 22:26
                                  </span>
                                </p>
                                <p className="history-message">
                                  <a href="/admin/orders/5790794">#1008</a> đã được gửi tới khách hàng
                                </p>
                              </div>
                            </li>
                            <li style={{padding: '20px 0'}}>
                              <a href="/admin/activity"><span>Xem thêm hoạt động</span></a>
                            </li>
                          </ul>
                        </div>


                            </div>
                          </div>
                        </div>
                       */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="dashboard-left"> */}
                  <div className="">
                    <section className="panel clearfix dashboard-sumary-panel" style={{marginBottom: 0}}>
                      <div className="dashboard-sumary">
                        <div className="sumary-left">
                          <div className="panel-body">
                            <div className>
                              <h2 className="report-caption-h2">Tổng quan trong ngày</h2>
                            </div>
                            <div>
                              <ul>
                                <li className="clearfix">
                                  <div className="panel-data-icon panel-data-icon-money">
                                    <svg className="next-icon">
                                      <use xlinkHref="#icon-doanhthu" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    </svg>
                                  </div>
                                  <div className="panel-data-info">
                                    <p className="panel-data-info-title">Doanh thu</p>
                                    <p className="panel-data-info-number number-money" id="total_sales">{this.state.thongke?this.state.thongke.doanh_thu.toLocaleString():""}₫</p>
                                  </div>
                                </li>
                                <li className="clearfix">
                                  <div className="panel-data-icon panel-data-icon-order">
                                    <svg className="next-icon">
                                      <use xlinkHref="#icon-order2" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    </svg>
                                  </div>
                                  <div className="panel-data-info">
                                    <p className="panel-data-info-title">Đơn hàng</p>
                                    <p className="panel-data-info-number number-order" id="total_orders">{this.state.thongke?this.state.thongke.don_hang.toLocaleString():""}</p>
                                  </div>
                                </li>
                                <li className="clearfix">
                                  <div className="panel-data-icon panel-data-icon-cus">
                                    <svg className="next-icon">
                                      <use xlinkHref="#icon-customer" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    </svg>
                                  </div>
                                  <div className="panel-data-info">
                                    <p className="panel-data-info-title">Khách hàng mới</p>
                                    <p className="panel-data-info-number number-cus" id="total_customers">{this.state.thongke?this.state.thongke.khach_moi.toLocaleString():""}</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="sumary-right">
                          <div className="panel-body panel-body-detail-order">
                            <div>
                              <h2 className="report-caption-h2">Thông tin cần biết</h2>
                            </div>
                            <div className="order-dashboard-wrapper">
                              <div className="order-dashboard-item">
                                <a href="https://docs.google.com/document/d/19o-IVs7ysj6ciTcBIs-mJ4o42pgYjyZwcAwXniPu4T8/edit?usp=sharing" target="_blank">
                                  <div className="order-dasboard-item-panel">
                                    <div className="order-dashboard-item-img">
                                      <svg className="next-icon">
                                        <use xlinkHref="#icon-bill" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                      </svg>
                                    </div>
                                    <p>
                                      {/* <span className="detail-order-number">4</span> */}
                                      <span className="detail-order-title">Nội quy</span>
                                    </p>
                                  </div>
                                </a>
                              </div>
                              <div className="order-dashboard-item">
                                <a href="https://docs.google.com/spreadsheets/d/1OyMaTRtmMozl_9kINSHzqzJZLTgQKb3hl-5NQs3gNhg/edit#gid=0" target="_blank">
                                  <div className="order-dasboard-item-panel">
                                    <div className="order-dashboard-item-img">
                                      <svg className="next-icon">
                                        <use xlinkHref="#icon-unfulfilled" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                      </svg>
                                    </div>
                                    <p>
                                      {/* <span className="detail-order-number">5</span> */}
                                      <span className="detail-order-title">Chính sách</span>
                                    </p>
                                  </div>
                                </a>
                              </div>
                              <div className="order-dashboard-item">
                                <a href="https://docs.google.com/spreadsheets/d/1xOW31ydhj03rLTveLQwc_h3VOPl1ffSgHKAY-1VXdng/edit?usp=sharing" target="_blank">
                                  <div className="order-dasboard-item-panel">
                                    <div className="order-dashboard-item-img">
                                      <svg className="next-icon">
                                        <use xlinkHref="#icon-abandoned" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                      </svg>
                                    </div>
                                    <p>
                                      {/* <span className="detail-order-number">0</span> */}
                                      <span className="detail-order-title">Bảng lương</span>
                                    </p>
                                  </div>
                                </a>
                              </div>
                              <div className="order-dashboard-item">
                                <a href="https://docs.google.com/spreadsheets/d/1OV2LoSBPkbbqL7DLU3ZvmLUncfi7Gq1KtAJGZa6znig/edit#gid=0" target="_blank">
                                  <div className="order-dasboard-item-panel">
                                    <div className="order-dashboard-item-img">
                                      <svg className="next-icon">
                                        <use xlinkHref="#icon-order-refund" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                      </svg>
                                    </div>
                                    <p>
                                      {/* <span className="detail-order-number">0</span> */}
                                      <span className="detail-order-title">Nhân Sự</span>
                                    </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bizweb-copyright text-center text-muted">
          <p>Nền tảng quản lý và bán hàng đa kênh được sử dụng nhiều nhất Việt Nam</p>
        </div>
      </div>
    </div>
  </div>
</div>

        )
      }else{
        return <div>
                <br></br>
                <br></br>
                <p><center>Tài khoản đang được đăng ký vào hệ thống!
                  <br></br>
                  Hãy đăng xuất và đăng nhập lại khi đăng ký vào hệ thống thành công.
                  </center></p>
            </div>
      }
    }
}

export default Dashboard;