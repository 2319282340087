import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Modal from 'react-responsive-modal';
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string'); 

class BaoCaoQCHomNay extends Component {

    constructor(props) {
        super(props);
        this.state = {
          acount: [],
          payment: [],
        };
      }

    

    componentWillMount() {

        let token = localStorage.getItem('token');

        axios.get(Config.API_URL + 'tong-so-don?token=' + token)
        .then(tongsodon => {

          this.setState({
            tongsodon: tongsodon.data,
          })
        })
        .catch(function (error) {
          console.log(error);
        });


        axios.get(Config.API_URL + 'tong-doanh-so-hom-nay?token=' + token)
        .then(tongdoanhso => {

          this.setState({
            tongdoanhso: tongdoanhso.data,
          })
        })
        .catch(function (error) {
          console.log(error);
        });


        axios.get(Config.API_URL + 'tong-gia-nhap-hom-nay?token=' + token)
        .then(tonggianhap => {

          this.setState({
            tonggianhap: tonggianhap.data,
          })
        })
        .catch(function (error) {
          console.log(error);
        });



        axios.get("https://graph.facebook.com/v3.3/me/adaccounts?limit=1000&fields=account_status,age,name,amount_spent,balance,business_name,timezone_name,timezone_offset_hours_utc,currency&access_token=" + Config.TOKEN_ADS)
            .then(response => {

                if(response && response.data && response.data.data){
                    this.setState({
                        acount: response.data.data,
                    })
                }
                


                if (localStorage.getItem(spend)) {
                    var spend = localStorage.getItem(spend);
                } else {
                    var spend = [];
                }

                if (localStorage.getItem(payment)) {
                    var payment = localStorage.getItem(payment);
                } else {
                    var payment = [];
                }

                if (localStorage.getItem(gioihan)) {
                    var gioihan = localStorage.getItem(gioihan);
                } else {
                    var gioihan = [];
                }

                if (localStorage.getItem(nothanhtoan)) {
                    var nothanhtoan = localStorage.getItem(nothanhtoan);
                } else {
                    var nothanhtoan = [];
                }

                if (localStorage.getItem(nguong)) {
                    var nguong = localStorage.getItem(nguong);
                } else {
                    var nguong = [];
                }

                if (localStorage.getItem(ketqua_ads)) {
                    var ketqua_ads = localStorage.getItem(ketqua_ads);
                } else {
                    var ketqua_ads = [];
                }

                if (localStorage.getItem(tongchitieu)) {
                    var tongchitieu = localStorage.getItem(tongchitieu);
                } else {
                    var tongchitieu = 0;
                }



                response.data.data.map((value) => {



                    async function getKetQuaAds() {

                        try {
                            const response_ads = await axios.get('https://graph.facebook.com/v3.3/'+value.id+'/insights?access_token='+Config.TOKEN_ADS+'&__activeScenarios=["page_load_visible_rows","page_load"]&__business_id=1143415902345331&_app=ADS_MANAGER&_priority=LOW&_reqName=adaccount/insights&_reqSrc=AdsPETableDataFetchingPolicy.fetchFooter>fetchAsyncAfterSyncFails&_sessionID=78b49d1fe2708b0e&action_attribution_windows=["default"]&date_preset=today&fields=["created_time","spend","reach","results","objective","cost_per_result","actions","cost_per_action_type","cpc","ctr","cpm","frequency","cost_per_unique_action_type"]&filtering=[{"field":"campaign.delivery_info","operator":"IN","value":["active","completed","inactive","limited","not_delivering","not_published","pending_review","recently_completed","recently_rejected","rejected","scheduled"]}]&include_headers=false&level=account&limit=5000&locale=vi_VN&method=get&pretty=0&suppress_http_code=1&xref=f2854b566dd09e8');
                            ketqua_ads.push({ id: value.id, data: response_ads.data.data[0] ? response_ads.data.data[0] : null })
                            localStorage.setItem('ketqua_ads',JSON.stringify(ketqua_ads))

                        } catch (error) {
                            console.error(error);
                        }

                    }

                    getKetQuaAds()


                    async function getSpend() {

                        try {
                            const response = await axios.get('https://graph.facebook.com/v3.3/' + value.id + '/insights?access_token=' + Config.TOKEN_ADS + '&date_preset=today&fields=["spend","account_id"]&locale=vi_VN');
                            spend.push({ id: value.id, data: response.data.data[0] ? response.data.data[0] : null })
                            localStorage.setItem('spend',JSON.stringify(spend))
                            if(response.data && response.data.data && response.data.data[0] && response.data.data[0].spend){
                                tongchitieu += parseInt(response.data.data[0].spend)
                                localStorage.setItem('tongchitieu',tongchitieu)
                            }
                            
                            

                        } catch (error) {
                            console.error(error);
                        }

                    }

                    getSpend()


                    async function getPayment() {

                        try {
                            const response1 = await axios.get('https://graph.facebook.com/v3.3/'+value.id+'?access_token='+Config.TOKEN_ADS+'&__business_id=1143415902345331&_reqName=adaccount&_reqSrc=AdsPaymentMethodsDataLoader&_sessionID=18b67a9a4d579d0f&fields=["funding_source_details"]&include_headers=false&locale=vi_VN&method=get&pretty=0&suppress_http_code=1');
                            payment.push({ id: value.id, data: response1.data.funding_source_details ? response1.data.funding_source_details : null })
                            localStorage.setItem('payment',JSON.stringify(payment))
                            

                        } catch (error) {
                            console.error(error);
                        }

                    }
                    getPayment()


                    async function gioiHan() {

                        try {
                            const response2 = await axios.get('https://graph.facebook.com/v3.3/'+value.id+'?access_token='+Config.TOKEN_ADS+'&__business_id=1143415902345331&_priority=HIGH&_reqName=adaccount&_reqSrc=AdsCMAccountSpendLimitDataLoader&_sessionID=72e2c55b3efb4325&fields=["spend_cap","amount_spent"]&include_headers=false&locale=vi_VN&method=get&pretty=0&suppress_http_code=1');
                            gioihan.push({ id: value.id, data: response2.data ? response2.data : null })
                            localStorage.setItem('gioihan',JSON.stringify(gioihan))
                            

                        } catch (error) {
                            console.error(error);
                        }

                    }
                    gioiHan()

                    async function noThanhToan() {

                        try {
                            const response2 = await axios.get('https://graph.facebook.com/v3.3/'+value.id+'?access_token='+Config.TOKEN_ADS+'&__business_id=1143415902345331&_reqName=adaccount&_reqSrc=AdsCMPaymentsAccountDataDispatcher&_sessionID=18b67a9a4d579d0f&fields=["current_unbilled_spend","max_billing_threshold","next_bill_date"]&include_headers=false&locale=vi_VN&method=get');
                            nothanhtoan.push({ id: value.id, data: response2.data ? response2.data : null })
                            localStorage.setItem('nothanhtoan',JSON.stringify(nothanhtoan))
                            

                        } catch (error) {
                            console.error(error);
                        }

                    }
                    noThanhToan()

                    async function nguongThanhToan() {

                        try {
                            const response2 = await axios.get('https://graph.facebook.com/v3.3/'+value.id+'/adspaymentcycle?access_token='+Config.TOKEN_ADS+'&__business_id=1811971562390709&_reqName=adaccount/adspaymentcycle&_reqSrc=AdsCMBillingTransactionsDataLoader&_sessionID=26310a90197fe6f8&include_headers=false&locale=vi_VN&method=get&pretty=0&suppress_http_code=1');
                            nguong.push({ id: value.id, data: response2.data.data[0] ? response2.data.data[0] : null })
                            localStorage.setItem('nguong',JSON.stringify(nguong))
                            

                        } catch (error) {
                            console.error(error);
                        }

                    }
                    nguongThanhToan()

                    
                })




            })
            .catch(function (error) {
                console.log(error);
            });


            


    }

    


    render() {

        return (
            <div id="content">
                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" /></svg></span>
                                    <h1 className="ui-title-bar__title">Thống kê hôm nay</h1></div>
                                <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                    <div className="action-bar__item action-bar__item--link-container" />
                                    <div className="ui-title-bar__mobile-primary-actions">
                                        <div className="ui-title-bar__actions"><a className="ui-button ui-button--primary ui-title-bar__action" href="/add-product">Thêm sản phẩm</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                                <div className="ui-title-bar__actions"><a className="ui-button ui-button--primary ui-title-bar__action" href="/add-product">Thêm sản phẩm</a></div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        {/* <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li className="filter-tab-item" data-tab-index={1}><a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab next-tab--is-active">Tất cả sản phẩm</a></li>
                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">
                                                                <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected"><span className="next-input__add-on next-input__add-on--before"><svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg></span>
                                                                    <input type="text" name="search" placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" defaultValue />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                        <thead>
                                                            <tr>
                                                                <th><span>#</span></th>
                                                                <th><span>Tên tk</span></th>
                                                                {/* <th><span>Ngày tạo</span></th> */}
                                                                <th><span>Tuổi</span></th>
                                                                <th><span>zone</span></th>
                                                                <th><span>Payment</span></th>
                                                                <th><span>currency</span></th>
                                                                <th><span>Đã chi tiêu</span></th>
                                                                <th><span>Cmt</span></th>
                                                                <th><span>Mess</span></th>
                                                                <th><span>Ladi</span></th>
                                                                <th><span>Giới hạn chi tiêu</span></th>
                                                                <th><span>Ngưỡng thanh toán</span></th>
                                                                {/* <th><span>Ngày t.toán</span></th> */}
                                                                <th><span>Trạng thái</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.acount && 
                                                                localStorage.getItem('spend') && 
                                                                localStorage.getItem('gioihan') &&
                                                                localStorage.getItem('payment') &&
                                                                localStorage.getItem('nothanhtoan') &&
                                                                localStorage.getItem('nguong') &&
                                                                localStorage.getItem('ketqua_ads')
                                                                ?
                                                                
                                                                this.state.acount.map( (value,key)=>{


                                                                    var ketqua_ads = JSON.parse(localStorage.getItem('ketqua_ads')).filter(function (returnableObjects) {
                                                                        if (returnableObjects) {
                                                                            return returnableObjects.id === value.id;
                                                                        } else {
                                                                            return null;
                                                                        }

                                                                    })

                                                                    if(ketqua_ads && ketqua_ads[0] && ketqua_ads[0].data && ketqua_ads[0].data.actions){
                                                                        var comment = ketqua_ads[0].data.actions.filter(function (returnableObjects) {
                                                                            if (returnableObjects) {
                                                                                return returnableObjects.action_type === "comment";
                                                                            } else {
                                                                                return null;
                                                                            }
    
                                                                        })
                                                                        var mess = ketqua_ads[0].data.actions.filter(function (returnableObjects) {
                                                                            if (returnableObjects) {
                                                                                return returnableObjects.action_type === "onsite_conversion.messaging_first_reply";
                                                                            } else {
                                                                                return null;
                                                                            }
    
                                                                        })
                                                                        var ladi = ketqua_ads[0].data.actions.filter(function (returnableObjects) {
                                                                            if (returnableObjects) {
                                                                                return returnableObjects.action_type === "complete_registration";
                                                                            } else {
                                                                                return null;
                                                                            }
    
                                                                        })
                                                                        
                                                                    }

                                                                    
                                                                    var spend = JSON.parse(localStorage.getItem('spend')).filter(function (returnableObjects) {
                                                                        if (returnableObjects) {
                                                                            return returnableObjects.id === value.id;
                                                                        } else {
                                                                            return null;
                                                                        }

                                                                    })


                                                                    var gioihan = JSON.parse(localStorage.getItem('gioihan')).filter(function (returnableObjects) {
                                                                        if (returnableObjects) {
                                                                            return returnableObjects.id === value.id;
                                                                        } else {
                                                                            return null;
                                                                        }

                                                                    })

                                                                    var payment = JSON.parse(localStorage.getItem('payment')).filter(function (returnableObjects) {
                                                                        if (returnableObjects) {
                                                                            return returnableObjects.id === value.id;
                                                                        } else {
                                                                            return null;
                                                                        }

                                                                    })

                                                                    var nothanhtoan = JSON.parse(localStorage.getItem('nothanhtoan')).filter(function (returnableObjects) {
                                                                        if (returnableObjects) {
                                                                            return returnableObjects.id === value.id;
                                                                        } else {
                                                                            return null;
                                                                        }

                                                                    })


                                                                    var nguong = JSON.parse(localStorage.getItem('nguong')).filter(function (returnableObjects) {
                                                                        if (returnableObjects) {
                                                                            return returnableObjects.id === value.id;
                                                                        } else {
                                                                            return null;
                                                                        }

                                                                    })


                                                                    return <tr key={key}>
                                                                    <td className="total">
                                                                        {key+1}
                                                                    </td>
                                                                    <td className="total">
                                                                        <a target="_blank" href={'https://business.facebook.com/adsmanager/manage/campaigns?act='+value.id.replace("act_",'')+'&business_id=1143415902345331&columns=name%2Ccreated_time%2Cdelivery%2Ccampaign_name%2Cbudget%2Cspend%2Creach%2Cresults%2Ccost_per_result%2Cactions%3Acomment%2Ccost_per_action_type%3Acomment%2Cactions%3Aonsite_conversion.messaging_first_reply%2Ccost_per_action_type%3Aonsite_conversion.messaging_first_reply%2Ccpc%2Crelevance_score%3Ascore%2Cctr%2Ccpm%2Cfrequency%2Cschedule%2Cstop_time%2Ccost_per_unique_action_type%3Aomni_complete_registration&attribution_windows=default&date=2019-06-05_2019-06-06%2Ctoday&filter_set=CAMPAIGN_GROUP_DELIVERY_INFO-STRING_SET%1EIN%1E[%22active%22%2C%22pending_review%22]%1DCAMPAIGN_DELIVERY_INFO-STRING_SET%1EIN%1E[%22active%22%2C%22pending_review%22]%1DADGROUP_DELIVERY_INFO-STRING_SET%1EIN%1E[%22active%22%2C%22pending_review%22]&root_level=ad'}>{value.name}</a>
                                                                    </td>
                                                                    {/* <td className="total">
                                                                        {
                                                                            nguong[0].data?
                                                                            dateFormat( nguong[0].data.created_time, "dd-mm-yyyy" )
                                                                            :
                                                                            ""}
                                                                    </td> */}
                                                                    <td className="total">
                                                                    {Math.ceil(value.age)}
                                                                    </td>
                                                                    <td className="vendor">
                                                                        {
                                                                            value && value.timezone_offset_hours_utc>0?
                                                                            "+"+value.timezone_offset_hours_utc
                                                                            :
                                                                            value && value.timezone_offset_hours_utc
                                                                            }
                                                                    </td>
                                                                    <td className="type">
                                                                            {
                                                                                payment && payment[0] && payment[0].data?
                                                                                payment[0].data.display_string
                                                                                :
                                                                                ""
                                                                            }
                                                                    </td>
                                                                    <td className="type">
                                                                        {value.currency}
                                                                    </td>
                                                                    <td className="type">
                                                                            {
                                                                                spend && spend[0] && spend[0].data ?
                                                                                    parseInt(spend[0].data.spend).toLocaleString()
                                                                                    :
                                                                                    ""
                                                                            }
                                                                    </td>
                                                                    <td className="type">
                                                                            {
                                                                                comment && comment[0] && spend && spend[0] && spend[0].data?
                                                                                <span data-tip={"Giá cmt: " + (parseInt(spend[0].data.spend)/comment[0].value).toLocaleString()}> 
                                                                                {comment[0].value}
                                                                                <ReactTooltip place={'right'}/>
                                                                                </span>
                                                                               
                                                                                    :
                                                                                    ""
                                                                            }
                                                                            
                                                                    </td>
                                                                    <td className="type">

                                                                    {
                                                                                mess && mess[0] && spend && spend[0] && spend[0].data?
                                                                                <span data-tip={"Giá tin nhắn: " + (parseInt(spend[0].data.spend)/mess[0].value).toLocaleString()}> 
                                                                                {mess[0].value}
                                                                                <ReactTooltip place={'right'}/>
                                                                                </span>
                                                                               
                                                                                    :
                                                                                    ""
                                                                            }

                                                                    </td>
                                                                    <td className="type">

                                                                    {
                                                                                ladi && ladi[0] && spend && spend[0] && spend[0].data?
                                                                                <span data-tip={"Giá chuyển đổi: " + (parseInt(spend[0].data.spend)/ladi[0].value).toLocaleString()}> 
                                                                                {ladi[0].value}
                                                                                <ReactTooltip place={'right'}/>
                                                                                </span>
                                                                               
                                                                                    :
                                                                                    ""
                                                                            }

                                                                    </td>
                                                                    <td className="type">
    
    <a target="_blank" href="">

                                                                           
                                                                            {
                                                                                gioihan && gioihan[0] && gioihan[0].data ?
                                                                                 parseInt(gioihan[0].data.spend_cap) - parseInt(gioihan[0].data.amount_spent) <= 100000 ?
                                                                                <span style={{color: 'red'}}>{parseInt(gioihan[0].data.amount_spent).toLocaleString() + " / " + parseInt(gioihan[0].data.spend_cap).toLocaleString()}</span>
                                                                                    :
                                                                                    <span style={{color: 'blue'}}>{parseInt(gioihan[0].data.amount_spent).toLocaleString() + " / " + parseInt(gioihan[0].data.spend_cap).toLocaleString()}</span>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                            </a>
                                                                    </td>
                                                                    <td className="vendor">


                                                                    {
                                                                              nothanhtoan && nothanhtoan[0] && nothanhtoan[0].data && nguong && nguong[0] && nguong[0].data ?
                                                                                 parseInt(parseInt(nguong[0].data.threshold_amount) - nothanhtoan[0].data.current_unbilled_spend.amount_in_hundredths/100) <= 500000 ?
                                                                                <span style={{color: 'red'}}>{(nothanhtoan[0].data.current_unbilled_spend.amount_in_hundredths/100).toLocaleString() + " / " + nguong[0].data.threshold_amount.toLocaleString()}</span>
                                                                                    :
                                                                                    <span style={{color: 'blue'}}>{(nothanhtoan[0].data.current_unbilled_spend.amount_in_hundredths/100).toLocaleString() + " / " + nguong[0].data.threshold_amount.toLocaleString()}</span>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                    </td>
                                                                    {/* <td className="vendor">
                                                                            { 
                                                                                nothanhtoan && nothanhtoan[0] && nothanhtoan[0].data && nothanhtoan[0].data.next_bill_date? 
                                                                                dateFormat(nothanhtoan[0].data.next_bill_date, "dd-mm-yyyy" ) 
                                                                                :
                                                                                ""
                                                                                }
                                                                    </td> */}
                                                                    <td className="total">
                                                                        {
                                                                            value.account_status===1?
                                                                            
                                                                            gioihan[0] && parseInt(gioihan[0].data.spend_cap) - parseInt(gioihan[0].data.amount_spent) === 0 && parseInt(gioihan[0].data.spend_cap)!== 0 ?
                                                                            <a target="_blank" href={"https://business.facebook.com/ads/manager/account_settings/account_billing/?act="+value.id.replace('act_','')+"&pid=p2&page=account_settings&tab=account_billing_settings"}>
                                                                                <label className="badge badge--status-warning badge--progress-complete">Đạt giới hạn</label>
                                                                                </a>
                                                                            :
                                                                            <a target="_blank" href={"https://business.facebook.com/ads/manager/account_settings/account_billing/?act="+value.id.replace('act_','')+"&pid=p2&page=account_settings&tab=account_billing_settings"}>
                                                                            <label className="badge badge--status-success badge--progress-complete">Hoạt động</label>
                                                                            </a>
                                                                           
                                                                            :
                                                                            <label className="badge badge--status-danger badge--progress-complete">Không hoạt động</label>
                                                                        }
                                                                        
                                                                    </td>
                                                                </tr>
                                                                } )
                                                                :
                                                                ""
                                                            }
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default BaoCaoQCHomNay;