const PickDateInitialState = {}
const PickDate = (state = PickDateInitialState, action) => {
    switch (action.type) {
        case "PICK_DATE":
                // console.log('Kết nối thành công với ADD_DATA: ' + JSON.stringify(action.pickDate));
                return {...state,chonThang: action.pickDate}
        default:
            return state
    }
}
export default PickDate; 