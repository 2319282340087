var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM'));
var countCart = 0;
var countTotal = 0;
if (CART_LOCAL) {
    CART_LOCAL.forEach(function (record) {
        countCart += parseInt(JSON.parse(record).quantity);
        countTotal += JSON.parse(record).quantity * JSON.parse(record).price;
    });
}
const CartInitialState = { countCart: countCart }
const Cart = (state = CartInitialState, action) => {
    switch (action.type) {
        case "ADD_CART":
            var item = action.addItem;
            if (localStorage.getItem('CART_ITEM')) {
                var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM'));
            } else {
                var CART_LOCAL = [];
            }
            if (CART_LOCAL.length > 0) {
                var checkTrung = false;
                JSON.parse(localStorage.getItem('CART_ITEM')).map((value, key) => {
                    if (JSON.parse(JSON.stringify(JSON.parse(value))).key === item.key) {
                        checkTrung = true;
                        var itemnew = {};
                        itemnew.key = JSON.parse(JSON.stringify(JSON.parse(value))).key;
                        itemnew.id = JSON.parse(JSON.stringify(JSON.parse(value))).id;
                        itemnew.name = JSON.parse(JSON.stringify(JSON.parse(value))).name;
                        itemnew.image = JSON.parse(JSON.stringify(JSON.parse(value))).image;
                        itemnew.color = JSON.parse(JSON.stringify(JSON.parse(value))).color;
                        itemnew.size = JSON.parse(JSON.stringify(JSON.parse(value))).size;
                        itemnew.colorKey = JSON.parse(JSON.stringify(JSON.parse(value))).colorKey;
                        itemnew.sizeKey = JSON.parse(JSON.stringify(JSON.parse(value))).sizeKey;
                        itemnew.price = JSON.parse(JSON.stringify(JSON.parse(value))).price;
                        itemnew.quantity = parseInt(JSON.parse(JSON.stringify(JSON.parse(value))).quantity) + item.quantity;
                        itemnew.url = JSON.parse(JSON.stringify(JSON.parse(value))).url;
                        CART_LOCAL[key] = JSON.stringify(itemnew);
                    }
                })
                if (checkTrung === false) {
                    CART_LOCAL.push(JSON.stringify(item));
                }

            } else {
                CART_LOCAL.push(JSON.stringify(item));
            }
            localStorage.setItem('CART_ITEM', JSON.stringify(CART_LOCAL));


            var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM'));
            var countCart = 0;
            var countTotal = 0;
            if (CART_LOCAL) {
                CART_LOCAL.forEach(function (record) {
                    countCart += parseInt(JSON.parse(record).quantity);
                    countTotal += JSON.parse(record).quantity * JSON.parse(record).price;
                });
            }

            return { ...state, countCart: countCart }


        case "CHANGE_QUANTITY":
            var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM'));
            var countCart = 0;
            var countTotal = 0;
            if (CART_LOCAL) {
                CART_LOCAL.forEach(function (record) {
                    countCart += parseInt(JSON.parse(record).quantity);
                    countTotal += JSON.parse(record).quantity * JSON.parse(record).price;
                });
            }
            return { ...state, countCart: countCart }

        case "DELETE_CART":
            var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM'));
            var countCart = 0;
            var countTotal = 0;
            if (CART_LOCAL) {
                CART_LOCAL.forEach(function (record) {
                    countCart += parseInt(JSON.parse(record).quantity);
                    countTotal += JSON.parse(record).quantity * JSON.parse(record).price;
                });
            }
            return { ...state, countCart: countCart }
        case "CHECK_OUT":
            localStorage.removeItem('CART_ITEM')
            var CART_LOCAL = JSON.parse(localStorage.getItem('CART_ITEM'));
            var countCart = 0;
            var countTotal = 0;
            if (CART_LOCAL) {
                CART_LOCAL.forEach(function (record) {
                    countCart += parseInt(JSON.parse(record).quantity);
                    countTotal += JSON.parse(record).quantity * JSON.parse(record).price;
                });
            }
            return { ...state, countCart: countCart }
        default:
            return state
    }
}
export default Cart;