import React, { Component } from 'react';
import * as Func from '../../../functions/function.js';

class Doanhthu extends Component {
   
    render (){
        
        return (
            <div className="section_dasborad_day section" style={{marginTop:0}}>
            <div className="row row_custome">
                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 col_custome padding-left">
                    <div className="wrap_bg_block_333">
                        <div className="wrap_bg_block_333_content">
                            <div className="count _1">
                                <svg className="total-revenue" style={{width:50,height:50}}>
                                    <use xlinkHref="#total-revenue" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    <svg id="total-revenue" width="100%" height="100%" x="12.5" y={10}>
                                        <g id="Group_1639" data-name="Group 1639" transform="translate(-450 -449.846)">
                                            <g id="Group_1638" data-name="Group 1638" transform="translate(450 449.846)">
                                            <path id="Path_4153" data-name="Path 4153" d="M462.08,479.041a12.072,12.072,0,0,1,9.526-4.639,12.338,12.338,0,0,1,1.71.118v-2.581c-1.749,1.573-6.283,2.693-11.61,2.693-5.294,0-9.815-1.107-11.584-2.673v3.322C451.007,478.9,461.956,479.048,462.08,479.041Z" transform="translate(-450.084 -464.897)" fill="#5eb858" />
                                            <path id="Path_4154" data-name="Path 4154" d="M450.088,490.489l-.006,3.374c.426,1.874,4.377,3.394,9.494,3.728a11.91,11.91,0,0,1,1.468-4.429C456.018,493.077,451.779,492,450.088,490.489Z" transform="translate(-450.056 -477.535)" fill="#5eb858" />
                                            <path id="Path_4155" data-name="Path 4155" d="M450.068,509.224l-.007,3.211a.414.414,0,0,1,.059-.052c.124,2.07,4.547,3.767,10.228,4.01a12,12,0,0,1-.878-4.534v-.007C455.132,511.583,451.575,510.574,450.068,509.224Z" transform="translate(-450.042 -490.298)" fill="#5eb858" />
                                            <path id="Path_4156" data-name="Path 4156" d="M461.088,530.519c-5.124-.085-9.428-1.219-11.04-2.765l-.006,3.027c0,2.28,5.2,4.128,11.61,4.128a31.546,31.546,0,0,0,3.512-.19A12.2,12.2,0,0,1,461.088,530.519Z" transform="translate(-450.028 -502.922)" fill="#5eb858" />
                                            <ellipse id="Ellipse_8" data-name="Ellipse 8" cx="11.623" cy="4.121" rx="11.623" ry="4.121" fill="#5eb858" />
                                            <path id="Path_4157" data-name="Path 4157" d="M495,484.7a10.473,10.473,0,1,0,10.477,10.47A10.477,10.477,0,0,0,495,484.7Zm.957,16.066v.662a.957.957,0,0,1-1.913,0v-.662a3.393,3.393,0,0,1-1.481-.911.959.959,0,0,1,.039-1.35.978.978,0,0,1,1.349.039,2.016,2.016,0,0,0,1.147.445,1.434,1.434,0,0,0-.1-2.863,3.343,3.343,0,0,1-.956-6.545v-.662a.957.957,0,1,1,1.913,0v.662a3.387,3.387,0,0,1,1.481.911.959.959,0,0,1-.039,1.35.93.93,0,0,1-1.35-.039,1.642,1.642,0,0,0-1.147-.445,1.433,1.433,0,0,0,.1,2.863,3.343,3.343,0,0,1,.957,6.546Z" transform="translate(-473.474 -473.642)" fill="#5eb858" />
                                            </g>
                                        </g>
                                    </svg>
                                </svg>
                            </div>
                            <div className="content">
                                <h2 style={{color:'#5EB858'}} data-bind="sumNetSales">{Func.FormatNumber(this.props.sales.tongdoanhthu)}</h2>
                                Tổng doanh thu
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 col_custome no_padding_left">
                    <div className="wrap_bg_block_333">
                        <div className="wrap_bg_block_333_content">
                            <div className="count _2">
                                <svg className="total-profit" style={{width:50,height:50}}>
                                    <use xlinkHref="#total-profit" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    <svg id="total-profit" width="100%" height="100%" x={9} y={10}>
                                        <g id="Mask_Group_3" data-name="Mask Group 3" transform="translate(-920 -634)" clipPath="url(#clip-path)">
                                            <g id="Group_1642" data-name="Group 1642" transform="translate(912.993 626.992)">
                                            <g id="Group_1641" data-name="Group 1641">
                                                <path id="Path_4163" data-name="Path 4163" d="M34.061,27.618A7.013,7.013,0,0,0,32.845,29.8h-19.7a7.13,7.13,0,0,0-1.287-2.184H9.953a4.369,4.369,0,0,1-2.317-.677H7.007v2.093a3.558,3.558,0,0,0,1.06,2.348,3.184,3.184,0,0,0,1.886.677H36.062a3,3,0,0,0,2.945-3.025V26.452a4.316,4.316,0,0,1-2.945,1.166Z" fill="#0089ff" />
                                                <path id="Path_4164" data-name="Path 4164" d="M36.062,8.008H9.953A2.965,2.965,0,0,0,7.007,11V23.08a3.581,3.581,0,0,0,1.06,2.355,3.184,3.184,0,0,0,1.886.677H36.062a3,3,0,0,0,2.945-3.032V11A2.964,2.964,0,0,0,36.062,8.008Zm.754,11.678a6.743,6.743,0,0,0-3.971,4.167h-19.7A7.164,7.164,0,0,0,9.2,19.73V14.368a6.78,6.78,0,0,0,3.935-4.14H32.863a6.325,6.325,0,0,0,3.955,4.179v5.279Z" fill="#0089ff" />
                                                <path id="Path_4165" data-name="Path 4165" d="M22.984,11.7a5.351,5.351,0,1,0,5.19,5.349A5.27,5.27,0,0,0,22.984,11.7Zm.426,8.41v1H22.5v-.928a3.73,3.73,0,0,1-1.639-.425l.284-1.156a3.306,3.306,0,0,0,1.589.435c.547,0,.919-.218.919-.612,0-.376-.307-.613-1.016-.86-1.027-.357-1.724-.85-1.724-1.809a1.81,1.81,0,0,1,1.622-1.759v-.94h.95v.872a3.423,3.423,0,0,1,1.382.326l-.28,1.117a3.131,3.131,0,0,0-1.383-.336c-.623,0-.825.276-.825.553,0,.326.334.533,1.149.85,1.142.415,1.6.958,1.6,1.848A1.879,1.879,0,0,1,23.41,20.109Z" fill="#0089ff" />
                                                <path id="Path_4166" data-name="Path 4166" d="M34.061,33.566a7,7,0,0,0-1.216,2.183h-19.7a7.113,7.113,0,0,0-1.287-2.183H9.953a4.361,4.361,0,0,1-2.317-.678H7.007v2.094a3.558,3.558,0,0,0,1.06,2.348,3.191,3.191,0,0,0,1.886.678H36.062a3,3,0,0,0,2.945-3.027V32.4a4.311,4.311,0,0,1-2.945,1.166h-2Z" fill="#0089ff" />
                                            </g>
                                            </g>
                                        </g>
                                    </svg>
                                </svg>
                            </div>
                            <div className="content">
                                <h2 style={{color:'#0089FF'}} data-bind="sumGrossProfit">{Func.FormatNumber(this.props.sales.tongdoanhso)}</h2>
                                <p>Tổng lợi nhuận</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 col_custome no_padding_left">
                    <div className="wrap_bg_block_333">
                        <div className="wrap_bg_block_333_content">
                            <div className="count _3">
                                <svg className="gross-margin" style={{width:50,height:50}}>
                                    <use xlinkHref="#gross-margin" xmlnsXlink="http://www.w3.org/1999/xlink" />
                                    <svg id="gross-margin" width="100%" height="100%" x={9} y={10}>
                                        <g id="Mask_Group_2" data-name="Mask Group 2" transform="translate(-1448 -620)" clipPath="url(#clip-path)">
                                            <g id="Group_1640" data-name="Group 1640" transform="translate(1207.999 379.999)">
                                            <path id="Path_4158" data-name="Path 4158" d="M268.055,248.509l.178,7.357a2.858,2.858,0,0,1-1.4,2.539l-13.392,7.836,7.947,4.634a.921.921,0,0,0,.538.124.938.938,0,0,0,.742-.474l8.785-15.453a.959.959,0,0,0-.013-.971Z" fill="#928fd2" />
                                            <path id="Path_4159" data-name="Path 4159" d="M250.258,251.526a.371.371,0,0,0-.188-.052.358.358,0,0,0-.1.014.376.376,0,0,0-.23.179.388.388,0,0,0-.038.291.378.378,0,0,0,.694.093.392.392,0,0,0,.038-.292A.382.382,0,0,0,250.258,251.526Z" fill="#928fd2" />
                                            <path id="Path_4160" data-name="Path 4160" d="M257.344,255.094a.385.385,0,0,0-.267.47.375.375,0,0,0,.176.233.37.37,0,0,0,.287.039.385.385,0,0,0,.268-.47A.378.378,0,0,0,257.344,255.094Z" fill="#928fd2" />
                                            <path id="Path_4161" data-name="Path 4161" d="M266.359,255.913l-.222-9.159a.951.951,0,0,0-.449-.787l-.849-.525a2,2,0,0,0-.525,1.355,2.026,2.026,0,0,0,.183.84,2.87,2.87,0,0,1-1.367,3.786,2.758,2.758,0,0,1-1.183.265,2.819,2.819,0,0,1-2.552-1.65,7.834,7.834,0,0,1-.706-3.241,7.729,7.729,0,0,1,1.326-4.341l-2.043-1.264a.928.928,0,0,0-.957-.012l-16.545,9.681a.957.957,0,0,0-.343,1.3l7.937,13.933a.936.936,0,0,0,.813.476.92.92,0,0,0,.468-.128l16.545-9.681A.954.954,0,0,0,266.359,255.913Zm-16.289-1.77a2.264,2.264,0,0,1-2.178-1.693,2.294,2.294,0,0,1,.225-1.733,2.242,2.242,0,0,1,1.369-1.065,2.217,2.217,0,0,1,1.71.229,2.266,2.266,0,0,1,1.05,1.386,2.3,2.3,0,0,1-.225,1.734,2.244,2.244,0,0,1-1.369,1.064A2.209,2.209,0,0,1,250.07,254.143Zm2.433,5.217a.927.927,0,0,1-.243-.032.952.952,0,0,1-.663-1.164l2.511-9.5a.936.936,0,0,1,1.148-.672.951.951,0,0,1,.663,1.163l-2.511,9.5A.94.94,0,0,1,252.5,259.36Zm5.523-1.689a2.221,2.221,0,0,1-.583.078,2.264,2.264,0,0,1-2.178-1.693,2.291,2.291,0,0,1,1.594-2.8h0a2.252,2.252,0,0,1,2.76,1.615A2.289,2.289,0,0,1,258.026,257.671Z" fill="#928fd2" />
                                            <path id="Path_4162" data-name="Path 4162" d="M261.946,249.788a.926.926,0,0,0,.395-.088.956.956,0,0,0,.455-1.262,3.967,3.967,0,0,1-.357-1.641,3.844,3.844,0,1,1,7.687,0,3.979,3.979,0,0,1-.181,1.19l1.162,1.921a5.746,5.746,0,1,0-10.543-3.111,5.882,5.882,0,0,0,.532,2.441A.936.936,0,0,0,261.946,249.788Z" fill="#928fd2" />
                                            </g>
                                        </g>
                                    </svg>
                                </svg>
                            </div>
                            <div className="content">
                                <h2 style={{color:'#928FD2'}} data-bind="sumGrossMargin">20%</h2>
                                <p>Tỷ suất lợi nhuận</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default Doanhthu;