import React, { Component } from 'react';
import * as Config from '../../config.js';
const queryString = require('query-string');

const axios = require('axios');
const qs = require('qs');
const writeFileP = require("write-file-p");
const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'cookie': ' _ga=GA1.2.1713494502.1571643006; remember_web_59ba36addc2b2f9401580f014c7f58ea4e30989d=eyJpdiI6InRQWlp4UEZsZ2RPOHh4WmRuXC9Ycnd3PT0iLCJ2YWx1ZSI6Img2N3VuNEtCVVU4QjBiVjBLOE5RbXhGUENOOGZ0ZktZSEJvcUtieERyUDZUVzFcL1I5RWtRNFB3cXJTdndpQlppckJhZVBaQ25MSzB5OWRiVU9DeTA2NnZmVnZnNGVcL285UU1leG8xOUNlanZBOXEySjg4b05CRGpsYkRLa1wvUUtZVFwvTExjM0phQzBUaXFyUUV2WlRuNEJXSWZ6ZTBSaFBiYjFPU05BQUs5bHc9IiwibWFjIjoiZDJmZGNkOThhNjg3YjE3ZTUyNDFkMjE2Yzc4ZWEzYWI3NDEzMTYxNzZmMzI4NWFlMGNhNzJkYjlkMjQxMDJlOSJ9; datapage=eyJpdiI6IkRlVWdyQndcLzJ5S2w4SUNGdGl3ejlBPT0iLCJ2YWx1ZSI6IkFtNGlcL1l2ZjZOYnU5bkxEWmVrVFFBPT0iLCJtYWMiOiJhZThkZjRhZDQ3Y2Q0ZmNlMzdmZjViN2YyMWIxNGI0ZTg1YjFkMGVmY2MwYWY2NTExOWFkNjBhYTMyMDFjNjNiIn0%3D; _gid=GA1.2.822711129.1578911866; XSRF-TOKEN=eyJpdiI6IjlkeTBtTnFXckcwY3NSdUcrM25ZNnc9PSIsInZhbHVlIjoiWGNIOVhIQ1wvdjUwZDAyaFwvME5vWUhXODVSN2p5SGhDYndKZHEwam55bHdqTU9GU0lOQzFxblhGZThNYmlJMGg5IiwibWFjIjoiNmU2NDM4MTY2YTFlNzE1Y2IzNDlmZTQ5ZGE3MDc3ODgzOWJmOTZjYTgzMDcyYTQ0Y2FmYjE1YTNmYjI4YzIxYiJ9; izpage_session=eyJpdiI6IlJDQnpYMVcxY2JZaEZnXC9pT2lKUjNBPT0iLCJ2YWx1ZSI6IlwvY1BYM3Z0eG9ibFV1ZUl2TXRiNURUQXMrME85U2pZckg0NlppdnRncVRqSXF5NWpkcnRYZit3SFwvbDUzQURcLzMiLCJtYWMiOiJlZmQ4NGQ1YmZkNGRkZjc3ZWMxNWMxYzZjODg1NTIyYWE0YmE5YWM5MzgwNGQwNDk0MjEwNmEyNDBjZmQ0ZTZjIn0%3D',
    'x-csrf-token': ' FqFdC4xcDu6sDmwQH12BjjRfKjQ1c8kPoy8IUBqW',
  }
  
  
class ConvertPhoneUid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: '',
            // start_next:''
        };
    }


    componentWillMount() {
        this.quetUid()
    }

    quetUid() {
        
        if(!this.state.start_next){
            var start = queryString.parse(window.location.search).start;
        }else{
            var start = parseInt(this.state.start_next)+1;
        }
        var end = parseInt(start) + 49;
        var sub_array = [];
        for (var i = start; i <= end; i++) {
            sub_array.push('0' + i);
        }
        // console.log(   sub_array.toString()   );
        

        axios.get('http://210.245.80.4:3003/bomhang/check', {
            params: {
                phone: sub_array.toString()
            }
        })
            .then(function (response) {

                if (response.data && response.data.code == '200' && response.data.data) {
                    response.data.data.map((value, key) => {
                        
                        if (value.phone && value.uid) {
                            console.log("hihi" +value.uid);
                            var order = {};
                            order.phone = value.phone;
                            order.uid = value.uid;
                            let token = localStorage.getItem('token');
                            axios.post(Config.API_URL + 'facebook/update-convert-phone?token=' + token, qs.stringify(order))
                                .then(response1 => {

                                })
                                .catch(function (error) {
                                    // console.log(error);
                                    // alert("Lỗi")
                                });

                        }
                    })

                }

            })
            .catch(function (error) {
                // console.log(error);
            })
            .finally(err => {
                //
                this.setState({
                    start_next: sub_array.slice(-1).pop(),
                    string_now: sub_array.toString(),
                    // time_out: 1000,
                })
                //
            });
    }


    componentDidUpdate(prevProps, prevState) {
        var count = 1;
        if(prevState.start_next!==this.state.start_next){
            this.quetUid();
            // setTimeout(() => { 
            //     this.quetUid();
            //     var count = parseInt(count) + 1;
            // }, count*1);
        }
    }
    

    render() {
        
        return (
            <div>

            </div>
        );
    }
}

export default ConvertPhoneUid;