import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import OverlayLoader from 'react-overlay-loading/lib/OverlayLoader'
import Modal from 'react-responsive-modal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReactTooltip from 'react-tooltip'
import { check_phone } from '../../functions/function.js';
import Select from 'react-select'
import Pusher from 'pusher-js';

const trangthai = [
  { value: 'Dương Nội', label: 'Dương Nội' },
  { value: 'Mai An - Đội 7', label: 'Mai An - Đội 7' },
  { value: 'CALLA Store', label: 'CALLA Store' },
];


const caLay = [
  { value: '3', label: 'Buổi tối' },
  { value: '2', label: 'Buổi chiều' },
  { value: '1', label: 'Buổi sáng' },
];

var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');
class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trangThai: '',
      search: '',
      loading: true,
      dateFrom: '',
      dateTo: '',
      website: '',
      khac: '',
      data: [],
      activePage: 0,
      itemsCountPerPage: 0,
      totalItemsCount: 0,
      pageRangeDisplayed: 5,
      page: 1,
      nhanthanhtoan: false,
      Loader: false,
      openChuanBi: false, // Modal
      openChuanBiVietTel: false,
      noteShip: "Cho xem.Không giao được liên hệ người gửi",
      t_id: '',
      selectedLyDo: { value: 'CALLA Store', label: 'CALLA Store' },
      selectedCaLay: { value: '2', label: 'Buổi chiều' },
      phone_gui: '',
      VanChuyenViettel: [],
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }


  handleChangeLyDo = (selectedLyDo) => {
    this.setState({ selectedLyDo });
  }
  handleChangeCaLay = (selectedCaLay) => {
    this.setState({ selectedCaLay });
  }

  handleChangeVanChuyenViettel = (selectedVanChuyenViettel) => {
    this.setState({ selectedVanChuyenViettel });
  }
  handleChangeVanChuyenGHNTinh = (selectedVanChuyenGHNTinh) => {
    this.setState({ selectedVanChuyenGHNTinh });

    axios.post("https://online-gateway.ghn.vn/shiip/public-api/master-data/district", {"province_id":selectedVanChuyenGHNTinh.value}, { headers: { "Content-Type": "application/json", "Token": Config.TOKEN_GHN } } )
        .then(responTinhGHNHuyen => {

          var VanChuyenGHNHuyen = [];

            for (var key in responTinhGHNHuyen.data) {
              if (!responTinhGHNHuyen.data.hasOwnProperty(key)) continue;
              var obj = responTinhGHNHuyen.data[key];
              for (var prop in obj) {

                if (!obj.hasOwnProperty(prop)) continue;

                var supporttype = '';
                if(obj[prop].SupportType == 3){
                  // Hỗ trợ Lấy/Giao/Trả
                }else if(obj[prop].SupportType == 1){
                  supporttype = "(Chỉ Lấy/Trả)"
                }else if(obj[prop].SupportType == 0){
                  supporttype = "(Không Lấy/Giao/Trả)"
                }

                if(obj[prop].DistrictID){
                  VanChuyenGHNHuyen.push({ value: obj[prop].DistrictID, label: obj[prop].DistrictName + supporttype });
                }
                

              }
            }

            this.setState({
              loading: false,
              // VanChuyenGHNHuyen: VanChuyenGHNHuyen,
              huyenGHN: VanChuyenGHNHuyen,
              selectedVanChuyenGHNHuyen: null,
              selectedVanChuyenGHNXa: null,
            })

        })
        .catch(function (error) { // End Get responsecaptra
          console.log(error);
        });

  }
  handleChangeVanChuyenGHNHuyen = (selectedVanChuyenGHNHuyen) => {
    this.setState({ selectedVanChuyenGHNHuyen });

    axios.post("https://online-gateway.ghn.vn/shiip/public-api/master-data/ward?district_id", {"district_id":selectedVanChuyenGHNHuyen.value}, { headers: { "Content-Type": "application/json", "Token": Config.TOKEN_GHN } } )
        .then(responTinhGHNXa => {

          var VanChuyenGHNXa = [];

            for (var key in responTinhGHNXa.data) {
              if (!responTinhGHNXa.data.hasOwnProperty(key)) continue;

              var obj = responTinhGHNXa.data[key];
              for (var prop in obj) {

                if (!obj.hasOwnProperty(prop)) continue;
                var supporttype = '';
                if(obj[prop].SupportType == 3){
                  // Hỗ trợ Lấy/Giao/Trả
                }else if(obj[prop].SupportType == 1){
                  supporttype = "(Chỉ Lấy/Trả)"
                }else if(obj[prop].SupportType == 0){
                  supporttype = "(Không Lấy/Giao/Trả)"
                }
                if(obj[prop].WardCode){
                  VanChuyenGHNXa.push({ value: obj[prop].WardCode, label: obj[prop].WardName + supporttype });
                }
                

              }
            }

            this.setState({
              loading: false,
              xaGHN: VanChuyenGHNXa,
              selectedVanChuyenGHNXa: null,
            })

        })
        .catch(function (error) { // End Get responsecaptra
          console.log(error);
        });
  }
  handleChangeVanChuyenGHNXa = (selectedVanChuyenGHNXa) => {
    this.setState({ selectedVanChuyenGHNXa });

if(this.state.selectedVanChuyenGHNHuyen){
// Get mã dịch vụ
axios.post("https://online-gateway.ghn.vn/shiip/public-api/v2/shipping-order/available-services", {"shop_id":3432530, "from_district": 1542, "to_district":this.state.selectedVanChuyenGHNHuyen.value}, { headers: { "Content-Type": "application/json", "Token": Config.TOKEN_GHN } }) // La Khê
// axios.post("https://online-gateway.ghn.vn/shiip/public-api/v2/shipping-order/available-services", {"shop_id":1663369, "from_district": 1542, "to_district":this.state.selectedVanChuyenGHNHuyen.value}, { headers: { "Content-Type": "application/json", "Token": Config.TOKEN_GHN } }) // La Nội
.then(getmadichvu => {
  // Get Fee
  axios.post("https://online-gateway.ghn.vn/shiip/public-api/v2/shipping-order/fee", {
    "coupon": null,
    "from_district_id": 1542,
    "height": 10,
    "insurance_fee": 0,
    "length": 10,
    "service_id": getmadichvu.data.data[0].service_id,
    "to_district_id": this.state.selectedVanChuyenGHNHuyen.value,
    "to_ward_code": parseInt(this.state.selectedVanChuyenGHNXa.value),
    "weight": parseInt(this.state.tongTrongLuong),
    "width": 10,
  }, { headers: { "ShopId":3432530, "Content-Type": "application/json", "Token": Config.TOKEN_GHN } } ) // La Khê
  // }, { headers: { "ShopId":1663369, "Content-Type": "application/json", "Token": Config.TOKEN_GHN } } ) // La Nội
    .then(responsefee => {
      if (responsefee.data.data) {
        this.setState({ cuocghn: parseInt(responsefee.data.data.total).toLocaleString(), cuocghn2: responsefee.data.data.total })
        this.setState({
          loading: false,
        })
      }
    })
    .catch(function (error) {
      alert("Địa chỉ không hợp lệ")
      // console.log(error);
    });
  // End Get Fee
})
.catch(function (error) {// End get mã dịch vụ
  // console.log(error);
});

  // End get mã dịch vụ
}
    




  }


  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        loading: true
      })
      // Get data
      var trangThai = '';
      if (this.state.trangThai) {
        trangThai = this.state.trangThai;
      }
      var search = '';
      if (this.state.search) {
        search = this.state.search;
      }
      var website = '';
      if (this.state.website) {
        website = this.state.website;
      }
      var khac = '';
      if (this.state.khac) {
        khac = this.state.khac;
      }
      var dateFrom = '';
      if (this.state.dateFrom) {
        dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
      }
      var dateTo = '';
      if (this.state.dateTo) {
        dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
      }


      let token = localStorage.getItem('token');
      axios.get(Config.API_URL + 'ordersFull?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
        .then(response => {
          this.setState({
            data: response.data,
            loading: false,
            activePage: response.data.dontongid.current_page,
            itemsCountPerPage: response.data.dontongid.per_page,
            totalItemsCount: response.data.dontongid.total,
            path: response.data.dontongid.path,
          })
        })
        .catch(function (error) {
          console.log(error);
        });


      axios.get(Config.API_URL + 'dem-so-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
        .then(responsedem => {

          this.setState({
            demsodon: responsedem.data,
          })
        })
        .catch(function (error) {
          console.log(error);
        });
      // End Get data
    }
  }


  chonTrangThai(e) {
    this.setState({
      trangThai: e,
    })
  }

  chonCaLay(e) {
    this.setState({
      caLay: e,
    })
  }

  chonVanChuyenViettel(e) {
    this.setState({
      VanChuyenViettel: e,
    })
  }
  chonVanChuyenGHNTinh(e) {
    this.setState({
      VanChuyenGHNTinh: e,
    })
  }

  timKiem(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({
      [name]: value
    });


  }

  chonWeb = (website) => {
    this.setState({
      website: website.value,
    });
  }

  khac = (khac) => {
    this.setState({
      khac: khac.value,
    });
  }

  handleChangeFrom = (dateFrom) => {
    this.setState({
      dateFrom: dateFrom
    });
  }

  handleChangeTo = (dateTo) => {
    this.setState({
      dateTo: dateTo
    });
  }

  componentWillMount() {
    let token = localStorage.getItem('token');
    this.setState({
      trangThai: queryString.parse(window.location.search).trangThai,
      search: queryString.parse(window.location.search).search,
      website: queryString.parse(window.location.search).website,
      dateFrom: queryString.parse(window.location.search).dateFrom,
      dateTo: queryString.parse(window.location.search).dateTo,
      khac: queryString.parse(window.location.search).khac,
      page: queryString.parse(window.location.search).page,
    })
    //config pusher
    // this.pusher = new Pusher('bac587b7ba55a2b602d3',{
    //     encrypted: true,
    //     cluster: "ap1"
    //   });
    // this.realtime = this.pusher.subscribe('kingorder');


    var TOKEN_PAGE = {};
    TOKEN_PAGE.hangorder = "EAATDV3f5ZCagBAPYtz1pkodCCwC8Oao5Mgt68Aj908WGhMS51gld3I4wiWZBwOZC9Me982RrO15SC4MuTJZCEZCwmZA9zeHa0CYBJUhSmj0F52wIPOOnmJeTwBedLq3OJf4MkcWmXUtZCi12PVoTg0W4AQIuaxZAkHpMTZBZCrXZAuHlVtgGCDfuUDtST1lgRNH3U3I6SXBgytykI3I3mDtBZC2K";
    TOKEN_PAGE.suni = "EAATDV3f5ZCagBAHwssas4AlL2s0xZALGY1oQ9mbtYtPeX037PuHqqKrnSdvpaUXLbZCePEePmgRyZC0ZCFN87ZCWeZCrFZCkZAVpARXXz0nAZCCqRBFy5PfHc5FcOClKCQ7H32ZBHCMOV7wE4OwdY32WNN2qQtDaxxImvWoJGTnu0UX4qrsvaZCxiBmHsLH004JAZBENch5ZBhakmaTfMXWxgf4Hs9";
    TOKEN_PAGE.kingorder = "EAATDV3f5ZCagBAH7VB9A19Qrc48t6ur4zpz4YJBZBk6JmTBRZC11xx1tabtM9ybj5lnap44sGgnTEZCgZBbbjkpPVkZA4YTZBEM520VC4tyAVaZCEejFfVK23KYbLd7YWiWtwpBRHxzDgFY13k0iQueGXt8ZBI8ZCDsqJpHTkc4xYEELZCHWDcJFaZC4IpZAkcDcbLXTzP4QmJtfNiUioH0MasFZBL";

    localStorage.setItem('TOKEN_PAGE', JSON.stringify(TOKEN_PAGE));


  }




  componentDidUpdate = (prevProps, prevState) => {

    const query = {
      trangThai: this.state.trangThai,
      search: this.state.search,
      website: this.state.website,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      khac: this.state.khac,
      page: this.state.page,
    };
    const searchString = qs.stringify(query);
    history.push(
      {
        pathname: '/orders',
        search: searchString,
      }
    )


    if (prevState.selectedLyDo !== this.state.selectedLyDo) {
      this.setState({ loading: true })
      let token = localStorage.getItem('token');
      axios.get(Config.API_URL + 'get-dia-chi-kho?token=' + token + '&ten_kho=' + this.state.selectedLyDo.value)
        .then(responkho => {
          // this.setState({
          //   demsodon: responkho.data,
          // })




          this.setState({
            openChuanBi: true,
            ten_shop_gui: responkho.data.ten_shop,
            dia_chi_kho: responkho.data.dia_chi,
            dia_chi_kho_tinh: responkho.data.tinh,
            dia_chi_kho_huyen: responkho.data.huyen,
            ten_shop: responkho.data.ten_shop,
            phone_kho: responkho.data.phone_kho,
            loading: false,
          });



          axios.get(Config.API_URL + 'get-sdt-cs?token=' + token + '&ten_cs=' + this.state.ten_cs)
            .then(respon_cs => {
              this.setState({
                phone_cs: respon_cs.data.sdt_cs,
              })



              if (respon_cs.data.sdt_cs) { // Nếu tồn tại sđt CS
                this.setState({
                  phone_kho_gui: respon_cs.data.sdt_cs,
                })
              } else { // Nếu không tồn tại sđt CS thì lấy SĐT cố định của kho
                this.setState({
                  phone_kho_gui: responkho.data.phone_kho,
                })
              }



            })
            .catch(function (error) {
              console.log(error);
            });






        })
        .catch(function (error) {
          console.log(error);
        });
    }

    // Get data
    if (prevState.trangThai !== this.state.trangThai || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan) {


      if (prevState.trangThai !== this.state.trangThai || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
        this.setState({
          page: 1
        })
      }
      this.setState({
        loading: true
      })
      var trangThai = '';
      if (this.state.trangThai) {
        trangThai = this.state.trangThai;
      }
      var search = '';
      if (this.state.search) {
        search = this.state.search;
      }
      var website = '';
      if (this.state.website) {
        website = this.state.website;
      }
      var khac = '';
      if (this.state.khac) {
        khac = this.state.khac;
      }
      var dateFrom = '';
      if (this.state.dateFrom) {
        dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
      }
      var dateTo = '';
      if (this.state.dateTo) {
        dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
      }
      let token = localStorage.getItem('token');
      axios.get(Config.API_URL + 'ordersFull?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
        .then(response => {
          this.setState({
            data: response.data,
            loading: false,
            activePage: response.data.dontongid.current_page,
            itemsCountPerPage: response.data.dontongid.per_page,
            totalItemsCount: response.data.dontongid.total,
            path: response.data.dontongid.path,
          });
        })
        .catch(function (error) {
          // this.setState({ loading: false });
          console.log(error);
        });

      axios.get(Config.API_URL + 'dem-so-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
        .then(responsedem => {

          this.setState({
            demsodon: responsedem.data,
          })
        })
        .catch(function (error) {
          console.log(error);
        });


    }
    // End Get data


    // Check tồn kho 
    if (prevState.data !== this.state.data) {
      var all_product = [];
      this.state.data.dontongid.data.map((valueOrder, key) => {

        JSON.parse(JSON.stringify(this.state.data.don_chi_tiet))[valueOrder.id].map((value, key) => {
          if (all_product[key] && all_product[key].key === value.key) {

          } else {
            all_product.push(value.key);
          }
        })


      })

      let token = localStorage.getItem('token');
      axios.post(Config.API_URL + 'check-ton-kho-full?token=' + token, qs.stringify({
        'product': all_product,
      }))

        .then(response => {
          this.setState({
            ton_kho: response.data.ton_kho,
            loading: false,
          });

        })
        .catch(() => {
          this.setState({ loading: false });
          alert('Có lỗi xảy ra')
        });


    }
    //  End check tồn kho



    // Check trùng
    if (prevState.data !== this.state.data) {
      var sdt_khach = [];
      this.state.data.dontongid.data.map((valueOrder, key) => {

        JSON.parse(JSON.stringify(this.state.data.don_tong))[valueOrder.id].map((value, key) => {


          if (sdt_khach[key] && sdt_khach[key] === value.phone) {

          } else {
            sdt_khach.push(value.phone);
          }


        })


      })

      let token = localStorage.getItem('token');
      axios.post(Config.API_URL + 'check-trung?token=' + token, qs.stringify({
        'product': sdt_khach,
      }))

        .then(response => {

          var result = Object.keys(response.data.check_trung).map(function (key) {
            return [Number(key), response.data.check_trung[key]];
          });

          this.setState({
            check_trung: result,
            loading: false,
          });

        })
        .catch(() => {
          this.setState({ loading: false });
          // alert('Có lỗi xảy ra')
        });


    }
    //  End Check trùng


    // Lịch sử giao
    if (prevState.data !== this.state.data) {
      var id_don = [];
      this.state.data.dontongid.data.map((valueOrder, key) => {

        JSON.parse(JSON.stringify(this.state.data.don_tong))[valueOrder.id].map((value, key) => {


          if (id_don[key] && id_don[key] === value.id) {

          } else {
            id_don.push(value.id);
          }


        })


      })

      let token = localStorage.getItem('token');
      axios.post(Config.API_URL + 'lich-su-ho-tro-giao?token=' + token, qs.stringify({
        'product': id_don,
      }))

        .then(response => {

          var result = Object.keys(response.data.check_trung).map(function (key) {
            return [Number(key), response.data.check_trung[key]];
          });

          this.setState({
            lich_su_giao: result,
            loading: false,
          });

        })
        .catch(() => {
          this.setState({ loading: false });
          // alert('Có lỗi xảy ra')
        });


    }
    //  Lịch sử giao

  }




  componentDidMount = () => {
    this.setState({
      loading: true
    })
    // Get data
    var trangThai = '';
    if (this.state.trangThai) {
      trangThai = this.state.trangThai;
    }
    var search = '';
    if (this.state.search) {
      search = this.state.search;
    }
    var website = '';
    if (this.state.website) {
      website = this.state.website;
    }
    var khac = '';
    if (this.state.khac) {
      khac = this.state.khac;
    }
    var dateFrom = '';
    if (this.state.dateFrom) {
      dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
    }
    var dateTo = '';
    if (this.state.dateTo) {
      dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
    }


    let token = localStorage.getItem('token');
    axios.get(Config.API_URL + 'ordersFull?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
      .then(response => {
        this.setState({
          data: response.data,
          loading: false,
          activePage: response.data.dontongid.current_page,
          itemsCountPerPage: response.data.dontongid.per_page,
          totalItemsCount: response.data.dontongid.total,
          path: response.data.dontongid.path,
        })
      })
      .catch(function (error) {
        console.log(error);
      });


    axios.get(Config.API_URL + 'dem-so-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
      .then(responsedem => {

        this.setState({
          demsodon: responsedem.data,
        })
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status == '500') {
            localStorage.removeItem('token');
            window.location = "/login";
          }
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    // End Get data
    // this.realtime.bind('nhandonhang', function(data){
    //   document.getElementById("order-"+data.id).style.display = "none";
    //   console.log(data.hoten+' đã nhận đơn' + data.id);
    // }, this);

  }
  handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber
    })
    window.scrollTo(0, 0);
  }

  updateTrangThai(ma_don, e) {
    this.setState({ loading: true })
    let token = localStorage.getItem('token');
    axios.post(Config.API_URL + 'cap-nhat-trang-thai?token=' + token, qs.stringify({ ma_don: ma_don, trang_thai: e }))
      .then((res) => {
        this.setState({ loading: false })
        // Get data
        var trangThai = '';
        if (this.state.trangThai) {
          trangThai = this.state.trangThai;
        }
        var search = '';
        if (this.state.search) {
          search = this.state.search;
        }
        var website = '';
        if (this.state.website) {
          website = this.state.website;
        }
        var khac = '';
        if (this.state.khac) {
          khac = this.state.khac;
        }
        var dateFrom = '';
        if (this.state.dateFrom) {
          dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
        }
        var dateTo = '';
        if (this.state.dateTo) {
          dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
        }


        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'ordersFull?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token)
          .then(response => {
            this.setState({
              data: response.data,
              loading: false,
              activePage: response.data.dontongid.current_page,
              itemsCountPerPage: response.data.dontongid.per_page,
              totalItemsCount: response.data.dontongid.total,
              path: response.data.dontongid.path,
            })
          })
          .catch(function (error) {
            console.log(error);
          });


        axios.get(Config.API_URL + 'dem-so-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
          .then(responsedem => {

            this.setState({
              demsodon: responsedem.data,
            })
          })
          .catch(function (error) {
            console.log(error);
          });
        // End Get data

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // Chuẩn bị
  onOpenModalChuanBi = (ma_don, van_don, sumTotal, order_detail, full_name, phone, address, huyen, tinh, t_id, nguon, ten_cs, ten_shop) => {
    this.setState({ loading: true })
    let token = localStorage.getItem('token');
    axios.get(Config.API_URL + 'get-dia-chi-kho?token=' + token + '&ten_kho=' + this.state.selectedLyDo.value)
      .then(responkho => {
        // this.setState({
        //   demsodon: responkho.data,
        // })


        if (ten_shop) {
          this.setState({ ten_shop_gui: ten_shop });
        } else {
          this.setState({ ten_shop_gui: responkho.data.ten_shop });
        }


        if (t_id !== null) {
          this.setState({
            openChuanBi: true,
            ma_don: Config.MA_PAGE + ma_don,
            van_don,
            sumTotal,
            order_detail,
            full_name,
            phone,
            address,
            huyen,
            tinh,
            t_id,
            nguon,
            dia_chi_kho: responkho.data.dia_chi,
            dia_chi_kho_tinh: responkho.data.tinh,
            dia_chi_kho_huyen: responkho.data.huyen,
            ten_shop: responkho.data.ten_shop,
            phone_kho: responkho.data.phone_kho,
            ten_shop_order: ten_shop,
            loading: false,
            ten_cs

          });
        } else {
          // alert('Chưa có id chat, không thể gửi tin nhắn FB')
          this.setState({
            openChuanBi: true,
            ma_don: Config.MA_PAGE + ma_don,
            van_don,
            sumTotal,
            order_detail,
            full_name,
            phone,
            address,
            huyen,
            tinh,
            t_id,
            nguon,
            dia_chi_kho: responkho.data.dia_chi,
            dia_chi_kho_tinh: responkho.data.tinh,
            dia_chi_kho_huyen: responkho.data.huyen,
            ten_shop: responkho.data.ten_shop,
            phone_kho: responkho.data.phone_kho,
            ten_shop_order: ten_shop,
            loading: false,
            ten_cs
          });
        }





        axios.get(Config.API_URL + 'get-sdt-cs?token=' + token + '&ten_cs=' + ten_cs)
          .then(respon_cs => {
            this.setState({
              phone_cs: respon_cs.data.sdt_cs,
            })



            if (respon_cs.data.sdt_cs) { // Nếu tồn tại sđt CS
              this.setState({
                phone_kho_gui: respon_cs.data.sdt_cs,
              })
            } else { // Nếu không tồn tại sđt CS thì lấy SĐT cố định của kho
              this.setState({
                phone_kho_gui: responkho.data.phone_kho,
              })
            }



          })
          .catch(function (error) {
            console.log(error);
          });





      })
      .catch(function (error) {
        console.log(error);
      });



    console.log(" t_id " + t_id);







  };

  onCloseModalChuanBi = () => {
    this.setState({ openChuanBi: false });
  };
  // Mở Modal







  // Chuẩn bị Viettel
  onOpenModalChuanBiVietTel = (ma_don, van_don, sumTotal, order_detail, full_name, phone, address, huyen, tinh, t_id, nguon, ten_cs, ten_shop, tongTrongLuong) => {
    this.setState({ loading: true })
    let token = localStorage.getItem('token');
    axios.get(Config.API_URL + 'get-dia-chi-kho?token=' + token + '&ten_kho=' + this.state.selectedLyDo.value)
      .then(responkho => {

        if (ten_shop) {
          this.setState({ ten_shop_gui: ten_shop });
        } else {
          this.setState({ ten_shop_gui: responkho.data.ten_shop });
        }


        this.setState({
          openChuanBiVietTel: true,
          ma_don: Config.MA_PAGE + ma_don,
          van_don,
          sumTotal,
          order_detail,
          full_name,
          phone,
          address,
          huyen,
          tinh,
          t_id,
          nguon,
          dia_chi_kho: responkho.data.dia_chi,
          dia_chi_kho_tinh: responkho.data.tinh,
          dia_chi_kho_huyen: responkho.data.huyen,
          ten_shop: responkho.data.ten_shop,
          phone_kho: responkho.data.phone_kho,
          ten_shop_order: ten_shop,
          // loading: false,
          ten_cs,
          tongTrongLuong,
          VanChuyenViettel: [],

        });





        axios.get(Config.API_URL + 'get-sdt-cs?token=' + token + '&ten_cs=' + ten_cs)
          .then(respon_cs => {
            this.setState({
              phone_cs: respon_cs.data.sdt_cs,
            })



            if (respon_cs.data.sdt_cs) { // Nếu tồn tại sđt CS
              this.setState({
                phone_kho_gui: respon_cs.data.sdt_cs,
              })
            } else { // Nếu không tồn tại sđt CS thì lấy SĐT cố định của kho
              this.setState({
                phone_kho_gui: responkho.data.phone_kho,
              })
            }

            // Get địa chỉ từ Viettel

            axios.post("https://location.okd.viettelpost.vn/location/v1.0/address?system=VTP&shortForm=true", { address: address + huyen + tinh })
              .then(response => {
                response.data.components.forEach(element => {

                  if (element.type == "OTHER") {
                    this.setState({ OTHER: element.name });
                  }
                  if (element.type == "HOUSE_NUMBER") {
                    this.setState({ HOUSE_NUMBER: element.name });
                  }
                  if (element.type == "STREET") {
                    this.setState({ STREET: element.name });
                  }

                  if (element.type == "WARD") {
                    this.setState({ ID_WARD: element.code });
                  }
                  if (element.type == "DISTRICT") {
                    this.setState({ ID_DISTRICT: element.code });
                  }
                  if (element.type == "PROVINCE") {
                    this.setState({ ID_PROVINCE: element.code });
                  }
                });

                this.setState({
                  formattedAddress: response.data.formattedAddress,
                  diachi_vt: response.data.components[0].name,
                  // loading: false,
                });

                axios.get(Config.API_URL + 'get-ship-viettel?token=' + token + '&ID_WARD=' + this.state.ID_WARD + '&ID_DISTRICT=' + this.state.ID_DISTRICT + '&ID_PROVINCE=' + this.state.ID_PROVINCE + '&WEIGHT=' + this.state.tongTrongLuong + '&PRICE=' + this.state.sumTotal)
                  .then(responshipvt => {
                    this.setState({
                      SERVICES_MORE: responshipvt.data.SERVICES_MORE,
                    })
                    var VanChuyenViettel = [];

                    for (var key in responshipvt.data.SERVICES_MORE) {
                      if (!responshipvt.data.SERVICES_MORE.hasOwnProperty(key)) continue;

                      var obj = responshipvt.data.SERVICES_MORE[key];
                      for (var prop in obj) {

                        if (!obj.hasOwnProperty(prop)) continue;
                        VanChuyenViettel.push({ value: obj[prop].MA_DV_CHINH + "|" + obj[prop].TEN_DICHVU, label: obj[prop].TEN_DICHVU + " | " + obj[prop].THOI_GIAN + " | " + parseInt(obj[prop].GIA_CUOC).toLocaleString() + "đ" });

                      }
                    }

                    this.setState({
                      loading: false,
                      VanChuyenViettel: VanChuyenViettel
                    })
                    // console.log(caLay);

                  })
                  .catch(function (error) {
                    // console.log(error);
                  });


              })
              .catch(() => {
                this.setState({ loading: false });
                // alert('Có lỗi xảy ra')
              });










          })
          .catch(function (error) {
            console.log(error);
          });





      })
      .catch(function (error) {
        console.log(error);
      });



    console.log(" t_id " + t_id);







  };

  onCloseModalChuanBiVietTel = () => {
    this.setState({ openChuanBiVietTel: false });
  };
  // End chuẩn bị Viettel


  onOpenModalChuanBiGHN = (thoigiantao, ma_don, van_don, sumTotal, order_detail, full_name, phone, address, xa, huyen, tinh, t_id, nguon, ten_cs, ten_shop, tongTrongLuong) => {
    this.setState({ loading: true })
    let token = localStorage.getItem('token');
    axios.get(Config.API_URL + 'get-dia-chi-kho?token=' + token + '&ten_kho=' + this.state.selectedLyDo.value)
      .then(responkho => {

        if (ten_shop) {
          this.setState({ ten_shop_gui: ten_shop });
        } else {
          this.setState({ ten_shop_gui: responkho.data.ten_shop });
        }


        this.setState({
          openChuanBiGHN: true,
          ma_don: Config.MA_PAGE + ma_don,
          van_don,
          sumTotal,
          order_detail,
          full_name,
          phone,
          address,
          xa,
          huyen,
          tinh,
          t_id,
          nguon,
          dia_chi_kho: responkho.data.dia_chi,
          dia_chi_kho_tinh: responkho.data.tinh,
          dia_chi_kho_huyen: responkho.data.huyen,
          ten_shop: responkho.data.ten_shop,
          phone_kho: responkho.data.phone_kho,
          ten_shop_order: ten_shop,
          ten_cs,
          tongTrongLuong,
          VanChuyenGHNTinh: [],
          selectedVanChuyenGHNXa: null,
          selectedVanChuyenGHNHuyen: null,
          selectedVanChuyenGHNTinh: null,
          thoigiantao,

        });

        // Get SĐT CS
        axios.get(Config.API_URL + 'get-sdt-cs?token=' + token + '&ten_cs=' + ten_cs)
          .then(respon_cs => {
            this.setState({
              phone_cs: respon_cs.data.sdt_cs,
            })
            if (respon_cs.data.sdt_cs) { // Nếu tồn tại sđt CS
              this.setState({
                phone_kho_gui: respon_cs.data.sdt_cs,
              })
            } else { // Nếu không tồn tại sđt CS thì lấy SĐT cố định của kho
              this.setState({
                phone_kho_gui: responkho.data.phone_kho,
              })
            }

          })
          .catch((error) => { // End respon_cs
            console.log('error ' + error);
          });
        // End Get SĐT CS


        // Get Session Token GHN
        axios.get("https://online-gateway.ghn.vn/shiip/public-api/master-data/province", { headers: { "Content-Type": "application/json", "Token": Config.TOKEN_GHN } })
          .then(responTinhGHNTinh => {


            var VanChuyenGHNTinh = [];

            for (var key in responTinhGHNTinh.data) {
              if (!responTinhGHNTinh.data.hasOwnProperty(key)) continue;

              var obj = responTinhGHNTinh.data[key];
              for (var prop in obj) {

                if (!obj.hasOwnProperty(prop)) continue;
                if(obj[prop].ProvinceID){
                  VanChuyenGHNTinh.push({ value: obj[prop].ProvinceID, label: obj[prop].ProvinceName });
                }
                

              }
            }

            this.setState({
              loading: false,
              // VanChuyenGHNTinh: VanChuyenGHNTinh,
              tinhGHN: VanChuyenGHNTinh
            })



          })
          .catch(function (error) { // End Get responsecaptra
            console.log(error);
          });
        // End Get Session Token GHN

      })
      .catch(function (error) { // catch responkho
        console.log(error);
      });

  }; // End onOpenModalChuanBiGHN


  // Chuẩn bị GHN


  onCloseModalChuanBiGHN = () => {
    this.setState({ openChuanBiGHN: false, loading: false, });
  };
  // End chuẩn bị GHN





  // Modal Ghi chú
  onOpenModalGhiChu = (id, note) => {
    this.setState({ chitietdon: '' });
    this.setState({ loading: false });
    this.setState({ giatridachon: [] })
    let token = localStorage.getItem('token');
    // axios.get(Config.API_URL + 'nhap-hang-chi-tiet?id=' + id + "&token=" + token)
    //     .then(response => {
    //         this.setState({chitietdon:response.data})

    //     });
    this.setState({
      openModalGhiChu: true,
      id: id,
      note: note,
    });

  };

  onCloseModalGhiChu = () => {
    this.setState({ openModalGhiChu: false });
  };


  // Gửi chuẩn bị GHTK
  guiChuanBi() {



    this.setState({ loading: true })
    let token = localStorage.getItem('token');
    var products = [];
    var sp_dagui = [];
    for (var i = 0; i < this.state.order_detail.length; i++) {
      sp_dagui.push("\n+" + this.state.order_detail[i].name);
      products.push(
        {
          "name": this.state.order_detail[i].name + " / " + this.state.order_detail[i].thuoctinh,
          "weight": this.state.order_detail[i].trong_luong / 1000,
          "quantity": this.state.order_detail[i].quantity
        }
      )
    }
    var ma_don = this.state.ma_don.replace(Config.MA_PAGE, "");

    if (this.state.phone_cs) {
      var phone_gui = this.state.phone_cs
    } else {
      var phone_gui = this.state.phone_kho
    }


    if (this.state.ten_shop_order) {
      var ten_shop = this.state.ten_shop_order;
    } else {
      var ten_shop = this.state.ten_shop;
    }

    // alert(phone_gui)
    var data = {
      "products": products,
      "order": {
        "id": this.state.ma_don,

        // Thông tin lấy hàng
        "pick_name": ten_shop,
        "pick_address": this.state.dia_chi_kho,
        "pick_province": this.state.dia_chi_kho_tinh,
        "pick_district": this.state.dia_chi_kho_huyen,
        "pick_tel": phone_gui,
        // Hết thông tin lấy hàng

        // Thông tin giao hàng
        "tel": this.state.phone,
        "name": this.state.full_name,
        "address": this.state.address,
        "province": this.state.tinh,
        "district": this.state.huyen,
        "ward": this.state.address, // Thêm yêu cầu bắt buộc địa cấp cấp 4 (thôn/ấp/xóm/tổ/khu/…)
        "hamlet": "Khác",
        "is_freeship": "1",
        // "pick_date": "2016-09-30",
        "pick_money": this.state.sumTotal,
        "note": this.state.noteShip,
        "value": parseInt(this.state.sumTotal),
        "pick_work_shift": this.state.selectedCaLay,
        // "transport": "fly", // Liên miền tự chọn bay, nội miền tự động chọn bộ 
        "transport": "road", // Liên miền tự chọn bay, nội miền tự động chọn bộ
        // "pick_option": "post", //Nhận một trong hai giá trị cod và post, mặc định là cod, biểu thị lấy hàng bởi COD hoặc Shop sẽ gửi tại bưu cục
        // Hết thông tin giao hàng

        // Phần thông tin hoàn hàng

        "use_return_address": 1, // 0 là giống với lấy hàng, 1 là khác.
        "return_name": "ROZA La Nội",
        "return_address": "Số 19, Ngõ 106, Đường La Nội",
        "return_street": "Đường La Nội", //Tên đường/phố của người nhận hàng hóa
        "return_ward": "Phường Dương Nội",
        "return_district": "Quận Hà Đông",
        "return_province": "Hà Nội",
        "return_tel": "0974154913",
        "return_email": "rozavn.shop@gmail.com",

        // Hết thông tin hoàn hàng
      },
      'ma_don': ma_don,
      'van_don': this.state.van_don,
    }


    // Get data
    var trangThai = '';
    if (this.state.trangThai) {
      trangThai = this.state.trangThai;
    }
    var search = '';
    if (this.state.search) {
      search = this.state.search;
    }
    var website = '';
    if (this.state.website) {
      website = this.state.website;
    }
    var khac = '';
    if (this.state.khac) {
      khac = this.state.khac;
    }
    var dateFrom = '';
    if (this.state.dateFrom) {
      dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
    }
    var dateTo = '';
    if (this.state.dateTo) {
      dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
    }


    axios.post(Config.API_URL + 'tao-don-ghtk-full?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token, qs.stringify(data))
      .then((response) => {

        console.log(response.data.success);

        if (response.data.error) {
          this.setState({
            loading: false,
          })
          alert("Lỗi: " + response.data.message + " " + response.data.error.ghtk_label)
        } else if (response.data.success === false) {
          this.setState({
            loading: false,
          })
          alert("Lỗi: " + response.data.message)
        } else {

          window.open(Config.API_URL + "in?madon=" + ma_don, '_blank');
          this.setState({
            data: response.data,
            loading: false,
            activePage: response.data.dontongid.current_page,
            itemsCountPerPage: response.data.dontongid.per_page,
            totalItemsCount: response.data.dontongid.total,
            path: response.data.dontongid.path,
            openChuanBi: false,
          })


          var nguon = this.state.nguon;
          var token_page = '';
          Object.keys(JSON.parse(localStorage.getItem('TOKEN_PAGE'))).forEach(function (k) {
            if (nguon === k) {
              token_page = JSON.parse(localStorage.getItem('TOKEN_PAGE'))[k];
            }
          });

          var text_chat = "Chào Quý Khách!\n- Đơn hàng:" + sp_dagui + "\nsắp tới nơi.\n- Khoảng 2 đến 4 ngày tới QK để ý nghe điện thoại và nhận hàng giúp shop nhé!\nCảm ơn Quý Khách!";
          axios.post('https://graph.facebook.com/v2.9/' + this.state.t_id + '/messages', qs.stringify({ message: text_chat, access_token: token_page }))
            .then((res) => {


            })
            .catch(function (error) {
              console.log(error);
            });


          axios.get(Config.API_URL + 'dem-so-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
            .then(responsedem => {

              this.setState({
                demsodon: responsedem.data,
              })
            })
            .catch(function (error) {
              console.log(error);
            });

          // Nhảy ra trang in
          // window.open(Config.API_URL+"in?madon="+ma_don,'_blank');
          // window.location.href = Config.API_URL+"in?madon="+ma_don;


        }






      })
      .catch(function (error) {
        console.log(error);
      });





  };


  // Gửi Chuẩn bị VietTel 
  guiChuanBiVietTel() {
    if (!this.state.selectedVanChuyenViettel) {
      alert("Chưa chọn dịch vụ vận chuyển");
      return;
    }



    this.setState({ loading: true })
    let token = localStorage.getItem('token');
    var products = [];
    var sp_dagui = [];
    for (var i = 0; i < this.state.order_detail.length; i++) {
      sp_dagui.push(this.state.order_detail[i].name + " / " + this.state.order_detail[i].thuoctinh + " x " + this.state.order_detail[i].quantity);
      products.push(
        {
          "PRODUCT_NAME": this.state.order_detail[i].name + " / " + this.state.order_detail[i].thuoctinh,
          "PRODUCT_PRICE": this.state.order_detail[i].price,
          "PRODUCT_WEIGHT": this.state.order_detail[i].trong_luong,
          "PRODUCT_QUANTITY": this.state.order_detail[i].quantity,
        }
      )
    }
    var ma_don = this.state.ma_don.replace(Config.MA_PAGE, "");
    console.log(sp_dagui);
    if (this.state.phone_cs) {
      var phone_gui = this.state.phone_cs
    } else {
      var phone_gui = this.state.phone_kho
    }


    if (this.state.ten_shop_order) {
      var ten_shop = this.state.ten_shop_order;
    } else {
      var ten_shop = this.state.ten_shop;
    }

    // alert(phone_gui)
    var data = {
      // "products": products,
      "order": {
        "ORDER_REFERENCE": this.state.ma_don,
        "ORDER_TYPE": 1,
        "DELIVERY_DATE": Moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        "GROUPADDRESS_ID": "9353660",
        "CUS_ID": 9775241,

        "SENDER_FULLNAME": ten_shop,
        "SENDER_ADDRESS": this.state.dia_chi_kho,
        "SENDER_PHONE": "0356525305",
        "SENDER_EMAIL": "angtnd@gmail.com",
        "SENDER_WARD": "597", // Mã Dương Nội
        "SENDER_DISTRICT": "30", // Mã Hà Đông
        "SENDER_PROVINCE": "1",// Mã Hà Nội
        "SENDER_LATITUDE": 0,
        "SENDER_LONGITUDE": 0,


        "RECEIVER_FULLNAME": this.state.full_name,
        "RECEIVER_ADDRESS": this.state.address,
        "RECEIVER_PHONE": this.state.phone,
        "RECEIVER_EMAIL": null,

        "RECEIVER_WARD": this.state.ID_WARD,
        "RECEIVER_DISTRICT": this.state.ID_DISTRICT,
        "RECEIVER_PROVINCE": this.state.ID_PROVINCE,
        "RECEIVER_LATITUDE": 0,
        "RECEIVER_LONGITUDE": 0,

        "PRODUCT_NAME": sp_dagui.toString(),
        "PRODUCT_DESCRIPTION": null,
        "PRODUCT_QUANTITY": this.state.order_detail.length,
        "PRODUCT_PRICE": this.state.sumTotal,
        "PRODUCT_WEIGHT": this.state.tongTrongLuong,

        "PRODUCT_WIDTH": 0,
        "PRODUCT_HEIGHT": 0,
        "PRODUCT_LENGTH": 0,
        "PRODUCT_TYPE": "HH",
        "ORDER_PAYMENT": 3,
        "ORDER_SERVICE": this.state.selectedVanChuyenViettel.value.split("|")[0],

        "DISPLAY_SERVICE_NAME": this.state.selectedVanChuyenViettel.value.split("|")[1],
        "ORDER_SERVICE_ADD": "XMG",
        "ORDER_VOUCHER": "",
        "ORDER_NOTE": "Cho khách xem hàng | Không giao được liên hệ người gửi",
        "DELIVERY_CODE": -1,

        "MONEY_COLLECTION": this.state.sumTotal,
        "MONEY_TOTALFEE": 0,
        "MONEY_FEECOD": 0,
        "MONEY_FEEVAS": 0,
        "MONEY_FEEINSURRANCE": 0,
        "MONEY_FEE": 0,
        "MONEY_FEEOTHER": 0,
        "MONEY_TOTALVAT": 0,
        "MONEY_TOTAL": 0,
        "LIST_ITEM": products,
        "XMG_EXTRA_MONEY": 25000,
        "ORDER_NUMBER": this.state.ma_don,
        "deviceId": "u2uvk5ln3zcalu6aa9eetm"
      },
      'ma_don': ma_don,
      'van_don': this.state.van_don,
    }


    // Get data
    var trangThai = '';
    if (this.state.trangThai) {
      trangThai = this.state.trangThai;
    }
    var search = '';
    if (this.state.search) {
      search = this.state.search;
    }
    var website = '';
    if (this.state.website) {
      website = this.state.website;
    }
    var khac = '';
    if (this.state.khac) {
      khac = this.state.khac;
    }
    var dateFrom = '';
    if (this.state.dateFrom) {
      dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
    }
    var dateTo = '';
    if (this.state.dateTo) {
      dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
    }


    axios.post(Config.API_URL + 'tao-don-viettel?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token, qs.stringify(data))
      .then((response) => {

        console.log(response.data.success);

        if (response.data.error) {
          this.setState({
            loading: false,
          })
          alert("Lỗi: " + response.data.message)
        } else if (response.data.success === false) {
          this.setState({
            loading: false,
          })
          alert("Lỗi: " + response.data.message)
        } else {

          window.open(Config.API_URL + "in-viettel?madon=" + ma_don, '_blank');
          this.setState({
            data: response.data,
            loading: false,
            activePage: response.data.dontongid.current_page,
            itemsCountPerPage: response.data.dontongid.per_page,
            totalItemsCount: response.data.dontongid.total,
            path: response.data.dontongid.path,
            openChuanBiVietTel: false,
          })


          var nguon = this.state.nguon;
          var token_page = '';
          Object.keys(JSON.parse(localStorage.getItem('TOKEN_PAGE'))).forEach(function (k) {
            if (nguon === k) {
              token_page = JSON.parse(localStorage.getItem('TOKEN_PAGE'))[k];
            }
          });

          var text_chat = "Chào Quý Khách!\n- Đơn hàng:" + sp_dagui + "\nsắp tới nơi.\n- Khoảng 2 đến 4 ngày tới QK để ý nghe điện thoại và nhận hàng giúp shop nhé!\nCảm ơn Quý Khách!";
          axios.post('https://graph.facebook.com/v2.9/' + this.state.t_id + '/messages', qs.stringify({ message: text_chat, access_token: token_page }))
            .then((res) => {


            })
            .catch(function (error) {
              console.log(error);
            });


          axios.get(Config.API_URL + 'dem-so-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
            .then(responsedem => {

              this.setState({
                demsodon: responsedem.data,
              })
            })
            .catch(function (error) {
              console.log(error);
            });

          // Nhảy ra trang in
          // window.open(Config.API_URL+"in?madon="+ma_don,'_blank');
          // window.location.href = Config.API_URL+"in?madon="+ma_don;


        }






      })
      .catch(function (error) {
        console.log(error);
      });




  };
  //End gửi chuẩn bị Viettel


  // Gửi chuẩn bị GHN
  guiChuanBiGHN() {
    // if(!this.state.selectedVanChuyenViettel){
    //   alert("Chưa chọn dịch vụ vận chuyển");
    //   return;
    // }



    this.setState({ loading: true })
    let token = localStorage.getItem('token');
    var products = [];
    var sp_dagui = [];
    for (var i = 0; i < this.state.order_detail.length; i++) {
      sp_dagui.push(this.state.order_detail[i].name + " / " + this.state.order_detail[i].thuoctinh + "[" + this.state.order_detail[i].key + "]" + " [" + this.state.order_detail[i].quantity + "cái]");
      products.push(
        {
          "id": parseInt(i + 1),
          "name": this.state.order_detail[i].name + " / " + this.state.order_detail[i].thuoctinh,
          "code": this.state.order_detail[i].key,
          "quantity": parseInt(this.state.order_detail[i].quantity),
          "errors": {
            "name": ""
          }
        }
      )
    }
    var ma_don = this.state.ma_don.replace(Config.MA_PAGE, "");
    console.log(sp_dagui);
    if (this.state.phone_cs) {
      var phone_gui = this.state.phone_cs
    } else {
      var phone_gui = this.state.phone_kho
    }


    if (this.state.ten_shop_order) {
      var ten_shop = this.state.ten_shop_order;
    } else {
      var ten_shop = this.state.ten_shop;
    }

    if(!this.state.selectedVanChuyenGHNXa || !this.state.selectedVanChuyenGHNHuyen || !this.state.selectedVanChuyenGHNTinh){
      alert("Chưa chọn đủ thông tin");
      this.setState({loading: false});
      return;
    }




    // Gửi đơn lên GHN
    axios.post("https://online-gateway.ghn.vn/shiip/public-api/v2/shipping-order/create", {
      "shop_id": 3432530,// Tự lấy địa chỉ ở La Khê
      // "shop_id": 1663369,// Tự lấy địa chỉ ở La Nội
      // "shop_id": 2479364, // Tự lấy địa chỉ ở Giao Thủy
      "payment_type_id": parseInt(1),
      "note": "Không giao được liên hệ người gửi",
      "required_note": "CHOXEMHANGKHONGTHU",


      // "return_phone": "0356525305",
      // "return_address": "Đội 7, Giao Nhân, Giao Thủy, Nam Định",
      // "return_district_id": 3193,
      // "return_ward_code": "250412",

      // La nội
      // "return_phone": "0356525305",
      // "return_address": "Số 19 ngõ 106 La Nội",
      // "return_district_id": 1542,
      // "return_ward_code": "1B1503",

      // La khê

      "return_phone": "0356525305",
      "return_address": "Số 19 ngõ 106 La Nội",
      "return_district_id": 1542,
      "return_ward_code": "1B1503",


      "client_order_code": ma_don,

      "to_name": this.state.full_name,
      "to_phone": this.state.phone,
      "to_address": this.state.address + ", " + this.state.xa + ", " + this.state.huyen + ", " + this.state.tinh,
      "to_ward_code": this.state.selectedVanChuyenGHNXa.value,
      "to_district_id": (this.state.selectedVanChuyenGHNHuyen.value),

      "cod_amount": parseInt(this.state.sumTotal),
      "content": sp_dagui.toString(),
      "weight": parseInt(this.state.tongTrongLuong),
      "length": 10,
      "width": 10,
      "height": 10,

      // "pick_station_id": 20032000, // Mã gửi tại bưu cục, nếu không gửi tại bưu cục thì để trống
      "deliver_station_id": 0,
      "insurance_value": parseInt(this.state.sumTotal),
      "service_id": 53321,
      "service_type_id": 2,
      "coupon": "",
      "total_service": this.state.cuocghn2,
      "file_id": "",
      "items": products,
      "from_location": {},
      "to_location": {
        "lat": null,
        "long": null
      },
      "return_location": {},
      "source": "5sao",
    }, {
      headers: { "Content-Type": "application/json", "ShopId": "3432530", "Token": Config.TOKEN_GHN } // La Khê
      // headers: { "Content-Type": "application/json", "ShopId": "1663369", "Token": Config.TOKEN_GHN } // La Nội
      // headers: { "Content-Type": "application/json", "ShopId": "2479364", "Token": Config.TOKEN_GHN } // Giao thủy
    })
      .then(doneghn => {
        if (doneghn.data) {

          var data = {
            "order": {
              "shop_id": 3432530, // La Khê
              // "shop_id": 1663369, // La Nội
              // "shop_id": 2479364, // Tự lấy địa chỉ ở Giao Thủy
              "payment_type_id": parseInt(1),
              "note": "Giao có vấn đề liên hệ shop ngay",
              "required_note": "CHOXEMHANGKHONGTHU",


              // "return_phone": "0356525305",
              // "return_address": "Đội 7, Giao Nhân, Giao Thủy, Nam Định",
              // "return_district_id": 3193,
              // "return_ward_code": "250412",

              "return_phone": "0396497826",
              "return_address": "Đối diện Số 11 dãy L, ngõ 18 Ngô Quyền, La Khê",
              "return_district_id": 1542,
              "return_ward_code": "1B1506",

              "client_order_code": ma_don,

              "to_name": this.state.full_name,
              "to_phone": this.state.phone,
              "to_address": this.state.address + ", " + this.state.xa + ", " + this.state.huyen + ", " + this.state.tinh,
              "to_ward_code": this.state.selectedVanChuyenGHNXa.value,
              "to_district_id": (this.state.selectedVanChuyenGHNHuyen.value),

              "cod_amount": this.state.sumTotal,
              "content": sp_dagui.toString(),
              "weight": this.state.tongTrongLuong,
              "length": 10,
              "width": 10,
              "height": 10,

              // "pick_station_id": 20032000, // Mã gửi tại bưu cục, nếu không gửi tại bưu cục thì để trống
              // "pick_station_id": 20032000,
              "deliver_station_id": 0,
              "insurance_value": this.state.sumTotal,
              "service_id": 53321,
              "service_type_id": 2,
              "coupon": "",
              "total_service": this.state.cuocghn2,
              "file_id": "",
              "items": products,
              "from_location": {},
              "to_location": {
                "lat": null,
                "long": null
              },
              "return_location": {},
              "source": "5sao",
            },
            'ma_don': ma_don,
            'van_don': doneghn.data.data.order_code,
          }
          // Get data
          var trangThai = '';
          if (this.state.trangThai) {
            trangThai = this.state.trangThai;
          }
          var search = '';
          if (this.state.search) {
            search = this.state.search;
          }
          var website = '';
          if (this.state.website) {
            website = this.state.website;
          }
          var khac = '';
          if (this.state.khac) {
            khac = this.state.khac;
          }
          var dateFrom = '';
          if (this.state.dateFrom) {
            dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
          }
          var dateTo = '';
          if (this.state.dateTo) {
            dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
          }


          axios.post(Config.API_URL + 'tao-don-ghn?trangThai=' + trangThai + '&search=' + search + '&website=' + website + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&khac=' + khac + "&page=" + this.state.page + "&token=" + token, qs.stringify(data))
            .then((response) => {

              console.log(response.data.success);

              if (response.data.error) {
                this.setState({
                  loading: false,
                })
                alert("Lỗi: " + response.data.message)
              } else if (response.data.success === false) {
                this.setState({
                  loading: false,
                })
                alert("Lỗi: " + response.data.message)
              } else {


                // Get token in
                axios.post("https://online-gateway.ghn.vn/shiip/public-api/v2/a5/gen-token", { "order_codes": ["" + doneghn.data.data.order_code + ""], "source": "5sao" }, {
                  headers: { "Content-Type": "application/json", "Token": Config.TOKEN_GHN }
                })
                  .then(responseina5 => {
                    if (responseina5.data.data) {
                      window.open(Config.API_URL + "in-ghn?madon=" + ma_don, '_blank');
                      this.setState({
                        data: response.data,
                        loading: false,
                        activePage: response.data.dontongid.current_page,
                        itemsCountPerPage: response.data.dontongid.per_page,
                        totalItemsCount: response.data.dontongid.total,
                        path: response.data.dontongid.path,
                        openChuanBiGHN: false,
                      })
                    }
                  })
                  .catch(function (error) {
                    alert("Địa chỉ không hợp lệ")
                    console.log(error.data);
                  });
                // End Get token in




                axios.get(Config.API_URL + 'dem-so-don?token=' + token + '&trangThai=' + trangThai + '&search=' + search)
                  .then(responsedem => {

                    this.setState({
                      demsodon: responsedem.data,
                    })
                  })
                  .catch(function (error) {
                    console.log(error);
                  });


              }


            })
            .catch(function (error) {
              console.log(error);
            });

        }
      })
      .catch(function (error) {
        alert(error.response.data.code_message_value);
        console.log(error.response);
      });
    // End Gửi đơn lên GHN







  };
  // End gửi chuẩn bị GHN

  tableRow() {


    if (this.state.data.don_tong) {
      var all_product = [];
      var rows = [];
      this.state.data.dontongid.data.map((valueOrder, key) => {

        var soLuong = 0;
        var cohang = 0;
        var ton_kho = 0;
        rows.push(

          <section className="ui-card " key={key} id={"order-" + valueOrder.id} >
            <div className="ui-card__section has-bulk-actions orders ">
              <div className="ui-type-container">
                <div className="orderContext">
                  <table id="order-table" className="table-hover quick-view-order bulk-action-context expanded">
                    <tbody>

                      <tr className="ui-nested-link-container parent-quick-view">
                        <td className="no-wrap col-md-2 col-sm-12 col-xs-12">
                          <Link to={"/order/" + valueOrder.id} data-nested-link-target="true">#{valueOrder.id}</Link>
                        </td>
                        <td className="no-wrap col-md-3 col-sm-12 col-xs-12 hidden-sm hidden-xs ">
                          <span>{valueOrder.created_at}</span>
                        </td>
                        {/* <td className="no-wrap col-md-3 col-sm-12 col-xs-12">
                          <span className={
                            valueOrder.trang_thai === 'Đã hủy' ? "badge badge--status-complete badge--progress-complete"
                              :
                              valueOrder.trang_thai === 'Đã xác nhận' ? "badge badge--status-success badge--progress-complete"
                                :
                                "badge badge--status-warning badge--progress-incomplete"
                          }>
                            {valueOrder.trang_thai}
                          </span>
                        </td> */}
                        <td className="no-wrap">
                          <span className={
                            valueOrder.giao_hang === 'Chưa chuyển' ? "badge badge--status-warning badge--progress-complete"
                              :
                              valueOrder.giao_hang === 'Đã lấy hàng/Đã nhập kho' ? "badge badge--status-success badge--progress-complete"
                                :
                                valueOrder.giao_hang === 'Delay lấy hàng' ? "badge badge--status-warning badge--progress-complete"
                                  :
                                  "badge badge--status-complete badge--progress-incomplete"
                          }>{valueOrder.giao_hang}</span>
                        </td>


                        {/* <td className="no-wrap">
                          <span className="badge badge--status-complete badge--progress-incomplete">
                          {valueOrder.tao_don?"Tạo đơn: "+valueOrder.tao_don:""}
                          </span>
                        </td> */}

                        <td className="no-wrap">
                          <span className="badge badge--status-success badge--progress-complete">
                            {valueOrder.cs ? "Chăm sóc: " + valueOrder.cs : ""}
                          </span>
                        </td>

                        {
                          valueOrder.ly_do_hoan ?
                            <td className="no-wrap">
                              <span className={"badge badge--status-complete badge--progress-incomplete"}>{"Lý do hoàn: " + valueOrder.ly_do_hoan}</span>
                            </td>
                            :
                            ""
                        }

                        {
                          valueOrder.nhan_hang_hoan ?
                            <td className="no-wrap">
                              <span className={"badge badge--status-warning badge--progress-incomplete"}>Đã nhận hàng hoàn</span>
                            </td>
                            :
                            ""
                        }



                      </tr>

                      <tr className="panel-quick-view panel-quick-view-open" style={{ 'backgroundColor': "#ffffff00" }}>
                        <td colSpan={7} className>
                          <div className="row" style={{ marginBottom: 0 }}>




                            <div className="line-mobile col-md-3 col-sm-12 col-xs-12 ">
                              <div className="next-card__section hide-when-printing grid-order-line-item-title">
                                <h3 className="next-heading next-heading--tiny next-heading--no-margin">
                                  <strong>Sản phẩm</strong>
                                </h3>
                              </div>
                              <div className="next-card__section next-card__section--no-vertical-spacing next-card__section_modify">
                                <table className="table--extra-expanded table--no-side-padding table--divided">
                                  <tbody>

                                    {

                                      JSON.parse(JSON.stringify(this.state.data.don_chi_tiet))[valueOrder.id].map((value, key) => {
                                        soLuong += parseInt(value.quantity);

                                        if (this.state.ton_kho) {
                                          var tonkho = this.state.ton_kho.filter(function (returnableObjects) {
                                            if (returnableObjects) {
                                              return returnableObjects.modelid === value.key;
                                            } else {
                                              return null;
                                            }

                                          })


                                        }

                                        //  console.log("tonkho "+tonkho);


                                        if (this.state.ton_kho && tonkho && tonkho[0] && tonkho[0].stock) {
                                          cohang += parseInt(tonkho[0].stock - tonkho[0].giu_cho);
                                          ton_kho = parseInt(tonkho[0].stock);
                                        }
                                        var html = "<img src=" + value.image + " width='400px' />"
                                        return (
                                          <tr key={key}   >
                                            <td width="50px" className="hide-when-printing">
                                              <div className="aspect-ratio aspect-ratio--square aspect-ratio--square--50" data-tip={html}>
                                                <img className="aspect-ratio__content" src={value.image} />
                                                <ReactTooltip html={true} place={'right'} />
                                              </div>
                                            </td>
                                            <td width="100%" style={{ paddingLeft: 10 }}>
                                              <a href={Config.HOME_URL + value.url} target="_blank">{value.name}</a>
                                              <p>
                                                <span>{value.color ? value.color + " / " : ""}{value.size ? value.size : ""}{value.thuoctinh ? value.thuoctinh : ""}</span>
                                              </p>
                                              <p>
                                                <span>SKU: {value.key ? value.key : ""} {value.trong_luong ? "(" + value.trong_luong + "gam)" : ""}</span>
                                              </p>
                                              <p>
                                                <span>{parseInt(value.price).toLocaleString()}₫</span> x <span>{value.quantity}</span>
                                              </p>
                                              <p className="type--subdued">
                                                {
                                                  this.state.ton_kho && tonkho.length > 0 && tonkho[0].stock && (tonkho[0].stock - tonkho[0].giu_cho) < 1 ?
                                                    <span style={{ color: 'red' }}> Trong kho: {tonkho[0].stock} <br /> Giữ chỗ: {tonkho[0].giu_cho} <br /> Nợ hàng: {tonkho[0].stock - tonkho[0].giu_cho} </span>
                                                    :
                                                    this.state.ton_kho && tonkho.length > 0 && tonkho[0].stock && (tonkho[0].stock - tonkho[0].giu_cho) >= 1 ?
                                                      <span style={{ color: 'green' }}> Trong kho: {tonkho[0].stock} <br /> Giữ chỗ: {tonkho[0].giu_cho} <br /> Khả dụng: {tonkho[0].stock - tonkho[0].giu_cho}</span>
                                                      :
                                                      ""
                                                }
                                              </p>

                                            </td>
                                          </tr>
                                        )
                                      })}

                                  </tbody>
                                </table>
                              </div>
                            </div>




                            <div className="col-md-2 col-sm-12 col-xs-12" style={{ paddingTop: 5 }}>
                              <table className="grid-order-table-info">
                                <tbody>
                                  <tr>
                                    <td>
                                      Số lượng: {soLuong}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Tiền hàng: {parseInt(valueOrder.countTotal).toLocaleString() + "₫"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Trọng lượng: {parseInt(valueOrder.tongTrongLuong).toLocaleString() + "g"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Phí VC: {parseInt(valueOrder.feeShip).toLocaleString() + "₫"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Tổng cộng: {parseInt(valueOrder.sumTotal).toLocaleString() + "₫"}</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>


                            <div className="col-md-3 col-sm-12 col-xs-12" style={{ paddingTop: 5 }}>
                              <table className="grid-order-table-info">
                                <tbody>
                                  <tr>
                                    <td className="grid-order-cus-info-icon"><i className="fa fa-user" aria-hidden="true" /></td>
                                    <td style={{ paddingLeft: 5 }}>
                                      {valueOrder.full_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="grid-order-cus-info-icon"><i className="fa fa-phone" aria-hidden="true" /></td>
                                    <td style={{ paddingLeft: 5 }}>
                                      {valueOrder.phone} ({check_phone(valueOrder.phone)}) (<a href={"check-info?phone=" + valueOrder.phone} target="_blank">Check Info</a>)
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="grid-order-cus-info-icon"><i className="fa fa-map-marker" aria-hidden="true" /></td>
                                    <td style={{ paddingLeft: 5 }}>
                                      <span>{valueOrder.address}</span><br />
                                      <span>{valueOrder.xa}</span><br />
                                      <span>{valueOrder.huyen}</span><br />
                                      <span>{valueOrder.tinh}</span><br />
                                      <span>{valueOrder.khu_vuc_vnpost}</span>

                                    </td>
                                  </tr>




                                  {
                                    valueOrder.van_don ?
                                      <tr>

                                        <td className="grid-order-cus-info-icon">
                                          {
                                            valueOrder.hang_van_chuyen == 'GHTK' ?
                                              <img src="https://api.goship.io/storage/images/carriers/ghtk_c.png"></img>
                                              :
                                              valueOrder.hang_van_chuyen == 'Viettel' ?
                                                <img src="https://viettelpost.com.vn/wp-content/uploads/2021/01/380x270.jpg"></img>
                                                :
                                                valueOrder.hang_van_chuyen == 'Bưu điện' ?
                                                  <img src="https://api.goship.io/storage/images/carriers/vnpost_c.png"></img>
                                                  :
                                                  ""
                                          }
                                        </td>
                                        <td >
                                          {
                                            valueOrder.hang_van_chuyen == 'GHTK' ?
                                              <a href={"https://khachhang.giaohangtietkiem.vn/khachhang?customer_created_from=2018-01-01+00:00:00&code=" + valueOrder.van_don} target="_blank"> {valueOrder.van_don} </a>
                                              :
                                              valueOrder.hang_van_chuyen == 'Viettel' ?
                                                <a href={"https://viettelpost.vn/thong-tin-don-hang?peopleTracking=sender&orderNumber=" + valueOrder.van_don} target="_blank"> {valueOrder.van_don} </a>
                                                :
                                                valueOrder.hang_van_chuyen == 'GHN' ?
                                                  <a href={"https://donhang.ghn.vn/?order_code=" + valueOrder.van_don} target="_blank"> {valueOrder.van_don} </a>
                                                  :
                                                  <a href={"http://www.vnpost.vn/vi-vn/dinh-vi/buu-pham?key=" + valueOrder.van_don} target="_blank"> {valueOrder.van_don} </a>
                                          }
                                          <br></br>
                                          {
                                            valueOrder.hang_van_chuyen == 'GHTK' ?
                                              <a href={Config.API_URL + "update-ghtk?label_id=" + valueOrder.van_don} target="_blank">(Cập nhật)</a>
                                              :
                                              <div>
                                                <div>{valueOrder.dinh_vi ? "🏪 " + valueOrder.dinh_vi : ""}</div>
                                                <div>{valueOrder.sdt_buu_ta ? "📱 " + valueOrder.sdt_buu_ta : ""}</div>
                                                <div>{valueOrder.ly_do_phat_buu_dien ? "📢 " + valueOrder.ly_do_phat_buu_dien : ""}</div>
                                              </div>
                                          }

                                        </td>



                                      </tr>

                                      :
                                      ""
                                  }
                                  {
                                    valueOrder.phi_gui_hang ?
                                      <tr>
                                        <td className="grid-order-cus-info-icon"><i className="fa fa-plane" aria-hidden="true" /></td>
                                        <td >{"Phí gửi hàng: " + valueOrder.phi_gui_hang.toLocaleString() + "đ"}</td>
                                      </tr>
                                      :
                                      ""
                                  }

                                  {
                                    valueOrder.giao_hang == 'Không giao được hàng' && valueOrder.phi_hoan_hang || valueOrder.giao_hang == 'Đã đối soát trả hàng' && valueOrder.phi_hoan_hang ?
                                      <tr>
                                        <td className="grid-order-cus-info-icon"><i className="fa-flip-horizontal fa fa-plane" aria-hidden="true" /></td>
                                        <td >{"Phí hoàn hàng: " + valueOrder.phi_hoan_hang.toLocaleString() + "đ"}</td>
                                      </tr>
                                      :
                                      ""
                                  }

                                  {
                                    valueOrder.giao_hang == 'Không giao được hàng' && valueOrder.phi_hoan_hang || valueOrder.giao_hang == 'Đã đối soát trả hàng' && valueOrder.phi_hoan_hang ?
                                      <tr>
                                        <td className="grid-order-cus-info-icon"><i className="fa-flip-horizontal fa fa-plane" aria-hidden="true" /></td>
                                        <td >{"Tổng phí: " + (valueOrder.phi_gui_hang + valueOrder.phi_hoan_hang).toLocaleString() + "đ"}</td>
                                      </tr>
                                      :
                                      valueOrder.phi_gui_hang ?
                                        <tr>
                                          <td className="grid-order-cus-info-icon"><i className="fa-flip-horizontal fa fa-plane" aria-hidden="true" /></td>
                                          <td >{"Tổng phí: " + parseInt(valueOrder.phi_gui_hang).toLocaleString() + "đ"}</td>
                                        </tr>
                                        :
                                        ""
                                  }

                                </tbody></table>
                            </div>

                            <div className="line-mobile col-md-2">

                              <div>
                                {/* {
                                valueOrder.note ? 
                                <div className="has-error">
                                  <span style={{backgroundColor: 'rgb(254, 237, 186)', paddingBottom: '3px', paddingRight: '10px'}}><span className="field-validation-valid" data-valmsg-for="Note" data-valmsg-replace="true" />{valueOrder.note}</span>
                                </div>
                                :
                                ""
                              } */}

                                {
                                  valueOrder.note ?
                                    <span onClick={() => this.onOpenModalGhiChu(valueOrder.id, valueOrder.note)} style={{ backgroundColor: 'rgb(254, 237, 186)', paddingBottom: '3px', paddingRight: '10px' }}><span className="field-validation-valid" data-valmsg-for="Note" data-valmsg-replace="true" />{valueOrder.note}</span>
                                    :
                                    <span onClick={() => this.onOpenModalGhiChu(valueOrder.id, valueOrder.note)} style={{ paddingBottom: '3px', paddingRight: '10px' }}><span className="field-validation-valid" data-valmsg-for="Note" data-valmsg-replace="true" /><i className="fa fa-pencil-square-o" aria-hidden="true" /> Thêm ghi chú</span>

                                }
                                <br></br>



                                {
                                  this.state.check_trung ?
                                    this.state.check_trung.map((value, key) => {

                                      if (parseInt(value[0]) === parseInt(valueOrder.phone) && value[1] >= 2) {
                                        return <div>
                                          <a href={'orders?search=' + valueOrder.phone} target="_blank">
                                            <p><button className="ui-button ui-button--xemtrung quick-view-order-btn-refund" href="javascript:void(0);"><i className="fa fa-exclamation-triangle" aria-hidden="true" /> Xem đơn trùng</button></p>
                                          </a>
                                        </div>
                                      }

                                    })
                                    :
                                    ""
                                }


                                {
                                  this.state.lich_su_giao ?
                                    this.state.lich_su_giao.map((value, key) => {

                                      if (parseInt(value[0]) === parseInt(valueOrder.id)) {


                                        return Object.keys(value[1]).map(key2 =>
                                          <font color='red'>
                                            <option title={'Lần ' + (parseInt(key2) + 1) + ': ' + value[1][key2]['ly_do'] + ' lúc ' + value[1][key2]['created_at']} style={{ width: '150px', textOverflow: 'ellipsis', cursor: 'pointer', overflow: 'hidden', whiteSpace: 'nowrap' }} value={key2}>
                                              {'Lần ' + (parseInt(key2) + 1) + ': ' + value[1][key2]['ly_do'] + ' lúc ' + value[1][key2]['created_at']}
                                            </option>
                                          </font>
                                        )



                                      }

                                    })
                                    :
                                    ""
                                }






                                {/* <textarea onBlur={(event) => this.isChangeNote(valueOrder.id,event)} className="form-control textarea-mobile next-input" cols={20} name="Note" placeholder="Nhập Ghi chú" rows={3} style={{ color:'red', resize: 'none' }} defaultValue={valueOrder.note ? valueOrder.note : ""} /> */}



                                {
                                  // valueOrder.trang_thai !== 'Đã hủy' && !valueOrder.van_don && cohang && cohang >= 0 && !valueOrder.van_don? 
                                  valueOrder.trang_thai !== 'Đã hủy' && !valueOrder.van_don && ton_kho > 0 && !valueOrder.van_don && valueOrder.telesale === 'Thành công' && JSON.parse(localStorage.getItem('info')).kho == '1' ?
                                    this.state.loading ?
                                      ""
                                      :
                                      <p><button onClick={() => this.onOpenModalChuanBi(valueOrder.id, valueOrder.van_don, valueOrder.sumTotal, JSON.parse(JSON.stringify(this.state.data.don_chi_tiet))[valueOrder.id], valueOrder.full_name, valueOrder.phone, valueOrder.address + ", " + valueOrder.xa, valueOrder.huyen, valueOrder.tinh, valueOrder.t_id, valueOrder.nguon, valueOrder.cs, valueOrder.ten_shop)} style={{ textAlign: 'center' }} className="ui-button ui-button--GHTK quick-view-order-btn-refund">Chuẩn bị GHTK</button></p>
                                    :
                                    ""
                                }


                                {valueOrder.trang_thai !== 'Đã hủy' && !valueOrder.van_don && ton_kho > 0 && !valueOrder.van_don && valueOrder.telesale === 'Thành công' && JSON.parse(localStorage.getItem('info')).kho == '1' ?
                                  this.state.loading ?
                                    ""
                                    :
                                    <p><button onClick={() => this.onOpenModalChuanBiVietTel(valueOrder.id, valueOrder.van_don, valueOrder.sumTotal, JSON.parse(JSON.stringify(this.state.data.don_chi_tiet))[valueOrder.id], valueOrder.full_name, valueOrder.phone, valueOrder.address + ", " + valueOrder.xa, valueOrder.huyen, valueOrder.tinh, valueOrder.t_id, valueOrder.nguon, valueOrder.cs, valueOrder.ten_shop, valueOrder.tongTrongLuong)} style={{ textAlign: 'center' }} className="ui-button--viettel quick-view-order-btn-refund">Chuẩn bị ViettelPost</button></p>
                                  :
                                  ""
                                }

                                {valueOrder.trang_thai !== 'Đã hủy' && !valueOrder.van_don && ton_kho > 0 && !valueOrder.van_don && valueOrder.telesale === 'Thành công' && JSON.parse(localStorage.getItem('info')).kho == '1' ?
                                  this.state.loading ?
                                    ""
                                    :
                                    <p><button onClick={() => this.onOpenModalChuanBiGHN(valueOrder.created_at,valueOrder.id, valueOrder.van_don, valueOrder.sumTotal, JSON.parse(JSON.stringify(this.state.data.don_chi_tiet))[valueOrder.id], valueOrder.full_name, valueOrder.phone, valueOrder.address, valueOrder.xa, valueOrder.huyen, valueOrder.tinh, valueOrder.t_id, valueOrder.nguon, valueOrder.cs, valueOrder.ten_shop, valueOrder.tongTrongLuong)} style={{ textAlign: 'center' }} className="ui-button--ghn quick-view-order-btn-refund">Chuẩn bị GHN</button></p>
                                  :
                                  ""
                                }



                                {
                                  valueOrder.trang_thai !== 'Đã hủy' && !valueOrder.van_don && ton_kho > 0 && !valueOrder.van_don && valueOrder.telesale === 'Thành công' && JSON.parse(localStorage.getItem('info')).kho == '1' ?
                                    this.state.loading ?
                                      ""
                                      :
                                      <p><button onClick={() => this.chuanBiVnPost(valueOrder.id)} style={{ textAlign: 'center' }} className="ui-button ui-button--buu-dien quick-view-order-btn-refund">Chuẩn bị Bưu Điện</button></p>
                                    :
                                    ""
                                }


                              </div>
                            </div>

                            <div className="col-md-2 panel-quick-view-action">
                              {/* {
                                valueOrder.trang_thai==='Chờ xác nhận'?
                                
                                  this.state.loading ?

                                    ""
                                    
                                    :
                                    <p><button onClick={() => this.updateTrangThai(valueOrder.id, 'Đã xác nhận')} className="ui-button btn-default quick-view-order-btn-cancel" href="javascript:void(0);">Xác nhận</button></p>

                                
                                :
                                ""
                              } */}





                              {
                                valueOrder.giao_hang === 'Chưa chuyển' && JSON.parse(localStorage.getItem('info')).kho != '1' ?
                                  <p><button onClick={() => this.updateTrangThai(valueOrder.id, 'Ưu tiên')} className="ui-button ui-button--uutien quick-view-order-btn-refund" href="javascript:void(0);"><i className="fa fa-paper-plane" aria-hidden="true" /> Ưu tiên</button></p>
                                  :
                                  ""
                              }

                              {
                                valueOrder.trang_thai !== 'Đã hủy' && JSON.parse(localStorage.getItem('info')).kho != '1' && !valueOrder.van_don ?
                                  this.state.loading ?

                                    ""
                                    :
                                    <p><button onClick={() => this.updateTrangThai(valueOrder.id, 'Đã hủy')} className="ui-button btn-default quick-view-order-btn-cancel" href="javascript:void(0);">Hủy</button></p>
                                  :
                                  ""
                              }

                              {JSON.parse(localStorage.getItem('info')).administrator == '1' ?
                                this.state.loading ?

                                  ""
                                  :
                                  <p><button onClick={() => this.updateTrangThai(valueOrder.id, 'Đã hủy')} className="ui-button btn-default quick-view-order-btn-cancel" href="javascript:void(0);">Hủy</button></p>
                                :
                                ""
                              }



                              {

                                JSON.parse(localStorage.getItem('info')).quyen == 1 ?
                                  valueOrder.trang_thai == 'Đã gửi' && valueOrder.giao_hang == 'Đã gửi' ?
                                    this.state.loading ?

                                      ""

                                      :
                                      <p><button onClick={() => this.updateTrangThai(valueOrder.id, 'Đã giao hàng')} className="ui-button btn-default quick-view-order-btn-cancel" href="javascript:void(0);">Đã giao</button></p>
                                    :
                                    ""
                                  :
                                  ""
                              }

                              {
                                valueOrder.giao_hang == 'Đã chuẩn bị' && JSON.parse(localStorage.getItem('info')).quyen == 1 ?
                                  this.state.loading ?

                                    ""

                                    :
                                    <p><button onClick={() => this.updateTrangThai(valueOrder.id, 'Đã giao hàng')} className="ui-button btn-default quick-view-order-btn-cancel" href="javascript:void(0);">Đã giao</button></p>
                                  :
                                  ""
                              }


                              {
                                valueOrder.giao_hang && JSON.parse(localStorage.getItem('info')).quyen == 1 ?
                                  this.state.loading ?

                                    ""

                                    :
                                    <p><button onClick={() => this.updateTrangThai(valueOrder.id, 'Đã đối soát')} className="ui-button btn-default quick-view-order-btn-cancel" href="javascript:void(0);">Đã đối soát</button></p>
                                  :
                                  ""
                              }




                              {
                                valueOrder.van_don ?
                                  this.state.loading ?
                                    ""
                                    :
                                    valueOrder.hang_van_chuyen == 'Viettel' ?
                                      <p><a target="_blank" href={Config.API_URL + "in-viettel?madon=" + valueOrder.id}> <button style={{ textAlign: 'center' }} className="ui-button ui-button--destructive quick-view-order-btn-refund">In Phiếu Viettel</button></a></p>
                                      :
                                      valueOrder.hang_van_chuyen == 'GHN' ?
                                        <p><a target="_blank" href={Config.API_URL + "in-ghn?madon=" + valueOrder.id}> <button style={{ textAlign: 'center' }} className="ui-button ui-button--destructive quick-view-order-btn-refund">In Phiếu GHN</button></a></p>
                                        :
                                        <p><a target="_blank" href={Config.API_URL + "in?madon=" + valueOrder.id}> <button style={{ textAlign: 'center' }} className="ui-button ui-button--destructive quick-view-order-btn-refund">In Phiếu</button></a></p>
                                  :
                                  ""
                              }


                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>


        )
      })

      if (rows.length < 1) {
        return <div className="col-sm-12" style={{ marginTop: "20px" }}>
          <center><h6>KHÔNG CÓ ĐƠN HÀNG THỎA MÃN</h6></center>
        </div>;
      } else {
        return <div style={{ marginTop: "20px" }}>{rows}</div>;
      }
    }

  }

  isChange = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({
      [name]: value
    });
  }
  isChangeNote = (ma_don, event) => {

    var value = event.target.value;
    let token = localStorage.getItem('token');
    if (value) {
      axios.post(Config.API_URL + 'cap-nhat-ghi-chu?token=' + token, qs.stringify({
        'ma_don': ma_don,
        'note': value,
      }))

        .then(response => {
          NotificationManager.success('Cập nhật ghi chú thành công: ' + ma_don, '', 2000, false);
        })
        .catch(() => {
          this.setState({ loading: false });
          alert('Có lỗi xảy ra')
        });
    }


  }


  capNhatGhiChu() {
    this.setState({ loading: true });
    if (this.state.id && this.state.note) {



      this.setState({ loading: true });
      var order = {};
      order.id = this.state.id; // Mã đơn
      order.note = this.state.note;

      var trangThai = '';
      if (this.state.trangThai) {
        trangThai = this.state.trangThai;
      }
      var search = '';
      if (this.state.search) {
        search = this.state.search;
      }
      var website = '';
      if (this.state.website) {
        website = this.state.website;
      }
      var khac = '';
      if (this.state.khac) {
        khac = this.state.khac;
      }
      var dateFrom = '';
      if (this.state.dateFrom) {
        dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
      }
      var dateTo = '';
      if (this.state.dateTo) {
        dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
      }

      let token = localStorage.getItem('token');
      axios.post(Config.API_URL + 'cap-nhat-ghi-chu-order?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + "&page=" + this.state.page, qs.stringify(order))
        .then(response => {


          this.setState({
            data: response.data,
            loading: false,
            activePage: response.data.dontongid.current_page,
            itemsCountPerPage: response.data.dontongid.per_page,
            totalItemsCount: response.data.dontongid.total,
            path: response.data.dontongid.path,
            openModalGhiChu: false
          });
        })
        .catch(function (error) {
          console.log(error);
          alert("Lỗi")
        });





    } else {
      alert('Chưa nhập đủ thông tin')
    }
  }






  // VNPOST

  chuanBiVnPost(id) {

    var trangThai = '';
    if (this.state.trangThai) {
      trangThai = this.state.trangThai;
    }
    var search = '';
    if (this.state.search) {
      search = this.state.search;
    }
    var website = '';
    if (this.state.website) {
      website = this.state.website;
    }
    var khac = '';
    if (this.state.khac) {
      khac = this.state.khac;
    }
    var dateFrom = '';
    if (this.state.dateFrom) {
      dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
    }
    var dateTo = '';
    if (this.state.dateTo) {
      dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
    }

    var order = {};
    order.ma_don = id; // Mã đơn
    let token = localStorage.getItem('token');
    axios.post(Config.API_URL + 'vnpost/chuan-bi?token=' + token + '&trangThai=' + trangThai + '&search=' + search + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + "&page=" + this.state.page, qs.stringify(order))
      .then(response => {
        window.open(Config.API_URL + "vnpost/in?id=" + id, '_blank');
        this.setState({
          data: response.data,
          loading: false,
          activePage: response.data.dontongid.current_page,
          itemsCountPerPage: response.data.dontongid.per_page,
          totalItemsCount: response.data.dontongid.total,
          path: response.data.dontongid.path,
          openModalGhiChu: false
        });
      })
      .catch(function (error) {
        console.log(error);
        alert("Lỗi")
      });
  }

  // END VNPOST

  render() {
    const { selectedLyDo } = this.state;
    const { selectedCaLay } = this.state;
    const { selectedVanChuyenViettel } = this.state;
    const { selectedVanChuyenGHNTinh } = this.state;
    const { selectedVanChuyenGHNHuyen } = this.state;
    const { selectedVanChuyenGHNXa } = this.state;

    const tinhGHN = this.state.tinhGHN;
    const huyenGHN = this.state.huyenGHN;
    const xaGHN = this.state.xaGHN;

    const { openModalGhiChu } = this.state;
    if (this.state.t_id) {
      console.log("sdsd " + this.state.t_id);
    }

    if(this.state.selectedVanChuyenGHNTinh){
      console.log(this.state.selectedVanChuyenGHNTinh);
    }

    // Modal
    const { openChuanBi } = this.state;
    const { openChuanBiVietTel } = this.state;
    const { openChuanBiGHN } = this.state;
    // Modal
    return (
      <div id="content">
        <NotificationContainer />
        {/* <OverlayLoader
          color={'red'}
          loader="ScaleLoader"
          // text="Loading... Please wait!"
          active={this.state.loading}
          backgroundColor={'black'}
          opacity=".4"
        > */}

        <div className="page default has-contextual-help page-order">
          <header className="ui-title-bar-container  ui-title-bar-container--full-width">
            <div className="ui-title-bar ">
              <div className="ui-title-bar__main-group">
                <div className="ui-title-bar__heading-group">
                  <span className="ui-title-bar__icon">
                    <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                    </svg>
                  </span>
                  <h1 className="ui-title-bar__title">Đơn hàng</h1>
                </div>
                <div className="action-bar">
                  <div className="ui-title-bar__mobile-primary-actions">
                    <div className="ui-title-bar__actions">
                      <Link to="/create" className="ui-button ui-button--primary ui-title-bar__action">Tạo đơn hàng</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ui-title-bar__actions-group">
                <div className="ui-title-bar__actions">
                  <Link to="/create" className="ui-button ui-button--primary ui-title-bar__action">Tạo đơn hàng</Link>
                </div>
              </div>
            </div>
            <div className="collapsible-header">
              <div className="collapsible-header__heading" />
            </div>
          </header>
          <div className="ui-layout ui-layout--full-width">
            <div className="ui-layout__sections">
              <div className="ui-layout__section">
                <div className="ui-layout__item">
                  <section className="ui-card ">
                    <div className id="filterAndSavedSearch" define="{filterer: new Bizweb.OrderFilterAndSavedSearch(this,{&quot;type_filter&quot;:&quot;orders&quot;})}" context="filterer">
                      <div className="next-tab__container ">
                        <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">

                          <li className="filter-tab-item" onClick={() => this.chonTrangThai()}>
                            <a className={!this.state.trangThai ? "filter-tab next-tab next-tab--is-active" : "filter-tab next-tab"}>Tất cả ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).tat_ca:"" })</a>
                            {/* <a className={!this.state.trangThai ? "filter-tab next-tab next-tab--is-active" : "filter-tab next-tab"}>Tất cả</a> */}
                          </li>

                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('uu_tien')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'uu_tien' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"} >Ưu tiên ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).uu_tien : ""})</a>
                          </li>

                          {/* <li className="filter-tab-item" onClick={() => this.chonTrangThai('cho_xac_nhan')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'cho_xac_nhan' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"} >Chờ x.nhận ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).cho_xac_nhan:"" })</a>
                          </li>
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('da_xac_nhan')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'da_xac_nhan' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Đã x.nhận ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).da_xac_nhan:"" })</a>
                          </li> */}
                          {/* <li className="filter-tab-item" onClick={() => this.chonTrangThai('luu_tru')}>
                            <a className={this.state.trangThai && this.state.trangThai === 'luu_tru' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Lưu trữ ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).luu_tru:"" })</a>
                          </li> */}
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('chua_chuyen')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'chua_chuyen' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Chưa chuyển ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).chua_chuyen : ""})</a>
                          </li>
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('da_gui')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'da_gui' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Đã gửi ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).da_gui : ""})</a>
                          </li>
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('delay')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'delay' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Delay ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).delay : ""})</a>
                          </li>
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('khong_lay_duoc')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'khong_lay_duoc' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Không lấy ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).khong_lay_duoc : ""})</a>
                          </li>
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('da_lay')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'da_lay' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Đã lấy ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).da_lay : ""})</a>
                          </li>
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('dang_giao_hang')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'dang_giao_hang' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Đang giao ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).dang_giao_hang : ""})</a>
                          </li>

                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('giao_loi')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'giao_loi' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Giao lỗi ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).giao_loi : ""})</a>
                          </li>


                          {/* <li className="filter-tab-item" onClick={() => this.chonTrangThai('da_giao')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'da_giao' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}><font color="blue">Đã giao </font> ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).da_giao:"" })</a>
                          </li>
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('da_doi_soat')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'da_doi_soat' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}><font color="blue">Đã đối soát </font> ({ this.state.demsodon?JSON.parse(JSON.stringify(this.state.demsodon)).da_doi_soat:"" })</a>
                          </li> */}
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('soat_hoan')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'soat_hoan' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}><font color="#ffae00">Soát hoàn </font> ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).soat_hoan : ""})</a>
                          </li>

                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('dang_hoan')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'dang_hoan' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}><font color="red">Đang Hoàn </font> ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).dang_hoan : ""})</a>
                          </li>
                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('da_hoan')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'da_hoan' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}><font color="#ffae00">Đã hoàn </font> ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).da_hoan : ""})</a>
                          </li>



                          <li className="filter-tab-item" onClick={() => this.chonTrangThai('da_huy')} >
                            <a className={this.state.trangThai && this.state.trangThai === 'da_huy' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Đã hủy ({this.state.demsodon ? JSON.parse(JSON.stringify(this.state.demsodon)).da_huy : ""})</a>
                          </li>

                        </ul>
                      </div>
                      <div className="next-card__section next-card__section--no-bottom-spacing">
                        <div className="obj-filter hide-when-printing table-filter-container">
                          <div className="next-input-wrapper">
                            <div className="next-field__connected-wrapper">

                              <div className="next-form next-form--full-width next-field--connected--no-border-radius">
                                <div className="next-input--stylized next-field--connected">
                                  <span className="next-input__add-on next-input__add-on--before">
                                    <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                  </span>
                                  <input type="text" onKeyDown={this._handleKeyDown} onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm đơn hàng" className="next-input next-input--invisible" />
                                </div>

                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ui-card__section has-bulk-actions orders "></div>
                  </section>
                  {this.tableRow()}

                  {/* {
                    this.state.loading ?
                      <div className="row">
                        <div className="col-md-12 pager">
                          <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                        </div>
                      </div>

                      
                      :
                     
                      <div>
                        {this.tableRow()}
                       
                        
                      </div>
 
                  } */}




                </div>
                <div className="ui-footer-help">
                  {
                    this.state.totalItemsCount ?
                      <Pagination
                        className="pagination-sm"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />
                      :
                      ""
                  }

                </div>
              </div>
            </div>
          </div>


          {/* Modal Chuẩn bị */}
          <Modal open={openChuanBi} onClose={this.onCloseModalChuanBi} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
            <div className="modal-content" style={{ 'width': '500px' }}>
              <div className="ui-modal__header">
                <div className="ui-modal__header-title">
                  <h2 className="ui-title" id="ModalTitle">Chuẩn bị hàng GHTK</h2>
                </div>
              </div>
              <div className="ui-modal__body">
                <div className="ui-modal__section">

                  <Select
                    value={selectedLyDo}
                    onChange={this.handleChangeLyDo}
                    options={trangthai}
                    placeholder='Chọn trạng trái'
                  />
                  <br></br>
                  <Select
                    value={selectedCaLay}
                    onChange={this.handleChangeCaLay}
                    options={this.state.caLay}
                    placeholder='Ca lấy'
                  />
                  <br></br>
                  <label class="next-label">Tên Shop: {this.state.ten_shop_gui}</label>
                  <label class="next-label">Địa chỉ: {this.state.dia_chi_kho}</label>
                  <label class="next-label">SĐT: {this.state.phone_kho_gui}</label>

                  <label class="next-label">Mã đơn</label>
                  <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập mã đơn" name="ma_don" type="text" defaultValue={this.state.ma_don} />

                  <label class="next-label">Mã vận đơn</label>
                  <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập vận đơn hoặc bỏ trống để tạo mới" name="van_don" type="text" defaultValue={this.state.van_don} />


                  <label class="next-label">Tiền thu hộ(COD)</label>
                  <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số tiền thu hộ" name="cod" type="text" defaultValue={this.state.sumTotal} />

                  <label className="next-label">Ghi chú</label>
                  <textarea onChange={(event) => this.isChange(event)} name="noteShip" className="next-input" rows={3} defaultValue={this.state.noteShip} />
                </div>
              </div>
              <div className="ui-modal__footer">
                <div className="ui-modal__footer-actions">
                  <div className="ui-modal__secondary-actions">
                    <div className="button-group">
                      <button onClick={this.onCloseModalChuanBi} className="ui-button close-modal" type="button">Đóng</button>
                    </div>
                  </div>
                  <div className="ui-modal__primary-actions">
                    <div className="button-group button-group--right-aligned">
                      {
                        this.state.loadUpdateIdFb ?
                          <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                            <span className="next-spinner" bind-show="isCalculating">
                              <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                            </span>

                          </button>
                          :

                          this.state.loading ?
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                            :
                            <button onClick={() => this.guiChuanBi()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>


                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Modal>
          {/* Modal */}



          {/* Chuẩn bị Viettel */}

          {/* Modal Chuẩn bị */}
          <Modal open={openChuanBiVietTel} onClose={this.onCloseModalChuanBiVietTel} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
            <div className="modal-content" style={{ 'width': '500px' }}>
              <div className="ui-modal__header">
                <div className="ui-modal__header-title">
                  <h2 className="ui-title" id="ModalTitle">Chuẩn bị hàng VietTel {this.state.ma_don}</h2>
                </div>
              </div>
              <div className="ui-modal__body">
                <div className="ui-modal__section">

                  <Select
                    value={selectedLyDo}
                    onChange={this.handleChangeLyDo}
                    options={trangthai}
                    placeholder='Chọn trạng trái'
                  />
                  <br></br>
                  <Select
                    value={selectedVanChuyenViettel}
                    onChange={this.handleChangeVanChuyenViettel}
                    options={this.state.VanChuyenViettel}
                    placeholder='Chọn dịch vụ vận chuyển'
                  />
                  <br></br>
                  {/* <label class="next-label">Tên Shop: {this.state.ten_shop_gui}</label>
                                 <label class="next-label">Địa chỉ kho: {this.state.dia_chi_kho}</label>
                                 <label class="next-label">SĐT kho: {this.state.phone_kho_gui}</label> */}

                  <label class="next-label">Đ/C Khách Hệ Thống:<i> {this.state.address}, {this.state.xa}, {this.state.huyen}, {this.state.tinh}</i></label>
                  <label class="next-label">Đ/C Khách Viettel: <b>{this.state.formattedAddress}</b></label>

                  {/* 
                                    <label class="next-label">Mã đơn</label>
                                    <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập mã đơn" name="ma_don" type="text" defaultValue={this.state.ma_don} />

                                    <label class="next-label">Mã vận đơn</label>
                                    <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập vận đơn hoặc bỏ trống để tạo mới" name="van_don" type="text" defaultValue={this.state.van_don} /> */}

                  <label class="next-label">Tiền thu hộ(COD)</label>
                  <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số tiền thu hộ" name="cod" type="text" defaultValue={this.state.sumTotal} />

                  <label class="next-label">Trọng lượng</label>
                  <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập trọng lượng" name="tongTrongLuong" type="text" defaultValue={this.state.tongTrongLuong} />

                  {/* <label className="next-label">Ghi chú</label>
                                    <textarea onChange={(event) => this.isChange(event)} name="noteShip" className="next-input" rows={3} defaultValue={this.state.noteShip} /> */}
                </div>
              </div>
              <div className="ui-modal__footer">
                <div className="ui-modal__footer-actions">
                  <div className="ui-modal__secondary-actions">
                    <div className="button-group">
                      <button onClick={this.onCloseModalChuanBiVietTel} className="ui-button close-modal" type="button">Đóng</button>
                    </div>
                  </div>
                  <div className="ui-modal__primary-actions">
                    <div className="button-group button-group--right-aligned">
                      {
                        this.state.loadUpdateIdFb ?
                          <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                            <span className="next-spinner" bind-show="isCalculating">
                              <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                            </span>

                          </button>
                          :

                          this.state.loading ?
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                            :
                            <button onClick={() => this.guiChuanBiVietTel()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>


                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Modal>
          {/* Modal */}

          {/* End chuẩn bị VietTel */}





          {/* Chuẩn bị GHN */}
          <Modal open={openChuanBiGHN} onClose={this.onCloseModalChuanBiGHN} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
            <div className="modal-content" style={{ 'width': '800px' }}>
              <div className="ui-modal__header">
                <div className="ui-modal__header-title">
                  <h2 className="ui-title" id="ModalTitle">Chuẩn bị hàng GHN {this.state.ma_don} | {this.state.thoigiantao}</h2>
                </div>
              </div>
              <div className="ui-modal__body">
                <div className="ui-modal__section">

                  <Select
                    value={selectedLyDo}
                    onChange={this.handleChangeLyDo}
                    options={trangthai}
                    placeholder='Chọn trạng trái'
                  />

                  <label class="next-label"><b>Cước: {this.state.cuocghn}</b></label>

                  <label class="next-label">Đ/C:<i> {this.state.address}, {this.state.xa}, {this.state.huyen}, {this.state.tinh}</i></label>
                  
                  
                  <div className="next-grid next-grid--no-outside-padding">
                    <div className="next-grid__cell">
                      <div className="next-input-wrapper">
                        <label className="next-label" htmlFor="Address_City">Tỉnh / Thành phố</label>
                        <Select
                          value={selectedVanChuyenGHNTinh}
                          onChange={this.handleChangeVanChuyenGHNTinh}
                          options={tinhGHN}
                          placeholder='Chọn Tỉnh / Thành Phố'
                        />
                      </div>
                    </div>
                    <div className="next-grid__cell" bind-show="have_city">
                      <div className="next-input-wrapper">
                        <label className="next-label" htmlFor="Address_DistrictId">Quận/Huyện</label>
                        <Select
                          value={selectedVanChuyenGHNHuyen}
                          onChange={this.handleChangeVanChuyenGHNHuyen}
                          options={huyenGHN}
                          placeholder='Chọn Huyện/Quận'
                        />
                      </div>
                    </div>

                    <div className="next-grid__cell">
                      <div className="next-input-wrapper">
                        <label className="next-label" htmlFor="Address_Address1">Xã/Phường</label>
                        <Select
                          value={selectedVanChuyenGHNXa}
                          onChange={this.handleChangeVanChuyenGHNXa}
                          options={xaGHN}
                          placeholder='Chọn Xã/Phường'
                        />
                      </div>
                    </div>


                  </div>

                  <div className="next-grid next-grid--no-outside-padding">
                   
                  </div>
                                            
              


                  <label class="next-label">Tiền thu hộ(COD)</label>
                  <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập số tiền thu hộ" name="cod" type="text" defaultValue={this.state.sumTotal} />

                  <label class="next-label">Trọng lượng</label>
                  <input onChange={(event) => this.isChange(event)} className="next-input" placeholder="Nhập trọng lượng" name="tongTrongLuong" type="text" defaultValue={this.state.tongTrongLuong} />

                  {/* <label className="next-label">Ghi chú</label>
                                    <textarea onChange={(event) => this.isChange(event)} name="noteShip" className="next-input" rows={3} defaultValue={this.state.noteShip} /> */}
                </div>
              </div>
              <div className="ui-modal__footer">
                <div className="ui-modal__footer-actions">
                  <div className="ui-modal__secondary-actions">
                    <div className="button-group">
                      <button onClick={this.onCloseModalChuanBiGHN} className="ui-button close-modal" type="button">Đóng</button>
                    </div>
                  </div>
                  <div className="ui-modal__primary-actions">
                    <div className="button-group button-group--right-aligned">
                      {
                        this.state.loadUpdateIdFb ?
                          <button className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">
                            <span className="next-spinner" bind-show="isCalculating">
                              <svg className="next-icon next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-spinner" /> </svg>
                            </span>

                          </button>
                          :

                          this.state.loading ?
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                            :
                            <button onClick={() => this.guiChuanBiGHN()} className="ui-button ui-button--primary js-btn-loadable has-loading" type="submit">Xác nhận</button>


                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Modal>
          {/* Modal */}

          {/* End chuẩn bị GHN */}



          {/* Modal ghi chú */}
          <Modal open={openModalGhiChu} onClose={this.onCloseModalGhiChu} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
            <div className="modal-content" style={{ 'width': '750px' }}>
              <div className="ui-modal__header">
                <div className="ui-modal__header-title">
                  <h2 className="ui-title" id="ModalTitle">GHI CHÚ ĐƠN {this.state.id}</h2>
                </div>
              </div>
              <div className="ui-modal__body">
                <div className="ui-modal__section">

                  <textarea onChange={(event) => this.isChange(event)} autoComplete="off" className="next-input" value={this.state.note} placeholder="Nhập ghi chú" name="note" type="text" />


                </div>
              </div>
              <div className="ui-modal__footer">
                <div className="ui-modal__footer-actions">

                  <div className="form-group mt-2">
                    {
                      this.state.loading ?
                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                        :
                        <button type="submit" onClick={() => this.capNhatGhiChu()} className="btn btn-primary">Cập nhật</button>
                    }
                  </div>
                </div>
              </div>
            </div>

          </Modal>
          {/* Modal ghi chú*/}



        </div>
        {/* </OverlayLoader> */}

      </div>

    );
  }
}

export default Order;