import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-responsive-modal';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { lam_tron } from '../../functions/function.js';
import { validate } from '@babel/types';
import Pusher from 'pusher-js';
import { SlowBuffer } from 'buffer';
import Select from 'react-select'
import { check_phone } from '../../functions/function.js';
import { json } from 'body-parser';
const moment = require('moment');
var dateFormat = require('dateformat');
const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');

class TKQuangCao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trangThai: 'tat_ca',
            search: '',
            loading: false,
            dateFrom: '',
            dateTo: '',
            website: '',
            khac: '',
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
            nhanthanhtoan: false,
            Loader: false,
            openChuanBi: false, // Modal
            openNhapVanDon: false, // Modal
            openChiTietVanDon: false, // Modal
            openNhapKho: false, // Modal
            openModalGhiChu: false, // Modal
            noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
            t_id: '',
            so_luong: '',
              startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            // startDate: moment().format('YYYY-MM-01'),
            endDate: moment().format('YYYY-MM-DD'),
            selectedLyDo: null,
            ly_do:'',
            token_ads:''
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.DanhSachBM();
        this.getInfo();
    }

    getInfo() {
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'facebook/info-uid?token=' + token + '&uid=' + queryString.parse(window.location.search).uid)
            .then(response => {
                this.setState({
                    info: response.data,
                });
            });
    }

    DanhSachBM(){
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'facebook/danh-sach-tai-khoan-bm?token=' + token + '&uid='+queryString.parse(window.location.search).uid + "&page=" + this.state.page)
            .then(response => {
                this.setState({
                    tong: response.data.data.length,
                    items: response.data.data,
                    // loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                    openNhapKho: false
                });
            })
            .catch(function (error) {
                console.log(error);
                alert("Lỗi")
            });
    }

    getBm() {
        // this.setState({ loading: true })
        axios.get('https://graph.facebook.com/v6.0/me/businesses?fields=id,name,is_disabled_for_integrity_reasons,picture{url},owned_ad_accounts{id,name,account_status,age},can_create_ad_account,created_by&access_token=' + queryString.parse(window.location.search).token)
            .then(data => {
                this.CapNhatBmData(data.data.data)
                this.CapNhatSoLuongBm(data.data.data.length)
            })
            .catch(function (error) {
                alert("Lỗi TOKEN")
            });
    }

    CapNhatBmData(data) {
        
        data.map((value, key) => {
            setTimeout(() => { 
                this.UpdateDataBM(value);
                if ((key + 1) >= data.length) {
                    this.setState({ loading: false })
                    NotificationManager.success('Cập nhật xong dữ liệu mới', '', 5000);
                }
            }, key * 3000);
        })
    }

    CapNhatBM(){
        this.setState({ loading: true })
        this.getBm()
    }
    
    CapNhatSoLuongBm(sl){
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'facebook/cap-nhat-so-luong-bm?token=' + token + '&so_luong=' + sl + '&uid=' + queryString.parse(window.location.search).uid )
    }

    UpdateDataBM(value) {
        var order = {};
                order.uid = queryString.parse(window.location.search).uid;
                order.id_bm = value.id;
                order.name = value.name;
                order.avatar = value.picture.data.url;
                order.trang_thai = value.is_disabled_for_integrity_reasons;
                if(value.created_by){
                    order.tao_boi = value.created_by.name;
                }else{
                    order.tao_boi = '';
                }
                
                if(value.owned_ad_accounts){
                    order.so_tk_ads = value.owned_ad_accounts.data.length;
                }else{
                    order.so_tk_ads = '';
                }
                
                let token = localStorage.getItem('token');
                axios.post(Config.API_URL + 'facebook/cap-nhat-bm-data?token=' + token, qs.stringify(order) + '&uid=' + queryString.parse(window.location.search).uid + "&page=" + this.state.page)
                    .then(response => {
                        this.getLinkBM(value);
                        this.setState({
                            tong: response.data.data.length,
                            items: response.data.data,
                            // loading: false,
                            activePage: response.data.current_page,
                            itemsCountPerPage: response.data.per_page,
                            totalItemsCount: response.data.total,
                            path: response.data.path,
                            openNhapKho: false
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        // alert("Lỗi")
                    });
    }

    getLinkBM(value) {
        console.log(value.id);

        axios.get('https://graph.facebook.com/v6.0/' + value.id + '/pending_users?fields=id%2Crole%2Cemail%2Cinvite_link%2Cexpiration_time&access_token=' + queryString.parse(window.location.search).token)
            .then(data => {
                // if(data.data && data.data.data && data.data.data.length > 0){
                if(data.data && data.data.data){
                    this.CapNhatLinkBMData(value.id,data.data.data);
                }

            })
            .catch(function (error) {
                // alert("Lỗi TOKEN")
            });
    }

    CapNhatLinkBMData(id_bm,data) {
        var order = {};
        order.id_bm = id_bm;
        order.link_admin_bm = data;
        let token = localStorage.getItem('token');
        axios.post(Config.API_URL + 'facebook/cap-nhat-bm-data-link?token=' + token, qs.stringify(order) + '&uid=' + queryString.parse(window.location.search).uid + "&page=" + this.state.page)
            .then(response => {
                this.setState({
                    tong: response.data.data.length,
                    items: response.data.data,
                    // loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                    openNhapKho: false
                });
            })
            .catch(function (error) {
                console.log(error);
                // alert("Lỗi")
            });
    }

    componentDidUpdate = (prevProps, prevState) => {

        const query = {
            uid: queryString.parse(window.location.search).uid,
            token: queryString.parse(window.location.search).token,
            page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
            {
                pathname: '/tai-khoan-quang-cao',
                search: searchString,
            }
        )

        // Get data
        if (prevState.page !== this.state.page || this.state.uid !== prevState.uid || this.state.token !== prevState.token) {

            // this.setState({
            //     loading: true
            // })
            var startDate = '';
            if (this.state.startDate) {
                startDate = this.state.startDate;
            }
            var endDate = '';
            if (this.state.endDate) {
                endDate = this.state.endDate;
            }


            this.DanhSachBM();
            // End Get data



        }
    }



    // Tạo Link
    TaoLinkBm() {
        this.setState({loading: true})
        this.GetListBM()
    }

    // B1: Get danh sách tài khoản(Link < 3 và Live)
    GetListBM() {
        let token = localStorage.getItem('token');
        axios.get( Config.API_URL + 'facebook/get-list-bm?token=' + token + '&uid=' + queryString.parse(window.location.search).uid )
            .then(data => {
                // console.log(data.data.data);
                
                data.data.data.map((value,key) => {
                    // console.log(value.id_bm);
                    setTimeout(() => { 
                        if(value.link_admin_bm && JSON.parse(value.link_admin_bm).length < 5){
                            this.PostTaoLink(value.id_bm,value.name_bm)
                        }else if(!value.link_admin_bm){
                            this.PostTaoLink(value.id_bm,value.name_bm)
                        }else{
                            NotificationManager.warning('Đã tạo đủ link '+value.name_bm, '', 5000);
                        }

                        if ((key + 1) >= data.data.data.length) {
                            this.CapNhatBM()
                            // this.setState({ loading: false })
                            // alert('Tạo xong Link BM')
                            NotificationManager.success('Tạo link xong', '', 5000);
                        }

                }, key * 3000);


                })
            })
            .catch(function (error) {
                alert("Lỗi Get List BM")
            });
    }
    // B2: Lặp qua từng tài khoản để tạo
    PostTaoLink(id_bm,name_bm) {
        var link = 'https://graph.facebook.com/v3.0/'+id_bm+'/business_users?access_token='+queryString.parse(window.location.search).token;
        var order = {};
        order.brandId= id_bm;
        order.email= 'backup'+Math.floor((Math.random() * 9999999999999999) + 1)+'@gmail.com';
        order.method= 'post';
        order.pretty= '0';
        order.roles= '["ADMIN", "FINANCE_EDITOR"]';
        order.suppress_http_code= '1';
        axios.post( link, qs.stringify(order) )
            .then(response => {
                // if(!response.data){
                //     NotificationManager.warning('Tạo link lỗi '+name_bm, '', 5000);
                // }else{
                //     NotificationManager.success('Tạo link thành công '+name_bm, '', 5000);
                // }
                this.setState({
                    tong: response.data.data.length,
                    items: response.data.data,
                    // loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                    openNhapKho: false
                });
            })
            .catch(()=>{
                // NotificationManager.warning('Tạo link lỗi '+name_bm, '', 5000);
            });

    }
    // End Tạo Link


    // Tạo TK ADS
    TaoAccADS() {
        this.setState({loading: true})
        this.GetListBMTaoAds()
    }
    // B1: Get danh sách BM

    GetListBMTaoAds() {
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'facebook/get-list-bm?token=' + token + '&uid=' + queryString.parse(window.location.search).uid)
            .then(data => {
                // console.log(data.data.data);

                data.data.data.map((value, key) => {
                    // console.log(value.id_bm);
                    setTimeout(() => {
                        this.PostTaoAds(value.id_bm,value.name_bm)

                        if ((key + 1) >= data.data.data.length) {
                            this.CapNhatBM()
                            // this.setState({ loading: false })
                            // alert('Tạo xong Link BM')
                        }

                    }, key * 3000);


                })
            })
            .catch(function (error) {
                alert("Lỗi Get List BM")
            });
    }

    // B2: Tạo TK ADS

    PostTaoAds(id_bm,name_bm) {
        var link = 'https://graph.facebook.com/v7.0/'+id_bm+'/adaccount?access_token='+queryString.parse(window.location.search).token;
        var order = {};
        order._reqName= 'object:brand/adaccount';
        order._reqSrc= 'AdAccountActions.brands';
        order.ad_account_created_from_bm_flag= 'true';
        order.currency= 'VND';
        order.end_advertiser= id_bm;
        order.invoicing_emails= [];
        order.locale= 'vi_VN';
        order.media_agency= 'UNFOUND';
        order.method= 'post';
        order.name= name_bm+'-'+Math.floor((Math.random() * 99999) + 1);
        order.partner= 'UNFOUND';
        order.po_number= '';
        order.pretty= '0';
        order.suppress_http_code= '1';
        order.timezone_id= '140';
        order.xref= 'f1359428c5492c8';
        axios.post( link, qs.stringify(order) )
            .then(response => {
                if(response.data.error){
                    NotificationManager.warning('Tạo TK ADS lỗi '+name_bm, '', 5000);
                }else{
                    NotificationManager.success('Tạo TK ADS thành công '+name_bm, '', 5000);
                }
            })
            .catch(err => { 
                NotificationManager.warning('Tạo lỗi '+id_bm, '', 5000);
              });

    }

    // B3: Gán Quyền quản trị

    //
    // End Tạo TK ADS

    // Xem link
    
      onOpenModalNhapKho = (uid,id_bm) => {
        this.setState({ chitietdon: '' });
        this.setState({ loading: false });
        this.setState({ giatridachon: [] })
        this.setState({
            openNhapKho: true,
        });
        this.getXemLink(uid,id_bm);

    };

    onCloseModalNhapKho = () => {
        this.setState({ openNhapKho: false });
    };

    getXemLink(uid,id_bm){
        this.setState({linkBm:''})
        let token = localStorage.getItem('token');
        axios.get( Config.API_URL + 'facebook/get-xem-link-bm?token=' + token + '&uid=' + uid + '&id_bm=' + id_bm)
            .then(data => {
                this.setState({linkBm:data.data})
            })
            .catch(function (error) {
                alert("Lỗi Get List BM")
            });
    }

    // Xem link

    render() {
        const { openNhapKho } = this.state;

        console.log(this.state.loading);
        
        return (
            <div>
                <NotificationContainer />
                <div id="content">
                    <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                        <header className="ui-title-bar-container ui-title-bar-container--full-width">
                            <div className="ui-title-bar ">
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group"><span className="ui-title-bar__icon"><svg className="next-icon next-icon--color-slate-lighter next-icon--size-20"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" /></svg></span>
                                        <h1 className="ui-title-bar__title">TẤT CẢ BM</h1>
        </div>
                                    <div define="{titleBarActions: new Bizweb.TitleBarActions(this)}" className="action-bar">
                                        <div className="action-bar__item action-bar__item--link-container" />
                                        <div className="ui-title-bar__mobile-primary-actions">
                                            {
                                                this.state.loading?
                                                <div className="ui-title-bar__actions"><a className="ui-button ui-button--primary ui-title-bar__action" ><i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} /></a></div>
                                                :
                                                <div className="ui-title-bar__actions"><a onClick={() => this.CapNhatBM()} className="ui-button ui-button--primary ui-title-bar__action" >LÀM MỚI</a></div>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="ui-title-bar__actions-group">

                                
                                <div className="ui-title-bar__actions"><Link to="danh-sach-tai-khoan-quan-ly-bm" className="ui-button ui-button--primary ui-title-bar__action" >Trở lại</Link></div>
                                    {
                                        this.state.loading ?
                                            <div className="ui-title-bar__actions"><a className="ui-button ui-button--primary ui-title-bar__action" ><i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} /></a></div>
                                            :
                                            <div className="ui-title-bar__actions"><a onClick={() => this.CapNhatBM()} className="ui-button ui-button--primary ui-title-bar__action" >LÀM MỚI</a></div>
                                    }

                                    {
                                        this.state.loading ?
                                            <div className="ui-title-bar__actions"><a className="ui-button ui-button--primary ui-title-bar__action" ><i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} /></a></div>
                                            :
                                            <div className="ui-title-bar__actions"><a onClick={() => this.TaoLinkBm()} className="ui-button ui-button--primary ui-title-bar__action" >TẠO LINK</a></div>
                                    }

                                    {
                                        this.state.loading ?
                                            <div className="ui-title-bar__actions"><a className="ui-button ui-button--primary ui-title-bar__action" ><i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} /></a></div>
                                            :
                                            <div className="ui-title-bar__actions"><a onClick={() => this.TaoAccADS()} className="ui-button ui-button--primary ui-title-bar__action" >TẠO TK ADS</a></div>
                                    }

                                </div>
                            </div>
                            <div className="collapsible-header">
                                <div className="collapsible-header__heading" />
                            </div>
                        </header>
                        <div className="ui-layout ui-layout--full-width">
                            <div className="ui-layout__sections">
                                <div className="ui-layout__section">
                                    <div className="ui-layout__item">
                                        <section className="ui-card">
                                            <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                                <div className="next-tab__container ">
                                                    <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
        <li className="filter-tab-item"><a className="filter-tab next-tab">Tất cả (<span id="donmoi">{this.state.tong}</span>)</a></li>
                                                    </ul>
                                                </div>
                                                {/* <div className="next-card__section next-card__section--no-bottom-spacing">
                                                    <div className="obj-filter hide-when-printing table-filter-container">
                                                        <div className="next-input-wrapper">
                                                            <div className="next-field__connected-wrapper">
                                                                <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">
                                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                    <div className="next-input--stylized next-field--connected"><span className="next-input__add-on next-input__add-on--before"><svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg></span>
                                                                        <input type="text" name="search" placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" defaultValue />
                                                                    </div>
                                                                </form>
                                                                <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                                <div className="ui-type-container clearfix">
                                                    <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                        <table id="price-rule-table" className="table-hover bulk-action-context expanded">
                                                            <thead>
                                                                <tr>
                                                                    <th><span>#</span></th>
                                                                    <th><span>Quản trị</span></th>
                                                                    <th><span>Avatar</span></th>
                                                                    <th><span>Full name</span></th>
                                                                    <th><span>ID Bm</span></th>
                                                                    <th><span>Tạo bởi</span></th>
                                                                    <th><span>Số TK</span></th>
                                                                    <th><span>Trạng thái</span></th>
                                                                    <th><span>Quản trị</span></th>
                                                                    <th><span>Số Link</span></th>
                                                                    <th><span>Link</span></th>
                                                                    <th><span>Time Cập nhật</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                    {
                        this.state.items ?
                            this.state.items.map((value,key) => {
                                return <tr>
                                    <td className="total">
                                        <p>{key+1}</p>
                                    </td>
                                    <td>
                                        <a href={"http://fb.com/"+value.uid} target="_blank" className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" currentitem="false">
                                            <img className="aspect-ratio__content" src={"https://graph.facebook.com/v2.10/"+value.uid+"/picture?type=large&redirect=true"} />
                                            <div className="__react_component_tooltip place-right type-dark " data-id="tooltip" />
                                        </a>
                                    </td>
                                    <td>
                                        <a className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" currentitem="false">
                                            <img className="aspect-ratio__content" src={value.avatar} />
                                            <div className="__react_component_tooltip place-right type-dark " data-id="tooltip" />
                                        </a>
                                    </td>
                                    <td className="total">
                                        <p>{value.name_bm}</p>
                                    </td>
                                    <td className="total">
                                        <p>{value.id_bm}</p>
                                    </td>
                                    <td className="total">
                                        <p>{value.tao_boi}</p>
                                    </td>
                                    <td className="total">
                                        <p>{value.so_tk_ads}</p>
                                    </td>
                                    <td className="total">
                                        {
                                            value.trang_thai == 'false'?
                                            <label className="badge badge--status-success badge--progress-complete">Live</label>
                                            :
                                            <label className="badge badge--status-warning badge--progress-complete">Die</label>
                                        }
                                        
                                    </td>
                                    <td className="total">
                                        <p>{value.quyen_quan_tri}</p>
                                    </td>
                                    <td className="total">
                                        <p>{value.link_admin_bm?JSON.parse(value.link_admin_bm).length:""}</p>
                                    </td>
                                    <td className="total">
                                        <p><a onClick={() => this.onOpenModalNhapKho(value.uid,value.id_bm)}> Xem</a></p>
                                    </td>

                                    <td className="total">
                                        <p>{value.time}</p>
                                    </td>

                                </tr>

                            })
                            :
                            ""
                    }
                                                                
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* Modal Nhập kho */}
                <Modal open={openNhapKho} onClose={this.onCloseModalNhapKho} center animationDuration={1000} styles={{ modal: { background: "rgba(0,0,0,.1)", padding: '0' }, overlay: {} }} closeIconSize={20}>
                    <div className="modal-content" style={{ 'width': '750px' }}>
                        <div className="ui-modal__header">
                            <div className="ui-modal__header-title">
                                <h2 className="ui-title" id="ModalTitle">DANH SÁCH LINK {this.state.linkBm?this.state.linkBm.name_bm:""} ({this.state.linkBm && this.state.linkBm.link_admin_bm?JSON.parse(this.state.linkBm.link_admin_bm).length:""})</h2>
                            </div>
                        </div>
                        <div className="ui-modal__body">
                            <div className="ui-modal__section">

                                <div className="">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Link</th>
                                                <th>Email</th>
                                                <th>Ngày hết hạn</th>
                                            </tr>
                                        </thead>
                                        <tbody>
{
    this.state.linkBm && this.state.linkBm.link_admin_bm?
    JSON.parse(this.state.linkBm.link_admin_bm).map( (value1,key1)=>{
return <tr>
<td>{key1+1}</td>
<td><a>{value1.invite_link}</a></td>
<td>{value1.email}</td>
<td>{moment(value1.expiration_time).fromNow()}</td>
</tr>
    } )
    :
    ""
}
                                            
                                           
                                        </tbody>
                                    </table>
                                </div>




                            </div>
                        </div>
                        {/* <div className="ui-modal__footer">
                            <div className="ui-modal__footer-actions">

                                <div className="form-group mt-2">
                                    {
                                        this.state.loading ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                            :
                                            <button type="submit" onClick={() => this.CapNhatTokenADS()} className="btn btn-primary">Cập nhật</button>
                                    }
                                </div>
                            </div>
                        </div> */}
                    </div>

                </Modal>
                {/* Modal nhập vận đơn*/}




            </div>
        )
    }
}

export default TKQuangCao;