import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Moment from 'moment'
import * as Config from '../../config.js';
import ReactTooltip from 'react-tooltip'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Modal from 'react-responsive-modal';
import './baocao.css';
import './month-picker.css';
import List from './List.js';
import { connect } from 'react-redux';
import { getDaysInMonth, custom_sort } from '../../functions/function.js';
import { thisExpression } from '@babel/types';


var dateFormat = require('dateformat');

const axios = require('axios');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');
class BaoCaoQC extends Component {
    constructor(props, context) {
        super(props, context)

        // Month
        this.state = {
            value: this.props.value
        }

        var now = new Date();
        this.state = {
            trangThai: '',
            search: '',
            loading: true,
            dateFrom: '',
            dateTo: '',
            website: '',
            khac: '',
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
            nhanthanhtoan: false,
            Loader: false,
            openChuanBi: false, // Modal
            noteShip: "Cho xem hàng, không giao được vui lòng liên hệ người gửi.",
            t_id: '',
            so_luong: '',
            acountADS: [],
            year: now.getFullYear(),
            month: now.getMonth() + 1,
            date: now.getDate()
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }


    // Month
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        })
    }

    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }


    timKiem(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });


    }



    componentWillMount() {
        this.setState({
            trangThai: queryString.parse(window.location.search).trangThai,
            search: queryString.parse(window.location.search).search,
            website: queryString.parse(window.location.search).website,
            dateFrom: queryString.parse(window.location.search).dateFrom,
            dateTo: queryString.parse(window.location.search).dateTo,
            khac: queryString.parse(window.location.search).khac,
            page: queryString.parse(window.location.search).page,
        })
    }




    componentDidUpdate = (prevProps, prevState) => {

        const query = {
            trangThai: this.state.trangThai,
            search: this.state.search,
            website: this.state.website,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            khac: this.state.khac,
            page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
            {
                pathname: '/bao-cao-quang-cao',
                search: searchString,
            }
        )

        // Get data
        if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac || prevState.page !== this.state.page || prevState.nhanthanhtoan !== this.state.nhanthanhtoan) {


            if (prevState.trangThai !== this.state.trangThai || prevState.search !== this.state.search || prevState.website !== this.state.website || prevState.dateFrom !== this.state.dateFrom || prevState.dateTo !== this.state.dateTo || prevState.khac !== this.state.khac) {
                this.setState({
                    page: 1
                })
            }
            this.setState({
                loading: true
            })
            var trangThai = '';
            if (this.state.trangThai) {
                trangThai = this.state.trangThai;
            }
            var search = '';
            if (this.state.search) {
                search = this.state.search;
            }
            var website = '';
            if (this.state.website) {
                website = this.state.website;
            }
            var khac = '';
            if (this.state.khac) {
                khac = this.state.khac;
            }
            var dateFrom = '';
            if (this.state.dateFrom) {
                dateFrom = (dateFormat(this.state.dateFrom, "dd-mm-yyyy"));
            }
            var dateTo = '';
            if (this.state.dateTo) {
                dateTo = (dateFormat(this.state.dateTo, "dd-mm-yyyy"));
            }
            let token = localStorage.getItem('token');
            axios.get(Config.API_URL + 'bao-cao-quang-cao?thang=' + this.state.year + '-' + this.state.month + "&token=" + token)
            .then(response => {
                this.setState({
                    items: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            })
            .catch(function (error) {
                // console.log(error);
            });
                




        }
        // End Get data



        if (this.props.chonThang) {
            if (prevState.year !== this.props.chonThang.year || prevState.month !== this.props.chonThang.month) {
                this.setState({
                    year: this.props.chonThang.year,
                    month: this.props.chonThang.month
                })
            }

        }





    }




    componentDidMount = () => {

        let token = localStorage.getItem('token');

            axios.get(Config.API_URL + 'bao-cao-quang-cao?thang=' + this.state.year + '-' + this.state.month + "&token=" + token)
            .then(response => {
                this.setState({
                    items: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            })
            .catch(function (error) {
                // console.log(error);
            });

    }


    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }


    chiPhiNgay(masp) {
        if( this.state.items && this.state.year && this.state.month){

            // Lọc theo mã trước, rồi lặp ngày
            const TCP = [];

            this.state.items.map( ()=>{
                var ketqua = this.state.items.filter(function (returnableObjects) {
                    if (returnableObjects) {
                        return (returnableObjects).ma_san_pham === masp;
                    } else {
                        return null;
                    }
                })

                 // Kết quả là data 1 sản phẩm, phần tử 0 => Lấy ra bao_cao và lặp => Lọc ngày
                 if (ketqua && ketqua[0] && ketqua[0].bao_cao) {

                    var bao_cao = JSON.parse( ketqua[0].bao_cao);
                     

                     
                         if (bao_cao) { // Data đã sắp xếp theo ngày
                            var datasort = JSON.parse(bao_cao.sort(custom_sort));
                            // console.log(datasort);
                            
                            // for (let i = 1; i <= getDaysInMonth(this.state.month, this.state.year); i++) {
                            //     // console.log(datasort);
                            // }
                            //  TCP.push(
                            //      <td><th><span>{i}</span></th></td>
                            //  );
                     }

                 }

            } )

        

        // if (TCP) {
        //     return <td>
        //         {TCP}
        //     </td>
        // } else {
        //     return <td className="vendor">
        //         <p><b>-</b></p>

        //     </td>
        // }

    }
    }

    
    soLuongDon(){
        
    }

    render() {

        
        const ngay = [];

       

        return (
            <div id="content">




                <div id="pages-index" className="page default has-contextual-help discounts-page" context="productsImportAndExport">
                    <header className="ui-title-bar-container ui-title-bar-container--full-width">
                        <div className="ui-title-bar ">
                            <div className="">
                                <div className="ui-title-bar__heading-group">
                                    <span className="ui-title-bar__icon">
                                        <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-discounts" />
                                        </svg>
                                    </span>
                                    <h1 className="ui-title-bar__title">Báo cáo hiệu quả QC</h1>
                                </div>
                            </div>
                            <div className="ui-title-bar__actions-group">
                                <div className="ui-title-bar__actions">
                                    <List chonNgay={() => this.chonNgay(ngay)} />

                                </div>
                            </div>
                        </div>
                        <div className="collapsible-header">
                            <div className="collapsible-header__heading" />
                        </div>
                    </header>
                    <div className="ui-layout ui-layout--full-width">
                        <div className="ui-layout__sections">
                            <div className="ui-layout__section">
                                <div className="ui-layout__item">
                                    <section className="ui-card">
                                        <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                            <div className="next-tab__container ">
                                                <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                    <li className="filter-tab-item" data-tab-index={1}>
                                                        <a href="javascript:void(0)" className="filter-tab filter-tab-active show-all-items next-tab next-tab--is-active">Tất cả sản phẩm</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="next-card__section next-card__section--no-bottom-spacing">
                                                <div className="obj-filter hide-when-printing table-filter-container">
                                                    <div className="next-input-wrapper">
                                                        <div className="next-field__connected-wrapper">
                                                            <form className="next-form next-form--full-width next-field--connected--no-border-radius" method="get">                                                    <label className="next-label helper--visually-hidden" htmlFor="query">Query</label>
                                                                <div className="next-input--stylized next-field--connected">
                                                                    <span className="next-input__add-on next-input__add-on--before">
                                                                        <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                                                    </span>
                                                                    <input type="text" onChange={(event) => this.timKiem(event)} name='search' defaultValue={this.state.search} placeholder="Tìm kiếm sản phẩm" className="next-input next-input--invisible" />
                                                                </div>
                                                            </form>
                                                            <div id="saved-search-actions-next" className="saved-search-actions-next" data-tg-refresh="saved-search-actions-next">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui-card__section has-bulk-actions products" refresh="products" id="products-refresh">
                                            <div className="ui-type-container clearfix">
                                                <div className="table-wrapper" define="{bulkActions: new Bizweb.ProductBulkActions(this,{&quot;type&quot;:&quot;sản phẩm&quot;})}" context="bulkActions">
                                                    <table id="price-rule-table" className="table-bordered bulk-action-context expanded baocao">
                                                        <thead>

                                                            <tr>

                                                                <th colSpan="3"><center>Sản phẩm</center></th>
                                                                <th colSpan="3"><center>Tháng {this.state.month}</center></th>
                                                                {ngay}

                                                            </tr>

                                                            <tr>
                                                                <th><span>IMG</span></th>
                                                                <th><span style={{ width: '50px' }}>MÃ</span></th>
                                                                <th><span style={{ width: '150px' }}>TÊN</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>


                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                <th><span>TCP</span></th>
                                                                <th><span>SLĐ</span></th>
                                                                <th><span>CPĐ</span></th>

                                                                {
                                                                    this.state.songay && this.state.songay > 28 ?
                                                                        <th><span>TCP</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 28 ?
                                                                        <th><span>SLĐ</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 28 ?
                                                                        <th><span>CPĐ</span></th>
                                                                        :
                                                                        ""
                                                                }



                                                                {
                                                                    this.state.songay && this.state.songay > 29 ?
                                                                        <th><span>TCP</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 29 ?
                                                                        <th><span>SLĐ</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 29 ?
                                                                        <th><span>CPĐ</span></th>
                                                                        :
                                                                        ""
                                                                }



                                                                {
                                                                    this.state.songay && this.state.songay > 30 ?
                                                                        <th><span>TCP</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 30 ?
                                                                        <th><span>SLĐ</span></th>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    this.state.songay && this.state.songay > 30 ?
                                                                        <th><span>CPĐ</span></th>
                                                                        :
                                                                        ""
                                                                }



                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.items ?
                                                                    this.state.items.map((value, key) => {
                                                                        var html = "<img src=" + value.image + " width='400px' />"
                                                                        return <tr>

                                                                            <td>
                                                                                <a className="aspect-ratio aspect-ratio--square aspect-ratio--square--50 aspect-ratio--interactive" data-tip={html}>
                                                                                    <img title={value.title} className="aspect-ratio__content" src={value.image} alt={value.title} />
                                                                                    <ReactTooltip html={true} place={'right'} />
                                                                                </a>
                                                                            </td>
                                                                            <td className="name">
                                                                                <div className="ui-stack ui-stack--wrap">
                                                                                    <div className="ui-stack-item">
                                                                                        {value.ma_san_pham}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="total">
                                                                                <p>{value.ten}</p>
                                                                            </td>

                                                                            <td className="vendor">
                                                                                <p><b>1</b></p>
                                                                            </td>
                                                                            <td className="vendor">
                                                                                <p><b>2</b></p>
                                                                            </td>
                                                                            <td className="vendor">
                                                                                <p><b>3</b></p>
                                                                            </td>


                                                                            {this.chiPhiNgay(value.ma_san_pham)}


                                                                        </tr>
                                                                    })
                                                                    :
                                                                    ""
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        chonThang: state.PickDate.chonThang
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        pickDate: (pickDate) => {
            dispatch({
                type: "PICK_DATE",
                pickDate
            })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BaoCaoQC)