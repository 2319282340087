import React, { Component } from 'react';
import * as Func from '../../functions/function.js';
import * as Config from '../../config.js';
import ReactChartkick, { ColumnChart  } from 'react-chartkick';
import DayPicker,{ DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import {  Link } from "react-router-dom";

import Showdate from './includes/Showdate';
import Helmet from 'react-helmet';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';

const axios = require('axios');
var moment = require('moment');


class ThongKeTheoNhanVien extends Component {
    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            chartData:[],
            // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            // endDate: moment().format('YYYY-MM-DD'), 
            startDate: moment().format('YYYY-MM-01'),
          endDate: moment().format('YYYY-MM-DD'),
            from: undefined,
            to: undefined,
            show: false
        };
        this.Chart = this.Chart.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }

    loadData(){
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'thong-ke/thong-ke-theo-nhan-vien?startDate='+this.state.startDate+'&endDate='+this.state.endDate+'&token=' + token)
        .then(rs => {
            this.setState({
                data: JSON.parse(JSON.stringify(rs.data.data)),
                chartData: JSON.parse(JSON.stringify(rs.data.chartData)),
            });
        });
    }

    componentDidMount(){
        this.loadData();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.state.startDate || nextProps.endDate != this.state.endDate){
            this.setState({
                startDate: nextProps.startDate ,
                endDate: nextProps.endDate
            },()=>this.loadData())
        }
    }
    
    tmplNv(item,key){
        return(
            <tr className="data-row" key={key} style={{opacity: 1}}>
                <td className="reports-property">
                    <div className="reports-property-content--drillable"><a className="value">{item.nhanvien}</a></div>
                </td>

                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{Func.FormatNumber(item.doanhso)}₫</span></div>
                </td>
                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{Func.FormatNumber(item.tongdon)}</span></div>
                </td>

                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{item.doanhsohoanthanh.toLocaleString()}đ</span></div>
                </td>
                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{item.tongdonhoanthanh}</span></div>
                </td>

                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{item.doanhsodonhoan.toLocaleString()}đ</span></div>
                </td>
                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{item.donhoan} ( {Func.lam_tron(item.donhoan/(item.donhoan+item.tongdonhoanthanh)*100)+"%"} )</span></div>
                </td>
            </tr>
        )
    }

    Chart(){
        return (
            <div className="next-card next-card--primary">
                <div id="reports-chart" className="reports-chart" style={{opacity: 1}}>
                    <section className="next-card__section">
                        <ColumnChart donut={true} thousands="." decimal="," data={this.state.chartData} />
                    </section>
                </div>
            </div>
        )
    }
    show(){
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }
    
    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        },()=>{this.loadData();this.show()});
    }
    onDateRangeSelectChange(event){
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate =  moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate =  moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate =  moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate =  moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate =  moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-')+moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate =  moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate =  moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate =  moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;
        
            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        },() => { this.loadData() }
        )  
    }
    showCalendar(){
        const from =  new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{marginBottom:10,width:350}}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> - 
                <span className="InputFromTo-to">
                <DayPickerInput
                    ref={el => (this.to = el)}
                    value={to}
                    placeholder="To"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: to,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                    }}
                    onDayChange={this.handleToChange}
                />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }

    render() {
        let doanhthu = 0;
        let tongdon = 0;
        let donhoan = 0;
        let donhoanthanh = 0;
        let doanhsohoanthanh = 0;
        let doanhsodonhoan = 0;
        return (
            <div id="content">
                <div id="dashboard" className="container-fluid-md">
                    <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">
                        <div className="ui-title-bar">
                            <div className="ui-title-bar__navigation">
                                <div className="ui-breadcrumbs">
                                <Link to="/analytics" className="ui-button ui-button--transparent ui-breadcrumb">
                                    <svg className="next-icon next-icon--size-20 next-icon--no-nudge"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" /> </svg>
                                    <span className="ui-breadcrumb__item">Báo cáo</span>
                                </Link>
                                </div>
                                    <div className="ui-title-bar__pagination">
                                </div>
                            </div>
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <h1 className="ui-title-bar__title">Doanh thu theo nhân viên</h1>
                                </div>
                                <div className="sub-title">
                                    <span>Dữ liệu được tổng hợp đến hết ngày {moment(this.state.endDate).format('DD/MM/YYYY')} </span><span data-bind="lagAggTime"> tính theo số lượng đơn đã đối soát và thời gian tạo đơn bắt đầu từ ngày 01/09/2019</span>
                                </div>
                                <div id="reportrangereceived" className="form-group" style={{position:'relative'}}>
                                    <span  onClick={this.show} style={{position:'absolute',top:'5px',width:'100%'}}>{ moment(this.state.startDate).format('DD/MM/YYYY') } đến { moment(this.state.endDate).format('DD/MM/YYYY') }</span>
                                    <i  onClick={this.show} className="fa fa-calendar" style={{float:'right',paddingTop: '7px',paddingLeft:'7px'}}></i>
                                    { this.state.show  ? <this.showCalendar /> : "" }
                                </div>
                            </div>
                        </div>

                        <this.Chart />

                        <div className="next-card next-card--primary" style={{position: 'relative'}}>
                            <section className="next-card__section">
                                <div id="report-table">
                                    <table className="expanded st">
                                        <thead>
                                            <tr>
                                                <th className="reports-property is-sortable"><span className="field">Tên nhân viên</span></th>

                                                <th className="reports-measure is-sortable"><span className="field">Doanh số chốt</span></th>
                                                <th className="reports-measure is-sortable"><span className="field">Đơn hàng chốt</span></th>

                                                <th className="reports-measure is-sortable"><span className="field">Doanh số thành công</span></th>
                                                <th className="reports-measure is-sortable"><span className="field">Đơn hàng thành công</span></th>

                                                <th className="reports-measure is-sortable"><span className="field">Doanh số hoàn</span></th>
                                                <th className="reports-measure is-sortable"><span className="field">Đơn hàng hoàn</span></th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map((item,key)=>{
                                                doanhthu += parseInt(item.doanhso);
                                                tongdon += parseInt(item.tongdon);

                                                donhoanthanh += parseInt(item.tongdonhoanthanh);
                                                doanhsohoanthanh += parseInt(item.doanhsohoanthanh);

                                                donhoan += parseInt(item.donhoan);
                                                doanhsodonhoan += parseInt(item.doanhsodonhoan);

                                                

                                                return this.tmplNv(item,key);
                                            },this)}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <th className="reports-property is-sortable"><span className="field">TỔNG</span></th>

                                                <th className="reports-measure is-sortable"><span className="field">{Func.FormatNumber(doanhthu)}</span></th>
                                                <th className="reports-measure is-sortable"><span className="field">{tongdon.toLocaleString()}</span></th>

                                                <th className="reports-measure is-sortable"><span className="field">{doanhsohoanthanh.toLocaleString()}</span></th>
                                                <th className="reports-measure is-sortable"><span className="field">{donhoanthanh.toLocaleString()}</span></th>

                                                <th className="reports-measure is-sortable"><span className="field">{doanhsodonhoan.toLocaleString()}</span></th>
                                                <th className="reports-measure is-sortable"><span className="field">{donhoan.toLocaleString()} ({Func.lam_tron(donhoan/(donhoan+donhoanthanh)*100)}%)</span></th>
                                                
                                            </tr>
                                        </tfoot>


                                    </table>
                                </div>
                            </section>
                        </div>
                        
                        {/* <div className="footer-row">
                            <div className="span24">
                                <footer>
                                    <div className="total-title" style={{width: '146px'}}>
                                        <p className="footer-main subdued"><strong>TỔNG</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '130px'}}>
                                        <p className="footer-main"><strong>{Func.FormatNumber(doanhthu)}₫</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '95px'}}>
                                        <p className="footer-main"><strong>{tongdon.toLocaleString()}</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '200px'}}>
                                        <p className="footer-main"><strong>{doanhsohoanthanh.toLocaleString()}</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '142px'}}>
                                        <p className="footer-main"><strong>{donhoanthanh.toLocaleString()}</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '245px'}}>
                                        <p className="footer-main"><strong>{doanhsodonhoan.toLocaleString()}</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '145px'}}>
                                        <p className="footer-main"><strong>{donhoan.toLocaleString()} ({Func.lam_tron(donhoan/(donhoan+donhoanthanh)*100)}%)</strong></p>
                                    </div>
                                </footer>
                            </div>
                        </div> */}

                        
                    </div>
                </div>
            </div>
        );
    }
}

export default ThongKeTheoNhanVien;