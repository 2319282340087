const productsInitialState = [
    {
        id: 1,
        name: 'Iphone 7 Plus',
        image: '',
        description: 'Sản phẩm do Apple sản phẩm',
        price: 500,
        stock: 10
    },
    {
        id: 2,
        name: 'Samsung Galaxy S7',
        image: '',
        description: 'Sản phẩm do Samsung sản phẩm',
        price: 400,
        stock: 15
    },
    {
        id: 3,
        name: 'Oppo F1s',
        image: '',
        description: 'Sản phẩm do Oppo sản phẩm',
        price: 600,
        stock: 5
    }
];
const products = (state = productsInitialState, action) => {
    switch (action.type) {
        default: return state
    }
}
export default products;