import React, { Component } from 'react';
import ReactChartkick, { ColumnChart  } from 'react-chartkick';

import * as Func from '../../functions/function.js';
import * as Config from '../../config.js';

const axios = require('axios');
var moment = require('moment');

class Revenuebymonth extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data:[],
          chart: [],
          startDate: moment().subtract(12, 'months').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        };
    }

    loadData(){
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'analytics/revenue-by-month?startDate='+this.state.startDate+'&endDate='+this.state.endDate+'&token=' + token)
        .then(rs => {
            this.setState({
                chart: JSON.parse(JSON.stringify(rs.data.doanhthuChart)),
                data: JSON.parse(JSON.stringify(rs.data.data)),
            });
        });
    }
    componentDidMount(){
        this.loadData();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.state.startDate || nextProps.endDate != this.state.endDate){
            this.setState({
                startDate: nextProps.startDate ,
                endDate: nextProps.endDate
            },()=>this.loadData())
        }
    }

    render() {
        let tongdon = 0;
        let tongdoanhthu =  0;
        let tongdonhoanthanh = 0;
        let tongdoanhthuhoanthanh = 0;
        let tongdonhoan = 0 ;
        return (
            <div id="reports-chart" className="reports-chart" style={{opacity: 1}}>
                <div className="next-card next-card--timeline" style={{position:'relative',margin:0}} >
                    <section className="next-card__section">
                        <ColumnChart donut={true} thousands="." decimal="," data={this.state.chart} />
                    </section>
                   
                    <section className="next-card__section">
                        <div id="report-table">
                            <table className="expanded st">
                                <thead>
                                <tr>
                                    <th className="reports-property is-sortable sorted-asc"><span className="field">Tháng</span></th>
                                    <th className="reports-measure is-sortable"><span className="field">SL Đơn bán</span></th>
                                    <th className="reports-measure is-sortable"><span className="field">Doanh thu bán được</span></th>
                                    <th className="reports-measure is-sortable"><span className="field">Đơn hàng đối soát</span></th>
                                    <th className="reports-measure is-sortable"><span className="field">Doanh thu đối soát</span></th>
                                    <th className="reports-measure is-sortable"><span className="field">Đơn hàng hoàn</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item,key)=>{
                                        tongdon += parseInt(item.tongdon);
                                        tongdoanhthu += parseInt(item.doanhthu);
                                        tongdonhoanthanh += parseInt(item.tongdonhoanthanh);
                                        tongdoanhthuhoanthanh += parseInt(item.doanhthuhoanthanh);
                                        tongdonhoan += parseInt(item.tongdonhoan);
                                        return (
                                            <tr key={key} className="summary last-row total-amount show-tr-when-printing">
                                                <td className="reports-property">{item.ngaythang}</td>
                                                <td className="reports-measure">{item.tongdon}</td>
                                                <td className="reports-measure">{Func.FormatNumber(item.doanhthu)}₫</td>
                                                <td className="reports-measure">{item.tongdonhoanthanh}</td>
                                                <td className="reports-measure">{Func.FormatNumber(item.doanhthuhoanthanh)}₫</td>
                                                <td className="reports-measure">{item.tongdonhoan}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>

                <div className="footer-row">
                    <div className="span24">
                        <footer>
                            <div className="total-title" style={{width: '240px'}}>
                                <p className="footer-main subdued"><strong>TỔNG</strong></p>
                            </div>
                            <div className="total-cell" style={{width: '128px'}}>
                                <p className="footer-main"><strong>{tongdon}</strong></p>
                        </div>
                            <div className="total-cell" style={{width: '215px'}}>
                                <p className="footer-main"><strong>{Func.FormatNumber(tongdoanhthu)}₫</strong></p>
                            </div>
                            <div className="total-cell" style={{width: '215px'}}>
                                <p className="footer-main"><strong>{tongdonhoanthanh}</strong></p>
                            </div>
                            <div className="total-cell" style={{width: '215px'}}>
                                <p className="footer-main"><strong>{Func.FormatNumber(tongdoanhthuhoanthanh)}₫</strong></p>
                            </div>
                            <div className="total-cell" style={{width: '200px'}}>
                                <p className="footer-main"><strong>{tongdonhoan}</strong></p>
                            </div>
                        </footer>
                    </div>
                </div>


            </div>
        )
    }
}
export default Revenuebymonth;