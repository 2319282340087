import React, { Component } from 'react';
import * as Func from '../../functions/function.js';
import * as Config from '../../config.js';
import ReactChartkick, { ColumnChart  } from 'react-chartkick';
import DayPicker,{ DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import {  Link } from "react-router-dom";
import Select from 'react-select'
import Showdate from './includes/Showdate';
import Helmet from 'react-helmet';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';

const axios = require('axios');
var moment = require('moment');

class HieuQuaQuangCao extends Component {
    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            chartData:[],
            startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            endDate:  moment().subtract(1, 'days').format('YYYY-MM-DD'),
            // startDate: moment().subtract(10, 'days').format('YYYY-MM-DD'),
            // endDate: moment().format('YYYY-MM-DD'), 
            // startDate: moment().format('YYYY-MM-01'),
        //   endDate: moment().format('YYYY-MM-DD'),
            from: undefined,
            to: undefined,
            show: false,
            selectedLyDo: null,
            trangthai: [],
            tong_chi_tieu: 0,
            so_don: 0,
            hieuquaon: false
        };
        this.Chart = this.Chart.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }

    handleChangeLyDo = (selectedLyDo) => {
        this.setState({ selectedLyDo });
        this.setState({
            trangThaiDaChon: selectedLyDo.value,
        });
    }

    loadData(){
        localStorage.removeItem('HIEU_QUA_QC');
        let token = localStorage.getItem('token');

        axios.get(Config.API_URL + 'danh-sach-ma-san-pham?token=' + token)
        .then(rstrangthai => {

            this.setState({
                trangthai: rstrangthai.data,
            });

            // Load Full
            rstrangthai.data.map((value, key) => {

                // console.log(value.value);
                this.loadTaiKhoanFull(value.value);

            })



        });

        
    }



    loadTaiKhoan() {
        localStorage.removeItem('CHI_TIET_CHIEN_DICH');
        this.setState({tong_chi_tieu: Number(0)})
        var url = "https://graph.facebook.com/v3.3/me/adaccounts?limit=1000&fields=account_status,age,name,amount_spent,balance,business_name,timezone_name,timezone_offset_hours_utc,currency&access_token=" + Config.TOKEN_ADS;
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'dem-so-don-quang-cao?token=' + token + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&masp=' + this.state.trangThaiDaChon )
        .then(rssodon => {
            this.setState({
                so_don: rssodon.data,
            });
        });
        
        axios.get(url)
        .then(response => {
            if(response && response.data && response.data.data){


                response.data.data.map( (value,key)=>{
                    this.setState({hieuquaon:false})
                    this.loadChienDich(value.id,value.name,this.state.trangThaiDaChon)
                    // console.log(value.id);
                    // console.log(value.name);
                } )

                // this.setState({
                //     acount: response.data.data,
                // })
            }
        })
        .catch(error => console.log("Có lỗi xảy ra"));
    }


    loadTaiKhoanFull(masp) {
        this.setState({hieuquaon:true})
        var item = {};
        item.key = masp;
        item.chitieu = 0;
       
        
        var CART_LOCAL = JSON.parse(localStorage.getItem('HIEU_QUA_QC'));
        
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'dem-so-don-quang-cao?token=' + token + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&masp=' + masp)
            .then(rssodon => {
                item.so_don = rssodon.data;
                if (localStorage.getItem('HIEU_QUA_QC')) {
                    var CART_LOCAL = JSON.parse(localStorage.getItem('HIEU_QUA_QC'));
                } else {
                    var CART_LOCAL = [];
                }
                if (CART_LOCAL.length > 0) {
                    var checkTrung = false;
                    JSON.parse(localStorage.getItem('HIEU_QUA_QC')).map((value, key) => {
                        if (JSON.parse(JSON.stringify(JSON.parse(value))).key === item.key) {
                            checkTrung = true;
                            var itemnew = {};
                            itemnew.key = JSON.parse(JSON.stringify(JSON.parse(value))).key;
                            itemnew.chitieu = Number(JSON.parse(JSON.stringify(JSON.parse(value))).chitieu);
                            itemnew.so_don = rssodon.data;
                            CART_LOCAL[key] = JSON.stringify(itemnew);
                        }
                    })
                    if (checkTrung === false) {
                        CART_LOCAL.push(JSON.stringify(item));
                    }

                } else {
                    CART_LOCAL.push(JSON.stringify(item));
                }
                localStorage.setItem('HIEU_QUA_QC', JSON.stringify(CART_LOCAL));



            });



        this.setState({tong_chi_tieu: Number(0)})
        var url = "https://graph.facebook.com/v3.3/me/adaccounts?limit=1000&fields=account_status,age,name,amount_spent,balance,business_name,timezone_name,timezone_offset_hours_utc,currency&access_token=" + Config.TOKEN_ADS;
        
        axios.get(url)
        .then(response => {
            if(response && response.data && response.data.data){


                response.data.data.map( (value,key)=>{
                    this.loadChienDichFull(value.id,value.name,masp)
                    // console.log(value.id);
                } )
            }
        })
        .catch(error => console.log("Có lỗi xảy ra"));
    }


    loadChienDich(id_tk,name,masp) {
        // Get giá trị tổng
        var url = 'https://graph.facebook.com/v7.0/'+id_tk+'/insights?access_token='+Config.TOKEN_ADS+'&__activeScenarios=["insightsTable.view"]&__business_id=1143415902345331&_app=ADS_MANAGER&_priority=LOW&_reqName=adaccount/insights&_reqSrc=AdsPETableDataFetchingPolicy.fetchFooter>fetchAsyncAfterSyncFails&_sessionID=1f1062fcf1314355&action_attribution_windows=["default"]&fields=["created_time","spend","reach","results","objective","cost_per_result","actions","cost_per_action_type","cpc","ctr","cpm","frequency","cost_per_unique_action_type"]&filtering=[{"field":"campaign.name","operator":"CONTAIN","value":"'+this.state.trangThaiDaChon+'"},{"field":"action_type","operator":"IN","value":["comment","onsite_conversion.messaging_first_reply","omni_complete_registration"]}]&include_headers=false&level=account&limit=5000&locale=vi_VN&method=get&pretty=0&suppress_http_code=1&time_range={"since":"'+this.state.startDate+'","until":"'+this.state.endDate+'"}&xref=f344613f384b4e';
        axios.get(url)
        .then(response => {

            
            var item = {};
            item.id_tk = id_tk.replace('act_','');
            item.ten_tk = name;
            item.masp = masp;
            
            var CART_LOCAL = JSON.parse(localStorage.getItem('CHI_TIET_CHIEN_DICH'));

            if(response.data.data && response.data.data.length > 0){
                // console.log(response.data);
                console.log(id_tk);
                console.log(name);
                // console.log(response.data.data[0].spend);
                item.chiphi = Number(response.data.data[0].spend);
                this.setState({tong_chi_tieu: Number(this.state.tong_chi_tieu) + Number(response.data.data[0].spend)})



                if (localStorage.getItem('CHI_TIET_CHIEN_DICH')) {
                    var CART_LOCAL = JSON.parse(localStorage.getItem('CHI_TIET_CHIEN_DICH'));
                } else {
                    var CART_LOCAL = [];
                }

                if (CART_LOCAL.length > 0) {
                    var checkTrung = false;
                    JSON.parse(localStorage.getItem('CHI_TIET_CHIEN_DICH')).map((value, key) => {
                        if (JSON.parse(JSON.stringify(JSON.parse(value))).id_tk === item.id_tk) {
                            checkTrung = true;
                            var itemnew = {};
                            itemnew.id_tk = JSON.parse(JSON.stringify(JSON.parse(value))).id_tk;
                            itemnew.ten_tk = JSON.parse(JSON.stringify(JSON.parse(value))).ten_tk;
                            itemnew.masp = JSON.parse(JSON.stringify(JSON.parse(value))).masp;
                            itemnew.chiphi = Number(JSON.parse(JSON.stringify(JSON.parse(value))).chiphi) + Number(response.data.data[0].spend);
                            CART_LOCAL[key] = JSON.stringify(itemnew);
                        }
                    })
                    if (checkTrung === false) {
                        CART_LOCAL.push(JSON.stringify(item));
                    }

                } else {
                    CART_LOCAL.push(JSON.stringify(item));
                }
                localStorage.setItem('CHI_TIET_CHIEN_DICH', JSON.stringify(CART_LOCAL));

                var CHI_TIET_CHIEN_DICH = JSON.parse(localStorage.getItem('CHI_TIET_CHIEN_DICH'));

                CHI_TIET_CHIEN_DICH.sort();
                this.setState({
                    CHI_TIET_CHIEN_DICH
                })



            }
            
        })
        .catch(error => console.log("Có lỗi xảy ra"));
    }

    loadChienDichFull(id_tk,name,masp) {

        
        

        


        // Get Facebook
        var tong_chi_tieu = 0;
        var url = 'https://graph.facebook.com/v7.0/'+id_tk+'/insights?access_token='+Config.TOKEN_ADS+'&__activeScenarios=["insightsTable.view"]&__business_id=1143415902345331&_app=ADS_MANAGER&_priority=LOW&_reqName=adaccount/insights&_reqSrc=AdsPETableDataFetchingPolicy.fetchFooter>fetchAsyncAfterSyncFails&_sessionID=1f1062fcf1314355&action_attribution_windows=["default"]&fields=["created_time","spend","reach","results","objective","cost_per_result","actions","cost_per_action_type","cpc","ctr","cpm","frequency","cost_per_unique_action_type"]&filtering=[{"field":"campaign.name","operator":"CONTAIN","value":"'+masp+'"},{"field":"action_type","operator":"IN","value":["comment","onsite_conversion.messaging_first_reply","omni_complete_registration"]}]&include_headers=false&level=account&limit=5000&locale=vi_VN&method=get&pretty=0&suppress_http_code=1&time_range={"since":"'+this.state.startDate+'","until":"'+this.state.endDate+'"}&xref=f344613f384b4e';
        axios.get(url)
        .then(response => {


            
            var item = {};
            item.key = masp;
            item.chitieu = 0;
            var CART_LOCAL = JSON.parse(localStorage.getItem('HIEU_QUA_QC'));

            

           

            

            if(response.data.data && response.data.data.length > 0){
                // this.tmplNv(masp,name);
                console.log(masp);
                console.log(name);
                console.log(Number(tong_chi_tieu) + Number(response.data.data[0].spend));

                

                if (localStorage.getItem('HIEU_QUA_QC')) {
                    var CART_LOCAL = JSON.parse(localStorage.getItem('HIEU_QUA_QC'));
                } else {
                    var CART_LOCAL = [];
                }
                if (CART_LOCAL.length > 0) {
                    var checkTrung = false;
                    JSON.parse(localStorage.getItem('HIEU_QUA_QC')).map((value, key) => {
                        if (JSON.parse(JSON.stringify(JSON.parse(value))).key === item.key) {
                            checkTrung = true;
                            var itemnew = {};
                            itemnew.key = JSON.parse(JSON.stringify(JSON.parse(value))).key;
                            itemnew.chitieu = Number(JSON.parse(JSON.stringify(JSON.parse(value))).chitieu) + Number(response.data.data[0].spend);
                            itemnew.so_don = Number(JSON.parse(JSON.stringify(JSON.parse(value))).so_don);
                            CART_LOCAL[key] = JSON.stringify(itemnew);
                        }
                    })
                    if (checkTrung === false) {
                        CART_LOCAL.push(JSON.stringify(item));
                    }

                } else {
                    CART_LOCAL.push(JSON.stringify(item));
                }
                localStorage.setItem('HIEU_QUA_QC', JSON.stringify(CART_LOCAL));

                var HIEU_QUA = JSON.parse(localStorage.getItem('HIEU_QUA_QC'));

                HIEU_QUA.sort();
                this.setState({
                    HIEU_QUA
                })



            }
            
        })
        .catch(error => console.log("Có lỗi xảy ra"));
    }


    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.trangThaiDaChon != this.state.trangThaiDaChon){
            this.setState({
                trangThaiDaChon: this.state.trangThaiDaChon,

            },()=>this.loadTaiKhoan())
        }
    }
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.state.startDate || nextProps.endDate != this.state.endDate){
            this.setState({
                startDate: nextProps.startDate ,
                endDate: nextProps.endDate,

            },()=>this.loadTaiKhoan())
        }
    }
    
    tmplNv(item,key){
        return(
            <tr className="data-row" style={{opacity: 1}}>
                <td className="reports-property">
                    <div className="reports-property-content--drillable"><a className="value">{item}</a></div>
                </td>

                {/* <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{Func.FormatNumber(item.doanhso)}₫</span></div>
                </td>
                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{Func.FormatNumber(item.tongdon)}</span></div>
                </td>

                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{item.doanhsohoanthanh.toLocaleString()}đ</span></div>
                </td>
                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{item.tongdonhoanthanh}</span></div>
                </td>

                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{item.doanhsodonhoan.toLocaleString()}đ</span></div>
                </td>
                <td className="reports-measure">
                    <div className="reports-measure-content"><span className="value">{item.donhoan} ( {Func.lam_tron(item.donhoan/(item.donhoan+item.tongdonhoanthanh)*100)+"%"} )</span></div>
                </td> */}
            </tr>
        )
    }

    Chart(){
        return (
            <div className="next-card next-card--primary">
                <div id="reports-chart" className="reports-chart" style={{opacity: 1}}>
                    <section className="next-card__section">
                        <ColumnChart donut={true} thousands="." decimal="," data={this.state.chartData} />
                    </section>
                </div>
            </div>
        )
    }
    show(){
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }
    
    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        },()=>{this.loadData();this.show()});
    }
    onDateRangeSelectChange(event){
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate =  moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate =  moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate =  moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate =  moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate =  moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate =  moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-')+moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate =  moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate =  moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate =  moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;
        
            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        },() => { this.loadData() }
        )  
    }
    showCalendar(){
        const from =  new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{marginBottom:10,width:350}}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> - 
                <span className="InputFromTo-to">
                <DayPickerInput
                    ref={el => (this.to = el)}
                    value={to}
                    placeholder="To"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: to,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                    }}
                    onDayChange={this.handleToChange}
                />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }

    Copy(){
        var copyText = document.getElementById("myInput");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      
        /* Copy the text inside the text field */
        document.execCommand("copy");
      
        /* Alert the copied text */
        // alert("Copied the text: " + copyText.value);
    }
    // tableRow(){
    //     var data = localStorage.getItem('HIEU_QUA_QC');
    //     console.log(data);
        
    // }
    render() {
        if(this.state.CHI_TIET_CHIEN_DICH){
            console.log(this.state.CHI_TIET_CHIEN_DICH);
        }
        
        let tong_chi_tieu = 0;
        let tong_don = 0;
        let donhoan = 0;
        let donhoanthanh = 0;
        let doanhsohoanthanh = 0;
        let doanhsodonhoan = 0;
        const { selectedLyDo } = this.state;
        return (
            <div id="content">
                <div id="dashboard" className="container-fluid-md">
                    <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">
                        <div className="ui-title-bar">
                            <div className="ui-title-bar__navigation">
                                <div className="ui-breadcrumbs">
                                <Link to="/analytics" className="ui-button ui-button--transparent ui-breadcrumb">
                                    <svg className="next-icon next-icon--size-20 next-icon--no-nudge"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" /> </svg>
                                    <span className="ui-breadcrumb__item">Báo cáo</span>
                                </Link>
                                </div>
                                    <div className="ui-title-bar__pagination">
                                </div>
                            </div>
                            <div className="ui-title-bar__main-group">
                                <div className="ui-title-bar__heading-group">
                                    <h1 className="ui-title-bar__title">Báo cáo Quảng Cáo</h1>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div id="reportrangereceived" className="form-group" style={{ position: 'relative' }}>
                                            <span onClick={this.show} style={{ position: 'absolute', top: '5px', width: '100%' }}>
                                                {moment(this.state.startDate).format('DD/MM/YYYY')} đến {moment(this.state.endDate).format('DD/MM/YYYY')}</span>
                                            <i onClick={this.show} className="fa fa-calendar" style={{ float: 'right', paddingTop: '7px', paddingLeft: '7px' }}></i>
                                            {this.state.show ? <this.showCalendar /> : ""}
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-3">

                                        <Select
                                            value={selectedLyDo}
                                            onChange={this.handleChangeLyDo}
                                            options={this.state.trangthai}
                                            placeholder='Chọn mã sản phẩm'
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                    <input type="text" value={this.state.tong_chi_tieu+ " " +this.state.so_don} id="myInput" />
                                    </div>
                                    <div className="col-sm-3">
                                    <button onClick={()=>this.Copy()}>Copy</button>
                                    </div>
                                </div>

                               

                                

                                  

                               
                                



                            </div>
                        </div>

                        {/* <this.Chart /> */}

                        <div className="next-card next-card--primary" style={{position: 'relative'}}>
                            <section className="next-card__section">
                                <div id="report-table">
                                    <table className="expanded st">
                                        {
                                            this.state.HIEU_QUA && this.state.hieuquaon ?
                                                <thead>
                                                    <tr>
                                                        <th className="reports-property is-sortable"><span className="field">STT</span></th>
                                                        <th className="reports-property is-sortable"><span className="field">Mã sản phẩm</span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">Số tiền đã chi tiêu</span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">Số đơn</span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">Chi phí / đơn</span></th>


                                                    </tr>
                                                </thead>
                                                :
                                                ""
                                        }

                                        {
                                            this.state.CHI_TIET_CHIEN_DICH ?
                                                <thead>
                                                    <tr>
                                                        <th className="reports-property is-sortable"><span className="field">STT</span></th>
                                                        <th className="reports-property is-sortable"><span className="field">Tài khoản</span></th>
                                                        <th className="reports-property is-sortable"><span className="field">Chi phí</span></th>
                                                        <th className="reports-property is-sortable"><span className="field">Chi phí/Đơn</span></th>


                                                    </tr>
                                                </thead>
                                                :
                                                ""
                                        }

                                        <tbody>


                                        {
                                                this.state.CHI_TIET_CHIEN_DICH?
                                                this.state.CHI_TIET_CHIEN_DICH.map((value, key) => {
                                                    return  <tr className="data-row" style={{opacity: 1}}>
                                                        <td className="reports-property">
                                                        <div className="reports-property-content--drillable">{key+1}</div>
                                                    </td>
                                                    <td className="reports-property">
                                                        <div className="reports-property-content--drillable"><a href={'https://business.facebook.com/adsmanager/manage/campaigns?act='+JSON.parse(value).id_tk+'&columns=name%2Ccreated_time%2Cdelivery%2Ccampaign_name%2Cbudget%2Cspend%2Creach%2Cresults%2Ccost_per_result%2Cactions%3Acomment%2Ccost_per_action_type%3Acomment%2Cactions%3Aonsite_conversion.messaging_first_reply%2Ccost_per_action_type%3Aonsite_conversion.messaging_first_reply%2Ccpc%2Crelevance_score%3Ascore%2Cctr%2Ccpm%2Cfrequency%2Cschedule%2Cstop_time%2Ccost_per_unique_action_type%3Aomni_complete_registration&attribution_windows=default&date=2019-06-05_2019-06-06%2Ctoday&filter_set=CAMPAIGN_GROUP_DELIVERY_INFO-STRING_SET%1EIN%1E["active"%2C"pending_review"%2C"completed"]%1DCAMPAIGN_DELIVERY_INFO-STRING_SET%1EIN%1E["active"%2C"pending_review"]%1DADGROUP_DELIVERY_INFO-STRING_SET%1EIN%1E["active"%2C"pending_review"]%1DSEARCH_BY_CAMPAIGN_GROUP_NAME-STRING%1ECONTAIN%1E"'+JSON.parse(value).masp+'"&root_level=ad'} target = "_blank" className="value">{JSON.parse(value).ten_tk}</a></div>
                                                    </td>
                                                    <td className="reports-property">
                                                        <div className="reports-property-content--drillable">{JSON.parse(value).chiphi.toLocaleString()}</div>
                                                    </td>
                                    
                                                </tr>
                                                })
                                                :
                                                ""
                                            }


                                            {
                                                this.state.HIEU_QUA && this.state.hieuquaon?
                                                this.state.HIEU_QUA.map((value, key) => {
                                                    tong_chi_tieu += parseInt(JSON.parse(value).chitieu);
                                                    tong_don += parseInt(JSON.parse(value).so_don);
                                                    
                                                    return  <tr className="data-row" style={{opacity: 1}}>
                                                        <td className="reports-property">
                                                        <div className="reports-property-content--drillable"><a className="value">{key+1}</a></div>
                                                    </td>
                                                    <td className="reports-property">
                                                        <div className="reports-property-content--drillable"><a className="value">{JSON.parse(value).key}</a></div>
                                                    </td>
                                    
                                                    <td className="reports-measure">
                                                        <div className="reports-measure-content"><span className="value">{JSON.parse(value).chitieu.toLocaleString()}</span></div>
                                                    </td>
                                                    <td className="reports-measure">
                                                        <div className="reports-measure-content"><span className="value">{JSON.parse(value).so_don}</span></div>
                                                    </td>
                                                    <td className="reports-measure">
                                                        <div className="reports-measure-content"><span className="value">{ JSON.parse(value).so_don?Math.ceil(JSON.parse(value).chitieu / JSON.parse(value).so_don).toLocaleString():'' }</span></div>
                                                    </td>
                                    
                                                </tr>
                                                })
                                                :
                                                "Chưa có dữ liệu"
                                            }
                                        </tbody>
                                        {
                                            this.state.HIEU_QUA && this.state.hieuquaon ?

                                                <tfoot>
                                                    <tr>
                                                        <th className="reports-property is-sortable"><span className="field">TỔNG</span></th>

                                                        <th className="reports-measure is-sortable"><span className="field">{Func.FormatNumber(tong_chi_tieu)}</span></th>
                                                        <th className="reports-measure is-sortable"><span className="field">{tong_don.toLocaleString()}</span></th>


                                                    </tr>
                                                </tfoot>
                                                :
                                                ""
                                        }

                                    </table>
                                </div>
                            </section>
                        </div>
                        
                        {/* <div className="footer-row">
                            <div className="span24">
                                <footer>
                                    <div className="total-title" style={{width: '146px'}}>
                                        <p className="footer-main subdued"><strong>TỔNG</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '130px'}}>
                                        <p className="footer-main"><strong>{Func.FormatNumber(doanhthu)}₫</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '95px'}}>
                                        <p className="footer-main"><strong>{tongdon.toLocaleString()}</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '200px'}}>
                                        <p className="footer-main"><strong>{doanhsohoanthanh.toLocaleString()}</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '142px'}}>
                                        <p className="footer-main"><strong>{donhoanthanh.toLocaleString()}</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '245px'}}>
                                        <p className="footer-main"><strong>{doanhsodonhoan.toLocaleString()}</strong></p>
                                    </div>
                                    <div className="total-cell" style={{width: '145px'}}>
                                        <p className="footer-main"><strong>{donhoan.toLocaleString()} ({Func.lam_tron(donhoan/(donhoan+donhoanthanh)*100)}%)</strong></p>
                                    </div>
                                </footer>
                            </div>
                        </div> */}

                        
                    </div>
                </div>
            </div>
        );
    }
}

export default HieuQuaQuangCao;