import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import * as Config from '../../config.js';
import { createBrowserHistory } from 'history';

import ReactChartkick, { ColumnChart } from 'react-chartkick';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Showdate from './includes/Showdate';
import Helmet from 'react-helmet';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
import Pagination from "react-js-pagination";

import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import ReactTooltip from 'react-tooltip'



import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];




const danhMuc = [
    { value: 'GHTK', label: 'GHTK' },
    { value: 'Bưu điện', label: 'Bưu điện' },
];

const trangThai = [
    { value: 'moi_xuat_kho', label: 'Chưa lấy' },
    { value: 'da_lay', label: 'Đã lấy' },
];



const axios = require('axios');
var moment = require('moment');
const qs = require('qs');
const history = createBrowserHistory();
const queryString = require('query-string');

class DaDoiSoatVnpot extends Component {

    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);
        this.state = {
            trang_thai:'',
            danhmuc:{ value: 'Bưu điện', label: 'Bưu điện' },
            trangThai:{ value: 'moi_xuat_kho', label: 'Chưa lấy' },
            data: [],
            activePage: 0,
            itemsCountPerPage: 0,
            totalItemsCount: 0,
            pageRangeDisplayed: 5,
            page: 1,
            startDate: moment().format('YYYY-MM-DD'),
            endDate:  moment().format('YYYY-MM-DD'),
            // startDate: moment().format('YYYY-MM-01'),
            // endDate: moment().format('YYYY-MM-DD'),
            from: undefined,
            to: undefined,
            show: false,
            ten_file: 'Xuất kho ' + moment().format('DD-MM-YYYY'),
        };
        this.handlePageChange = this.handlePageChange.bind(this);

        this.Chart = this.Chart.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
        this.show = this.show.bind(this);
        this.onDateRangeSelectChange = this.onDateRangeSelectChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }

    danhMuc = (e) => {
        this.setState({
          danhmuc: e,
          ten_file: e.value+' '+moment().format('DD-MM-YYYY'),
        });
      }

      trangThai = (e) => {
        this.setState({
          trangThai: e,
        });
      }

    handlePageChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        window.scrollTo(0, 0);
    }

    Chart() {

        return (
            <div className="next-card next-card--primary">
                <div id="reports-chart" className="reports-chart" style={{ opacity: 1 }}>
                    <section className="next-card__section">
                        <ColumnChart donut={true} thousands="." decimal="," data={this.state.chartData} />
                    </section>
                </div>
            </div>
        )
    }
    show() {
        this.setState({
            show: !this.state.show
        });
    }
    handleFromChange(from) {
        this.setState({
            startDate: moment(from).format('YYYY-MM-DD')
        });
    }

    handleToChange(to) {
        this.setState({
            endDate: moment(to).format('YYYY-MM-DD')
        }, () => { this.loadData(); this.show() });
    }
    onDateRangeSelectChange(event) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        this.show();
        switch (event.target.value) {
            case 'yesterday':
                startDate = endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                startDate = endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last30days':
                startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'last90days':
                startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastmonth':
                startDate = moment().subtract(1, 'months').format('YYYY-MM-01');
                endDate = moment().subtract(1, 'months').format('YYYY-MM-') + moment().subtract(1, 'months').daysInMonth();
                break;
            case 'thismonth':
                startDate = moment().format('YYYY-MM-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'thisyear':
                startDate = moment().format('YYYY-01-01');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case 'lastyear':
                startDate = moment().subtract(1, 'years').format('YYYY-01-01');
                endDate = moment().subtract(1, 'years').format('YYYY-12-31');
                break;

            default:
                break;
        };
        this.setState({
            startDate: startDate,
            endDate: endDate
        }, () => { this.loadData() }
        )
    }
    showCalendar() {
        const from = new Date(this.state.startDate);
        const to = new Date(this.state.endDate);
        const modifiers = { start: from, end: to };
        return (
            <div className="InputFromTo">
                <select name="dateRangeSelect" id="dateRangeSelect" onChange={this.onDateRangeSelectChange} className="ui-select sapo-textbox" style={{ marginBottom: 10, width: 350 }}>
                    <option selected="selected" value="custom">Tùy chọn</option>
                    <option value="today">Hôm nay</option>
                    <option value="yesterday">Hôm qua</option>
                    <option value="last7days">7 ngày trước</option>
                    <option value="last30days">30 ngày trước</option>
                    <option value="last90days">90 ngày trước</option>
                    <option value="lastmonth">Tháng trước</option>
                    <option value="thismonth">Tháng này</option>
                    <option value="thisyear">Năm nay</option>
                    <option value="lastyear">Năm trước</option>
                </select>
                <DayPickerInput
                    className="startDate"
                    value={from}
                    placeholder="From"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="DD-MM-YYYY"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        month: from,
                        months: Config.MONTHS,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                /> -
                <span className="InputFromTo-to">
                    <DayPickerInput
                        ref={el => (this.to = el)}
                        value={to}
                        placeholder="To"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers,
                            month: to,
                            months: Config.MONTHS,
                            numberOfMonths: 2,
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
                <Helmet>
                    <style>{`.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#f0f8ff!important;color:#4a90e2}.InputFromTo .DayPicker-Day{border-radius:0!important}.InputFromTo .DayPicker-Day--start{border-top-left-radius:50%!important;border-bottom-left-radius:50%!important}.InputFromTo .DayPicker-Day--end{border-top-right-radius:50%!important;border-bottom-right-radius:50%!important}.InputFromTo .DayPickerInput-Overlay{width:350px}.InputFromTo-to .DayPickerInput-Overlay{margin-left:-180px}.InputFromTo{position:absolute;z-index:9999;width:380px;height:auto;background:#fff;border-radius:5px;border:1px solid #ddd;height:300px;top:40px;padding:10px}`}
                    </style>
                </Helmet>
            </div>
        );
    }


    // Hàm

    componentDidMount() {
        this.loadData();
        this.setState({ten_file: this.state.danhmuc.label + ' ' + moment().format('DD-MM-YYYY')})
    }

    componentDidUpdate = (prevProps, prevState) => {

        const query = {
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            page: this.state.page,
        };
        const searchString = qs.stringify(query);
        history.push(
            {
                pathname: '/da-doi-soat-vnpost',
                search: searchString,
            }
        )

        // Get data
        if (prevState.page !== this.state.page || this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate || this.state.danhmuc !== prevState.danhmuc || this.state.trangThai !== prevState.trangThai) {

            this.setState({
                loading: true
            })
            var startDate = '';
            if (this.state.startDate) {
                startDate = this.state.startDate;
            }
            var endDate = '';
            if (this.state.endDate) {
                endDate = this.state.endDate;
            }


            this.loadData();
            // End Get data



        }
    }

    loadData() {
        // var trangThai = '';
        // if (this.state.trangThai) {
        //     var trangThai = this.state.trangThai;
        // }else{
        //     var trangThai = "";
        // }
        let token = localStorage.getItem('token');
this.setState({loading: true})
        // axios.get(Config.API_URL + 'da-doi-soat-vnpost?token=' + token + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + "&page=" + this.state.page + '&hang_van_chuyen=' + this.state.danhmuc.value + '&trang_thai=' + this.state.trangThai.value)
        axios.get(Config.API_URL + 'da-doi-soat-vnpost?token=' + token + "&page=" + this.state.page + '&trang_thai=' + this.state.trangThai)
            .then(response => {
                // console.log(response.data.data);

                this.setState({
                    data: response.data.data,
                    loading: false,
                    activePage: response.data.current_page,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    path: response.data.path,
                });
            });


    }

    chonTrangThai(e) {
        this.setState({
          trangThai: e,
        })
      }

    // End hàm

    render() {

        console.log(this.state.trangThai);
        

        var data = [];

        if(this.state.data){
            this.state.data.map((value1, key1) => {
                data.push([
                    value1.time_gui, 
                    value1.full_name + ', ' + value1.phone, 
                    value1.address+ ', ' + value1.xa + ', ' + value1.huyen + ', ' + value1.tinh,
                    value1.tinh,
                    '',
                    value1.phone,
                    value1.sumTotal,
                    value1.id,
                    'Quần áo',
                    value1.trong_luong,
                    value1.hang_van_chuyen,
                    value1.phi_gui_hang,
                ]);
            })
        }

        

        var multiDataSet = [
            {
                columns: ["Thời gian", "Tên người nhận", "Địa chỉ nhận","Tỉnh phát","Mã tỉnh","Số ĐT","Tiền COD","Mã Đơn","Sản Phẩm","Trọng Lượng","Dịch vụ","Phí Ship"],
                data: data
            },
        ];


        // console.log( this.state.data + 'hi');

        return (
            <div>



{/* 
<ExcelFile>
                <ExcelSheet data={dataSet1} name="Employees">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Wallet Money" value="amount"/>
                    <ExcelColumn label="Gender" value="sex"/>
                    <ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>
                </ExcelSheet>
                <ExcelSheet data={dataSet2} name="Leaves">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Total Leaves" value="total"/>
                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                </ExcelSheet>
            </ExcelFile> */}




            
                <div id="content">
                    <div id="dashboard" className="container-fluid-md">
                        <div className="ui-layout ui-layout--full-width ui-layout__dashboard dashboard-report">
                            {/* <div className="ui-title-bar">
                                <div className="ui-title-bar__navigation">
                                    <div className="ui-breadcrumbs">
                                        <Link className="ui-button ui-button--transparent ui-breadcrumb" to="/orders">
                                            <svg className="next-icon next-icon--size-20 next-icon--no-nudge">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" />
                                            </svg><span className="ui-breadcrumb__item">Đơn hàng</span></Link>
                                    </div>
                                    <div className="ui-title-bar__pagination" />
                                </div>
                                <div className="ui-title-bar__main-group">
                                    <div className="ui-title-bar__heading-group">
                                        <h1 className="ui-title-bar__title">Kiểm soát đơn hoàn</h1></div>
                                        <br></br>
                                        <br></br>
                                    <div className="row">

                                    <div className="col-sm-3">
                                            <Select
                                                autofocus="autofocus"
                                                closeMenuOnSelect={false}
                                                components={makeAnimated()}
                                                // isClearable
                                                // isMulti
                                                onChange={this.trangThai}
                                                options={trangThai}
                                                placeholder={this.state.trangThai?this.state.trangThai.label:'Chọn trạng thái'}
                                            />
                                        </div>

                                        <div className="col-sm-3">
                                            <Select
                                                autofocus="autofocus"
                                                closeMenuOnSelect={false}
                                                components={makeAnimated()}
                                                // isMulti
                                                onChange={this.danhMuc}
                                                options={danhMuc}
                                                placeholder={this.state.danhmuc?this.state.danhmuc.label:'Chọn đơn vị vận chuyển'}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <div id="reportrangereceived" className="form-group" style={{ position: 'relative' }}>
                                                <span onClick={this.show} style={{ position: 'absolute', top: '5px', width: '100%' }}>
                                                    {moment(this.state.startDate).format('DD/MM/YYYY')} đến {moment(this.state.endDate).format('DD/MM/YYYY')}</span>
                                                <i onClick={this.show} className="fa fa-calendar" style={{ float: 'right', paddingTop: '7px', paddingLeft: '7px' }}></i>
                                                {this.state.show ? <this.showCalendar /> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            {this.state.data?this.state.data.length:''+" Đơn"}
                                        <div className="danh-sach-hoan" style={{ position: 'relative' }}>
                                            {
                                                this.state.loading?
                                                'Loading...'
                                                :
                                                <ExcelFile filename={this.state.ten_file} element={<button>Xuất Excel</button>}>
                                                    <ExcelSheet dataSet={multiDataSet} name="Organization" />
                                                </ExcelFile>
                                            }
                                                
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="next-card next-card--primary" style={{ position: 'relative' }}>

                                <section className="ui-card">
                                    <div id="filterAndSavedSearch" context="filterandsavedsearch">
                                        <div className="next-tab__container">
                                            <ul className="next-tab__list filter-tab-list" id="filter-tab-list" role="tablist" data-has-next-tab-controller="true">
                                                <li onClick={() => this.chonTrangThai()} className="filter-tab-item">
                                                        <a className={!this.state.trangThai || this.state.trangThai == '[object Object]' ? "filter-tab filter-tab-active show-all-items next-tab next-tab--is-active" : "filter-tab next-tab"}>Tất cả đơn VnPost</a>
                                                    </li>
                                                <li onClick={() => this.chonTrangThai('chua_lay_tien')} className="filter-tab-item">
                                                    <a className={this.state.trangThai && this.state.trangThai === 'chua_lay_tien' ? "filter-tab next-tab next-tab--is-active" : "filter-tab  next-tab"}>Chưa lấy tiền</a>
                                                </li>
                                                {/* <li className="filter-tab-item"><a className="filter-tab next-tab">Đã nhận hàng hoàn (0)</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </section>

                                <section className="next-card__section">
                                    <div id="report-table">
                                        <table className="expanded st">
                                            <thead>
                                                <tr>
                                                    <th className="reports-measure is-sortable"><span className="field">STT</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Mã Đơn</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Time xuất kho</span></th>
                                                    <th className="reports-property is-sortable"><span className="field">Hãng VC</span></th>
                                                    <th className="reports-property is-sortable"><span className="field">Vận đơn</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Trạng thái giao hàng</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Tiền đã lấy</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Phí gửi</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">Phí hoàn</span></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data?
                                                    this.state.data.map((value, key) => {
                                                        return <tr key={key} className="data-row" style={{ opacity: 1 }}>
                                                             <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{key+1}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value"><Link to={"order/"+value.id}>#{value.id}</Link></span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{value.time_gui}</span></div>
                                                            </td>
                                                            <td className="reports-property">
                                                                <div className="reports-property-content--drillable">
                                                                    <a href={"orders?search="+ value.van_don} target="_blank">
                                                                    <span className="value" title={value.ly_do_hoan}>
                                                                        {
                                                                            value.ly_do_hoan?
                                                                            value.hang_van_chuyen+"?"
                                                                            :
                                                                            value.hang_van_chuyen
                                                                        }
                                                                        
                                                                    </span>
                                                                    </a>
                                                                    </div>
                                                            </td>
                                                            <td className="reports-measure" >
                                                                <div className="reports-measure-content">
                                                                    {
                                                                        value.hang_van_chuyen == 'GHTK' ?
                                                                            <a href={"https://khachhang.giaohangtietkiem.vn/khachhang?customer_created_from=2018-01-01+00:00:00&code=" + value.van_don} target="_blank"> {value.van_don} </a>
                                                                            :
                                                                            <a href={"http://www.vnpost.vn/vi-vn/dinh-vi/buu-pham?key=" + value.van_don} target="_blank"> {value.van_don} </a>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span >{value.giao_hang}</span> <ReactTooltip/></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{Number(value.da_lay_tien_doi_soat_vnpost).toLocaleString()}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{Number(value.phi_gui_hang).toLocaleString()}</span></div>
                                                            </td>
                                                            <td className="reports-measure">
                                                                <div className="reports-measure-content"><span className="value">{Number(value.phi_hoan_hang).toLocaleString()}</span></div>
                                                            </td>
                                                           
                                                         

                                                        </tr>
                                                    })
                                                    :''
                                                }

                                            </tbody>
                                            {/* <tfoot>
                                                <tr>
                                                    <th className="reports-property is-sortable"><span className="field">TỔNG</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">28.624.523</span></th>
                                                    <th className="reports-measure is-sortable"><span className="field">317</span></th>
                                                </tr>
                                            </tfoot> */}
                                        </table>
                                    </div>
                                </section>

                                <div className="ui-footer-help">
                                    {
                                        this.state.totalItemsCount ?
                                            <Pagination
                                                className="pagination-sm"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                            :
                                            ""
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default DaDoiSoatVnpot;