import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import * as Func from '../../../functions/function.js';
import * as Config from '../../../config.js';

// import '../main_sp.css'; 
// import '../main_report.css?v=1'; 


const axios = require('axios');
var moment = require('moment');

class TopDashboard extends Component {
     constructor(props) {
        super(props);
        this.state = {
          topItems:[],
          bestSaler: [],
          startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        };
    }

    loadData(){
        let token = localStorage.getItem('token');
        axios.get(Config.API_URL + 'analytics/topdashboard?startDate='+this.state.startDate+'&endDate='+this.state.endDate+'&token=' + token)
        .then(rs => {
            this.setState({
                topItems: JSON.parse(JSON.stringify(rs.data.topItems)),
                bestSaler: JSON.parse(JSON.stringify(rs.data.topNv)),
            });
        });
    }
    componentDidMount(){
        this.loadData();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.state.startDate || nextProps.endDate != this.state.endDate){
            this.setState({
                startDate: nextProps.startDate ,
                endDate: nextProps.endDate
            },()=>this.loadData())
        }
    }
    detailProduct(key,item){
        return  (
            <tr className="top_tr_height" key={key}>
                <td>
                    <img className="topvariant_image_variant" src={item.image} />
                    <span className="topvariant_text_variant">{item.name}</span>
                </td>
                <td>
                    <span className="topvariant_total">{Func.FormatNumber(item.tongdoanhso)}₫</span>
                </td>
            </tr>
        )
    }
   
    itemSaler(key,item){
        return  (
        <tr key={key} className="top_tr_height">
            <td>
                <span className="topsale_username">{item.tao_don}</span>
            </td>
            <td>
            <span className="topsale_total">{Func.FormatNumber(item.tongdoanhso)}₫</span>
            </td>
        </tr>
        )
    }

    render (){
        return (
            <div className="section_dasborad_day section" style={{marginTop: '0px'}}>
          <div className="row row_custome">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col_custome padding-left">
              <div className="rows">
                <div className="wrap_bg_left">
                  <div className="header_left header_title">
                    Top sản phẩm kinh doanh
                    <select className="select-condition" id="top-variant" data-bind-event-change="getTopVariant()">
                      <option value="totalsale" selected>Bán chạy nhất</option>
                      <option value="netsale">Lợi nhuận cao nhất</option>
                      <option value="grossprofit">Doanh thu cao nhất</option>
                    </select>
                  </div>
                  <div className="inner_content">
 
                        <div className="item-view-report" id="body_top_variant" style={{height: '330px'}}>
                            <div style={{height: '300px'}}>
                                <table className="sapo-table-line-item">
                                <thead>
                                    <tr>
                                    <th style={{width: '65%', textAlign: 'left'}}>Tên sản phẩm</th>
                                    <th style={{textAlign: 'right'}}>Tổng doanh số(đ)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {this.state.topItems.map( (item,key)=>{
                                     return this.detailProduct(key,item);
                                   },this)}
                                </tbody>
                                </table>
                            </div>
                            <div className="box-detai-report">
                                <Link className="detail-report" style={{display: 'block'}} id="link_top_variant" target="_blank" href="/analyticsnew/revenuebyvariant">Xem chi tiết báo cáo</Link>
                            </div>
                        </div>

                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col_custome no_padding_left">
              <div className="rows">
                <div className="wrap_bg_left">
                  <div className="header_left header_title">
                    Top nhân viên kinh doanh
                    <select className="select-condition" id="top-sales" data-bind-event-change="getTopSales()">
                      <option value="grossprofit" selected>Lợi nhuận cao nhất</option>
                      <option value="netsale">Doanh thu cao nhất</option>
                    </select>
                  </div>

                  <div className="inner_content">
                    <div className="item-view-report" id="body_top_sales" style={{height: '330px'}}>    <div style={{height: '300px'}}>
                        <table className="sapo-table-line-item">
                        <thead>
                            <tr>
                            <th style={{textAlign: 'left'}}>Tên nhân viên</th>
                            <th style={{textAlign: 'right'}}>Tổng doanh số</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.bestSaler.map( (item,key)=>{
                                     return this.itemSaler(key,item);
                            },this)}
                        </tbody>
                        </table>
                    </div>

                    <div className="box-detai-report">
                        <Link className="detail-report" style={{display: 'block'}} to="/analytics/revenuebyaccount">Xem chi tiết báo cáo</Link>
                    </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
    }
}
export default TopDashboard;