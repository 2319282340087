import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { HOME_URL } from '../../config';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: ''
        };
    }
    componentDidMount() {
        this.setState({ token: localStorage.getItem('token') })
    }

    logOut() {
        localStorage.clear();
        window.location.href = "login";
    }

    render() {
        return (
            <header className="ui-app-frame__header">
                <div className="ui-top-bar">
                    <div className="ui-top-bar__branding">
                        <Link to="/">
                            <img src="https://accounts.sapo.vn/images/Sapo-logo.svg" alt="bizweb" title="bizweb" />
                        </Link>
                    </div>
                    <div className="ui-top-bar__list">
                        <div className="ui-top-bar__item ui-top-bar__item--desktop-hidden ui-top-bar-open-sidebar">
                            <div className="ui-app-frame__aside-opener">
                                <button name="button" type="button" className="top-bar-button" aria-controls="AppFrameAside">
                                    <svg className="next-icon next-icon--size-20 next-icon--no-nudge" role="img" aria-labelledby="menu-c4072f7f35f301f1ee17b27ad93ae0e9-title">
                                        <title id="menu-c4072f7f35f301f1ee17b27ad93ae0e9-title">Open navigation</title>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#menu" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="ui-top-bar__item ui-top-bar__item--fill">
                        </div>

                        <div className="ui-top-bar__item ui-top-bar__item--separated ui-top-bar__item--bleed ui-top-bar__item--mobile-hidden ui-top-bar-acc">
                            <div className="ui-popover__container ui-popover__container--full-width-container">
                                <button name="button" type="button" className="top-bar-button top-bar-button--profile" id="AccountMenuActivator" data-tg-refresh="next-nav__avatar" aria-haspopup="true" aria-owns="ui-popover--1" aria-controls="ui-popover--1">
                                    <div className="top-bar-profile">
                                        <div alt={""} className="top-bar-profile__avatar">
                                            <span alt={""} className="user-avatar user-avatar--style-2">
                                                <span className="user-avatar__initials">
                                                    <img className="profile-pic img-circle" data-name={localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')).name : ""} />
                                                </span>
                                                <img alt={""} className="gravatar gravatar--size-thumb" src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" />
                                            </span>
                                        </div>
                                        <div className="top-bar-profile__summary">
                                            <p className="top-bar-profile__title">{localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')).name : ""}</p>
                                            <p className="top-bar-profile__description">
                                                <span>
                                                    <span className="text-aqua bold">{localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')).chuc_danh : ""}</span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </button>
                                <div className="ui-popover margin-10" role="dialog" style={{ maxWidth: 'none', marginRight: 0, marginLeft: 0, transformOrigin: '102px -5px 0px', left: '-25px' }}>
                                    <div className="ui-popover__tooltip" />
                                    <div className="ui-popover__content-wrapper">
                                        <div className="ui-popover__content">
                                            <div className="ui-popover__pane">
                                                <ul className="ui-action-list">


                                                    <li className="ui-action-list__item">
                                                        <Link to="/doi-soat" className="ui-action-list-action" data-allow-default={1}>
                                                            <span className="ui-action-list-action__text">
                                                                <div className="ui-stack ui-stack--wrap ui-stack--alignment-center ui-stack--spacing-tight">
                                                                    <div className="ui-stack-item">
                                                                        <svg className="next-icon next-icon--size-24" role="img" style={{ marginLeft: '-4px', marginRight: '-3px', marginTop: 2 }}>
                                                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#payments-billing" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="ui-stack-item ui-stack-item--fill">
                                                                        <span>Đối soát đơn</span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </Link>
                                                    </li>


                                                    <li className="ui-action-list__item">
                                                        <Link to="/vi-dien-tu" className="ui-action-list-action" data-allow-default={1}>
                                                            <span className="ui-action-list-action__text">
                                                                <div className="ui-stack ui-stack--wrap ui-stack--alignment-center ui-stack--spacing-tight">
                                                                    <div className="ui-stack-item">
                                                                        <svg className="next-icon next-icon--size-24" role="img" style={{ marginLeft: '-4px', marginRight: '-3px', marginTop: 2 }}>
                                                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#payments-billing" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="ui-stack-item ui-stack-item--fill">
                                                                        <span>Ví điện tử</span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li className="ui-action-list__item">
                                                        <a onClick={() => this.logOut()} href="javascript:void()" className="ui-action-list-action" data-no-turbolink="true" data-method="post">
                                                            <span className="ui-action-list-action__text">
                                                                <div className="ui-stack ui-stack--wrap ui-stack--alignment-center ui-stack--spacing-tight">
                                                                    <div className="ui-stack-item">
                                                                        <svg role="img" className="next-icon next-icon--size-16" aria-labelledby="minor-log-out-title"><title id="minor-log-out-title">Log out icon</title> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#minor-log-out" /> </svg>
                                                                    </div>      <div className="ui-stack-item ui-stack-item--fill">
                                                                        <span>Đăng xuất</span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </header>

        );
    }
}

export default Header;