import React, { Component } from 'react';
import { storage } from '../../firebase';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import Dropzone from 'react-dropzone'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import * as Config from '../../config';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
const axios = require('axios');
const qs = require('qs');
const danhMuc = [
    { value: 'san-pham-moi', label: 'Sản phẩm mới' },
    { value: 'san-pham-hot', label: 'Sản phẩm HOT' },
    { value: 'san-pham-ban-chay', label: 'Sản phẩm bán chạy' },
    { value: 'ao', label: 'Áo' },
    { value: 'vay', label: 'Váy' },
    { value: 'quan', label: 'Quần' },
    { value: 'do-mac-nha', label: 'Đồ mặc nhà' },
    { value: 'giay-dep', label: 'Giày dép' },
    { value: 'tui-vi', label: 'Túi ví' },
]
class AddProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mau: [],
            size: [],
            price: '',
            image: '',
            images:[],
            url: '',
            progress: 0,
            name:'',
            danhmuc:'',
            mau_obj: {},
            trong_luong:0
            
        }
    }

    danhMuc = (e) => {
        this.setState({
          danhmuc: e,
        });
      }

  
    handleChange(e) {

        var namekey = e.target.name;

        if (e.target.files[0]) {
            const image = e.target.files[0];
            var d = new Date();
            const uploadTask = storage.ref(`images/` + d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear() + "-" + d.getTime() + `/${image.name}`).put(image);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({ progress });
                },
                (error) => {
                    // error function ....
                    console.log(error);
                },
                () => {
                    // complete function ....
                    storage.ref('images/' + d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear() + "-" + d.getTime()).child(image.name).getDownloadURL().then(url => {
                        // this.setState({url});


                        var list = this.state.list;
                        list.forEach(function (element) {
                            if (element.key === namekey) {
                                element.image = url;
                            }
                        })
                        this.setState({
                            list: list
                        });



                    })
                });


        }

    }

    anhBia(e) {
        var nameimg = e.target.name;

        if (e.target.files[0]) {
            const image = e.target.files[0];
            var d = new Date();
            const uploadTask = storage.ref(`images/` + d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear() + "-" + d.getTime() + `/${image.name}`).put(image);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({ progress });
                },
                (error) => {
                    // error function ....
                    console.log(error);
                },
                () => {
                    storage.ref('images/' + d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear() + "-" + d.getTime()).child(image.name).getDownloadURL().then(url => {
                        this.setState({ [nameimg]: url });
                        let images = this.state.images;
                        // images.push(url);
                        if(nameimg === 'anh_1'){
                            images[0] = url;
                        }else if(nameimg === 'anh_2'){
                            images[1] = url;
                        }else if(nameimg === 'anh_3'){
                            images[2] = url;
                        }else if(nameimg === 'anh_4'){
                            images[3] = url;
                        }else if(nameimg === 'anh_5'){
                            images[4] = url;
                        }
                        
                        this.setState({
                            images: images
                        });
                    })
                });


        }
    }



    anhMau(e) {
        var nameimg = e.target.name;
        

        if (e.target.files[0]) {
            const image = e.target.files[0];
            var d = new Date();
            const uploadTask = storage.ref(`images/` + d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear() + "-" + d.getTime() + `/${image.name}`).put(image);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({ progress });
                },
                (error) => {
                    // error function ....
                    console.log(error);
                },
                () => {
                    storage.ref('images/' + d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear() + "-" + d.getTime()).child(image.name).getDownloadURL().then(url => {


                        var mauArray = this.state.mauArray;
                        mauArray.forEach(function (element) {
                            if (element.key == nameimg) {
                                element.img = url;
                            }
                        })
                        this.setState({
                            mauArray: mauArray, 
                            anhdaidien: mauArray[0].img, // Hiii
                        });

                        ///

                        var list = [];
                        if (mauArray) {
                            mauArray.map((value0, key0) => {

                                if (mauArray && JSON.parse(JSON.stringify(value0)).img) {
                                    var img = JSON.parse(JSON.stringify(value0)).img;
                                } else {
                                    var img = '';
                                }

                                if (mauArray && JSON.parse(JSON.stringify(value0)).name) {
                                    var name = JSON.parse(JSON.stringify(value0)).name;
                                } else {
                                    var name = '';
                                }

                                this.state.size.map((value1, key1) => {
                                    list.push({
                                        itemid: this.state.ma_sp,
                                        key: key0 + "" + key1,
                                        name: this.state.name,
                                        image: img,
                                        color: name,
                                        size: value1,
                                        price: this.state.giaban,
                                        sl: 0
                                    })
                                })
                            })
                        }

                        this.setState({ list: list })

                        ///

                    })
                });


        }
    }



    addMau(mau) {
        this.setState({ mau })
    }
    addSize(size) {
        this.setState({ size })
    }

    inputPrice(event) {
        var name = event.target.name;
        var value = event.target.value;
        var list = this.state.list;
        list.forEach(function (element) {
            if (element.key === name) {
                element.price = value;
            }
        })
        this.setState({
            list: list
        });
    }

    inputSL(event) {
        var name = event.target.name;
        var value = event.target.value;
        var list = this.state.list;
        list.forEach(function (element) {
            if (element.key === name) {
                element.sl = value;
            }
        })
        this.setState({
            list: list
        });
    }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.giaban !== this.state.giaban || prevState.mau !== this.state.mau || prevState.size !== this.state.size || prevState.mauArray!==this.state.mauArray ) {
            var list = [];
            if( this.state.mauArray){
                this.state.mauArray.map((value0, key0) => {

                    if( this.state.mauArray && JSON.parse(JSON.stringify(value0)).img){
                        var img = JSON.parse(JSON.stringify(value0)).img;
                    }else{
                        var img = '';
                    }

                    if(this.state.mauArray && JSON.parse(JSON.stringify(value0)).name){
                        var name = JSON.parse(JSON.stringify(value0)).name;
                    }else{
                        var name = '';
                    }

                    this.state.size.map((value1, key1) => {
                        list.push({ 
                            itemid: this.state.ma_sp,
                            key: key0 + "" + key1,
                            name: this.state.name,
                            image: img, 
                            color: name, 
                            size: value1, 
                            price: this.state.giaban, 
                            sl: 0 
                        })
                    })
                })
            }
            
            this.setState({ list: list })
        }


        if (prevState.mau !== this.state.mau){
            var mau_obj = [];
            this.state.mau.map((value0, key0) => {
                mau_obj.push( {key: key0, name: value0, img: ""} );
            })
            this.setState({ mau_obj:mau_obj })
        }

        if(this.state.mau_obj){
            var mauArray = [];
        if(prevState.mau_obj !== this.state.mau_obj){
            Object.keys( JSON.parse(JSON.stringify(this.state.mau_obj)) ).forEach(key => {
                mauArray.push(JSON.parse(JSON.stringify(this.state.mau_obj))[key])
            })
            this.setState({ mauArray })
        }
        }
    }

    addProduct() {
        let token = localStorage.getItem('token');
        if( this.state.mauArray && this.state.trong_luong > 0){
            axios.post(Config.API_URL + 'addProduct?token=' + token, qs.stringify({

                'itemid': this.state.ma_sp, 
                'name': this.state.name,
                // 'description': this.state.descript,
                // 'categories': JSON.stringify(this.state.danhmuc),
                'image': this.state.anhdaidien,
                // 'images': JSON.stringify(this.state.images),
                'mau_sac': JSON.stringify(this.state.mauArray),
                'kich_thuoc': JSON.stringify(this.state.size),
                'models': JSON.stringify(this.state.list),
                'price': this.state.giaban,
                // 'link_nguon': this.state.link_nguon,
                'trong_luong': this.state.trong_luong,
                // 'stock': "",

            }))
                .then((res) => {
                    if (res.data.success === 'Thành công') {
                        NotificationManager.success('Thêm sản phẩm', '', 2000, false);
                    } else {
                        NotificationManager.error(res.data.success, '', 2000, false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }else{
            NotificationManager.error('Chưa điền đủ thông tin', '', 2000, false);
        }
       
    }



    render() {

        console.log("listlistlistlist "+ JSON.stringify(this.state.list));
        // console.log('mauArray '+ JSON.stringify(this.state.mauArray));
        return (





<div id="content">
<NotificationContainer />
  <div className="modal" data-tg-refresh="modal" id="modal_container" style={{display: 'none'}} aria-hidden="true" aria-labelledby="ModalTitle" tabIndex={-1} />
  <div className="modal-bg" data-tg-refresh="modal" id="modal_backdrop" />
  <div id="products-new" className="product-detail page">
    <div className="aviary-modal-bg"><div className="aviary-modal" /></div>
    <div autoComplete="off" data-context="form">
      <header className="ui-title-bar-container">
        <div className="ui-title-bar">
          <div className="ui-title-bar__navigation">
            <div className="ui-breadcrumbs">
              <Link to="/products" className="ui-button ui-button--transparent ui-breadcrumb">
                <svg className="next-icon next-icon--size-20 next-icon--no-nudge"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-left-thinner" /> </svg>
                <span className="ui-breadcrumb__item">Quay lại danh sách sản phẩm</span>
              </Link>
            </div>
          </div>
          <div className="ui-title-bar__main-group">
            <div className="ui-title-bar__heading-group">
              <span className="ui-title-bar__icon">
                <svg className="next-icon next-icon--color-slate-lighter next-icon--size-20">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-products" />
                </svg>
              </span>
              <h1 className="ui-title-bar__title">
                Thêm mới sản phẩm
              </h1>
            </div>
          </div>
        </div><div className="collapsible-header"><div className="collapsible-header__heading" /></div>
      </header>
      <div className="ui-layout">
        <div className="ui-layout__sections">
          <div className="ui-layout__section ui-layout__section--primary">
                                    <div className="ui-layout__item">
                                        <section className="ui-card" id="product-form-container">
                                            <div className="ui-card__section">
                                                <div className="ui-type-container">

                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="product-name"> Mã sản phẩm </label>
                                                        <input autofocus="autofocus" onChange={(event) => this.isChange(event)} placeholder="Mã sản phẩm" className="next-input" size={30} type="text" name="ma_sp" />
                                                    </div>

                                                    <div className="next-input-wrapper">
                                                        <label className="next-label" htmlFor="product-name"> Tên sản phẩm </label>
                                                        <input autofocus="autofocus" onChange={(event) => this.isChange(event)} placeholder="Nhập tên sản phẩm" className="next-input" size={30} type="text" name="name" />
                                                    </div>
                                                  



                                                </div>
                                            </div>
                                        </section>

              
            </div>
            <div className="ui-layout__item" id="product-outer-variants" data-tg-refresh="product-outer-variants">
              <div data-define="{productVariantOptions: new Bizweb.ProductVariantOptions(this,[{&quot;values&quot;:[],&quot;index&quot;:0,&quot;name&quot;:&quot;Kích thước&quot;,&quot;position&quot;:1,&quot;id&quot;:0}],[],{maxOptions: 3,maxOptionNameLength: 255,optionTitleDelimiter: &quot;<span class=\&quot;type--subdued\&quot;> • </span>&quot;,
                                  maxVariants: 100,
                                  maxOptionLength: 255,
                                  hasFulfillmentServices: false,
                                  test: false,
                                }
                              )}" data-context="productVariantOptions">
                <input type="hidden" name="product[variant][id]" id="product_variant_id" />
                <section className="ui-card">
                  <header className="ui-card__header">
                    <h2 className="ui-heading">Thuộc tính</h2>
                  </header>
                  <div className="ui-card__section">
                    <div className="ui-type-container">
                      <div className="ui-type-container">


                        <div className="next-input-wrapper">
                            <label className="next-label" htmlFor="product-name"> Màu sắc </label>
                            <TagsInput inputProps={{placeholder: 'Màu sắc'}} value={this.state.mau} onChange={(event) => this.addMau(event)} />
                            <div className="row mt-10">
                                        {
                                            this.state.mau.map((valuemau,keymau) => {
                                                return <div key={keymau} className="col-lg-2">
                                                    <Dropzone>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} name={keymau} onChange={(e) => this.anhMau(e)} />
                                                                    <div className="dropify-wrapper" style={{ padding: "0px 0px", borderRadius: '7px' }}>
                                                                        <div className="dropify-message">
                                                                           {
                                                                               this.state.mauArray?

                                                                               this.state.mauArray.map( (valuearr,keyarr)=>{
                                                                                   if(valuearr.key == keymau && valuearr.img){
                                                                                       return <img key={keyarr} src={valuearr.img} height="94px" width="94px" />
                                                                                   }else if(valuearr.key == keymau){
                                                                                    return <div> <p>Ảnh {valuemau}</p></div>
                                                                                   }
                                                                               } )
                                                                               
                                                                               :
                                                                               ""
                                                                           }
                                                                        </div>
                                                                        <div className="dropify-loader" />
                                                                        {/* <input type="file" className="dropify" /> */}
                                                                    </div>

                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            })
                                        }
                                    </div>
                        </div>

                        <div className="next-input-wrapper">
                            <label className="next-label" htmlFor="product-name"> Kích thước </label>
                            <TagsInput inputProps={{placeholder: 'Kích thước'}} value={this.state.size} onChange={(event) => this.addSize(event)} />

                        </div>

                        <div className="next-input-wrapper">
                            <label className="next-label" htmlFor="product-name"> Cập nhật giá bán </label>
                            <input onChange={(event) => this.isChange(event)} placeholder="Giá bán phẩm" className="next-input" type="text" name="giaban" />

                        </div>


                      </div>
                    </div>
                  </div>
                </section>
               
                    {
                                    this.state.mau && this.state.size && this.state.list?
                                    <section className="ui-card">
                                    <header className="ui-card__header"><h2 className="ui-heading">Danh sách phân loại hàng</h2></header>
                                    <div className="ui-card__section">
                                      <div className="ui-type-container">
                                    <div className="form-group">
                                    <div className="table-responsive pt-3">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Ảnh</th>
                                                    <th>Màu</th>
                                                    <th>Size</th>
                                                    <th>Giá bán</th>
                                                    <th>Số lượng tồn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.mauArray && this.state.size ?
                                                        this.state.mauArray.map((value0, key0) => {
                                                            return this.state.size.map((value, key) => {
                                                                return <tr key={key}>
                                                                    <td>
                                                                        <div>

                                                                            <img src={value0.img || 'http://via.placeholder.com/60x60'} alt="Uploaded images" height="60" width="60" />

                                                                        </div>
                                                                    </td>
                                                                    <td>{value0.name}</td>
                                                                    <td>{value}</td>
                                                                    <td>
                                                                        <input name={key0 + "" + key} onChange={(event) => this.inputPrice(event)} type="text" className="form-control" defaultValue={this.state.giaban} />
                                                                    </td>
                                                                    <td>
                                                                        <input name={key0 + "" + key} onChange={(event) => this.inputSL(event)} type="text" className="form-control" defaultValue={0}/>
                                                                    </td>
                                                                </tr>
                                                            })
                                                            
                                                        })
                                                        :
                                                        ""
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </div>
                  </div>
                </section>
                                    :
                                    ""
                                }
                   
                <section className="ui-card">
                  <div className="ui-card__section">
                    <div className="ui-type-container">
                      <div className="ui-stack ui-stack--wrap ui-stack--vertical ui-stack--spacing-tight">
                        <h3 className="ui-subheading">Khối lượng</h3>
                        <p className="type--subdued">
                          Được dùng để tính phí vận chuyển
                        </p>
                        <div className="ui-stack ui-stack--wrap ui-stack--distribution-equal-spacing">
                          <div className="ui-stack-item" style={{maxWidth: 215}}>
                            <div className="next-input-wrapper">
                              <div className="next-field__connected-wrapper">
                                <input onChange={(event) => this.isChange(event)} type="text" name="trong_luong" defaultValue={this.state.trong_luong} className="next-field--connected next-input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
           </div>
          {/* <div className="ui-layout__section ui-layout__section--secondary"> */}
            {/* <div className="ui-layout__item">
              <div className="next-card">
                <header className="next-card__header">
                  <h3 className="ui-heading">Trạng thái</h3>
                </header>
                <section className="next-card__section">
                  <div className="visibility" id="PublishingPanel" data-context="publishingPanel" data-define="{publishingPanel: new Bizweb.PublishingPanel(this,{&quot;showDateTimePicker&quot;: false, &quot;is_published&quot;:true})}">
                    <div className="ui-form__section">
                      <div className="ui-form__element">
                        <fieldset className="ui-choice-list"> 
                          <ul>
                            <li>
                              <div className="next-input-wrapper">
                                <label className="next-label next-label--switch" htmlFor="active-true">
                                  Hiển thị
                                </label>
                                <input type="radio" name="product[active]" id="active-true" defaultValue="true" data-bind-checked="isAlreadyPublished()" bind-event-click="setPublishedTrue()" className="next-radio" defaultChecked="&quot;checked&quot;" />
                                <span className="next-radio--styled" />
                              </div>
                            </li>
                            <li>
                              <div className="next-input-wrapper">
                                <label className="next-label next-label--switch" htmlFor="active-false">
                                  Ẩn
                                </label>
                                <input type="radio" name="product[active]" id="active-false" defaultValue="false" data-bind-checked="!isAlreadyPublished()" bind-event-click="setPublishedFalse()" className="next-radio" />
                                <span className="next-radio--styled" />
                              </div>
                            </li>
                          </ul>
                        </fieldset>
                      </div>
                      <div className="ui-form__element" data-bind-show="showSetLink()">
                        <button className="ui-button btn--link" data-bind-event-click="toggleUserInputDateOptions()" type="button" name="button">Đặt lịch hiển thị</button>
                      </div>
                    </div>
                    <div data-bind-show="showDateTimePicker" className="ui-form__section hide">
                      <div className="ui-form__element">
                        <div className="next-input-wrapper">
                          <label className="next-label">Thời gian hiển thị</label>
                          <div className="ui-stack ui-stack--alignment-center">
                            <div className="ui-stack-item ui-stack-item--fill">
                              <div className="next-field__connected-wrapper">
                                <input data-rel="datepicker-now" name="product[date]" id="Date" type="text" className="next-field--connected next-input js-no-dirty" placeholder="Chọn ngày" />
                                <input data-rel="timepicker" name="product[time]" id="Time" data-template="dropdown" data-show-meridian="true" data-minute-step={10} type="text" className="next-field--connected next-input js-no-dirty" placeholder="Chọn giờ" autoComplete="off" />
                              </div>
                            </div>
                            <div className="ui-stack-item">
                              <button className="ui-button btn--link" data-bind-event-click="cancelUserInputDate()" type="button" name="button"><svg className="next-icon next-icon--size-12"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-remove" /> </svg></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div> */}
            {/* <div className="ui-layout__item">
              <section className="ui-card ui-card--type-aside">
                <header className="ui-card__header"><h2 className="ui-heading">Phân loại</h2></header>
                <div className="ui-card__section">
                  <div className="ui-type-container">
                    <label htmlFor="collections" className="next-label">Link nhập</label>
                    <div id="js-collections" data-tg-refresh="js-collections" data-define="{productCollections: new Bizweb.ProductCollectionDisplay(this)}" data-context="productCollections">
                      <div className="ui-popover__container ui-popover__container--full-width-container" data-context="remoteMultiSelectAutocomplete" data-define="{ 'remoteMultiSelectAutocomplete': new Bizweb.AutocompleteV2.RemoteMultiSelectAutocomplete(this, &quot;\/admin\/custom_collections\/dropdown&quot;, $node, &quot;results&quot;, {&quot;values&quot;:[],&quot;position&quot;:&quot;top&quot;}) }">
                        <div className="next-input-wrapper">
                            <div className="next-input--stylized">
                                <span className="next-input__add-on next-input__add-on--before">
                                    <svg className="next-icon next-icon--color-slate-lightest next-icon--size-16"> <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-search-reverse" /> </svg>
                                </span>
                                <input onChange={(event) => this.isChange(event)} name="link_nguon" id="link_nguon" className="next-input next-input--invisible next-input js-no-dirty next-input--invisible" placeholder="Link gốc" size={30} type="text" />
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 </section>
            </div> */}
          {/* </div> */}
        </div>
      </div>
      <div className="ui-page-actions">
        <div className="ui-page-actions__container">
          <div className="ui-page-actions__actions ui-page-actions__actions--primary">
            <div className="ui-page-actions__button-group">
              {/* <a className="btn" data-allow-default={1} href="/admin/products">Hủy</a> */}
              <button name="button" type="submit" className="btn js-btn-primary js-btn-loadable btn-primary has-loading" onClick={ ()=>this.addProduct()}>
                Thêm ngay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>







        )
    }
}

export default AddProduct;